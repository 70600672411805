import * as DateFns from "date-fns";
import hu from "date-fns/locale/hu";
import { ObjectUtils } from "./ObjectUtils";

export enum DateFormat {
    yyyymmdd = "yyyy.MM.dd",
    dateTime = "yyyy. MM. dd. HH:mm:ss",
    dateTimeDashed = "yyyy-MM-dd HH:mm:ss",
    default = "yyyy-MM-dd",
    yyyymmddhhmm = "yyyy. MM. dd. HH:mm",
    yyyymmddhhmmNoSpace = "yyyy.MM.dd.HH:mm",
    calendar = "yyyy MMMM",
    mmdd = "MM.dd",
}

enum TimeUnit {
    second = "second",
    minute = "minute",
    hour = "hour",
    day = "day",
}

export interface Time {
    hour: number;
    minute: number;
}

class DateUtils {
    public static readonly MS_TO_SEC_DIVIDER = 1000;
    public static readonly MS_TO_MIN_DIVIDER = DateUtils.MS_TO_SEC_DIVIDER * 60;
    public static readonly MS_TO_HOUR_DIVIDER = DateUtils.MS_TO_MIN_DIVIDER * 60;
    public static readonly MS_TO_DAY_DIVIDER = DateUtils.MS_TO_HOUR_DIVIDER * 24;

    public static readonly SEC_TO_MIN_DIVIDER = 60;
    public static readonly SEC_TO_HOUR_DIVIDER = DateUtils.SEC_TO_MIN_DIVIDER * 60;
    public static readonly SEC_TO_DAY_DIVIDER = DateUtils.SEC_TO_HOUR_DIVIDER * 24;

    /**
     * Parse string to Date
     * @param dateString string
     * @param dateFormat DateFormat
     */
    public static parse(dateString: string, dateFormat: DateFormat = DateFormat.default): Date {
        return DateFns.parse(dateString, dateFormat, new Date());
    }

    private static formatDate(date: Date, format: DateFormat): string {
        return DateFns.format(date, format, { locale: hu });
    }

    public static format(date: string, format: DateFormat): string;
    public static format(date: Date, format: DateFormat): string;

    /**
     * Format Date to string
     * @param date Date | string
     * @param format DateFormat
     */
    public static format(date: Date | string, format: DateFormat): string {
        if (date instanceof Date) {
            return DateUtils.formatDate(date, format);
        }
        return DateUtils.formatDate(DateUtils.parse(date), format);
    }

    /**
     * Calculate age by dateOfBirth
     * @param dateOfBirth Date
     */
    public static getAge(dateOfBirth: Date): number {
        const ageDiffInMs = new Date().getTime() - dateOfBirth.getTime();
        const ageDate = new Date(ageDiffInMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    /**
     * Subtract years from a date
     * @param date Date
     * @param yearsToSubtract number
     */
    public static subYears(date: Date, yearsToSubtract: number): Date {
        return DateFns.subYears(date, yearsToSubtract);
    }

    /**
     * Add days to a date
     * @param date Date
     * @param daysToAdd number
     */
    public static addDays(date: Date, daysToAdd: number): Date {
        return DateFns.addDays(date, daysToAdd);
    }

    /**
     * Add months to a date
     * @param date Date
     * @param monthsToAdd number
     */
    public static addMonth(date: Date, monthsToAdd: number): Date {
        return DateFns.addMonths(date, monthsToAdd);
    }

    /**
     * Subtract months from a date
     * @param date Date
     * @param monthsToSubtract number
     */
    public static subMonth(date: Date, monthsToSubtract: number): Date {
        return DateFns.subMonths(date, monthsToSubtract);
    }

    /**
     * Convert Value in timeUnit to seconds
     * @param value number
     * @param timeUnit TimeUnit
     */
    public static toSeconds(value: number, timeUnit: TimeUnit): number {
        let date: Date = new Date(0);
        switch (timeUnit) {
            case TimeUnit.minute:
                date = DateFns.addMinutes(new Date(0), value);
                break;
            case TimeUnit.hour:
                date = DateFns.addHours(new Date(0), value);
                break;
            case TimeUnit.day:
                date = DateFns.addDays(new Date(0), value);
                break;
            case TimeUnit.second:
            default:
                date = DateFns.addSeconds(new Date(0), value);
        }

        return date.getTime() / DateUtils.MS_TO_SEC_DIVIDER;
    }

    /**
     * Convert seconds to the given TimeUnit
     * @param seconds number
     * @param timeUnit TimeUnit
     */
    public static secondsTo(seconds: number | null, timeUnit: TimeUnit): number | null {
        if (seconds === null) {
            return null;
        }

        let divider = 1;
        switch (timeUnit) {
            case TimeUnit.minute:
                divider = DateUtils.SEC_TO_MIN_DIVIDER;
                break;
            case TimeUnit.hour: {
                divider = DateUtils.SEC_TO_HOUR_DIVIDER;
                break;
            }
            case TimeUnit.day: {
                divider = DateUtils.SEC_TO_DAY_DIVIDER;
                break;
            }
            case TimeUnit.second:
            default:
        }
        return seconds / divider;
    }

    /**
     * Get given date first day of month
     * @param date Date
     */
    public static startOfMonth(date: Date): Date {
        return DateFns.startOfMonth(date);
    }

    /**
     * Get given date last day of month
     * @param date Date
     */
    public static endOfMonth(date: Date): Date {
        return DateFns.endOfMonth(date);
    }

    /**
     * Check if two date is the same day
     * @param date Date
     * @param date2 Date
     */
    public static isSameDay(date: Date, date2: Date): boolean {
        const d1: Date = DateFns.startOfDay(date);
        const d2: Date = DateFns.startOfDay(date2);
        return DateFns.isSameDay(d1, d2);
    }

    /**
     * Get interval for Calendar
     * start: (first day of month) start of week
     * end: (last day of month) end of week
     * @param date Date
     */
    public static getCalendarIntervals(date: Date): { start: Date; end: Date } {
        const start: Date = DateFns.subDays(DateFns.startOfWeek(DateUtils.startOfMonth(date), { weekStartsOn: 1 }), 7);
        const end: Date = DateFns.addDays(DateFns.endOfWeek(DateUtils.endOfMonth(date), { weekStartsOn: 1 }), 7);
        return { start, end };
    }

    public static getNearestTimeUnit(seconds: number, units: TimeUnit[] = ObjectUtils.enumAsArray<TimeUnit>(TimeUnit)): TimeUnit {
        if (seconds === 0 && units.includes(TimeUnit.second)) {
            return TimeUnit.second;
        }

        if (seconds % DateUtils.SEC_TO_DAY_DIVIDER === 0 && units.includes(TimeUnit.day)) {
            return TimeUnit.day;
        }

        if (seconds % DateUtils.SEC_TO_HOUR_DIVIDER === 0 && units.includes(TimeUnit.hour)) {
            return TimeUnit.hour;
        }

        if (seconds % DateUtils.SEC_TO_MIN_DIVIDER === 0 && units.includes(TimeUnit.minute)) {
            return TimeUnit.minute;
        }

        return TimeUnit.second;
    }

    /**
     * Convert time to Time object
     * @param timeString hh:mm
     */
    public static getTime(timeString: string | null): Time {
        if (!timeString) {
            return { minute: 0, hour: 0 };
        }
        const parsedValue: string[] = timeString.split(":");

        return {
            hour: Number.parseInt(parsedValue[0], 10) || 0,
            minute: Number.parseInt(parsedValue[1], 10) || 0,
        };
    }

    public static isValid(date: any): boolean {
        return DateFns.isValid(date);
    }

    /**
     * Turns the given date's hours and minutes to the last quarter. E. g. 21:38 becomes 21:30, 07:50 becomes 07:45, etc.
     */
    public static lastQuarterMinutes(date: Date): Date {
        const minutes: number = DateFns.getMinutes(date);
        if (minutes > 0 && minutes < 15) {
            return DateFns.setMinutes(date, 0);
        } else if (minutes > 15 && minutes < 30) {
            return DateFns.setMinutes(date, 15);
        } else if (minutes > 30 && minutes < 45) {
            return DateFns.setMinutes(date, 30);
        } else {
            return DateFns.setMinutes(date, 45);
        }
    }

    public static toTimeFormat(date: Date): string {
        return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    }
}

export { DateUtils, TimeUnit };
