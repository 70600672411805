import { ContentPageUtils } from "./ContentPageUtils";

enum ValidatorMessage {
    invalidPassword = "invalidPassword",
    passwordsNotMatch = "passwordsNotMatch",
    required = "required",
    invalidEmail = "invalidEmail",
    invalidYouTubeURL = "invalidYouTubeURL",
}

class ValidatorConstants {
    public static readonly EMAIL_REGEX: RegExp = /(?:[a-z0-9!#$%&'+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    public static readonly MIN_PASSWORD_LENGTH: number = 8;
    public static readonly MAX_CONTENT_TITLE_LENGTH: number = 120;
    public static readonly MAX_CONTENT_FRONT_PAGE_LEAD_LENGTH: number = 130;
    public static readonly MAX_GALLERY_CAPTION_LENGTH: number = 300;
    public static readonly ACCEPTED_DOCUMENT_MIME_TYPES: string = "application/pdf";
}

class Validator {
    /**
     * Check password not empty and at least 8 chars length
     * @param password password
     */
    public static validatePassword(password: string): ValidatorMessage | null {
        if (!password) {
            return ValidatorMessage.invalidPassword;
        }

        return null;
    }

    /**
     * Check passwords equality
     * @param password password
     * @param passwordRepeat passwordRepeat
     */
    public static validatePasswordRepeat(password: string, passwordRepeat: string): ValidatorMessage | null {
        if (password !== passwordRepeat) {
            return ValidatorMessage.passwordsNotMatch;
        }
        return this.validatePassword(password);
    }

    /**
     * Validate given string is non null or empty
     * @param value string | null
     */
    public static validateNonEmpty(value: string | null): ValidatorMessage | null {
        if (value === "" || value === null) {
            return ValidatorMessage.required;
        }
        return null;
    }

    /**
     * Validate email by regex
     * @param email email
     */
    public static validateEmail(email: string): ValidatorMessage | null {
        if (!email.trim() || !ValidatorConstants.EMAIL_REGEX.test(email)) {
            return ValidatorMessage.invalidEmail;
        }
        return null;
    }

    /**
     * Check if YouTube video ID can be parsed from an URL.
     * If it can't be parsed, it's invalid.
     * @param url full YouTube video URl
     */
    public static validateYouTubeURL(url: string): ValidatorMessage | null {
        if (ContentPageUtils.getYouTubeID(url) === null) {
            return ValidatorMessage.invalidYouTubeURL;
        }

        return null;
    }
}

export { Validator, ValidatorConstants, ValidatorMessage };
