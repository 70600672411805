import gql from "graphql-tag";
import { DocumentNode } from "graphql";

class Fragments {
    public static readonly admin: DocumentNode = gql`
        fragment Admin on Admin {
            id
            name
            email
            is_active
            created_at
            updated_at
            granted_permissions
        }
    `;

    public static readonly adminAuthenticationResult: DocumentNode = gql`
        fragment AdminAuthenticationResult on AdminAuthenticationResult {
            token
            admin {
                ...Admin
            }
        }
        ${Fragments.admin}
    `;

    public static readonly paginatorInfo: DocumentNode = gql`
        fragment PaginatorInfo on PaginatorInfo {
            count
            currentPage
            firstItem
            hasMorePages
            lastItem
            lastPage
            perPage
            total
        }
    `;

    public static readonly round: DocumentNode = gql`
        fragment Round on Round {
            id
            number
            #date_from
            #date_to
        }
    `;

    public static readonly season: DocumentNode = gql`
        fragment Season on Season {
            id
            name
            rounds {
                ...Round
            }
        }
        ${Fragments.round}
    `;

    public static readonly decade: DocumentNode = gql`
        fragment Decade on Decade {
            id
            years
            url
            title
            lead
            lead_image
            content
            meta_title
            meta_keywords
            meta_description
            content_title
            front_page_title
            front_page_lead
            front_page_image
            created_at
            updated_at
        }
    `;

    public static readonly category: DocumentNode = gql`
        fragment Category on Category {
            id
            url
            title
            meta_title
            meta_keywords
            meta_description
            created_at
            updated_at
        }
    `;

    public static readonly teamLogo: DocumentNode = gql`
        fragment TeamLogo on TeamLogo {
            thumbnail
            match_page
            full
        }
    `;

    public static readonly team: DocumentNode = gql`
        fragment Team on Team {
            id
            name
            logo {
                ...TeamLogo
            }
        }
        ${Fragments.teamLogo}
    `;

    public static readonly galleryImage: DocumentNode = gql`
        fragment GalleryImage on GalleryImage {
            url
            caption
            credit
        }
    `;

    public static readonly gallery: DocumentNode = gql`
        fragment Gallery on Gallery {
            id
            title
            image_count
            images {
                ...GalleryImage
            }
            created_at
            updated_at
        }
        ${Fragments.galleryImage}
    `;

    public static readonly textContentListItem: DocumentNode = gql`
        fragment TextContentListItem on TextContent {
            id
            url
            title
            # TODO: paraméterek?
            updated_at
            is_active
        }
    `;

    public static readonly galleryContentListItem: DocumentNode = gql`
        fragment GalleryContentListItem on GalleryContent {
            id
            url
            title
            # TODO: paraméterek?
            updated_at
            is_active
        }
    `;

    public static readonly videoContentListItem: DocumentNode = gql`
        fragment VideoContentListItem on VideoContent {
            id
            url
            title
            # TODO: paraméterek?
            updated_at
            is_active
        }
    `;

    public static readonly anyContentListItem: DocumentNode = gql`
        fragment AnyContentListItem on Content {
            ... on TextContent {
                ...TextContentListItem
            }
            ... on GalleryContent {
                ...GalleryContentListItem
            }
            ... on VideoContent {
                ...VideoContentListItem
            }
        }
        ${Fragments.textContentListItem}
        ${Fragments.galleryContentListItem}
        ${Fragments.videoContentListItem}
    `;

    public static readonly textContent: DocumentNode = gql`
        fragment TextContent on TextContent {
            id
            url
            title
            front_page_title
            front_page_lead
            front_page_image
            lead
            lead_image
            content
            meta_title
            meta_keywords
            meta_description
            meta_image
            author
            is_active
            active_from
            active_to
            updated_at
            last_published_at
            decade {
                ...Decade
            }
            category {
                ...Category
            }
            rounds {
                ...Round
            }
            teams {
                ...Team
            }
        }
        ${Fragments.decade}
        ${Fragments.category}
        ${Fragments.round}
        ${Fragments.team}
    `;

    public static readonly galleryContent: DocumentNode = gql`
        fragment GalleryContent on GalleryContent {
            id
            url
            title
            front_page_title
            front_page_lead
            front_page_image
            lead
            lead_image
            content
            meta_title
            meta_keywords
            meta_description
            meta_image
            author
            is_active
            active_from
            active_to
            updated_at
            last_published_at
            decade {
                ...Decade
            }
            category {
                ...Category
            }
            rounds {
                ...Round
            }
            teams {
                ...Team
            }
            gallery {
                ...Gallery
            }
        }
        ${Fragments.decade}
        ${Fragments.category}
        ${Fragments.round}
        ${Fragments.team}
        ${Fragments.gallery}
    `;

    public static readonly videoContent: DocumentNode = gql`
        fragment VideoContent on VideoContent {
            id
            url
            title
            front_page_title
            front_page_lead
            front_page_image
            lead
            lead_image
            content
            meta_title
            meta_keywords
            meta_description
            meta_image
            author
            is_active
            active_from
            active_to
            updated_at
            last_published_at
            decade {
                ...Decade
            }
            category {
                ...Category
            }
            rounds {
                ...Round
            }
            teams {
                ...Team
            }
            video_url
        }
        ${Fragments.decade}
        ${Fragments.category}
        ${Fragments.round}
        ${Fragments.team}
    `;

    public static readonly anyContent: DocumentNode = gql`
        fragment AnyContent on Content {
            ... on TextContent {
                ...TextContent
            }
            ... on GalleryContent {
                ...GalleryContent
            }
            ... on VideoContent {
                ...VideoContent
            }
        }
        ${Fragments.textContent}
        ${Fragments.galleryContent}
        ${Fragments.videoContent}
    `;

    public static readonly referee: DocumentNode = gql`
        fragment Referee on Referee {
            jnnev
            jntip
        }
    `;

    public static readonly player: DocumentNode = gql`
        fragment Player on Player {
            jnkod
            jnnev
            kezdcsere
            mez
        }
    `;

    public static readonly staffMember: DocumentNode = gql`
        fragment StaffMember on StaffMember {
            nev
            kispad_jelleg
            hazaivendeg
        }
    `;

    public static readonly replacement: DocumentNode = gql`
        fragment Replacement on Replacement {
            csereido
            fnull
            irany
            hazaivendeg
            jatekos {
                ...Player
            }
        }
        ${Fragments.player}
    `;

    public static readonly goal: DocumentNode = gql`
        fragment Goal on Goal {
            ido
            fnull
            gol
            ongol
            hazaivendeg
            jatekos {
                ...Player
            }
        }
        ${Fragments.player}
    `;

    public static readonly card: DocumentNode = gql`
        fragment Card on Card {
            hazaivendeg
            jatekos {
                ...Player
            }
            lap
            ido
        }
        ${Fragments.player}
    `;

    public static readonly match: DocumentNode = gql`
        fragment Match on Match {
            id
            status
            home_team_name
            home_team_goals
            guest_team_name
            guest_team_goals
            ticket_info
            played_at
            contents {
                ...AnyContentListItem
            }
        }
        ${Fragments.anyContentListItem}
    `;

    public static readonly information: DocumentNode = gql`
        fragment Information on Information {
            id
            url
            title
            front_page_title
            front_page_lead
            front_page_position
            lead
            lead_image
            front_page_image
            meta_image
            content
            meta_title
            meta_keywords
            meta_description
            author
            is_active
            active_from
            active_to
            created_at
            updated_at
            last_published_at
        }
    `;

    public static readonly document: DocumentNode = gql`
        fragment Document on Document {
            id
            url
            file_name
            created_at
            updated_at
        }
    `;
}

export { Fragments };
