import { applyMiddleware, createStore } from "redux";
import { batchDispatchMiddleware } from "redux-batched-actions";
import reducers, { initialRootReducerState } from "reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/es/storage/session";

const middlewares = [thunk, batchDispatchMiddleware];

const persistConfig = {
    key: "root",
    storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(persistedReducer, initialRootReducerState, process.env.NODE_ENV === "development" ? composeWithDevTools(applyMiddleware(...middlewares)) : applyMiddleware(...middlewares));

const persistor = persistStore(store);

export { store, persistor };
