import React from "react";
import { Permission } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { InputWrapper } from "components/Inputs/InputWrapper";
import { Input } from "components/Inputs/Input";
import { TableWrapper } from "components/TableElements";
import { ObjectUtils } from "utils/ObjectUtils";
import { Checkbox } from "components/Inputs/Checkbox";
import { CaptionText } from "theme/global";
import { FieldArray, FormikProps, Field, FieldProps } from "formik";
import { Validator } from "utils/Validator";
import { IntlHelpers } from "i18n/IntlHelpers";
import { cleanupFormikErrors } from "utils/misc";
import { Main } from "components/cms/MainElements";
import { Button } from "components/Button";
import styled from "styled-components";

export type FormValues = {
    id?: string;
    name: string;
    email: string;
    permissions: Permission[];
    isActive?: boolean;
};

type Props<T> = {
    formProps: FormikProps<T>;
    onBackClick: () => void;
    formMode: "create" | "edit";
    isLoading: boolean;
    onAdminPasswordReset?: (email: string) => Promise<void>;
};

export const UserValidator = (values: FormValues) => {
    const errors: { [key in keyof FormValues]?: string } = {};
    errors.name = IntlHelpers.getValidationError(Validator.validateNonEmpty(values.name)) || undefined;
    errors.email = IntlHelpers.getValidationError(Validator.validateEmail(values.email)) || undefined;
    return cleanupFormikErrors<FormValues>(errors);
};

class UserForm extends React.Component<Props<FormValues>> {
    public render(): React.ReactElement {
        const props = this.props.formProps;
        return (
            <form onSubmit={props.handleSubmit} noValidate>
                <Main.Heading headingText={Intl.formatMessage({ id: `pages.users.${this.props.formMode}.title` })} backButtonClick={this.props.onBackClick}>
                    <Button btnLabel={Intl.formatMessage({ id: "common.save" })} type="submit" disabled={this.props.isLoading} />
                </Main.Heading>

                <TableWrapper>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell auto">
                            <Field name="name">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.users.form.name.name" })} errorMsg={meta.error}>
                                        <Input type="text" ariaLabel={Intl.formatMessage({ id: "pages.users.form.name.name" })} {...field} />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                        <div className="cell auto">
                            <Field name="email">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.users.form.email.name" })} errorMsg={meta.error}>
                                        <Input type="text" ariaLabel={Intl.formatMessage({ id: "pages.users.form.email.name" })} {...field} />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                    </div>

                    {this.props.formMode === "edit" && (
                        <div className="grid-x grid-margin-x-15">
                            <div className="cell auto">
                                <Field name="isActive">
                                    {({ field }: FieldProps) => {
                                        return <Checkbox checkboxLabel={Intl.formatMessage({ id: "pages.users.form.isActive.name" })} {...field} defaultChecked={field.value} />;
                                    }}
                                </Field>
                            </div>
                            <div className="cell auto">
                                <ResetPassword>
                                    <CaptionText as="h2" thStyle={true}>
                                        {Intl.formatMessage({ id: "pages.users.form.passwordReset.name" })}:
                                    </CaptionText>
                                    <Button
                                        btnLabel={Intl.formatMessage({ id: "pages.users.form.passwordReset.button" })}
                                        onClick={() => (this.props.onAdminPasswordReset ? this.props.onAdminPasswordReset(props.values.email) : null)}
                                    />
                                </ResetPassword>
                            </div>
                        </div>
                    )}

                    <CaptionText as="h2" thStyle={true}>
                        {Intl.formatMessage({ id: "pages.users.form.permissions.name" })}
                    </CaptionText>
                    <FieldArray
                        name="permissions"
                        render={arrayHelpers => {
                            return ObjectUtils.enumAsArray<Permission>(Permission).map((permission, index) => (
                                <Field name={`permissions[${permission}]`} key={index}>
                                    {({ field }: FieldProps) => {
                                        return (
                                            <Checkbox
                                                checkboxLabel={Intl.formatMessage({ id: `enum.permissions.${permission}` })}
                                                {...field}
                                                checked={props.values.permissions.includes(permission)}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    if (e.target.checked) arrayHelpers.push(permission);
                                                    else {
                                                        const idx = props.values.permissions.indexOf(permission);
                                                        arrayHelpers.remove(idx);
                                                    }
                                                }}
                                            />
                                        );
                                    }}
                                </Field>
                            ));
                        }}
                    />
                </TableWrapper>
            </form>
        );
    }
}

const ResetPassword = styled.div`
    display: flex;
    align-items: center;
    h2 {
        margin: unset;
    }
    button {
        margin-left: 1rem;
    }
`;

export { UserForm };
