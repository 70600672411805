import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { PageType } from "utils/TypeUtils";
import { isNil } from "lodash";
import { ContentForm } from "./ContentForm";
import { Api } from "api/Api";
import { ContentInput, ContentTypePagePath, ContentTypeListPath, ContentTypename } from "api/ApiTypes";
import { Alert } from "components/cms/Alert/Alert";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Category, ContentType, CreateContentInput, Decade, Season, UpdateContentInput, AnyContent, Team } from "api/graphql/types";
import { Log } from "utils/Log";
import LoadingOverlay from "components/LoadingOverlay";
import { Redirect } from "react-router-dom";
import { AppPath, Path } from "utils/Path";
import { Formik, FormikHelpers } from "formik";
import { Validator } from "utils/Validator";
import { cleanupFormikErrors } from "utils/misc";
import { ApiError } from "api/ApiError";
import { GraphQLClient } from "api/graphql/GraphQLClient";
import { DateFormat, DateUtils } from "utils/DateUtils";

interface RouteParams {
    contentType?: string; // for create
    contentUrl?: string; // for edit
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ComponentProps;

export type ContentPageExtraInfo = {
    id: string | null;
    type: string | null;
    updatedAt: any | null;
    lastPublishedAt: any | null;
};

interface State {
    content: ContentInput;
    extraInfo: ContentPageExtraInfo;
    categories: Category[];
    decades: Decade[];
    seasons: Season[];
    selectedTeams?: Team[];
    isLoading: boolean;
}

class ContentPageComponent extends Component<Props, State> {
    private static getInitialStateFromProps = (): State => {
        return {
            content: {
                type: ContentType.TEXT,
                url: "",
                title: "",
                front_page_title: "",
                front_page_lead: "",
                front_page_image: "",
                lead: "",
                lead_image: "",
                content: "",
                meta_title: "",
                meta_keywords: "",
                meta_description: "",
                meta_image: "",
                author: "",
                is_active: false,
                active_from: null,
                active_to: null,
            },
            extraInfo: {
                id: null,
                type: null,
                updatedAt: null,
                lastPublishedAt: null,
            },
            categories: [],
            decades: [],
            seasons: [],
            isLoading: false,
        };
    };

    public readonly state: State = ContentPageComponent.getInitialStateFromProps();

    public componentDidMount(): void {
        this.fetchAdditionalFields();
        this.refreshContent(this.props);
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.match.params.contentUrl !== this.props.match.params.contentUrl) {
            this.refreshContent(this.props);
        }
    }

    private fetchAdditionalFields = (): void => {
        this.setState({ isLoading: true }, async () => {
            try {
                const categories = await Api.listCategories();
                const seasons = await Api.getSeasonsWithRounds();
                const decades = await Api.listDecades({ count: 50 });
                this.setState({
                    isLoading: false,
                    categories,
                    seasons,
                    decades: decades.data,
                });
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.setState({
                    isLoading: false,
                });
            }
        });
    };

    private refreshContent = (props: Props) => {
        const { contentUrl } = props.match.params;
        if (!contentUrl) {
            this.setState(ContentPageComponent.getInitialStateFromProps());
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const content: AnyContent | null = await Api.getContentByUrl(contentUrl);

                    this.setState({
                        isLoading: false,
                        content: content ? this.getContentInput(content) : { ...this.state.content },
                        extraInfo: { ...this.state.extraInfo, id: content?.id || null, type: content?.__typename || null, updatedAt: content?.updated_at, lastPublishedAt: content?.last_published_at },
                        selectedTeams: content?.teams || [],
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({
                        isLoading: false,
                    });
                }
            },
        );
    };

    private getTypeFromTypename = (typename: string): ContentType => {
        switch (typename) {
            case ContentTypename.TextContent:
                return ContentType.TEXT;
            case ContentTypename.GalleryContent:
                return ContentType.GALLERY;
            case ContentTypename.VideoContent:
                return ContentType.VIDEO;
            default:
                Log.warning(`(getTypeFromTypename) Wrong __typename ${typename}`);
                return ContentType.TEXT;
        }
    };

    private getPathTypeFromTypename = (__typename: string | null): string => {
        switch (__typename) {
            case ContentTypename.TextContent:
                return ContentTypePagePath.text;
            case ContentTypename.GalleryContent:
                return ContentTypePagePath.gallery;
            case ContentTypename.VideoContent:
                return ContentTypePagePath.video;
            default:
                Log.warning(`(getPathTypeFromTypename) Wrong __typename ${__typename}`);
                return ContentTypePagePath.text;
        }
    };

    private getContentInput = (content: AnyContent): ContentInput => {
        return {
            type: this.getTypeFromTypename(content.__typename),
            url: content.url,
            title: content.title,
            front_page_title: content.front_page_title,
            front_page_lead: content.front_page_lead,
            front_page_image: content.front_page_image,
            gallery_id: content.__typename === ContentTypename.GalleryContent ? content.gallery?.id : undefined,
            lead: content.lead,
            lead_image: content.lead_image,
            content: content.content,
            meta_title: content.meta_title,
            meta_keywords: content.meta_keywords,
            meta_description: content.meta_description,
            meta_image: content.meta_image,
            author: content.author,
            is_active: content.is_active,
            active_from: content.active_from ? new Date(content.active_from) : this.state.content.active_from,
            active_to: content.active_to ? new Date(content.active_to) : this.state.content.active_to,
            decade_id: content.decade?.id,
            category_id: content.category?.id,
            rounds: { sync: content.rounds.map(round => round.id) },
            teams: { sync: content.teams.map(round => round.id) },
            video_url: content.__typename === "VideoContent" ? content.video_url : undefined,
        };
    };

    /**
     * If the embedded <iframe> is within <figure> tags, the editor doesn't show it, so we replace it with <div>s.
     * @param content source content possibly containing <figure>
     */
    private replaceFigure = (content: string): string => {
        let newContent: string = content;
        newContent = newContent.replace(/<figure class="media"><div data-oembed-url/g, "<div data-oembed-url");
        newContent = newContent.replace(/<\/iframe><\/div><\/div><\/figure>/g, "</iframe></div></div>");
        return newContent;
    };

    private getCreateInput = (content: CreateContentInput | UpdateContentInput): CreateContentInput => {
        return {
            ...content,
            content: content.content ? this.replaceFigure(content.content) : content.content,
            type: this.getTypeFromPath() as ContentType,
            url: "url" in content ? content.url : "",
            title: content.title || "",
            is_active: content.is_active || false,
            active_from: content.active_from ? DateUtils.format(content.active_from, DateFormat.dateTimeDashed) : null,
            active_to: content.active_to ? DateUtils.format(content.active_to, DateFormat.dateTimeDashed) : null,
            decade_id: content.decade_id,
            category_id: content.category_id,
            rounds: content.rounds,
            video_url: content.video_url,
        };
    };

    private getUpdateInput = (content: CreateContentInput | UpdateContentInput): UpdateContentInput => {
        return {
            type: content.type,
            title: content.title,
            front_page_title: content.front_page_title,
            front_page_lead: content.front_page_lead,
            front_page_image: content.front_page_image,
            gallery_id: content.gallery_id,
            lead: content.lead,
            lead_image: content.lead_image,
            content: content.content ? this.replaceFigure(content.content) : content.content,
            meta_title: content.meta_title,
            meta_keywords: content.meta_keywords,
            meta_description: content.meta_description,
            meta_image: content.meta_image,
            author: content.author,
            is_active: content.is_active,
            active_from: content.active_from ? DateUtils.format(content.active_from, DateFormat.dateTimeDashed) : null,
            active_to: content.active_to ? DateUtils.format(content.active_to, DateFormat.dateTimeDashed) : null,
            decade_id: content.decade_id,
            category_id: content.category_id,
            rounds: content.rounds,
            teams: content.teams,
            video_url: content.video_url,
        };
    };

    private getTypeFromPath = (): ContentType | string => {
        switch (this.props.match.params.contentType) {
            case ContentTypeListPath.text:
                return ContentType.TEXT;
            case ContentTypeListPath.gallery:
                return ContentType.GALLERY;
            case ContentTypeListPath.video:
                return ContentType.VIDEO;
            case ContentTypeListPath.all:
            default:
                Log.warning(`Wrong path type: ${this.props.match.params.contentType}`);
                return "";
        }
    };

    private getPageType = (): PageType => {
        return AppPath.getPageType(this.props.match.path);
    };

    private onSubmit = async (content: ContentInput, formikHelpers: FormikHelpers<ContentInput>): Promise<void> => {
        try {
            if (this.getPageType() === PageType.create) {
                const result = await Api.createContent(this.getCreateInput(content));
                if (result) {
                    Alert.success({ title: Intl.formatMessage({ id: "pages.contents.page.create.success" }) });
                    this.props.history.push(Path.editContent(result.url));
                }
            } else if (this.getPageType() === PageType.edit && !isNil(this.state.extraInfo.id)) {
                const result = await Api.updateContent(this.state.extraInfo.id, this.getUpdateInput(content));
                if (result) {
                    Alert.success({ title: Intl.formatMessage({ id: "pages.contents.page.edit.success" }) });
                    if (this.props.match.params.contentUrl) {
                        this.props.history.push(Path.editContent(this.props.match.params.contentUrl));
                    }
                }
            } else {
                throw new Error("View cannot submit!");
            }
        } catch (error) {
            if (error instanceof ApiError) {
                if (error.validation?.input) {
                    const errors = GraphQLClient.parseValidationErrors<ContentInput>(error.validation.input);
                    formikHelpers.setErrors({
                        title: errors?.title,
                        active_from: errors?.active_from,
                        active_to: errors?.active_to,
                        front_page_title: errors?.front_page_title,
                        front_page_lead: errors?.front_page_lead,
                        author: errors?.author,
                        meta_title: errors?.meta_title,
                        meta_description: errors?.meta_description,
                        lead: errors?.lead,
                        content: errors?.content,
                    });
                }
            }
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private formValidator = (values: ContentInput) => {
        const errors: { [key in keyof ContentInput]?: string } = {};
        errors.title = IntlHelpers.getValidationError(Validator.validateNonEmpty(values.title || "")) || undefined;
        return cleanupFormikErrors<ContentInput>(errors);
    };

    public render(): React.ReactElement {
        const { content } = this.state;
        if (this.state.isLoading) {
            return <LoadingOverlay />;
        }

        const isInvalidCreateProps: boolean =
            this.getPageType() === PageType.create && (isNil(this.props.match.params.contentType) || !Object.values(ContentTypePagePath).includes(this.props.match.params.contentType));

        if (isInvalidCreateProps) {
            Log.warning(`pageType is create, but wrong content type given in URL: ${this.props.match.params.contentType}`);
            return <Redirect to={Path.contentList(ContentTypeListPath.all)} />;
        }

        return (
            <Formik initialValues={content} validate={this.formValidator} validateOnBlur={true} validateOnChange={false} onSubmit={this.onSubmit}>
                {props => (
                    <ContentForm
                        formProps={props}
                        pageType={this.getPageType()}
                        contentType={this.props.match.params.contentType || this.getPathTypeFromTypename(this.state.extraInfo.type) || ""}
                        extraInfo={this.state.extraInfo}
                        categories={this.state.categories}
                        decades={this.state.decades}
                        seasons={this.state.seasons}
                        selectedTeams={this.state.selectedTeams}
                    />
                )}
            </Formik>
        );
    }
}

const ContentPage: React.ComponentClass<Props, State> = ContentPageComponent;

export { ContentPage };
