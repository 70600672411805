import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { hexToRGB, CaptionText } from "theme/global";
import { SVGIconProfile, SVGLogoMOLKupaWhite } from "components/SVGCollection";
import { connect } from "react-redux";
import { getDeploymentEnvironmentText } from "utils/Constants";
import { Intl } from "i18n/Intl";
import * as GqlTypes from "api/graphql/types";
import { ApplicationState } from "reducers";
import { isNil } from "lodash";
import { Path } from "utils/Path";

interface ReduxProps {
    admin: GqlTypes.Admin | null;
}

const AppBar: React.FC<ReduxProps> = ({ admin }) => {
    const envText = getDeploymentEnvironmentText();
    return (
        <AppBarWrapper>
            <AppBarFixedContainer className="grid-x align-middle">
                <div className="cell auto" title="Home">
                    <Link to="/">
                        <span className="grid-x align-bottom">
                            <span className="cell shrink" aria-hidden="true">
                                <SVGLogoMOLKupaWhite focusable="false" />
                            </span>

                            <CaptionCell className="cell auto">
                                <CaptionText as="span">
                                    <div style={{ position: "relative" }}>
                                        CMS
                                        {envText && <DeploymentEnvText>{envText}</DeploymentEnvText>}
                                    </div>
                                </CaptionText>
                            </CaptionCell>
                        </span>
                    </Link>
                </div>

                <div className="cell shrink">
                    <div className="grid-x align-middle">
                        {admin && (
                            <>
                                <IconProfileCell className="cell shrink" aria-hidden="true">
                                    <SVGIconProfile focusable="false" />
                                </IconProfileCell>

                                <CaptionTextNameCell className="cell auto">
                                    <span className="show-for-sr">Logged in as:</span> {admin.name}
                                </CaptionTextNameCell>
                            </>
                        )}

                        <LogoutWrapperCell className="cell shrink">
                            {!isNil(admin) ? (
                                <Link to={Path.logout}>
                                    <CaptionText as="span">{Intl.formatMessage({ id: "common.logout" })}</CaptionText>
                                </Link>
                            ) : (
                                <Link to={Path.login}>
                                    <CaptionText as="span">{Intl.formatMessage({ id: "common.login" })}</CaptionText>
                                </Link>
                            )}
                        </LogoutWrapperCell>
                    </div>
                </div>
            </AppBarFixedContainer>
        </AppBarWrapper>
    );
};

const AppBarWrapper = styled.div`
    height: ${props => props.theme.box.appBar.height}px;
`;

const AppBarFixedContainer = styled.div`
    background-color: ${props => props.theme.background.appBar.backgroundColor};
    color: ${props => props.theme.typo.appBar.color};
    height: inherit;
    left: 0;
    padding-left: ${props => props.theme.box.appBar.paddingLeft}px;
    /* Note: props.theme.box.appBar.paddingRight - paddingRight of ButtonWrapperCell's button */
    padding-right: ${props => props.theme.box.appBar.paddingRight - 18}px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1050;

    a {
        color: inherit;
        display: inline-block;
    }
`;

const CaptionCell = styled.span`
    padding-left: 5px;

    ${CaptionText} {
        display: inline-block;
        transform: translateY(1px);
    }
`;

const IconProfileCell = styled.div`
    padding-right: 16px;
`;

const CaptionTextNameCell = styled(CaptionText)`
    padding-right: 20px;
`;

const DeploymentEnvText = styled.div`
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    position: absolute;
    white-space: nowrap;
    bottom: -18px;
    left: 0;
`;

const LogoutWrapperCell = styled.div`
    border-left: 1px solid rgba(${props => hexToRGB(props.theme.typo.appBar.color)}, 0.3);

    a {
        padding: 6px 18px;
    }
`;

export default connect(
    ({ auth: { admin } }: ApplicationState): ReduxProps => ({
        admin,
    }),
)(AppBar);
