import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { Route, RouteProps, withRouter, RouteComponentProps } from "react-router-dom";
import { Admin, Permission } from "api/graphql/types";
import RedirectToLogin from "routes/RedirectToLogin";
import { ApplicationState } from "reducers";
import { PermissionUtils } from "utils/PermissionUtils";
import { Alert } from "components/cms/Alert/Alert";
import { Intl } from "i18n/Intl";
import { Path } from "utils/Path";
import { isNil } from "lodash";
import { AuthSelectors } from "selectors/AuthSelectors";

interface ReduxProps {
    isLoggedIn: boolean;
    admin: Admin | null;
}

type ComponentProps = {
    permission?: Permission;
};

type Props = ComponentProps & ReduxProps & RouteProps & RouteComponentProps;

class PrivateRoute extends React.Component<Props> {
    private readonly redirectIfNoPermission = () => {
        if (this.props.admin && !isNil(this.props.permission) && !PermissionUtils.hasPermission(this.props.admin, this.props.permission)) {
            Alert.error({ title: Intl.formatMessage({ id: "error.noPermission" }) });
            this.props.history.push(Path.dashboard);
        }
    };

    public render(): React.ReactElement {
        this.redirectIfNoPermission();
        if (!this.props.isLoggedIn) {
            return <Route {...this.props} component={RedirectToLogin} />;
        }
        return <Route component={this.props.component} render={this.props.render} {...this.props} />;
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        isLoggedIn: AuthSelectors.isLoggedIn(state),
        admin: state.auth.admin,
    };
};

export default withRouter(connect(mapStateToProps)(PrivateRoute));
