import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

interface Props {
    ariaId: string;
    disabled?: boolean;
    isOpened: boolean;
}

export const FoldableFC: React.FC<React.PropsWithChildren<Props>> = (props: React.PropsWithChildren<Props>) => {
    const [height, setHeight] = useState(0);
    const [folding, setFolding] = useState(false);
    const foldableRef = useRef<HTMLDivElement>(null);
    let foldingTimeout: number | null = null;

    const calculateHeight = () => {
        const calculatedHeight = (foldableRef.current && foldableRef.current.scrollHeight) || 0;
        setHeight(calculatedHeight);
    };

    // TODO: Itt a breakpoint hooktól függővé tenni
    /*
    useEffect(() => {
        window.addEventListener('resize', calculateHeight);
        return () => {
            window.removeEventListener('resize', calculateHeight);
        };
    }, [props.isOpened]);*/

    const handleOpenChange = () => {
        setFolding(true);

        foldingTimeout = window.setTimeout(() => {
            foldingTimeout = null;
            setFolding(false);
        }, 250);
    };

    useEffect(() => {
        calculateHeight();
        handleOpenChange();
        return () => {
            if (foldingTimeout !== null) {
                clearTimeout(foldingTimeout);
            }
        };
    }, [props.isOpened, handleOpenChange, foldingTimeout]);

    /*
    useEffect(() => {
        if (foldingTimeout === null && folding === false) {
            calculateHeight();
        }
    }, [props.children]);*/

    const { ariaId, children, disabled, isOpened, ...otherProps } = props;

    return (
        <Presentation id={`${ariaId}-Group`} role="presentation" {...otherProps}>
            <Region id={`${ariaId}-Region`} role="region" aria-hidden={!isOpened} aria-labelledby={`${ariaId}-Heading`} disabled={disabled}>
                <FoldableElement
                    style={{
                        height: isOpened ? height : 0,
                        overflow: "hidden",
                    }}
                >
                    <div ref={foldableRef} style={!folding && isOpened ? { overflow: "visible" } : {}}>
                        {children}
                    </div>
                </FoldableElement>
            </Region>
        </Presentation>
    );
};

const Presentation = styled.div``;

const Region = styled.div<{ disabled?: boolean }>`
    opacity: ${props => (props.disabled ? ".15" : "")};
    pointer-events: ${props => (props.disabled ? "none" : "")};
`;

const FoldableElement = styled.div`
    overflow: hidden;
    transition: height 250ms ease;
    will-change: height;

    > div {
        overflow: hidden;
    }
`;
