import * as _ from "lodash";
import React, { Component, ChangeEvent } from "react";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Intl } from "i18n/Intl";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { Main } from "components/cms/MainElements";
import { Path } from "utils/Path";
import { SVGContentGallery, SVGContentText, SVGContentVideo, SVGIconAdd, SVGIconErrorClearRound, SVGIconSuccessTickCircle } from "components/SVGCollection";
import { Column, Table } from "components/Tables/Table";
import { ObjectUtils } from "utils/ObjectUtils";
import { Api } from "api/Api";
import { ContentTypeListPath, ContentTypename, ContentTypePagePath } from "api/ApiTypes";
import { AnyContentListItem, ContentField, ContentType, getContentsListItemsVariables, OrderDirection } from "api/graphql/types";
import { isEqual, isNil } from "lodash";
import { Alert } from "components/cms/Alert/Alert";
import { Log } from "utils/Log";
import { Button } from "components/Button";
import styled from "styled-components";
import { Color } from "theme/theme";
import { hexToRGB } from "theme/global";
import { Search } from "../../components/Inputs/Search";
import { TableWrapper } from "../../components/TableElements";

enum ContentListTableColumn {
    __typename = "__typename",
    title = "title",
    id = "id",
    updated_at = "updated_at",
    is_active = "is_active",
}

interface State {
    options: getContentsListItemsVariables;
    contents: AnyContentListItem[];
    total: number;
    pageCount: number;
    isLoading: boolean;
    isCreateDropdownOpened: boolean;
    search: string;
}

interface RouteParams {
    contentType?: string;
}

interface ComponentProps {}

type Props = ComponentProps & RouteComponentProps<RouteParams>;

class ContentListComponent extends Component<Props, State> {
    public readonly state: State = {
        options: {
            count: Table.DEFAULT_PAGE_SIZE,
            page: 1,
        },
        contents: [],
        total: 0,
        pageCount: 0,
        isLoading: true,
        isCreateDropdownOpened: false,
        search: "",
    };

    public componentDidMount(): void {
        this.refreshContents(this.state.options);
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (!isEqual(prevProps.match.params.contentType, this.props.match.params.contentType)) {
            this.onSearchClear();
        }
        if (!isEqual(prevState.options, this.state.options)) {
            this.refreshContents(this.state.options);
        }
    }

    private refreshContents = (options: getContentsListItemsVariables): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { data, paginatorInfo } = await Api.getContents({ ...options, filters: { ...this.state.options.filters, type: this.getContentTypeFromPath() } });

                    this.setState({
                        contents: data,
                        total: paginatorInfo.total,
                        pageCount: paginatorInfo.lastPage,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ contents: [], isLoading: false });
                }
            },
        );
    };

    private getContentTypeFromPath = (): ContentType | undefined => {
        switch (this.props.match.params.contentType) {
            case ContentTypeListPath.text:
                return ContentType.TEXT;
            case ContentTypeListPath.gallery:
                return ContentType.GALLERY;
            case ContentTypeListPath.video:
                return ContentType.VIDEO;
            case ContentTypeListPath.all:
            default:
                return undefined;
        }
    };

    private getTypeIcon = (typename: string): React.ReactElement => {
        switch (typename) {
            case ContentTypename.TextContent:
            case ContentTypePagePath.text:
                return <SVGContentText width={30} height={30} focusable="false" />;
            case ContentTypename.GalleryContent:
            case ContentTypePagePath.gallery:
                return <SVGContentGallery width={30} height={30} focusable="false" />;
            case ContentTypename.VideoContent:
            case ContentTypePagePath.video:
                return <SVGContentVideo width={30} height={30} focusable="false" />;
            default:
                return <></>;
        }
    };

    private getColumns = () => {
        if (this.props.match.params.contentType !== ContentTypeListPath.all) {
            return ObjectUtils.enumAsArray<ContentListTableColumn>(ContentListTableColumn).filter((column: ContentListTableColumn) => {
                return column !== ContentListTableColumn.__typename;
            });
        }
        return ObjectUtils.enumAsArray<ContentListTableColumn>(ContentListTableColumn);
    };

    private readonly columns = (): Array<Column<AnyContentListItem>> => {
        return this.getColumns().map(
            (columnName: ContentListTableColumn): Column<AnyContentListItem> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `pages.contents.list.table.${columnName}` }),
                accessor: columnName as keyof AnyContentListItem,
                renderCell: (content: AnyContentListItem): React.ReactElement | null => {
                    switch (columnName) {
                        case ContentListTableColumn.__typename:
                            return this.getTypeIcon(content[columnName]);
                        case ContentListTableColumn.is_active:
                            return <>{content.is_active ? <SVGIconSuccessTickCircle /> : <SVGIconErrorClearRound />}</>;
                        case ContentListTableColumn.title:
                        case ContentListTableColumn.id:
                            return <>{content[columnName]}</>;
                        case ContentListTableColumn.updated_at:
                            return <>{new Date(content[columnName]).toLocaleString("hu")}</>;
                        default:
                            return null;
                    }
                },
                isNonSortable: [ContentListTableColumn.__typename, ContentListTableColumn.is_active].includes(columnName),
            }),
        );
    };

    private convertSortFieldToColumnId = (columnId?: ContentField | null): keyof AnyContentListItem | undefined => {
        switch (columnId) {
            case ContentField.TITLE:
                return ContentListTableColumn.title;
            case ContentField.UPDATED_AT:
                return ContentListTableColumn.updated_at;
            default:
                return undefined;
        }
    };

    private convertColumnIdToSortField = (columnId?: string): ContentField | undefined => {
        switch (columnId) {
            case ContentListTableColumn.title:
                return ContentField.TITLE;
            case ContentListTableColumn.updated_at:
                return ContentField.UPDATED_AT;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<AnyContentListItem>, order?: OrderDirection): void => {
        const sortField = this.convertColumnIdToSortField(column?.id || undefined);
        this.setState({
            options: {
                ...this.state.options,
                sortBy: !isNil(sortField)
                    ? {
                          field: sortField,
                          direction: order || OrderDirection.ASC,
                      }
                    : undefined,
            },
        });
    };

    private onPageChange = (pageNum: number): void => {
        this.setState({ options: { ...this.state.options, page: pageNum } });
    };

    private readonly onFilterChange = _.debounce(() => {
        const { search, options } = this.state;
        const isId = /^\d+$/.test(search);
        this.setState(
            {
                options: {
                    ...options,
                    page: 1,
                    filters: {
                        title: !isId ? search : undefined,
                        id: isId ? search : undefined,
                    },
                },
            },
            () => this.refreshContents(this.state.options),
        );
    }, 500);

    private readonly onSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
        this.setState(
            {
                search: e.currentTarget.value,
            },
            () => this.onFilterChange(),
        );
    };

    private readonly onSearchClear = (): void => {
        this.setState(
            {
                search: "",
            },
            () => this.onFilterChange(),
        );
    };

    public render(): React.ReactElement {
        if (isNil(this.props.match.params.contentType) || !Object.values(ContentTypeListPath).includes(this.props.match.params.contentType)) {
            Log.warning("Wrong contentType argument: ", this.props.match.params.contentType);
            return <Redirect to={Path.contentList(ContentTypeListPath.all)} />;
        }

        return (
            <>
                <Main.Heading headingText={Intl.formatMessage({ id: `pages.contents.list.title.${this.props.match.params.contentType}` })}>
                    {this.props.match.params.contentType === ContentTypeListPath.all ? (
                        <>
                            {this.state.isCreateDropdownOpened && (
                                <LanguageselectorOverlay type="button" onClick={() => this.setState({ isCreateDropdownOpened: !this.state.isCreateDropdownOpened })} />
                            )}

                            <LanguageSelectorWrapper>
                                <StyledLanguageselectorToggleButton type="button" onClick={() => this.setState({ isCreateDropdownOpened: !this.state.isCreateDropdownOpened })}>
                                    <span className="grid-x align-middle">
                                        <span className="cell shrink" aria-hidden="true">
                                            <SVGIconAdd focusable="false" />
                                        </span>
                                        <span className="cell auto">{Intl.formatMessage({ id: "pages.contents.list.createDropdown.default" })}</span>
                                    </span>
                                </StyledLanguageselectorToggleButton>

                                {this.state.isCreateDropdownOpened && (
                                    <LanguageSelectorDropdown>
                                        {[ContentTypePagePath.text, ContentTypePagePath.gallery, ContentTypePagePath.video].map((path: string) => {
                                            return (
                                                <LanguageSelectorDropdownButton key={path} type="button" onClick={() => this.props.history.push(Path.createContent(path))}>
                                                    <span className="grid-x align-middle">
                                                        <span className="cell shrink" aria-hidden="true">
                                                            {this.getTypeIcon(path)}
                                                        </span>

                                                        <span className="cell auto">{Intl.formatMessage({ id: `pages.contents.list.createDropdown.${path}` })}</span>
                                                    </span>
                                                </LanguageSelectorDropdownButton>
                                            );
                                        })}
                                    </LanguageSelectorDropdown>
                                )}
                            </LanguageSelectorWrapper>
                        </>
                    ) : (
                        <Button
                            btnLabel={Intl.formatMessage({ id: `pages.contents.list.createContentButton.${this.props.match.params.contentType}` })}
                            renderIcon={<SVGIconAdd focusable="false" />}
                            onClick={() => {
                                this.props.history.push(Path.createContent(this.props.match.params.contentType!));
                            }}
                        />
                    )}
                </Main.Heading>
                <TableWrapper>
                    <div className="grid-x justify-center">
                        <div className="cell small-6">
                            <Search ariaLabel={Intl.formatMessage({ id: "pages.contents.list.search" })} value={this.state.search} onChange={this.onSearchChange} onClearClick={this.onSearchClear} />
                        </div>
                    </div>
                    <Table
                        keyExtractor={(item: AnyContentListItem, column?: Column<AnyContentListItem>): string => {
                            return `${item.id}_${column ? column.id : ""}`;
                        }}
                        columns={this.columns()}
                        sortBy={{
                            columnId: this.convertSortFieldToColumnId(this.state.options.sortBy?.field),
                            order: this.state.options.sortBy?.direction || undefined,
                        }}
                        data={this.state.contents}
                        count={this.state.total}
                        pageCount={this.state.pageCount}
                        currentPage={this.state.options.page || 1}
                        isSortable={true}
                        onSortOrderChange={this.onSortOrderChange}
                        onPageChange={this.onPageChange}
                        isPaginationEnabled={this.state.total > Table.DEFAULT_PAGE_SIZE}
                        isLoading={this.state.isLoading}
                        onRowClick={(content: AnyContentListItem): void => {
                            this.props.history.push(Path.editContent(content.url));
                        }}
                        renderEmpty={() => ""}
                    />
                </TableWrapper>
            </>
        );
    }
}

export const ContentList = withRouter(ContentListComponent);

// tslint:disable-next-line: no-default-export
export default ContentList;

const LanguageSelectorWrapper = styled.div`
    position: relative;
    z-index: 1052;
`;

const LanguageselectorToggleButton = styled.button`
    font-size: 14px;
    line-height: 17px;
    padding: 6px 21px 6px 15px;

    > .grid-x {
        .cell {
            &:first-of-type {
                padding-right: 5px;

                svg {
                    border-radius: 3px;
                }
            }

            & + .cell {
                padding-left: 10px;
            }
        }
    }
`;

const StyledLanguageselectorToggleButton = styled(LanguageselectorToggleButton)`
    display: inline-block;
    vertical-align: bottom;
    height: 48px;
    padding-left: 14px;
    padding-right: 14px;
    background-color: ${props => props.theme.background.button.primary.backgroundColor};
    border: 1px solid ${props => props.theme.background.button.primary.backgroundColor};
    border-radius: 24px;
    font-size: 16px;
    text-transform: uppercase;
    user-select: none;

    &:hover {
        background-color: ${props => props.theme.background.button.primary.hover};
    }
`;

const LanguageSelectorDropdown = styled.div`
    background-color: ${Color.white};
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(${hexToRGB(Color.secondaryXD)}, 0.2);
    margin-top: 10px;
    position: absolute;
    right: 0;
    top: 100%;
    width: 175px;
    z-index: 1;

    &::before {
        background-color: ${Color.white};
        border-radius: 2px;
        content: "";
        height: 12px;
        pointer-events: none;
        position: absolute;
        right: 21px;
        top: -5px;
        transform-origin: center;
        transform: rotate(-45deg);
        width: 12px;
    }
`;

const LanguageSelectorDropdownButton = styled.button`
    color: ${Color.secondaryXD};
    display: block;
    font-size: 14px;
    line-height: 17px;
    padding: 15px;
    text-align: left;
    width: 100%;

    .shrink {
        padding-right: 15px;
    }

    & + & {
        border-top: 1px solid ${Color.grayD};
    }

    &:active,
    &:hover {
        font-weight: 700;
    }
`;

const LanguageselectorOverlay = styled.button`
    bottom: 0;
    cursor: default;
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1051;
`;
