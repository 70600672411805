import React from "react";
import { Intl } from "i18n/Intl";
import { Main } from "components/cms/MainElements";
import { InputWrapper } from "components/Inputs/InputWrapper";
import { Input } from "components/Inputs/Input";
import { TableWrapper } from "components/TableElements";
import { CaptionText } from "theme/global";
import { InputCDN } from "components/cms/InputCDN";
import { Button } from "components/Button";
import { AnyContentListItem } from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "components/cms/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ContentModal } from "pages/common/ContentModal";
import { HIGHLIGHTS_NUMBER } from "utils/Constants";

interface State {
    isLoading: boolean;
    contents: AnyContentListItem[];
    contentModal: {
        mounted: boolean;
        key: number | null;
        selectedContent: AnyContentListItem | null;
    };
}

class HighlightsPage extends React.Component {
    public state: State = {
        isLoading: true,
        contents: [],
        contentModal: {
            mounted: false,
            key: null,
            selectedContent: null,
        },
    };

    public componentDidMount(): void {
        this.fetchContents();
    }

    private readonly fetchContents = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const contents = await Api.getFrontPageTopContents();

                    this.setState({
                        contents,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private readonly openContentModal = (content: AnyContentListItem | null, key: number): void => {
        this.setState({
            contentModal: {
                mounted: true,
                key,
                selectedContent: content,
            },
        });
    };

    private readonly closeContentModal = (): void => {
        this.setState({
            contentModal: {
                mounted: false,
                key: null,
                selectedContent: null,
            },
        });
    };

    private readonly onContentSelected = (contents: AnyContentListItem[]): void => {
        const currentContents = this.state.contents;
        currentContents[this.state.contentModal.key!] = contents[0];
        this.setState({
            contents: currentContents,
        });
    };

    private readonly getIdsFromContents = (): string[] => {
        return this.state.contents.map((content: AnyContentListItem) => content.id);
    };

    private readonly onPublish = async (): Promise<void> => {
        if (this.state.contents.length !== 5) {
            Alert.error({ title: Intl.formatMessage({ id: "pages.highlights.messages.publishValidation" }) });
            return;
        }
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const response = await Api.updateFrontPageTopSection({
                        contentIds: this.getIdsFromContents(),
                    });
                    this.setState({
                        contents: response,
                        isLoading: false,
                    });
                    Alert.success({ title: Intl.formatMessage({ id: "pages.highlights.messages.publishSuccess" }) });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private readonly getContentByKey = (key: number): AnyContentListItem | null => {
        const { contents } = this.state;
        if (contents[key]) {
            return contents[key];
        }
        return null;
    };

    private readonly renderInputs = (): React.ReactElement => {
        return (
            <>
                {Array.from(Array(HIGHLIGHTS_NUMBER).keys()).map((key: number) => {
                    const content = this.getContentByKey(key);
                    return (
                        <div className="grid-x grid-margin-x-15 mt-0" key={key}>
                            <div className="cell auto">
                                <CaptionText as="h2" thStyle={true}>
                                    {Intl.formatMessage({ id: "pages.highlights.form.content.label" }, { position: key + 1 })}
                                </CaptionText>

                                <InputCDN browseButton={<Button btnLabel={Intl.formatMessage({ id: "pages.highlights.addContent" })} onClick={() => this.openContentModal(content, key)} />}>
                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.highlights.form.content.name" })} key={key}>
                                        <Input ariaLabel={Intl.formatMessage({ id: "pages.highlights.form.content.name" })} value={content?.url || ""} />
                                    </InputWrapper>
                                </InputCDN>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    public render(): React.ReactElement {
        const { contentModal } = this.state;
        return (
            <>
                <Main.Heading headingText={Intl.formatMessage({ id: "pages.highlights.title" })}>
                    <Button btnLabel={Intl.formatMessage({ id: "common.publish" })} onClick={this.onPublish} disabled={this.state.isLoading} />
                </Main.Heading>
                <ContentModal
                    mounted={contentModal.mounted}
                    onContentSelected={this.onContentSelected}
                    onModalClose={this.closeContentModal}
                    selectedContents={contentModal.selectedContent || undefined}
                    single={true}
                />
                <TableWrapper>{this.renderInputs()}</TableWrapper>
            </>
        );
    }
}

export { HighlightsPage };
