/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminLogin
// ====================================================

export interface adminLogin_adminLogin_admin {
  __typename: "Admin";
  id: string;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  granted_permissions: Permission[];
}

export interface adminLogin_adminLogin {
  __typename: "AdminAuthenticationResult";
  token: string;
  admin: adminLogin_adminLogin_admin;
}

export interface adminLogin {
  adminLogin: adminLogin_adminLogin;
}

export interface adminLoginVariables {
  credentials: AdminLoginDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminLogout
// ====================================================

export interface adminLogout {
  adminLogout: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetAdminPassword
// ====================================================

export interface resetAdminPassword_resetAdminPassword_admin {
  __typename: "Admin";
  id: string;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  granted_permissions: Permission[];
}

export interface resetAdminPassword_resetAdminPassword {
  __typename: "AdminAuthenticationResult";
  token: string;
  admin: resetAdminPassword_resetAdminPassword_admin;
}

export interface resetAdminPassword {
  resetAdminPassword: resetAdminPassword_resetAdminPassword;
}

export interface resetAdminPasswordVariables {
  input: SetAdminPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminPasswordReset
// ====================================================

export interface adminPasswordReset {
  adminPasswordReset: boolean;
}

export interface adminPasswordResetVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAdmin
// ====================================================

export interface createAdmin_createAdmin {
  __typename: "Admin";
  id: string;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  granted_permissions: Permission[];
}

export interface createAdmin {
  createAdmin: createAdmin_createAdmin;
}

export interface createAdminVariables {
  createData: CreateAdminDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAdmin
// ====================================================

export interface updateAdmin_updateAdmin {
  __typename: "Admin";
  id: string;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  granted_permissions: Permission[];
}

export interface updateAdmin {
  updateAdmin: updateAdmin_updateAdmin;
}

export interface updateAdminVariables {
  input: UpdateAdminDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createContent
// ====================================================

export interface createContent_createContent_TextContent {
  __typename: "TextContent";
  id: string;
  url: string;
}

export interface createContent_createContent_GalleryContent {
  __typename: "GalleryContent";
  id: string;
  url: string;
}

export interface createContent_createContent_VideoContent {
  __typename: "VideoContent";
  id: string;
  url: string;
}

export type createContent_createContent = createContent_createContent_TextContent | createContent_createContent_GalleryContent | createContent_createContent_VideoContent;

export interface createContent {
  createContent: createContent_createContent;
}

export interface createContentVariables {
  input: CreateContentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateContent
// ====================================================

export interface updateContent_updateContent_TextContent {
  __typename: "TextContent";
  id: string;
}

export interface updateContent_updateContent_GalleryContent {
  __typename: "GalleryContent";
  id: string;
}

export interface updateContent_updateContent_VideoContent {
  __typename: "VideoContent";
  id: string;
}

export type updateContent_updateContent = updateContent_updateContent_TextContent | updateContent_updateContent_GalleryContent | updateContent_updateContent_VideoContent;

export interface updateContent {
  updateContent: updateContent_updateContent;
}

export interface updateContentVariables {
  id: string;
  input: UpdateContentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateMatch
// ====================================================

export interface updateMatch_updateMatch_contents_TextContent {
  __typename: "TextContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export interface updateMatch_updateMatch_contents_GalleryContent {
  __typename: "GalleryContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export interface updateMatch_updateMatch_contents_VideoContent {
  __typename: "VideoContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export type updateMatch_updateMatch_contents = updateMatch_updateMatch_contents_TextContent | updateMatch_updateMatch_contents_GalleryContent | updateMatch_updateMatch_contents_VideoContent;

export interface updateMatch_updateMatch {
  __typename: "Match";
  id: string;
  status: MatchStatus | null;
  home_team_name: string | null;
  /**
   *  Végeredmény: hazai csapat góljainak száma büntetőpárbaj nélkül 
   */
  home_team_goals: number | null;
  guest_team_name: string | null;
  /**
   *  Végeredmény: vendég csapat góljainak száma büntetőpárbaj nélkül 
   */
  guest_team_goals: number | null;
  ticket_info: string | null;
  /**
   *  A mérkőzés ideje 
   */
  played_at: any | null;
  /**
   *  Az összes kapcsolódó cikket csak admin kérheti le 
   */
  contents: updateMatch_updateMatch_contents[];
}

export interface updateMatch {
  updateMatch: updateMatch_updateMatch;
}

export interface updateMatchVariables {
  id: string;
  input: UpdateMatchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateFrontPageTopSection
// ====================================================

export interface updateFrontPageTopSection_updateFrontPageTopSection_TextContent {
  __typename: "TextContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export interface updateFrontPageTopSection_updateFrontPageTopSection_GalleryContent {
  __typename: "GalleryContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export interface updateFrontPageTopSection_updateFrontPageTopSection_VideoContent {
  __typename: "VideoContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export type updateFrontPageTopSection_updateFrontPageTopSection = updateFrontPageTopSection_updateFrontPageTopSection_TextContent | updateFrontPageTopSection_updateFrontPageTopSection_GalleryContent | updateFrontPageTopSection_updateFrontPageTopSection_VideoContent;

export interface updateFrontPageTopSection {
  updateFrontPageTopSection: updateFrontPageTopSection_updateFrontPageTopSection[];
}

export interface updateFrontPageTopSectionVariables {
  contentIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDecade
// ====================================================

export interface updateDecade_updateDecade {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface updateDecade {
  updateDecade: updateDecade_updateDecade;
}

export interface updateDecadeVariables {
  id: string;
  input: UpdateDecadeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createGallery
// ====================================================

export interface createGallery_createGallery_images {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

export interface createGallery_createGallery {
  __typename: "Gallery";
  id: string;
  title: string;
  image_count: number;
  images: createGallery_createGallery_images[];
  created_at: any;
  updated_at: any | null;
}

export interface createGallery {
  createGallery: createGallery_createGallery;
}

export interface createGalleryVariables {
  input: CreateGalleryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateGallery
// ====================================================

export interface updateGallery_updateGallery_images {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

export interface updateGallery_updateGallery {
  __typename: "Gallery";
  id: string;
  title: string;
  image_count: number;
  images: updateGallery_updateGallery_images[];
  created_at: any;
  updated_at: any | null;
}

export interface updateGallery {
  updateGallery: updateGallery_updateGallery;
}

export interface updateGalleryVariables {
  id: string;
  input: UpdateGalleryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createInformation
// ====================================================

export interface createInformation_createInformation {
  __typename: "Information";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_position: number | null;
  lead: string | null;
  lead_image: string | null;
  front_page_image: string | null;
  meta_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  updated_at: any | null;
  last_published_at: any | null;
}

export interface createInformation {
  createInformation: createInformation_createInformation;
}

export interface createInformationVariables {
  input: CreateInformationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateInformation
// ====================================================

export interface updateInformation_updateInformation {
  __typename: "Information";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_position: number | null;
  lead: string | null;
  lead_image: string | null;
  front_page_image: string | null;
  meta_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  updated_at: any | null;
  last_published_at: any | null;
}

export interface updateInformation {
  updateInformation: updateInformation_updateInformation;
}

export interface updateInformationVariables {
  id: string;
  input: UpdateInformationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadDocument
// ====================================================

export interface uploadDocument_uploadDocument {
  __typename: "Document";
  id: string;
  url: string;
  file_name: string;
  created_at: any;
  updated_at: any | null;
}

export interface uploadDocument {
  uploadDocument: uploadDocument_uploadDocument;
}

export interface uploadDocumentVariables {
  file: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminMe
// ====================================================

export interface adminMe_adminMe {
  __typename: "Admin";
  id: string;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  granted_permissions: Permission[];
}

export interface adminMe {
  adminMe: adminMe_adminMe | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listAdmins
// ====================================================

export interface listAdmins_listAdmins_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface listAdmins_listAdmins_data {
  __typename: "Admin";
  id: string;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  granted_permissions: Permission[];
}

export interface listAdmins_listAdmins {
  __typename: "AdminPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listAdmins_listAdmins_paginatorInfo;
  /**
   * A list of Admin items.
   */
  data: listAdmins_listAdmins_data[];
}

export interface listAdmins {
  listAdmins: listAdmins_listAdmins | null;
}

export interface listAdminsVariables {
  filters?: AdminFilters | null;
  sortBy?: AdminSort | null;
  count: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getContentsListItems
// ====================================================

export interface getContentsListItems_adminContents_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface getContentsListItems_adminContents_data_TextContent {
  __typename: "TextContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export interface getContentsListItems_adminContents_data_GalleryContent {
  __typename: "GalleryContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export interface getContentsListItems_adminContents_data_VideoContent {
  __typename: "VideoContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export type getContentsListItems_adminContents_data = getContentsListItems_adminContents_data_TextContent | getContentsListItems_adminContents_data_GalleryContent | getContentsListItems_adminContents_data_VideoContent;

export interface getContentsListItems_adminContents {
  __typename: "ContentPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: getContentsListItems_adminContents_paginatorInfo;
  /**
   * A list of Content items.
   */
  data: getContentsListItems_adminContents_data[];
}

export interface getContentsListItems {
  adminContents: getContentsListItems_adminContents | null;
}

export interface getContentsListItemsVariables {
  filters?: ContentFilters | null;
  sortBy?: ContentOrder | null;
  count: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getContentByUrl
// ====================================================

export interface getContentByUrl_adminContents_data_TextContent_decade {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface getContentByUrl_adminContents_data_TextContent_category {
  __typename: "Category";
  id: string;
  url: string;
  title: string;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface getContentByUrl_adminContents_data_TextContent_rounds {
  __typename: "Round";
  id: string;
  number: number;
}

export interface getContentByUrl_adminContents_data_TextContent_teams_logo {
  __typename: "TeamLogo";
  thumbnail: string | null;
  match_page: string | null;
  full: string | null;
}

export interface getContentByUrl_adminContents_data_TextContent_teams {
  __typename: "Team";
  id: string;
  name: string;
  logo: getContentByUrl_adminContents_data_TextContent_teams_logo | null;
}

export interface getContentByUrl_adminContents_data_TextContent {
  __typename: "TextContent";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  meta_image: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  updated_at: any | null;
  last_published_at: any | null;
  decade: getContentByUrl_adminContents_data_TextContent_decade | null;
  category: getContentByUrl_adminContents_data_TextContent_category | null;
  rounds: getContentByUrl_adminContents_data_TextContent_rounds[];
  teams: getContentByUrl_adminContents_data_TextContent_teams[];
}

export interface getContentByUrl_adminContents_data_GalleryContent_decade {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface getContentByUrl_adminContents_data_GalleryContent_category {
  __typename: "Category";
  id: string;
  url: string;
  title: string;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface getContentByUrl_adminContents_data_GalleryContent_rounds {
  __typename: "Round";
  id: string;
  number: number;
}

export interface getContentByUrl_adminContents_data_GalleryContent_teams_logo {
  __typename: "TeamLogo";
  thumbnail: string | null;
  match_page: string | null;
  full: string | null;
}

export interface getContentByUrl_adminContents_data_GalleryContent_teams {
  __typename: "Team";
  id: string;
  name: string;
  logo: getContentByUrl_adminContents_data_GalleryContent_teams_logo | null;
}

export interface getContentByUrl_adminContents_data_GalleryContent_gallery_images {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

export interface getContentByUrl_adminContents_data_GalleryContent_gallery {
  __typename: "Gallery";
  id: string;
  title: string;
  image_count: number;
  images: getContentByUrl_adminContents_data_GalleryContent_gallery_images[];
  created_at: any;
  updated_at: any | null;
}

export interface getContentByUrl_adminContents_data_GalleryContent {
  __typename: "GalleryContent";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  meta_image: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  updated_at: any | null;
  last_published_at: any | null;
  decade: getContentByUrl_adminContents_data_GalleryContent_decade | null;
  category: getContentByUrl_adminContents_data_GalleryContent_category | null;
  rounds: getContentByUrl_adminContents_data_GalleryContent_rounds[];
  teams: getContentByUrl_adminContents_data_GalleryContent_teams[];
  gallery: getContentByUrl_adminContents_data_GalleryContent_gallery | null;
}

export interface getContentByUrl_adminContents_data_VideoContent_decade {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface getContentByUrl_adminContents_data_VideoContent_category {
  __typename: "Category";
  id: string;
  url: string;
  title: string;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface getContentByUrl_adminContents_data_VideoContent_rounds {
  __typename: "Round";
  id: string;
  number: number;
}

export interface getContentByUrl_adminContents_data_VideoContent_teams_logo {
  __typename: "TeamLogo";
  thumbnail: string | null;
  match_page: string | null;
  full: string | null;
}

export interface getContentByUrl_adminContents_data_VideoContent_teams {
  __typename: "Team";
  id: string;
  name: string;
  logo: getContentByUrl_adminContents_data_VideoContent_teams_logo | null;
}

export interface getContentByUrl_adminContents_data_VideoContent {
  __typename: "VideoContent";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  meta_image: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  updated_at: any | null;
  last_published_at: any | null;
  decade: getContentByUrl_adminContents_data_VideoContent_decade | null;
  category: getContentByUrl_adminContents_data_VideoContent_category | null;
  rounds: getContentByUrl_adminContents_data_VideoContent_rounds[];
  teams: getContentByUrl_adminContents_data_VideoContent_teams[];
  video_url: string | null;
}

export type getContentByUrl_adminContents_data = getContentByUrl_adminContents_data_TextContent | getContentByUrl_adminContents_data_GalleryContent | getContentByUrl_adminContents_data_VideoContent;

export interface getContentByUrl_adminContents {
  __typename: "ContentPaginator";
  /**
   * A list of Content items.
   */
  data: getContentByUrl_adminContents_data[];
}

export interface getContentByUrl {
  adminContents: getContentByUrl_adminContents | null;
}

export interface getContentByUrlVariables {
  filters?: ContentFilters | null;
  count: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: seasons
// ====================================================

export interface seasons_seasons_rounds {
  __typename: "Round";
  id: string;
  number: number;
}

export interface seasons_seasons {
  __typename: "Season";
  id: string;
  name: string;
  rounds: seasons_seasons_rounds[];
}

export interface seasons {
  seasons: seasons_seasons[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listMatches
// ====================================================

export interface listMatches_matches_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface listMatches_matches_data_contents_TextContent {
  __typename: "TextContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export interface listMatches_matches_data_contents_GalleryContent {
  __typename: "GalleryContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export interface listMatches_matches_data_contents_VideoContent {
  __typename: "VideoContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export type listMatches_matches_data_contents = listMatches_matches_data_contents_TextContent | listMatches_matches_data_contents_GalleryContent | listMatches_matches_data_contents_VideoContent;

export interface listMatches_matches_data {
  __typename: "Match";
  id: string;
  status: MatchStatus | null;
  home_team_name: string | null;
  /**
   *  Végeredmény: hazai csapat góljainak száma büntetőpárbaj nélkül 
   */
  home_team_goals: number | null;
  guest_team_name: string | null;
  /**
   *  Végeredmény: vendég csapat góljainak száma büntetőpárbaj nélkül 
   */
  guest_team_goals: number | null;
  ticket_info: string | null;
  /**
   *  A mérkőzés ideje 
   */
  played_at: any | null;
  /**
   *  Az összes kapcsolódó cikket csak admin kérheti le 
   */
  contents: listMatches_matches_data_contents[];
}

export interface listMatches_matches {
  __typename: "MatchPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listMatches_matches_paginatorInfo;
  /**
   * A list of Match items.
   */
  data: listMatches_matches_data[];
}

export interface listMatches {
  matches: listMatches_matches | null;
}

export interface listMatchesVariables {
  filters: MatchFilters;
  sortBy?: MatchOrder | null;
  count: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listCategories
// ====================================================

export interface listCategories_categories {
  __typename: "Category";
  id: string;
  url: string;
  title: string;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface listCategories {
  categories: listCategories_categories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listDecades
// ====================================================

export interface listDecades_decades_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface listDecades_decades_data {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface listDecades_decades {
  __typename: "DecadePaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listDecades_decades_paginatorInfo;
  /**
   * A list of Decade items.
   */
  data: listDecades_decades_data[];
}

export interface listDecades {
  decades: listDecades_decades | null;
}

export interface listDecadesVariables {
  sortBy?: DecadeOrder | null;
  count: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDecade
// ====================================================

export interface getDecade_decadeById {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface getDecade {
  decadeById: getDecade_decadeById | null;
}

export interface getDecadeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listTeams
// ====================================================

export interface listTeams_teams_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface listTeams_teams_data_logo {
  __typename: "TeamLogo";
  thumbnail: string | null;
  match_page: string | null;
  full: string | null;
}

export interface listTeams_teams_data {
  __typename: "Team";
  id: string;
  name: string;
  logo: listTeams_teams_data_logo | null;
}

export interface listTeams_teams {
  __typename: "TeamPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listTeams_teams_paginatorInfo;
  /**
   * A list of Team items.
   */
  data: listTeams_teams_data[];
}

export interface listTeams {
  teams: listTeams_teams | null;
}

export interface listTeamsVariables {
  filters?: TeamFilters | null;
  sortBy?: TeamOrder | null;
  count: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listGalleries
// ====================================================

export interface listGalleries_galleries_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface listGalleries_galleries_data_images {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

export interface listGalleries_galleries_data {
  __typename: "Gallery";
  id: string;
  title: string;
  image_count: number;
  images: listGalleries_galleries_data_images[];
  created_at: any;
  updated_at: any | null;
}

export interface listGalleries_galleries {
  __typename: "GalleryPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listGalleries_galleries_paginatorInfo;
  /**
   * A list of Gallery items.
   */
  data: listGalleries_galleries_data[];
}

export interface listGalleries {
  galleries: listGalleries_galleries | null;
}

export interface listGalleriesVariables {
  filters?: GalleryFilters | null;
  sortBy?: GalleryOrder | null;
  count: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listInformations
// ====================================================

export interface listInformations_adminInformations_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface listInformations_adminInformations_data {
  __typename: "Information";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_position: number | null;
  lead: string | null;
  lead_image: string | null;
  front_page_image: string | null;
  meta_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  updated_at: any | null;
  last_published_at: any | null;
}

export interface listInformations_adminInformations {
  __typename: "InformationPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listInformations_adminInformations_paginatorInfo;
  /**
   * A list of Information items.
   */
  data: listInformations_adminInformations_data[];
}

export interface listInformations {
  adminInformations: listInformations_adminInformations | null;
}

export interface listInformationsVariables {
  filters?: InformationFilters | null;
  sortBy?: InformationOrder | null;
  count: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listDocuments
// ====================================================

export interface listDocuments_documents_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface listDocuments_documents_data {
  __typename: "Document";
  id: string;
  url: string;
  file_name: string;
  created_at: any;
  updated_at: any | null;
}

export interface listDocuments_documents {
  __typename: "DocumentPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listDocuments_documents_paginatorInfo;
  /**
   * A list of Document items.
   */
  data: listDocuments_documents_data[];
}

export interface listDocuments {
  documents: listDocuments_documents | null;
}

export interface listDocumentsVariables {
  sortBy?: DocumentOrder | null;
  count: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Admin
// ====================================================

export interface Admin {
  __typename: "Admin";
  id: string;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  granted_permissions: Permission[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminAuthenticationResult
// ====================================================

export interface AdminAuthenticationResult_admin {
  __typename: "Admin";
  id: string;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  granted_permissions: Permission[];
}

export interface AdminAuthenticationResult {
  __typename: "AdminAuthenticationResult";
  token: string;
  admin: AdminAuthenticationResult_admin;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaginatorInfo
// ====================================================

export interface PaginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Round
// ====================================================

export interface Round {
  __typename: "Round";
  id: string;
  number: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Season
// ====================================================

export interface Season_rounds {
  __typename: "Round";
  id: string;
  number: number;
}

export interface Season {
  __typename: "Season";
  id: string;
  name: string;
  rounds: Season_rounds[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Decade
// ====================================================

export interface Decade {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Category
// ====================================================

export interface Category {
  __typename: "Category";
  id: string;
  url: string;
  title: string;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TeamLogo
// ====================================================

export interface TeamLogo {
  __typename: "TeamLogo";
  thumbnail: string | null;
  match_page: string | null;
  full: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Team
// ====================================================

export interface Team_logo {
  __typename: "TeamLogo";
  thumbnail: string | null;
  match_page: string | null;
  full: string | null;
}

export interface Team {
  __typename: "Team";
  id: string;
  name: string;
  logo: Team_logo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GalleryImage
// ====================================================

export interface GalleryImage {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Gallery
// ====================================================

export interface Gallery_images {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

export interface Gallery {
  __typename: "Gallery";
  id: string;
  title: string;
  image_count: number;
  images: Gallery_images[];
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TextContentListItem
// ====================================================

export interface TextContentListItem {
  __typename: "TextContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GalleryContentListItem
// ====================================================

export interface GalleryContentListItem {
  __typename: "GalleryContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VideoContentListItem
// ====================================================

export interface VideoContentListItem {
  __typename: "VideoContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AnyContentListItem
// ====================================================

export interface AnyContentListItem_TextContent {
  __typename: "TextContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export interface AnyContentListItem_GalleryContent {
  __typename: "GalleryContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export interface AnyContentListItem_VideoContent {
  __typename: "VideoContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export type AnyContentListItem = AnyContentListItem_TextContent | AnyContentListItem_GalleryContent | AnyContentListItem_VideoContent;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TextContent
// ====================================================

export interface TextContent_decade {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface TextContent_category {
  __typename: "Category";
  id: string;
  url: string;
  title: string;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface TextContent_rounds {
  __typename: "Round";
  id: string;
  number: number;
}

export interface TextContent_teams_logo {
  __typename: "TeamLogo";
  thumbnail: string | null;
  match_page: string | null;
  full: string | null;
}

export interface TextContent_teams {
  __typename: "Team";
  id: string;
  name: string;
  logo: TextContent_teams_logo | null;
}

export interface TextContent {
  __typename: "TextContent";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  meta_image: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  updated_at: any | null;
  last_published_at: any | null;
  decade: TextContent_decade | null;
  category: TextContent_category | null;
  rounds: TextContent_rounds[];
  teams: TextContent_teams[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GalleryContent
// ====================================================

export interface GalleryContent_decade {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface GalleryContent_category {
  __typename: "Category";
  id: string;
  url: string;
  title: string;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface GalleryContent_rounds {
  __typename: "Round";
  id: string;
  number: number;
}

export interface GalleryContent_teams_logo {
  __typename: "TeamLogo";
  thumbnail: string | null;
  match_page: string | null;
  full: string | null;
}

export interface GalleryContent_teams {
  __typename: "Team";
  id: string;
  name: string;
  logo: GalleryContent_teams_logo | null;
}

export interface GalleryContent_gallery_images {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

export interface GalleryContent_gallery {
  __typename: "Gallery";
  id: string;
  title: string;
  image_count: number;
  images: GalleryContent_gallery_images[];
  created_at: any;
  updated_at: any | null;
}

export interface GalleryContent {
  __typename: "GalleryContent";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  meta_image: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  updated_at: any | null;
  last_published_at: any | null;
  decade: GalleryContent_decade | null;
  category: GalleryContent_category | null;
  rounds: GalleryContent_rounds[];
  teams: GalleryContent_teams[];
  gallery: GalleryContent_gallery | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VideoContent
// ====================================================

export interface VideoContent_decade {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface VideoContent_category {
  __typename: "Category";
  id: string;
  url: string;
  title: string;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface VideoContent_rounds {
  __typename: "Round";
  id: string;
  number: number;
}

export interface VideoContent_teams_logo {
  __typename: "TeamLogo";
  thumbnail: string | null;
  match_page: string | null;
  full: string | null;
}

export interface VideoContent_teams {
  __typename: "Team";
  id: string;
  name: string;
  logo: VideoContent_teams_logo | null;
}

export interface VideoContent {
  __typename: "VideoContent";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  meta_image: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  updated_at: any | null;
  last_published_at: any | null;
  decade: VideoContent_decade | null;
  category: VideoContent_category | null;
  rounds: VideoContent_rounds[];
  teams: VideoContent_teams[];
  video_url: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AnyContent
// ====================================================

export interface AnyContent_TextContent_decade {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface AnyContent_TextContent_category {
  __typename: "Category";
  id: string;
  url: string;
  title: string;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface AnyContent_TextContent_rounds {
  __typename: "Round";
  id: string;
  number: number;
}

export interface AnyContent_TextContent_teams_logo {
  __typename: "TeamLogo";
  thumbnail: string | null;
  match_page: string | null;
  full: string | null;
}

export interface AnyContent_TextContent_teams {
  __typename: "Team";
  id: string;
  name: string;
  logo: AnyContent_TextContent_teams_logo | null;
}

export interface AnyContent_TextContent {
  __typename: "TextContent";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  meta_image: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  updated_at: any | null;
  last_published_at: any | null;
  decade: AnyContent_TextContent_decade | null;
  category: AnyContent_TextContent_category | null;
  rounds: AnyContent_TextContent_rounds[];
  teams: AnyContent_TextContent_teams[];
}

export interface AnyContent_GalleryContent_decade {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface AnyContent_GalleryContent_category {
  __typename: "Category";
  id: string;
  url: string;
  title: string;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface AnyContent_GalleryContent_rounds {
  __typename: "Round";
  id: string;
  number: number;
}

export interface AnyContent_GalleryContent_teams_logo {
  __typename: "TeamLogo";
  thumbnail: string | null;
  match_page: string | null;
  full: string | null;
}

export interface AnyContent_GalleryContent_teams {
  __typename: "Team";
  id: string;
  name: string;
  logo: AnyContent_GalleryContent_teams_logo | null;
}

export interface AnyContent_GalleryContent_gallery_images {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

export interface AnyContent_GalleryContent_gallery {
  __typename: "Gallery";
  id: string;
  title: string;
  image_count: number;
  images: AnyContent_GalleryContent_gallery_images[];
  created_at: any;
  updated_at: any | null;
}

export interface AnyContent_GalleryContent {
  __typename: "GalleryContent";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  meta_image: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  updated_at: any | null;
  last_published_at: any | null;
  decade: AnyContent_GalleryContent_decade | null;
  category: AnyContent_GalleryContent_category | null;
  rounds: AnyContent_GalleryContent_rounds[];
  teams: AnyContent_GalleryContent_teams[];
  gallery: AnyContent_GalleryContent_gallery | null;
}

export interface AnyContent_VideoContent_decade {
  __typename: "Decade";
  id: string;
  years: string;
  url: string;
  title: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  content_title: string | null;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface AnyContent_VideoContent_category {
  __typename: "Category";
  id: string;
  url: string;
  title: string;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface AnyContent_VideoContent_rounds {
  __typename: "Round";
  id: string;
  number: number;
}

export interface AnyContent_VideoContent_teams_logo {
  __typename: "TeamLogo";
  thumbnail: string | null;
  match_page: string | null;
  full: string | null;
}

export interface AnyContent_VideoContent_teams {
  __typename: "Team";
  id: string;
  name: string;
  logo: AnyContent_VideoContent_teams_logo | null;
}

export interface AnyContent_VideoContent {
  __typename: "VideoContent";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_image: string | null;
  lead: string | null;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  meta_image: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  updated_at: any | null;
  last_published_at: any | null;
  decade: AnyContent_VideoContent_decade | null;
  category: AnyContent_VideoContent_category | null;
  rounds: AnyContent_VideoContent_rounds[];
  teams: AnyContent_VideoContent_teams[];
  video_url: string | null;
}

export type AnyContent = AnyContent_TextContent | AnyContent_GalleryContent | AnyContent_VideoContent;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Referee
// ====================================================

export interface Referee {
  __typename: "Referee";
  /**
   *  Játékvezető neve 
   */
  jnnev: string | null;
  /**
   *  Játékvezető neve 
   */
  jntip: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Player
// ====================================================

export interface Player {
  __typename: "Player";
  /**
   *  A játékos kódja 
   */
  jnkod: number | null;
  /**
   *  A játékos neve 
   */
  jnnev: string | null;
  /**
   *  A játékos kezdő vagy csere volt-e a mérkőzésen 
   */
  kezdcsere: PlayerStatus | null;
  /**
   *  A játékos mezszáma 
   */
  mez: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StaffMember
// ====================================================

export interface StaffMember {
  __typename: "StaffMember";
  nev: string | null;
  /**
   *  A stábtag szerepe 
   */
  kispad_jelleg: string | null;
  hazaivendeg: MemberStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Replacement
// ====================================================

export interface Replacement_jatekos {
  __typename: "Player";
  /**
   *  A játékos kódja 
   */
  jnkod: number | null;
  /**
   *  A játékos neve 
   */
  jnnev: string | null;
  /**
   *  A játékos kezdő vagy csere volt-e a mérkőzésen 
   */
  kezdcsere: PlayerStatus | null;
  /**
   *  A játékos mezszáma 
   */
  mez: number | null;
}

export interface Replacement {
  __typename: "Replacement";
  csereido: string | null;
  /**
   *  Mérkőzés szakasza 
   */
  fnull: string | null;
  irany: ReplacementType | null;
  hazaivendeg: MemberStatus | null;
  jatekos: Replacement_jatekos | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Goal
// ====================================================

export interface Goal_jatekos {
  __typename: "Player";
  /**
   *  A játékos kódja 
   */
  jnkod: number | null;
  /**
   *  A játékos neve 
   */
  jnnev: string | null;
  /**
   *  A játékos kezdő vagy csere volt-e a mérkőzésen 
   */
  kezdcsere: PlayerStatus | null;
  /**
   *  A játékos mezszáma 
   */
  mez: number | null;
}

export interface Goal {
  __typename: "Goal";
  /**
   *  Gól ideje a mérkőzésen 
   */
  ido: string | null;
  /**
   *  Mérkőzés szakasza 
   */
  fnull: string | null;
  /**
   *  Az adott mérkőzésen a játékos által szerzett gólok száma 
   */
  gol: number | null;
  /**
   *  Az adott mérkőzésen a játékos által szerzett öngólok száma 
   */
  ongol: number | null;
  hazaivendeg: MemberStatus | null;
  jatekos: Goal_jatekos | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Card
// ====================================================

export interface Card_jatekos {
  __typename: "Player";
  /**
   *  A játékos kódja 
   */
  jnkod: number | null;
  /**
   *  A játékos neve 
   */
  jnnev: string | null;
  /**
   *  A játékos kezdő vagy csere volt-e a mérkőzésen 
   */
  kezdcsere: PlayerStatus | null;
  /**
   *  A játékos mezszáma 
   */
  mez: number | null;
}

export interface Card {
  __typename: "Card";
  hazaivendeg: MemberStatus | null;
  jatekos: Card_jatekos | null;
  lap: CardType | null;
  ido: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Match
// ====================================================

export interface Match_contents_TextContent {
  __typename: "TextContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export interface Match_contents_GalleryContent {
  __typename: "GalleryContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export interface Match_contents_VideoContent {
  __typename: "VideoContent";
  id: string;
  url: string;
  title: string;
  updated_at: any | null;
  is_active: boolean;
}

export type Match_contents = Match_contents_TextContent | Match_contents_GalleryContent | Match_contents_VideoContent;

export interface Match {
  __typename: "Match";
  id: string;
  status: MatchStatus | null;
  home_team_name: string | null;
  /**
   *  Végeredmény: hazai csapat góljainak száma büntetőpárbaj nélkül 
   */
  home_team_goals: number | null;
  guest_team_name: string | null;
  /**
   *  Végeredmény: vendég csapat góljainak száma büntetőpárbaj nélkül 
   */
  guest_team_goals: number | null;
  ticket_info: string | null;
  /**
   *  A mérkőzés ideje 
   */
  played_at: any | null;
  /**
   *  Az összes kapcsolódó cikket csak admin kérheti le 
   */
  contents: Match_contents[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Information
// ====================================================

export interface Information {
  __typename: "Information";
  id: string;
  url: string;
  title: string;
  front_page_title: string | null;
  front_page_lead: string | null;
  front_page_position: number | null;
  lead: string | null;
  lead_image: string | null;
  front_page_image: string | null;
  meta_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  author: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  updated_at: any | null;
  last_published_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Document
// ====================================================

export interface Document {
  __typename: "Document";
  id: string;
  url: string;
  file_name: string;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdminSortField {
  EMAIL = "EMAIL",
  ID = "ID",
  NAME = "NAME",
}

export enum CardType {
  RED = "RED",
  YELLOW = "YELLOW",
}

export enum ContentField {
  ACTIVE_FROM = "ACTIVE_FROM",
  ACTIVE_TO = "ACTIVE_TO",
  CREATED_AT = "CREATED_AT",
  FRONT_PAGE_POSITION = "FRONT_PAGE_POSITION",
  ID = "ID",
  TITLE = "TITLE",
  UPDATED_AT = "UPDATED_AT",
  URL = "URL",
}

export enum ContentType {
  GALLERY = "GALLERY",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

export enum DecadeField {
  CREATED_AT = "CREATED_AT",
  ID = "ID",
  UPDATED_AT = "UPDATED_AT",
}

export enum DocumentField {
  CREATED_AT = "CREATED_AT",
  ID = "ID",
  UPDATED_AT = "UPDATED_AT",
}

export enum GalleryField {
  CREATED_AT = "CREATED_AT",
  ID = "ID",
  TITLE = "TITLE",
  UPDATED_AT = "UPDATED_AT",
}

export enum InformationField {
  ACTIVE_FROM = "ACTIVE_FROM",
  ACTIVE_TO = "ACTIVE_TO",
  CREATED_AT = "CREATED_AT",
  ID = "ID",
  TITLE = "TITLE",
  UPDATED_AT = "UPDATED_AT",
  URL = "URL",
}

export enum MatchOrderField {
  CREATED_AT = "CREATED_AT",
  ID = "ID",
  PLAYED_AT = "PLAYED_AT",
  UPDATED_AT = "UPDATED_AT",
}

export enum MatchStatus {
  CANCELLED = "CANCELLED",
  INTERRUPTED = "INTERRUPTED",
  PLANNED = "PLANNED",
  PLAYED = "PLAYED",
}

export enum MemberStatus {
  HAZAI = "HAZAI",
  VENDEG = "VENDEG",
}

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum Permission {
  MANAGE_ADMINS = "MANAGE_ADMINS",
  MANAGE_CATEGORIES = "MANAGE_CATEGORIES",
  MANAGE_CONTENT = "MANAGE_CONTENT",
  MANAGE_DECADES = "MANAGE_DECADES",
  MANAGE_DOCUMENTS = "MANAGE_DOCUMENTS",
  MANAGE_FRONT_PAGE = "MANAGE_FRONT_PAGE",
  MANAGE_GALLERIES = "MANAGE_GALLERIES",
  MANAGE_INFORMATIONS = "MANAGE_INFORMATIONS",
  MANAGE_MATCHES = "MANAGE_MATCHES",
  MANAGE_TEAMS = "MANAGE_TEAMS",
}

export enum PlayerStatus {
  CSERE = "CSERE",
  KEZD = "KEZD",
}

export enum ReplacementType {
  BE = "BE",
  KI = "KI",
}

export enum TeamOrderField {
  ID = "ID",
  NAME = "NAME",
}

export interface AdminFilters {
  id?: string | null;
  name?: string | null;
  email?: string | null;
  is_active?: boolean | null;
}

export interface AdminLoginDataInput {
  email: string;
  password: string;
}

export interface AdminSort {
  field: AdminSortField;
  direction: OrderDirection;
}

export interface ContentBelongsToMany {
  sync?: string[] | null;
}

export interface ContentFilters {
  search?: string | null;
  id?: string | null;
  title?: string | null;
  is_active?: boolean | null;
  url?: string | null;
  type?: ContentType | null;
  is_front_page_top_content?: boolean | null;
  decade_id?: string | null;
  category_id?: string | null;
}

export interface ContentOrder {
  field: ContentField;
  direction: OrderDirection;
}

export interface CreateAdminDataInput {
  name: string;
  email: string;
  permissions?: Permission[] | null;
}

export interface CreateContentInput {
  type: ContentType;
  url: string;
  video_url?: string | null;
  title: string;
  front_page_title?: string | null;
  front_page_lead?: string | null;
  lead?: string | null;
  lead_image?: string | null;
  front_page_image?: string | null;
  meta_image?: string | null;
  content?: string | null;
  meta_title?: string | null;
  meta_keywords?: string | null;
  meta_description?: string | null;
  author?: string | null;
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
  category_id?: string | null;
  decade_id?: string | null;
  gallery_id?: string | null;
  teams?: TeamBelongsToMany | null;
  rounds?: RoundBelongsToMany | null;
  matches?: MatchBelongsToMany | null;
}

export interface CreateGalleryInput {
  title: string;
  images: GalleryImageInput[];
}

export interface CreateInformationInput {
  url: string;
  title: string;
  front_page_title?: string | null;
  front_page_lead?: string | null;
  lead?: string | null;
  lead_image?: string | null;
  front_page_image?: string | null;
  meta_image?: string | null;
  content?: string | null;
  meta_title?: string | null;
  meta_keywords?: string | null;
  meta_description?: string | null;
  author?: string | null;
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
}

export interface DecadeOrder {
  field: DecadeField;
  direction: OrderDirection;
}

export interface DocumentOrder {
  field: DocumentField;
  direction: OrderDirection;
}

export interface GalleryFilters {
  id?: string | null;
  title?: string | null;
}

export interface GalleryImageInput {
  url: string;
  caption?: string | null;
  credit?: string | null;
}

export interface GalleryOrder {
  field: GalleryField;
  direction: OrderDirection;
}

export interface InformationFilters {
  id?: string | null;
  title?: string | null;
  is_active?: boolean | null;
  url?: string | null;
}

export interface InformationOrder {
  field: InformationField;
  direction: OrderDirection;
}

export interface MatchBelongsToMany {
  sync?: string[] | null;
}

export interface MatchFilters {
  season_id?: string | null;
  round_id?: string | null;
}

export interface MatchOrder {
  field: MatchOrderField;
  direction: OrderDirection;
}

export interface RoundBelongsToMany {
  sync?: string[] | null;
}

export interface SetAdminPasswordInput {
  token: string;
  email: string;
  password: string;
}

export interface TeamBelongsToMany {
  sync?: string[] | null;
}

export interface TeamFilters {
  id?: string | null;
  name?: string | null;
}

export interface TeamOrder {
  field: TeamOrderField;
  direction: OrderDirection;
}

export interface UpdateAdminDataInput {
  id: string;
  name?: string | null;
  email?: string | null;
  is_active?: boolean | null;
  permissions?: Permission[] | null;
}

export interface UpdateContentInput {
  type?: ContentType | null;
  title?: string | null;
  video_url?: string | null;
  front_page_title?: string | null;
  front_page_lead?: string | null;
  lead?: string | null;
  lead_image?: string | null;
  front_page_image?: string | null;
  meta_image?: string | null;
  content?: string | null;
  meta_title?: string | null;
  meta_keywords?: string | null;
  meta_description?: string | null;
  author?: string | null;
  is_active?: boolean | null;
  active_from?: any | null;
  active_to?: any | null;
  category_id?: string | null;
  decade_id?: string | null;
  gallery_id?: string | null;
  teams?: TeamBelongsToMany | null;
  rounds?: RoundBelongsToMany | null;
  matches?: MatchBelongsToMany | null;
}

export interface UpdateDecadeInput {
  title?: string | null;
  lead?: string | null;
  content?: string | null;
  meta_title?: string | null;
  meta_keywords?: string | null;
  meta_description?: string | null;
  content_title?: string | null;
  front_page_title?: string | null;
  front_page_lead?: string | null;
  front_page_image?: string | null;
  lead_image?: string | null;
}

export interface UpdateGalleryInput {
  title: string;
  images?: GalleryImageInput[] | null;
}

export interface UpdateInformationInput {
  title?: string | null;
  front_page_title?: string | null;
  front_page_lead?: string | null;
  lead?: string | null;
  lead_image?: string | null;
  front_page_image?: string | null;
  meta_image?: string | null;
  content?: string | null;
  meta_title?: string | null;
  meta_keywords?: string | null;
  meta_description?: string | null;
  author?: string | null;
  is_active?: boolean | null;
  active_from?: any | null;
  active_to?: any | null;
}

export interface UpdateMatchInput {
  video_url?: string | null;
  ticket_info?: string | null;
  contents?: ContentBelongsToMany | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
