import React from "react";
import styled from "styled-components";

type Props = {
    ariaLabel?: string;
    btnLabel?: string;
    disabled?: boolean;
    expanded?: boolean;
    hollow?: boolean;
    iconRight?: boolean;
    innerRef?: (ref: HTMLButtonElement | null) => void;
    justifyContent?: "center";
    renderIcon?: React.ReactElement;
    secondary?: boolean;
    onlyIcon?: boolean;
    type?: "button" | "submit" | "reset";
} & Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "ref">;

class ButtonComponent extends React.PureComponent<Props> {
    private renderButtonInner = (): JSX.Element => {
        const { ariaLabel, btnLabel, iconRight, justifyContent, renderIcon } = this.props;

        if (renderIcon) {
            if (ariaLabel) {
                return (
                    <>
                        <span className="show-for-sr">{ariaLabel}</span>
                        <IconWrapper aria-hidden={true}>{renderIcon}</IconWrapper>
                    </>
                );
            }

            if (iconRight) {
                return (
                    <span className={`grid-x align-middle${justifyContent ? " align-center" : ""}`}>
                        <LabelWrapper className={`cell ${justifyContent ? "shrink" : "auto"}`}>{btnLabel}</LabelWrapper>
                        <IconWrapperRight className="cell shrink" aria-hidden={true}>
                            {renderIcon}
                        </IconWrapperRight>
                    </span>
                );
            }

            return (
                <span className={`grid-x align-middle${justifyContent ? " align-center" : ""}`}>
                    <IconWrapperLeft className="cell shrink" aria-hidden={true}>
                        {renderIcon}
                    </IconWrapperLeft>
                    <LabelWrapper className={`cell ${justifyContent ? "shrink" : "auto"}`}>{btnLabel}</LabelWrapper>
                </span>
            );
        }

        return <LabelWrapper>{btnLabel}</LabelWrapper>;
    };

    private renderButton = (): JSX.Element => {
        const { expanded, hollow, innerRef, secondary, onlyIcon, type, ...otherProps } = this.props;

        if (secondary) {
            return (
                <SecondaryButton ref={innerRef} type={type || "button"} expanded={expanded} {...otherProps}>
                    {this.renderButtonInner()}
                </SecondaryButton>
            );
        }

        if (hollow) {
            return (
                <HollowButton ref={innerRef} type={type || "button"} expanded={expanded} {...otherProps}>
                    {this.renderButtonInner()}
                </HollowButton>
            );
        }

        if (onlyIcon) {
            return (
                <OnlyIconButton ref={innerRef} type={type || "button"} expanded={expanded} {...otherProps}>
                    {this.renderButtonInner()}
                </OnlyIconButton>
            );
        }

        return (
            <StyledButton ref={innerRef} type={type || "button"} expanded={expanded} secondary={secondary} {...otherProps}>
                {this.renderButtonInner()}
            </StyledButton>
        );
    };

    render(): JSX.Element {
        return this.renderButton();
    }
}

export const LabelWrapper = styled.span`
    display: inline-block;
    font-size: ${props => props.theme.typo.button.fontSize}px;
    font-weight: ${props => props.theme.typo.button.fontweight};
    line-height: ${props => props.theme.box.button.height - props.theme.box.button.borderWidth * 2}px;
    position: relative;
`;

export const IconWrapper = styled.span`
    display: inline-block;
    position: relative;
`;

const IconWrapperLeft = styled(IconWrapper)`
    padding-right: 24px;
`;

const IconWrapperRight = styled(IconWrapper)`
    padding-left: 24px;
`;

export const StyledButton = styled.button<Props>`
    background-color: ${props => props.theme.background.button.primary.backgroundColor};
    border-radius: ${props => props.theme.box.button.borderRadius}px;
    border: ${props => props.theme.box.button.borderWidth}px solid ${props => props.theme.box.button.primary.borderColor};
    display: ${props => (props.expanded ? "block" : "inline-block")};
    height: ${props => props.theme.box.button.height}px;
    margin-left: ${props => props.expanded && 0};
    margin-right: ${props => props.expanded && 0};
    padding-left: ${props => props.theme.box.button.paddingLeft}px;
    padding-right: ${props => props.theme.box.button.paddingRight}px;
    user-select: none;
    vertical-align: bottom;
    width: ${props => props.expanded && "100%"};

    ${LabelWrapper},
    ${IconWrapper} {
        color: ${props => props.theme.typo.button.primary.color};
    }

    &:hover {
        background-color: ${props => props.theme.background.button.primary.hover};
    }

    &:disabled {
        background-color: ${props => props.theme.background.button.disabled.backgroundColor};
        border-color: ${props => props.theme.box.button.disabled.borderColor};
        cursor: not-allowed;
    }
`;

export const SecondaryButton = styled(StyledButton)`
    background-color: ${props => props.theme.background.button.secondary.backgroundColor};
    border: ${props => props.theme.box.button.borderWidth}px solid ${props => props.theme.box.button.secondary.borderColor};
    &:hover {
        background-color: ${props => props.theme.background.button.secondary.hover};
    }
`;

export const HollowButton = styled(StyledButton)`
    background-color: ${props => props.theme.background.button.hollow.backgroundColor};
    border: ${props => props.theme.box.button.hollow.borderWidth}px solid ${props => props.theme.box.button.secondary.borderColor};

    &:hover {
        background-color: ${props => props.theme.background.button.hollow.hover};
    }

    ${LabelWrapper} {
        line-height: ${props => props.theme.box.button.height - props.theme.box.button.hollow.borderWidth * 2}px;
    }
`;

export const OnlyIconButton = styled(HollowButton)`
    background-color: transparent;
    border-color: transparent;
`;

export const Button: React.ComponentClass<Props> = ButtonComponent;
