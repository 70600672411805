import React, { ReactNode } from "react";
import styled from "styled-components";

import { hexToRGB, keyframesBlinkInputShadow } from "theme/global";
import { InputWrapperStyled, InputWrapperErrorWrapper } from "components/Inputs/InputWrapper";
import { SVGIconTick } from "components/SVGCollection";

interface Props {
    checkboxLabel: ReactNode;
    errorMsg?: string;
    hasError?: boolean | null;
    innerRef?: (ref: JSX.Element) => void;
    [x: string]: any;
}

class CheckboxComponent extends React.PureComponent<Props> {
    render(): JSX.Element {
        const { checkboxLabel, errorMsg, hasError, innerRef, ...otherProps } = this.props;

        return (
            <CheckboxWrapper as="label">
                <Input ref={innerRef} className="show-for-sr" type="checkbox" hasError={hasError} {...otherProps} />

                {checkboxLabel}

                {errorMsg && <InputWrapperErrorWrapper>{errorMsg}</InputWrapperErrorWrapper>}

                <CheckboxToggle aria-hidden="true" hasError={hasError}>
                    <SVGIconTick focusable="false" />
                </CheckboxToggle>
            </CheckboxWrapper>
        );
    }
}

export const CheckboxWrapper = styled(InputWrapperStyled)`
    color: ${props => props.theme.typo.checkbox.color};
    display: block;
    font-weight: 400;
    min-height: ${props => props.theme.box.checkbox.height}px;
    padding-left: ${props => props.theme.box.checkbox.paddingLeft}px;
    padding-top: ${props => Math.ceil((props.theme.box.checkbox.height - props.theme.typo.body.lineHeight) / 2)}px;
    position: relative;

    & + ${InputWrapperStyled} {
        margin-top: ${props => props.theme.box.body.formSpacing}px;
    }
`;

export const CheckboxToggle = styled.span<Props>`
    align-items: center;
    background-color: ${props => props.theme.background.checkbox.backgroundColor};
    border-radius: ${props => props.theme.box.checkbox.borderRadius}px;
    border: ${props => (props.hasError ? props.theme.box.input.hasError.borderWidth : props.theme.box.checkbox.borderWidth)}px solid
        ${props => (props.hasError ? props.theme.box.input.hasError.borderColor : props.theme.box.checkbox.borderColor)};
    color: ${props => props.theme.typo.checkbox.toggle.color};
    cursor: pointer;
    display: flex;
    height: ${props => props.theme.box.checkbox.height}px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: ${props => props.theme.box.checkbox.width}px;

    svg {
        transform-origin: center;
        transform: scale(0);
        transition: transform 125ms ease-in;
    }
`;

export const Input = styled.input<Props>`
    &:checked {
        ~ ${CheckboxToggle} {
            background-color: ${props => props.theme.background.checkbox.checked.backgroundColor};
            border: ${props => (props.hasError ? props.theme.box.input.hasError.borderWidth : props.theme.box.checkbox.borderWidth)}px solid
                ${props => (props.hasError ? props.theme.box.input.hasError.borderColor : props.theme.background.checkbox.checked.backgroundColor)};
            box-shadow: ${props => props.theme.box.checkbox.checked.boxShadow}
                rgba(${props => hexToRGB(props.theme.box.checkbox.checked.boxShadowColor)}, ${props => props.theme.box.checkbox.checked.boxShadowOpacity});

            svg {
                transform: scale(1);
            }
        }
    }

    &:focus {
        &:not(:focus-visible) {
            ~ ${CheckboxToggle} {
                box-shadow: none;
            }
        }
    }

    &:focus-visible {
        ~ ${CheckboxToggle} {
            animation: ${keyframesBlinkInputShadow} 1s 1;
        }
    }
`;

export const Checkbox: React.ComponentClass<Props> = CheckboxComponent;
