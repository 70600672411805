import React from "react";
import { OrderDirection } from "api/graphql/types";
import { TableWrapper, Table as TableElement } from "components/TableElements";
import { hexToRGB } from "../../theme/global";
import { ColumnHeader } from "./ColumnHeader";
import { SVGIconSearch } from "../SVGCollection";
import { Intl } from "i18n/Intl";
import styled from "styled-components";
import { Color } from "theme/theme";
import { TableFooter } from "./TableFooter";
import { SSkeletonLine } from "components/Skeleton";

interface SortBy {
    columnId?: string | null;
    order?: OrderDirection;
}

interface Column<T> {
    /** Unique key for columns */
    id: string;

    /** label for column */
    name: string;

    /** custom className for columns. If value not set, using accessor */
    className?: string;

    /** item key accessor of type */
    accessor: keyof T;

    /** Render cell overwrite */
    renderCell?: (item: T, column: Column<T>) => React.ReactElement<any> | null;

    /** disable sorting on column */
    isNonSortable?: boolean;
}

interface Props<T> {
    /**
     * Extract unique key for columns and cells
     */
    keyExtractor: (item: T, column?: Column<T>) => string;

    /**
     * Render component after row
     */
    renderUnderRow?: (item: T) => React.ReactElement<any> | null;

    /**
     * When a column's header is pressed
     */
    onSortOrderChange?: (column?: Column<T>, sortOrder?: OrderDirection) => void;

    /**
     * When a column's header is pressed
     */
    onPageChange?: (newPage: number) => void;

    /**
     * Sort by which column and which direction
     */
    sortBy?: SortBy;

    /**
     * Columns of table
     */
    columns: Array<Column<T>>;

    limit?: number | null;

    /**
     * Data for table
     */
    data: T[];

    count: number;

    currentPage?: number;

    pageCount?: number;

    isPaginationEnabled?: boolean;

    isSortable?: boolean;

    isLoading: boolean;

    hideHeader?: boolean;

    /**
     * Empty component, when loading finished and count is 0
     */
    renderEmpty?: () => React.ReactElement<any> | string;

    onRowClick?: (item: T) => void;

    rowClassName?: (item: T) => string;

    renderItem?: (item: T, index: number, array: T[]) => React.ReactElement<any> | null;

    renderRow?: (item: T, index: number, children: React.ReactElement<any>) => React.ReactElement<any>;
}

class Table<T> extends React.Component<Props<T>> {
    public static readonly DEFAULT_PAGE_SIZE: number = 15;

    public static getCurrentPage(offset: number | null = null, limit: number | null = null, count: number): number {
        const currentPage: number = offset && limit ? Math.ceil(offset / limit) + 1 : 1;
        // return 1, when current offset overflow page count (eg.: on page 3 search for term and count change to 1 element)
        if (offset && limit && count + limit < currentPage * limit) {
            return 1;
        }
        return currentPage;
    }

    private renderCell = (item: T, column: Column<T>): React.ReactElement<any> => {
        const cell: React.ReactElement<any> | null = column.renderCell ? column.renderCell(item, column) : null;
        if (cell) {
            return <td key={this.props.keyExtractor(item, column)}>{cell}</td>;
        }
        return <td key={this.props.keyExtractor(item, column)}>{item[column.accessor]}</td>;
    };

    private readonly dataLoadingView = () => {
        let i = 0;
        return (
            <>
                {new Array(this.props.limit || Table.DEFAULT_PAGE_SIZE).fill("1").map(() => {
                    return (
                        <tr key={`skeleton-${++i}`}>
                            {new Array(this.props.columns.length).fill("1").map(() => {
                                return (
                                    <td key={`skeleton-${++i}`}>
                                        <SSkeletonLine />
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </>
        );
    };

    private renderRow = (item: T, index: number): React.ReactElement<any> => {
        const onRowClick = this.props.onRowClick
            ? () => {
                  this.props.onRowClick!(item);
              }
            : undefined;

        if (this.props.renderRow) {
            return this.props.renderRow(
                item,
                index,
                <React.Fragment>
                    {this.props.columns.map(
                        (column: Column<T>): React.ReactElement<any> => {
                            return this.renderCell(item, column);
                        },
                    )}
                </React.Fragment>,
            );
        }

        return (
            <tr key={this.props.keyExtractor(item)} className={onRowClick ? "clickable" : ""} onClick={onRowClick}>
                {this.props.columns.map(
                    (column: Column<T>): React.ReactElement<any> => {
                        return this.renderCell(item, column);
                    },
                )}
                {this.props.renderUnderRow && this.props.renderUnderRow(item)}
            </tr>
        );
    };

    private renderHeader = (): React.ReactElement<any> => {
        const sortBy: SortBy | undefined = this.props.sortBy;
        return (
            <thead>
                <tr>
                    {this.props.columns.map(
                        (column: Column<T>): React.ReactElement<any> => {
                            return (
                                <ColumnHeader
                                    column={column}
                                    key={column.id}
                                    isSortable={!!this.props.isSortable && !column.isNonSortable}
                                    sortOrder={sortBy && sortBy.columnId === column.id ? sortBy.order : undefined}
                                    onChangeSort={this.props.onSortOrderChange || undefined}
                                />
                            );
                        },
                    )}
                </tr>
            </thead>
        );
    };

    private renderBody = (): React.ReactElement => {
        if (!this.props.isLoading && this.props.count === 0 && this.props.renderEmpty) {
            const result: React.ReactElement | string = this.props.renderEmpty();
            if (typeof result === "string") {
                return (
                    <tbody>
                        <tr>
                            <td colSpan={100}>
                                <EmptyList>
                                    <SVGIconSearch width={64} height={64} focusable="false" />
                                    <br />
                                    <span>{Intl.formatMessage({ id: "components.table.emptyList" })}</span>
                                </EmptyList>
                            </td>
                        </tr>
                    </tbody>
                );
            }
            return result;
        }
        return <tbody>{this.props.data.map(this.renderRow)}</tbody>;
    };

    public render(): React.ReactElement {
        return (
            <TableWrapper>
                <TableElement>
                    {!this.props.hideHeader && this.renderHeader()}
                    {this.props.isLoading ? (
                        this.dataLoadingView()
                    ) : (
                        <>
                            {this.renderBody()}
                            {this.props.isPaginationEnabled && this.props.onPageChange && (
                                <TableFooter currentPage={this.props.currentPage || 1} pagesCount={this.props.pageCount || 1} onPageChange={this.props.onPageChange} />
                            )}
                        </>
                    )}
                </TableElement>
            </TableWrapper>
        );
    }
}

export const EmptyList = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    height: 400px;
    justify-content: center;
    color: ${Color.secondaryD};

    span {
        color: ${props => props.theme.typo.table.th.color};
        font-size: ${props => props.theme.typo.table.th.fontSize}px;
        text-transform: ${props => props.theme.typo.table.th.textTransform};
        margin-top: 20px;
        font-weight: bold;
    }
`;

export const TableButtonWrapper = styled.div`
    display: flex;
`;

export const TableButton = styled.button`
    background: none;
    margin-right: 10px;
    color: rgba(${hexToRGB(Color.secondaryXD)}, 0.5);
    transition color 300ms ease-in-out;
    
    &:hover {
        color: ${Color.secondaryXD};
    }
`;

export { Table };
export type { Column };
