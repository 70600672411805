import { AlertOptions } from "components/cms/Alert/AlertTypes";
import { createAction, ActionsUnion } from "./Helpers";

export enum AlertActionType {
    SHOW = "ALERT_SHOW",
    HIDE = "ALERT_HIDE",
}

export const AlertActions = {
    show: (alertOptions: AlertOptions) => createAction(AlertActionType.SHOW, alertOptions),
    hide: () => createAction(AlertActionType.HIDE),
};

//eslint-disable-next-line no-redeclare
export type AlertActions = ActionsUnion<typeof AlertActions>;
