import React from "react";
import { RouteProps } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { Permission } from "api/graphql/types";

type Props = {
    layout: any;
    layoutProps?: {};
    component: any;
    permission?: Permission;
} & RouteProps;

class LayoutWrapper extends React.Component<Props> {
    public render(): React.ReactElement {
        const { layout: LayoutComponent, component: Component, ...otherProps } = this.props;

        return (
            <PrivateRoute
                {...otherProps}
                render={renderProps => (
                    <LayoutComponent {...renderProps} {...this.props.layoutProps}>
                        <Component {...renderProps} />
                    </LayoutComponent>
                )}
            />
        );
    }
}

export { LayoutWrapper };
