import React, { ReactNode } from "react";
import styled from "styled-components";

import { Color } from "theme/theme";
import { CaptionText, hexToRGB } from "theme/global";
import { StyledInput } from "components/Inputs/Input";
import { SVGIconWarning } from "components/SVGCollection";
import { DateInput } from "./DateInput";

interface Props {
    children: ReactNode;
    errorMsg?: string;
    note?: JSX.Element | string;
    fakeLabel: string;
    // Note: FakeLabel's emergency switch (value !== "").
    floatUpFakeLabel?: boolean;
    isDisabled?: boolean;
    hideFakeLabel?: boolean;
    renderIcon?: React.ReactElement;
    [x: string]: any;
}

class InputWrapperComponent extends React.PureComponent<Props> {
    render(): JSX.Element {
        const { children, errorMsg, fakeLabel, floatUpFakeLabel, hideFakeLabel, note, ...otherProps } = this.props;

        return (
            <InputWrapperStyled {...otherProps}>
                <InputWrapperInnerWrapper>
                    {children}

                    {errorMsg && (
                        <InnerWrapperIconWrapper aria-hidden="true">
                            <SVGIconWarning focusable="false" />
                        </InnerWrapperIconWrapper>
                    )}

                    {this.props.renderIcon && <InnerWrapperIconWrapper aria-hidden="true">{this.props.renderIcon}</InnerWrapperIconWrapper>}

                    <FakeLabel floatUpFakeLabel={floatUpFakeLabel} aria-hidden="true" style={hideFakeLabel ? { opacity: 0 } : {}}>
                        {fakeLabel}
                    </FakeLabel>
                </InputWrapperInnerWrapper>

                {!!note && !errorMsg && (
                    <InputWrapperNoteWrapper as="div" aria-live="assertive" aria-atomic="true">
                        {note}
                    </InputWrapperNoteWrapper>
                )}
                <InputWrapperErrorWrapper as="div" aria-live="assertive" aria-atomic="true">
                    {errorMsg}
                </InputWrapperErrorWrapper>
            </InputWrapperStyled>
        );
    }
}

export const InputWrapperInnerWrapper = styled.div`
    position: relative;
`;

export const InnerWrapperIconWrapper = styled.div`
    align-items: center;
    display: flex;
    height: ${props => props.theme.box.input.height}px;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 8px;
    top: 0;
    width: ${props => props.theme.box.input.height}px;
`;

export const FakeLabel = styled.div<Props>`
    background-color: ${props => props.theme.background.input.backgroundColor};
    color: ${props => (props.floatUpFakeLabel ? props.theme.typo.input.color : props.theme.typo.input.fakeLabel.color)};
    font-size: ${props => props.theme.typo.input.fontSize}px;
    font-weight: ${props => props.theme.typo.input.fontWeight};
    height: ${props => props.theme.box.input.height - props.theme.box.input.paddingTop - props.theme.box.input.paddingBottom - props.theme.box.input.borderWidth * 2}px;
    /* Note: left = Inputs' borderLeft + paddingLeft - FakeLabel's paddingLeft */
    left: ${props => props.theme.box.input.paddingLeft + props.theme.box.input.borderWidth - 10}px;
    line-height: ${props => props.theme.box.input.height - props.theme.box.input.paddingTop - props.theme.box.input.paddingBottom - props.theme.box.input.borderWidth * 2}px;
    max-width: calc(100% - ${props => (props.theme.box.input.paddingLeft + props.theme.box.input.borderWidth - 10) * 2}px);
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    text-transform: none;
    top: ${props => props.theme.box.input.paddingTop + props.theme.box.input.borderWidth}px;
    transform-origin: left top;
    transform: ${props =>
        props.floatUpFakeLabel
            ? /* Note: scale = CaptionText.fontSize / input.fontSize; translateY = Math.floor(lineHeight + lineHeight / 2 * scale) * -1) */
              `scale(${props.theme.typo.CaptionText.fontSize / props.theme.typo.input.fontSize}) translateY(-${Math.floor(
                  props.theme.box.input.height -
                      props.theme.box.input.paddingTop -
                      props.theme.box.input.paddingBottom -
                      props.theme.box.input.borderWidth * 2 +
                      ((props.theme.box.input.height - props.theme.box.input.paddingTop - props.theme.box.input.paddingBottom - props.theme.box.input.borderWidth * 2) / 2) *
                          (props.theme.typo.CaptionText.fontSize / props.theme.typo.input.fontSize),
              )}px)`
            : "scale(1), translateY(0)"};
    transition: transform 150ms;
    white-space: nowrap;
    width: auto;
    z-index: 1;
`;

export const InputWrapperErrorWrapper = styled(CaptionText)`
    color: ${props => props.theme.typo.input.hasError.color};
    padding-top: 8px;
    text-align: right;

    &:empty {
        display: none;
    }
`;

export const InputWrapperNoteWrapper = styled(CaptionText)`
    color: ${Color.secondary};
    padding-top: 8px;
    padding-left: 22px;
    text-align: left;

    &:empty {
        display: none;
    }
`;

export const InputWrapperStyled = styled.div<{ isDisabled?: boolean; renderIcon?: React.ReactElement }>`
    & + & {
        margin-top: ${props => props.theme.box.body.formSpacing}px;
    }

    ${props =>
        props.isDisabled
            ? `
        ${StyledInput} {
            background-color: ${Color.grayL};
            border-color: ${Color.grayD};
            color: ${Color.grayD};
        }

        ${DateInput} {
            background-color: ${Color.grayL};
            border-color: ${Color.grayD};
            color: ${Color.grayD};
        }

        ${FakeLabel} {
            color: ${Color.grayD};
        }
    `
            : ""}

    ${props =>
        props.renderIcon
            ? `
        ${StyledInput} {
            padding-right: 66px;
        }

        ${DateInput} {
            padding-right: 66px;
        }

    `
            : ""}
`;

const StyledDownShiftList = styled.ul<{ $align: "bottom" | "top" }>`
    background-color: ${Color.white};
    border-radius: 16px;
    border: 1px solid ${Color.grayD};
    ${props => (props.$align === "bottom" ? "bottom: 100%;" : "")}
    box-shadow: 0 4px 24px 0 rgba(${hexToRGB(Color.grayD)}, 0.4);
    left: 0;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    overflow: hidden;
    padding-left: 0;
    position: absolute;
    right: 0;
    ${props => (props.$align === "top" ? "top: 100%;" : "")}
    z-index: 2;

    &:empty {
        display: none;
    }
`;

const StyledDownShiftListElement = styled.li`
    box-shadow: 0 -1px 0 0 ${Color.grayD};
    color: ${Color.secondary};
    margin: 0;
    padding: 16px 15px;
`;

export const StyledDownShift = {
    List: StyledDownShiftList,
    ListElement: StyledDownShiftListElement,
};

export const InputWrapper: React.ComponentClass<Props> = InputWrapperComponent;
