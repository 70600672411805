import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { Intl } from "i18n/Intl";
import "react-datepicker/dist/react-datepicker.css";
import { Input } from "./Input";
import styled from "styled-components";
import { Color } from "theme/theme";
import { SVGIconDelete } from "../SVGCollection";
import { StyledButton, LabelWrapper } from "../Button";
import hu from "date-fns/locale/hu";
registerLocale("hu", hu);

interface ComponentProps {
    value: Date | null;
    onChange: (date: Date | null) => void;
    disabled?: boolean;
    placeholder?: string;
    showClearButton?: boolean;
}

type Props = ComponentProps;

class DateInput extends React.PureComponent<Props> {
    ref: React.RefObject<DatePicker> = React.createRef();

    render(): JSX.Element {
        const CustomInput: React.FC<any> = ({ value, onClick }) => {
            return <Input ariaLabel={"dateInput"} readOnly={true} value={value || this.props.placeholder || ""} onClick={onClick} style={value === "" ? { fontStyle: "italic" } : undefined} />;
        };
        const showDeleteButton = this.props.showClearButton === true && this.props.value !== null;
        return (
            <StyledWrapper className="grid-x" showButton={showDeleteButton}>
                <div className="cell auto">
                    <DatePicker
                        locale="hu"
                        ref={this.ref}
                        selected={this.props.value}
                        onChange={this.props.onChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption={Intl.formatMessage({ id: "components.dateInput.timeCaption" })}
                        dateFormat="yyyy.MM.dd. HH:mm"
                        disabled={this.props.disabled}
                        customInput={<CustomInput />}
                        placeholderText={this.props.placeholder || "yyyy. MM. dd. HH:mm"}
                    />
                </div>
                {showDeleteButton === true && (
                    <div className="cell shrink">
                        <ShowButton
                            onClick={() => {
                                // @ts-ignore
                                this.ref.current?.clear();
                            }}
                            type="button"
                            title="Remove"
                        >
                            <span aria-hidden="true">
                                <SVGIconDelete focusable="false" />
                            </span>
                        </ShowButton>
                    </div>
                )}
            </StyledWrapper>
        );
    }
}

const StyledWrapper = styled.div<{ showButton: boolean }>`
    ${props =>
        props.showButton === true
            ? `
        input {
            border-bottom-right-radius: 0;
            border-right-color: transparent;
            border-top-right-radius: 0;
            border-right: 0;

        }
    `
            : `
        input {
            margin-right: 49px;

            &:read-only {
                background: none !important;
            }

        }
    `}

    ${StyledButton} {
        background-color: ${Color.secondary};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-color: ${Color.secondary};
        height: 50px;
        min-width: 128px;

        &:active,
        &:hover {
            background-color: ${Color.secondaryL};
            border-left-color: ${Color.secondaryL};
        }

        &:not(:last-of-type) {
            border-radius: 0;
        }
    }

    ${LabelWrapper} {
        color: ${Color.white};
        font-size: 14px;
        line-height: 48px;
    }

    .react-datepicker-popper {
        z-index: 2;
    }
`;

const ShowButton = styled.button`
    align-items: center;
    border-bottom: 1px solid ${Color.secondary};
    border-top: 1px solid ${Color.secondary};
    border-right: 1px solid ${Color.secondary};
    border-top-right-radius: ${props => props.theme.box.input.borderRadius}px;
    border-bottom-right-radius: ${props => props.theme.box.input.borderRadius}px;
    color: ${Color.secondary};
    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;

    &:hover {
        color: ${Color.secondaryL};
    }

    &:active {
        color: ${Color.primary};
    }
`;

export { DateInput };
