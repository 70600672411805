import React from "react";
import styled from "styled-components";
import TextareaAutosize from "react-autosize-textarea";

import { StyledInput } from "components/Inputs/Input";

type Props = {
    ariaLabel: string;
    hasError?: boolean;
    innerRef?: (ref: HTMLInputElement | null) => void;
} & React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

class TextareaComponent extends React.PureComponent<Props> {
    render(): JSX.Element {
        const { ariaLabel, innerRef, hasError, ...otherProps } = this.props;

        return (
            // Note: in order to the "floating label" CSS effect work, required needs to be true. If required can not be true, please use InputWrapper's "floatUpFakeLabel" emergency switch (value !== "").
            <StyledTextarea as={TextareaAutosize} ref={innerRef} aria-label={ariaLabel} required={true} rows={1} {...otherProps} />
        );
    }
}

const StyledTextarea = styled(StyledInput)`
    height: auto;
    min-height: ${props => props.theme.box.input.height}px;
    resize: vertical;
`;

export const Textarea: React.ComponentClass<Props> = TextareaComponent;
