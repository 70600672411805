import React from "react";
import { Menu } from "components/cms/MenuElements";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Intl } from "i18n/Intl";
import { Permission, Admin } from "api/graphql/types";
import { PermissionUtils } from "utils/PermissionUtils";
import { ApplicationState } from "reducers";
import { connect } from "react-redux";

interface ReduxProps {
    admin: Admin;
}

export type MenuItemType = {
    name: string;
    icon: string | JSX.Element;
    path: string;
    permission?: Permission;
    subMenus?: MenuItemType[];
};

export type MenuProps = {
    menuItems: Array<MenuItemType | undefined>;
    currentPath: string;
};

type Props = MenuProps & RouteComponentProps & ReduxProps;

class LeftMenuComponent extends React.Component<Props> {
    private readonly renderMenuItem = (menuItem: MenuItemType, key: number) => {
        const hasChildren = menuItem.subMenus && menuItem.subMenus.length > 0;
        const MenuLiComp = hasChildren ? Menu.LiFoldable : Menu.Li;
        const isFoldableOpened = hasChildren ? menuItem.subMenus!.some(item => this.props.currentPath.indexOf(item.path) === 0) : false;
        return (
            <MenuLiComp
                key={`menuItem_${key}`}
                btnLabel={Intl.formatMessage({ id: `menu.${menuItem.name}` })}
                renderIcon={menuItem.icon}
                isActive={!hasChildren ? this.props.currentPath.indexOf(menuItem.path) === 0 : isFoldableOpened}
                defaultOpened={hasChildren ? isFoldableOpened : undefined}
            >
                {hasChildren ? (
                    menuItem.subMenus!.map(this.renderMenuItem)
                ) : (
                    <button
                        type="button"
                        title={menuItem.name}
                        onClick={() => {
                            this.props.history.push(menuItem.path);
                        }}
                    >
                        <span className="show-for-sr">{menuItem.name}</span>
                    </button>
                )}
            </MenuLiComp>
        );
    };

    public render(): React.ReactElement {
        return (
            <Menu.Container>
                {(this.props.menuItems.filter(mi => typeof mi !== "undefined" && PermissionUtils.hasPermission(this.props.admin, mi.permission)) as MenuItemType[]).map(this.renderMenuItem)}
            </Menu.Container>
        );
    }
}

const mapStateToProps = ({ auth: { admin } }: ApplicationState): ReduxProps => ({
    admin: admin!,
});

const LeftMenu = withRouter(connect(mapStateToProps)(LeftMenuComponent));

export { LeftMenu };
