import { Admin, Permission } from "api/graphql/types";

class PermissionUtils {
    public static readonly hasPermission = (admin: Admin, permission?: Permission): boolean => {
        if (!permission) {
            return true;
        }
        return admin.granted_permissions.includes(permission);
    };
}
export { PermissionUtils };
