import React from "react";
import { Intl } from "i18n/Intl";
import { ValidatorMessage } from "utils/Validator";
import { FormattedMessage, MessageValue } from "react-intl";
import { ApiError } from "api/ApiError";
import { ObjectUtils } from "utils/ObjectUtils";
import { FileSize } from "utils/FileSize";

export class IntlHelpers {
    /**
     * Returns an Intl formatted message
     * Error: error.message
     * ApiError: error.intlKey
     * @param error Error | ApiError
     */
    public static getMessageFromError<T>(error: ApiError<T> | Error): string {
        if (error instanceof ApiError) {
            if (error.intlKey) {
                return Intl.formatMessage({ id: error.intlKey });
            } else {
                return error.message;
            }
        }
        return Intl.formatMessage({ id: error.message });
    }

    /**
     * Get formatted error message from ValidatorMessage
     * @param message ValidatorMessage
     * @param values Intl variables into message
     */
    public static getValidationError(message: ValidatorMessage | null, values?: { [key: string]: MessageValue }): string | null {
        return message ? Intl.formatMessage({ id: `error.validation.${message}` }, values || {}) : null;
    }

    /**
     * Returns a span, injected html message
     *
     * reason: Intl.formattedHTMLMessage not work
     * @param messageDescriptor FormattedMessage.MessageDescriptor
     * @param values ?{ [key: string]: MessageValue }
     */
    public static asHtml(messageDescriptor: FormattedMessage.MessageDescriptor, values?: { [key: string]: MessageValue }) {
        return <span dangerouslySetInnerHTML={{ __html: Intl.formatMessage(messageDescriptor, values) }} />;
    }

    public static fromI18nToEnum<T>(enumType: object, value: any, translationKey: string): T | undefined {
        if (typeof value !== "string") {
            return undefined;
        }

        const enumArray = ObjectUtils.enumAsArray<T>(enumType);
        const index = enumArray.findIndex((enumValue: T): boolean => {
            return Intl.formatMessage({ id: `${translationKey}.${enumValue}` }) === value;
        });

        return index !== -1 ? enumArray[index] : undefined;
    }

    public static getFileSize(fileSizeInBytes: number): string {
        const getSizeIn = (newSize: FileSize) => Math.round((fileSizeInBytes / newSize) * 100) / 100;

        if (fileSizeInBytes < FileSize.KB) {
            return Intl.formatMessage({ id: "fileSize.B" }, { size: fileSizeInBytes });
        }
        if (fileSizeInBytes < FileSize.MB) {
            return Intl.formatMessage({ id: "fileSize.KB" }, { size: getSizeIn(FileSize.KB) });
        }

        if (fileSizeInBytes < FileSize.GB) {
            return Intl.formatMessage({ id: "fileSize.MB" }, { size: getSizeIn(FileSize.MB) });
        }

        return Intl.formatMessage({ id: "fileSize.GB" }, { size: getSizeIn(FileSize.GB) });
    }

    public static getWeekdays(): string[] {
        return Array.from({ length: 7 }).map((_: any, index: number): string => Intl.formatMessage({ id: `common.weekdaysShortName.${index + 1}` }));
    }
}
