import React from "react";
import DraggableTableCell from "./DraggableTableCell";
import { SVGIconDragIndicator } from "../SVGCollection";
import styled from "styled-components";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { Color } from "theme/theme";
import { Intl } from "i18n/Intl";

type PropType = {
    isDragging: boolean;
    dragHandleProp?: DraggableProvidedDragHandleProps;
};

export const DragHandle = (props: PropType) => {
    return (
        <StyledDTC isDragOccurring={props.isDragging}>
            <button {...props.dragHandleProp} type="button" className="button--hover-icon-not-scale" title={Intl.formatMessage({ id: "common.dragEntry" })}>
                <span className="show-for-sr">TODO</span>

                <span aria-hidden="true">
                    <SVGIconDragIndicator focusable="false" />
                </span>
            </button>
        </StyledDTC>
    );
};

export const StyledDTC = styled(DraggableTableCell)`
    width: 52px;

    button {
        color: ${Color.secondary};
    }
`;
