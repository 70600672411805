import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "theme/global";
import { BrowserRouter } from "react-router-dom";
import { theme } from "theme/theme";
import { store, persistor } from "./createStore";
import ReduxWrapper from "./ReduxWrapper";
import { Log } from "utils/Log";
import { art } from "utils/Art";
import { App } from "App";

Log.init();
art();

if (process.env.NODE_ENV === "development") {
    window.reduxStore = store;
}

ReactDOM.render(
    <ReduxWrapper reduxPersistor={persistor} reduxStore={store}>
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </>
        </ThemeProvider>
    </ReduxWrapper>,
    document.getElementById("root"),
);
