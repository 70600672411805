import { RouteComponentProps, withRouter } from "react-router-dom";
import React, { ChangeEvent } from "react";
import { Intl } from "i18n/Intl";
import { Main } from "components/cms/MainElements";
import { Select, SelectOption } from "components/Inputs/Select";
import { TableWrapper } from "components/TableElements";
import { Round, Season } from "api/graphql/types";
import LoadingOverlay from "components/LoadingOverlay";
import { Api } from "api/Api";
import { Alert } from "components/cms/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { MatchListTable } from "pages/Matches/MatchListTable";

type Props = RouteComponentProps;

interface State {
    isLoading: boolean;
    seasons: Season[];
    search: {
        season: string | null;
        round: string | null;
    };
    seasonOptions: SelectOption[];
    roundOptions: SelectOption[];
}

class MatchListPageComponent extends React.Component<Props, State> {
    public routeParams: URLSearchParams = new URLSearchParams(this.props.location.search);

    public state: State = {
        isLoading: false,
        seasons: [],
        search: {
            season: this.routeParams.get("season"),
            round: this.routeParams.get("round"),
        },
        seasonOptions: [],
        roundOptions: [],
    };

    public componentDidMount(): void {
        this.fetchSeasons();
    }

    private readonly fetchSeasons = async (): Promise<void> => {
        this.setState({ isLoading: true }, async () => {
            try {
                const response = await Api.getSeasonsWithRounds();
                this.setState(
                    {
                        seasons: response,
                        isLoading: false,
                    },
                    this.setSeasonOptions,
                );
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    private readonly setSeasonOptions = (): void => {
        const { seasons } = this.state;
        const seasonOptions: SelectOption[] = [
            {
                title: Intl.formatMessage({ id: "pages.matches.form.season.placeholder" }),
                value: undefined,
            },
        ];
        seasons.forEach((season: Season) => {
            seasonOptions.push({
                title: season.name,
                value: season.id,
            });
        });

        this.setState({
            seasonOptions,
        });
    };

    private readonly setRoundOptions = (seasonId: string | null): void => {
        const { seasons } = this.state;
        const season: Season | undefined = seasons.find(season => season.id === seasonId);
        const roundOptions: SelectOption[] = [
            {
                title: Intl.formatMessage({ id: "pages.matches.form.round.placeholder" }),
                value: undefined,
            },
        ];
        if (season) {
            season.rounds
                .sort((a, b) => {
                    return a.number < b.number ? 1 : a.number === b.number ? 0 : -1;
                })
                .forEach((round: Round) => {
                    roundOptions.push({
                        title: String(round.number),
                        value: round.id,
                    });
                });
        }
        this.setState({
            roundOptions,
        });
    };

    private readonly onSeasonChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        this.setState(
            {
                search: {
                    ...this.state.search,
                    season: event.currentTarget.value,
                },
            },
            () => this.setRoundOptions(this.state.search.season),
        );
    };

    private readonly onRoundChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        this.setState({
            search: {
                ...this.state.search,
                round: event.currentTarget.value,
            },
        });
    };

    public render() {
        const { search, isLoading } = this.state;

        if (isLoading) {
            return <LoadingOverlay />;
        }
        return (
            <>
                <Main.Heading headingText={Intl.formatMessage({ id: "pages.matches.list.title" })} />
                <TableWrapper>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell auto">
                            <Select fakeLabel={Intl.formatMessage({ id: "pages.matches.form.season.name" })} options={this.state.seasonOptions} onChange={this.onSeasonChange} />
                        </div>
                        <div className="cell auto">
                            <Select
                                fakeLabel={Intl.formatMessage({ id: "pages.matches.form.round.name" })}
                                disabled={this.state.roundOptions.length === 1}
                                options={this.state.roundOptions}
                                onChange={this.onRoundChange}
                            />
                        </div>
                    </div>
                    <MatchListTable search={search} />
                </TableWrapper>
            </>
        );
    }
}

const MatchListPage = withRouter(MatchListPageComponent);
export { MatchListPage };
