import React from "react";
import AppBar from "components/AppBar";
import { ModalError404 } from "components/Modals/ModalError404";

class Error404Page extends React.PureComponent {
    public render() {
        return (
            <>
                <AppBar />
                <ModalError404 />
            </>
        );
    }
}

export { Error404Page };
