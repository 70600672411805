import CKEditor from "@ckeditor/ckeditor5-react";
import { FormikProps, Field, FieldProps } from "formik";
import { isNil } from "lodash";
import React, { Component } from "react";
import slugify from "slugify";
import { CKEditorDefaultConfig } from "utils/Constants";
import { ContentInput } from "../../api/ApiTypes";
import { Button } from "../../components/Button";
import { InputCDN } from "../../components/cms/InputCDN";
import { Main } from "../../components/cms/MainElements";
import { DateInput } from "../../components/Inputs/DateInput";
import { Input } from "../../components/Inputs/Input";
import { InputWrapper, InnerWrapperIconWrapper, InputWrapperErrorWrapper } from "../../components/Inputs/InputWrapper";
import { SVGIconWarning } from "../../components/SVGCollection";
import { TableWrapper, TableFormWrapper } from "../../components/TableElements";
import { Intl } from "../../i18n/Intl";
import { CaptionText } from "../../theme/global";
import { Env } from "../../utils/Env";
import { PageType } from "../../utils/TypeUtils";
import { ValidatorConstants } from "../../utils/Validator";
import { TopInfo, LeadWrapper, ContentFieldWrapper } from "../ContentPage/ContentForm";
import { InformationPageExtraInfo } from "./InformationPage";

type Props<T> = {
    extraInfo?: InformationPageExtraInfo;
    pageType: PageType;
    formProps: FormikProps<T>;
    onBackClick: () => void;
};

// @ts-ignore
const CustomEditor = ClassicEditor;

class InformationForm extends Component<Props<ContentInput>> {
    private readonly onPageImageSelect = (field: string): void => {
        window.bfml.openLibrary({
            embedAuthToken: "auth",
            maximumSelectableAsset: 1,
            onSelection: (assets: string[]) => {
                this.props.formProps.setFieldValue(field, assets[0], true);
            },
        });
    };

    private readonly renderError = (error?: string): React.ReactElement => {
        return !isNil(error) ? (
            <>
                <InnerWrapperIconWrapper aria-hidden="true">
                    <SVGIconWarning focusable="false" />
                </InnerWrapperIconWrapper>
                <InputWrapperErrorWrapper as="div" aria-live="assertive" aria-atomic="true">
                    {error}
                </InputWrapperErrorWrapper>
            </>
        ) : (
            <></>
        );
    };

    private readonly onSaveClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, isPublished: boolean): void => {
        event.preventDefault();
        if (isPublished) {
            this.props.formProps.setFieldValue("is_active", true);
        }
        this.props.formProps.handleSubmit();
    };

    public render(): React.ReactElement {
        return (
            <form onSubmit={this.props.formProps.handleSubmit} noValidate>
                <Main.Heading headingText={Intl.formatMessage({ id: `pages.informations.${this.props.pageType}.title` })} backButtonClick={() => this.props.onBackClick()}>
                    <div className="grid-x align-middle grid-margin-x-15">
                        <div className="cell auto">
                            <Button
                                btnLabel={Intl.formatMessage({ id: "common.save" })}
                                secondary
                                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.onSaveClick(event, false)}
                            />
                        </div>
                        <div className="cell auto">
                            <Button btnLabel={Intl.formatMessage({ id: "common.publish" })} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.onSaveClick(event, true)} />
                        </div>
                    </div>
                </Main.Heading>
                <TableWrapper>
                    {this.props.pageType === PageType.edit && (
                        <div className="grid-x">
                            <div className="cell">
                                <TopInfo>
                                    <span>{Intl.formatMessage({ id: "pages.informations.form.topInfo.id" }, { id: this.props.extraInfo?.id })}</span>
                                    <span>
                                        {Intl.formatMessage({ id: "pages.informations.form.topInfo.updatedAt" }, { updatedAt: new Date(this.props.extraInfo?.updatedAt).toLocaleString("hu") })}
                                    </span>
                                    <span>
                                        {Intl.formatMessage(
                                            { id: "pages.informations.form.topInfo.publishedAt" },
                                            { publishedAt: this.props.extraInfo?.lastPublishedAt && new Date(this.props.extraInfo?.lastPublishedAt).toLocaleString("hu") },
                                        )}
                                    </span>
                                </TopInfo>
                            </div>
                        </div>
                    )}
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6 mt-35">
                            <Field name="url">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.informations.form.url.title" })} errorMsg={meta.error}>
                                        <Input
                                            type="text"
                                            ariaLabel={Intl.formatMessage({ id: "pages.informations.form.url.title" })}
                                            {...field}
                                            value={this.props.pageType === PageType.edit ? this.props.extraInfo?.url : field.value}
                                            readOnly={this.props.pageType === PageType.edit}
                                            isBackgroundBlue={this.props.pageType === PageType.edit}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                        <div className="cell auto date-input-max-width">
                            <CaptionText as="h2" thStyle={true} className="mt-0">
                                {Intl.formatMessage({ id: "pages.informations.form.active_from.title" })}
                            </CaptionText>

                            <div className="grid-x">
                                <div className="cell auto">
                                    <Field name="active_from">
                                        {({ field, form, meta }: FieldProps) => (
                                            <>
                                                <DateInput
                                                    {...field}
                                                    onChange={(date: Date | null) => {
                                                        form.setFieldValue(field.name, date);
                                                    }}
                                                    showClearButton
                                                />
                                                {this.renderError(meta.error)}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </div>
                        <div className="cell auto date-input-max-width">
                            <CaptionText as="h2" thStyle={true} className="mt-0">
                                {Intl.formatMessage({ id: "pages.informations.form.active_to.title" })}
                            </CaptionText>

                            <div className="grid-x">
                                <div className="cell auto">
                                    <Field name="active_to">
                                        {({ field, form, meta }: FieldProps) => (
                                            <>
                                                <DateInput
                                                    {...field}
                                                    onChange={(date: Date | null) => {
                                                        form.setFieldValue(field.name, date);
                                                    }}
                                                    showClearButton
                                                />
                                                {this.renderError(meta.error)}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6">
                            <Field name="title">
                                {({ field, form, meta }: FieldProps) => (
                                    <InputWrapper
                                        fakeLabel={Intl.formatMessage({ id: "pages.informations.form.title.title" }, { maxCharacters: ValidatorConstants.MAX_CONTENT_TITLE_LENGTH })}
                                        errorMsg={meta.error}
                                    >
                                        <Input
                                            type="text"
                                            ariaLabel={Intl.formatMessage({ id: "pages.informations.form.title.title" }, { maxCharacters: ValidatorConstants.MAX_CONTENT_TITLE_LENGTH })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                form.setFieldValue("title", e.currentTarget.value);
                                                // Editing the URL field is not permitted in edit mode
                                                if (this.props.pageType === PageType.create) {
                                                    form.setFieldValue("url", slugify(e.currentTarget.value.toLowerCase(), { lower: true }));
                                                }
                                            }}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                        <div className="cell small-6 auto">
                            <Field name="lead_image">
                                {({ field, form, meta }: FieldProps) => (
                                    <InputCDN
                                        browseButton={<Button onClick={() => this.onPageImageSelect("lead_image")} btnLabel={Intl.formatMessage({ id: "common.upload" })} />}
                                        showRemove={!!field.value && field.value !== ""}
                                        onRemove={() => {
                                            form.setFieldValue("lead_image", "", true);
                                        }}
                                        hideShowButton={!field.value}
                                        previewURL={`${Env.mediaLibraryAssetUrl}/${field.value}`}
                                    >
                                        <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.informations.form.lead_image.title" })} errorMsg={meta.error}>
                                            <Input
                                                type="text"
                                                readOnly={true}
                                                ariaLabel={Intl.formatMessage({
                                                    id: "pages.informations.form.lead_image.title",
                                                })}
                                                {...field}
                                            />
                                        </InputWrapper>
                                    </InputCDN>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6">
                            <Field name="front_page_title">
                                {({ field, form, meta }: FieldProps) => (
                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.informations.form.front_page_title.title" })} errorMsg={meta.error}>
                                        <Input
                                            type="text"
                                            ariaLabel={Intl.formatMessage({ id: "pages.informations.form.front_page_title.title" })}
                                            {...field}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => form.setFieldValue("front_page_title", e.currentTarget.value)}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                        <div className="cell small-6 auto">
                            <Field name="front_page_image">
                                {({ field, form, meta }: FieldProps) => (
                                    <InputCDN
                                        browseButton={<Button onClick={() => this.onPageImageSelect("front_page_image")} btnLabel={Intl.formatMessage({ id: "common.upload" })} />}
                                        showRemove={!!field.value && field.value !== ""}
                                        onRemove={() => {
                                            form.setFieldValue("front_page_image", "", true);
                                        }}
                                        hideShowButton={!field.value}
                                        previewURL={`${Env.mediaLibraryAssetUrl}/${field.value}`}
                                    >
                                        <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.informations.form.front_page_image.title" })} errorMsg={meta.error}>
                                            <Input
                                                type="text"
                                                readOnly={true}
                                                ariaLabel={Intl.formatMessage({
                                                    id: "pages.informations.form.front_page_image.title",
                                                })}
                                                {...field}
                                            />
                                        </InputWrapper>
                                    </InputCDN>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell auto">
                            <Field name="front_page_lead">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper
                                        fakeLabel={Intl.formatMessage(
                                            { id: "pages.informations.form.front_page_lead.title" },
                                            { maxCharacters: ValidatorConstants.MAX_CONTENT_FRONT_PAGE_LEAD_LENGTH },
                                        )}
                                        errorMsg={meta.error}
                                    >
                                        <Input
                                            type="text"
                                            ariaLabel={Intl.formatMessage(
                                                { id: "pages.informations.form.front_page_lead.title" },
                                                { maxCharacters: ValidatorConstants.MAX_CONTENT_FRONT_PAGE_LEAD_LENGTH },
                                            )}
                                            {...field}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell auto">
                            <Field name="lead">
                                {({ meta }: FieldProps) => (
                                    <>
                                        <CaptionText as="h2" thStyle={true} className="mt-0">
                                            {Intl.formatMessage({ id: "pages.informations.form.lead.title" })}
                                        </CaptionText>
                                        <InputWrapper hideFakeLabel fakeLabel={Intl.formatMessage({ id: "pages.informations.form.lead.title" })} errorMsg={meta.error}>
                                            <LeadWrapper>
                                                <CKEditor
                                                    editor={CustomEditor}
                                                    config={{
                                                        toolbar: {
                                                            items: ["bulletedList"],
                                                        },
                                                    }}
                                                    data={this.props.formProps.values.lead}
                                                    onChange={(_event: any, editor: any) => {
                                                        this.props.formProps.setFieldValue("lead", editor.getData());
                                                    }}
                                                />
                                            </LeadWrapper>
                                        </InputWrapper>
                                    </>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell auto">
                            <Field name="content">
                                {({ meta }: FieldProps) => (
                                    <ContentFieldWrapper>
                                        <CaptionText as="h2" thStyle={true} className="mt-0">
                                            {Intl.formatMessage({ id: "pages.informations.form.content.title" })}
                                        </CaptionText>
                                        <InputWrapper hideFakeLabel fakeLabel={Intl.formatMessage({ id: "pages.informations.form.content.title" })} errorMsg={meta.error}>
                                            <CKEditor
                                                editor={CustomEditor}
                                                config={{
                                                    toolbar: {
                                                        items: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "|", "indent", "outdent", "|", "blockQuote", "undo", "redo"],
                                                    },
                                                    heading: { ...CKEditorDefaultConfig.heading },
                                                }}
                                                data={this.props.formProps.values.content}
                                                onChange={(_event: any, editor: any) => {
                                                    // Uncomment this line to see CKEditor's content
                                                    // console.log(editor.getData());
                                                    this.props.formProps.setFieldValue("content", editor.getData());
                                                }}
                                            />
                                        </InputWrapper>
                                    </ContentFieldWrapper>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6">
                            <Field name="author">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.informations.form.author.title" })} errorMsg={meta.error}>
                                        <Input type="text" ariaLabel={Intl.formatMessage({ id: "pages.informations.form.author.title" })} {...field} />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                    </div>
                    <TableFormWrapper>
                        <table>
                            <thead>
                                <tr>
                                    <th>{Intl.formatMessage({ id: "pages.informations.form.metaTable.headers.name" })}</th>

                                    <th>{Intl.formatMessage({ id: "pages.informations.form.metaTable.headers.value" })}</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td className="w-190">{Intl.formatMessage({ id: "pages.informations.form.metaTable.meta_title.title" })}</td>

                                    <td>
                                        <Field name="meta_title">
                                            {({ field, meta, form }: FieldProps) => (
                                                <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.informations.form.metaTable.meta_title.title" })} errorMsg={meta.error}>
                                                    <Input
                                                        type="text"
                                                        ariaLabel={Intl.formatMessage({ id: "pages.informations.form.metaTable.meta_title.title" })}
                                                        {...field}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            form.setFieldValue("meta_title", e.currentTarget.value);
                                                        }}
                                                    />
                                                </InputWrapper>
                                            )}
                                        </Field>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="w-190">{Intl.formatMessage({ id: "pages.informations.form.metaTable.meta_description.title" })}</td>

                                    <td>
                                        <Field name="meta_description">
                                            {({ field, meta, form }: FieldProps) => (
                                                <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.informations.form.metaTable.meta_description.title" })} errorMsg={meta.error}>
                                                    <Input
                                                        type="text"
                                                        ariaLabel={Intl.formatMessage({ id: "pages.informations.form.metaTable.meta_description.title" })}
                                                        {...field}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            form.setFieldValue("meta_description", e.currentTarget.value);
                                                        }}
                                                    />
                                                </InputWrapper>
                                            )}
                                        </Field>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="w-190">{Intl.formatMessage({ id: "pages.informations.form.metaTable.meta_keywords.title" })}</td>

                                    <td>
                                        <Field name="meta_keywords">
                                            {({ field, meta, form }: FieldProps) => (
                                                <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.informations.form.metaTable.meta_keywords.title" })} errorMsg={meta.error}>
                                                    <Input
                                                        type="text"
                                                        ariaLabel={Intl.formatMessage({ id: "pages.informations.form.metaTable.meta_keywords.title" })}
                                                        {...field}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            form.setFieldValue("meta_keywords", e.currentTarget.value);
                                                        }}
                                                    />
                                                </InputWrapper>
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-190">{Intl.formatMessage({ id: "pages.informations.form.metaTable.meta_image.title" })}</td>

                                    <td>
                                        <Field name="meta_image">
                                            {({ field, form, meta }: FieldProps) => (
                                                <InputCDN
                                                    browseButton={<Button onClick={() => this.onPageImageSelect("meta_image")} btnLabel={Intl.formatMessage({ id: "common.browse" })} />}
                                                    showRemove={!!field.value && field.value !== ""}
                                                    onRemove={() => {
                                                        form.setFieldValue("meta_image", "", true);
                                                    }}
                                                    hideShowButton={!field.value}
                                                    previewURL={`${Env.mediaLibraryAssetUrl}/${field.value}`}
                                                    showButtonClassName="h-40"
                                                >
                                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.informations.form.metaTable.meta_image.title" })} errorMsg={meta.error}>
                                                        <Input type="text" readOnly={true} ariaLabel={Intl.formatMessage({ id: "pages.informations.form.metaTable.meta_image.title" })} {...field} />
                                                    </InputWrapper>
                                                </InputCDN>
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </TableFormWrapper>
                </TableWrapper>
            </form>
        );
    }
}

export { InformationForm };
