import React from "react";
import { Main } from "components/cms/MainElements";
import { Intl } from "i18n/Intl";
import { Decade, DecadeField, listDecadesVariables, OrderDirection } from "api/graphql/types";
import { Column, Table } from "components/Tables/Table";
import { Alert } from "components/cms/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { ObjectUtils } from "utils/ObjectUtils";
import * as _ from "lodash";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Path } from "utils/Path";
import { isNil } from "lodash";

type Props = {} & RouteComponentProps;

interface State {
    options: listDecadesVariables;
    total: number;
    pageCount: number;

    decades: Decade[];
    isLoading: boolean;
}

enum DecadeListTableColumn {
    title = "title",
    id = "id",
    years = "years",
    updated_at = "updated_at",
}

class DecadeListPageComponent extends React.Component<Props, State> {
    public state: State = {
        options: {
            count: Table.DEFAULT_PAGE_SIZE,
            page: 1,
        },
        total: 0,
        pageCount: 0,
        decades: [],
        isLoading: true,
    };

    public componentDidMount(): void {
        this.fetchDecades();
    }

    public componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (!_.isEqual(prevState.options, this.state.options)) {
            this.fetchDecades();
        }
    }

    public readonly fetchDecades = async (): Promise<void> => {
        this.setState({ isLoading: true }, async () => {
            try {
                const { data, paginatorInfo } = await Api.listDecades(this.state.options);

                this.setState({
                    decades: data,
                    total: paginatorInfo.total,
                    pageCount: paginatorInfo.lastPage,
                    isLoading: false,
                });
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.setState({
                    isLoading: false,
                });
            }
        });
    };

    private readonly columns: Array<Column<Decade>> = ObjectUtils.enumAsArray<DecadeListTableColumn>(DecadeListTableColumn).map(
        (columnName: DecadeListTableColumn): Column<Decade> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `pages.decades.list.table.${columnName}` }),
            accessor: columnName as keyof Decade,
            renderCell: (decadeListItem: Decade): React.ReactElement<any> | null => {
                switch (columnName) {
                    case DecadeListTableColumn.id:
                        return <>{decadeListItem.id}</>;
                    case DecadeListTableColumn.updated_at:
                        return <>{new Date(decadeListItem.updated_at).toLocaleString("hu")}</>;
                    default:
                        return null;
                }
            },
            isNonSortable: ![DecadeListTableColumn.id, DecadeListTableColumn.updated_at].includes(columnName),
        }),
    );

    private convertSortFieldToColumnId = (columnId?: DecadeField | null): keyof Decade | undefined => {
        switch (columnId) {
            case DecadeField.ID:
                return DecadeListTableColumn.id;
            case DecadeField.UPDATED_AT:
                return DecadeListTableColumn.updated_at;
            default:
                return undefined;
        }
    };

    private convertColumnIdToSortField = (columnId?: string): DecadeField | undefined => {
        switch (columnId) {
            case DecadeListTableColumn.id:
                return DecadeField.ID;
            case DecadeListTableColumn.updated_at:
                return DecadeField.UPDATED_AT;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<Decade>, order?: OrderDirection): void => {
        const sortField = this.convertColumnIdToSortField(column?.id || undefined);
        this.setState({
            options: {
                ...this.state.options,
                sortBy: !isNil(sortField)
                    ? {
                          field: sortField,
                          direction: order || OrderDirection.ASC,
                      }
                    : undefined,
            },
        });
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        this.setState({
            options: {
                ...options,
                page: pageNum,
            },
        });
    };

    public render(): React.ReactElement {
        return (
            <>
                <Main.Heading headingText={Intl.formatMessage({ id: "pages.decades.list.title" })} />
                <Table
                    keyExtractor={(item: Decade, column?: Column<Decade>): string => {
                        return `${item.id}_${column?.id || ""}`;
                    }}
                    columns={this.columns}
                    sortBy={{
                        columnId: this.convertSortFieldToColumnId(this.state.options.sortBy?.field),
                        order: this.state.options.sortBy?.direction || undefined,
                    }}
                    data={this.state.decades}
                    count={this.state.total}
                    pageCount={this.state.pageCount}
                    currentPage={this.state.options.page || 1}
                    isSortable={true}
                    onSortOrderChange={this.onSortOrderChange}
                    onPageChange={this.onPageChange}
                    isPaginationEnabled={this.state.total > Table.DEFAULT_PAGE_SIZE}
                    isLoading={this.state.isLoading}
                    onRowClick={(decadeListItem: Decade): void => {
                        this.props.history.push(Path.editDecade(decadeListItem.id));
                    }}
                    renderEmpty={() => ""}
                />
            </>
        );
    }
}

const DecadeListPage = withRouter(DecadeListPageComponent);

export { DecadeListPage };
