import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Color } from "theme/theme";
import { H2 } from "theme/global";
import { StyledButton } from "components/Button";
import { SVGIconArrowLeft } from "components/SVGCollection";
import { Intl } from "i18n/Intl";

export enum InfobarDisplayType {
    List,
    ListReadonly,
    Create,
    Edit,
    Hidden,
}

type PropType = {
    headingIcon?: React.ReactElement;
    headingText: string;
    backButtonClick?: () => void;
    infobarDisplayType?: InfobarDisplayType;
};

export const HeadingWrapper: React.FC<PropsWithChildren<PropType>> = props => {
    return (
        <>
            <HeadingStickyWrapper className="grid-x align-middle">
                {props.backButtonClick && (
                    <BackButtonWrapper className="cell shrink">
                        <button type="button" onClick={props.backButtonClick}>
                            <span className="show-for-sr">{Intl.formatMessage({ id: "common.back" })}</span>

                            <span aria-hidden="true">
                                <SVGIconArrowLeft width={48} height={48} viewBox="0 0 24 24" focusable="false" />
                            </span>
                        </button>
                    </BackButtonWrapper>
                )}

                <div className="grid-x align-middle cell auto">
                    <TextAndIconWrapper className="cell shrink">
                        {props.headingIcon && <HeadingIcon>{props.headingIcon}</HeadingIcon>}
                        <H2 as="h1">{props.headingText}</H2>
                    </TextAndIconWrapper>
                </div>

                <div className="cell shrink">{props.children}</div>
            </HeadingStickyWrapper>
        </>
    );
};

const HeadingStickyWrapper = styled.div`
    background-color: ${Color.grayL};
    padding: 20px 30px;
    position: sticky;
    top: 69px;
    z-index: 4;

    ${H2} {
        margin-bottom: 0;
        margin-top: 0;
        padding-right: 30px;
    }

    ${StyledButton} {
        min-width: 152px;
    }
`;

const BackButtonWrapper = styled.div`
    padding-right: 30px;
`;

const TextAndIconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const HeadingIcon = styled.div`
    margin-right: 5px;
`;

export const Main = {
    Heading: HeadingWrapper,
};
