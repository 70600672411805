import React, { ReactNode } from "react";
import styled from "styled-components";

import { hexToRGB, keyframesBlinkInputShadow } from "theme/global";
import { InputWrapperStyled, InputWrapperErrorWrapper } from "components/Inputs/InputWrapper";

type Props = {
    errorMsg?: string;
    hasError?: boolean;
    innerRef?: Exclude<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>["ref"], string>;
    name: string;
    radioLabel: ReactNode;
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "ref">;

class RadioComponent extends React.PureComponent<Props> {
    render(): JSX.Element {
        const { errorMsg, hasError, innerRef, radioLabel, ...otherProps } = this.props;

        return (
            <RadioWrapper as="label">
                <Input ref={innerRef} className="show-for-sr" type="radio" {...otherProps} />

                {radioLabel}

                {errorMsg && <InputWrapperErrorWrapper>{errorMsg}</InputWrapperErrorWrapper>}

                <RadioToggle aria-hidden="true" hasError={hasError} />
            </RadioWrapper>
        );
    }
}

export const RadioWrapper = styled(InputWrapperStyled)`
    color: ${props => props.theme.typo.checkbox.color};
    display: block;
    min-height: ${props => props.theme.box.radio.height}px;
    padding-left: ${props => props.theme.box.radio.paddingLeft}px;
    padding-top: ${props => Math.ceil((props.theme.box.radio.height - props.theme.typo.body.lineHeight) / 2)}px;
    position: relative;

    & + ${InputWrapperStyled} {
        margin-top: ${props => props.theme.box.body.formSpacing}px;
    }
`;

export const RadioToggle = styled.span<{ hasError?: boolean }>`
    align-items: center;
    background-color: ${props => props.theme.background.radio.backgroundColor};
    border-radius: ${props => props.theme.box.radio.borderRadius};
    border: ${props => (props.hasError ? props.theme.box.input.hasError.borderWidth : props.theme.box.radio.borderWidth)}px solid
        ${props => (props.hasError ? props.theme.box.input.hasError.borderColor : props.theme.box.radio.borderColor)};
    color: ${props => props.theme.typo.checkbox.color};
    cursor: pointer;
    display: flex;
    height: ${props => props.theme.box.radio.height}px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: ${props => props.theme.box.radio.width}px;

    &::before {
        background-color: ${props => props.theme.background.radio.innerToggle.backgroundColor};
        border-radius: ${props => props.theme.box.radio.innerToggle.borderRadius};
        box-shadow: ${props => props.theme.box.radio.innerToggle.boxShadow}
            rgba(${props => hexToRGB(props.theme.box.radio.innerToggle.boxShadowColor)}, ${props => props.theme.box.radio.innerToggle.boxShadowOpacity});
        content: "";
        height: ${props => props.theme.box.radio.innerToggle.height}px;
        transform-origin: center;
        transform: scale(0);
        transition: transform 125ms ease-in;
        width: ${props => props.theme.box.radio.innerToggle.width}px;
    }
`;

export const Input = styled.input`
    &:checked {
        ~ ${RadioToggle} {
            &::before {
                transform: scale(1);
            }
        }
    }

    &:focus {
        &:not(:focus-visible) {
            ~ ${RadioToggle} {
                box-shadow: none;
            }
        }
    }

    &:focus-visible {
        ~ ${RadioToggle} {
            animation: ${keyframesBlinkInputShadow} 1s 1;
        }
    }
`;

export const Radio: React.ComponentClass<Props> = RadioComponent;
