import gql from "graphql-tag";
import { DocumentNode } from "graphql";

import { Fragments } from "./Fragments";

class Mutations {
    public static readonly adminLogin: DocumentNode = gql`
        mutation adminLogin($credentials: AdminLoginDataInput!) {
            adminLogin(credentials: $credentials) {
                ...AdminAuthenticationResult
            }
        }
        ${Fragments.adminAuthenticationResult}
    `;

    public static readonly adminLogout: DocumentNode = gql`
        mutation adminLogout {
            adminLogout
        }
    `;

    public static readonly resetAdminPassword: DocumentNode = gql`
        mutation resetAdminPassword($input: SetAdminPasswordInput!) {
            resetAdminPassword(input: $input) {
                ...AdminAuthenticationResult
            }
        }
        ${Fragments.adminAuthenticationResult}
    `;

    public static readonly adminPasswordReset: DocumentNode = gql`
        mutation adminPasswordReset($email: String!) {
            adminPasswordReset(email: $email)
        }
    `;

    public static readonly createAdmin: DocumentNode = gql`
        mutation createAdmin($createData: CreateAdminDataInput!) {
            createAdmin(createData: $createData) {
                ...Admin
            }
        }
        ${Fragments.admin}
    `;

    public static readonly updateAdmin: DocumentNode = gql`
        mutation updateAdmin($input: UpdateAdminDataInput!) {
            updateAdmin(input: $input) {
                ...Admin
            }
        }
        ${Fragments.admin}
    `;

    public static readonly createContent: DocumentNode = gql`
        mutation createContent($input: CreateContentInput!) {
            createContent(input: $input) {
                ... on TextContent {
                    id
                    url
                }
                ... on GalleryContent {
                    id
                    url
                }
                ... on VideoContent {
                    id
                    url
                }
            }
        }
    `;

    public static readonly updateContent: DocumentNode = gql`
        mutation updateContent($id: ID!, $input: UpdateContentInput!) {
            updateContent(id: $id, input: $input) {
                ... on TextContent {
                    id
                }
                ... on GalleryContent {
                    id
                }
                ... on VideoContent {
                    id
                }
            }
        }
    `;

    public static readonly updateMatch: DocumentNode = gql`
        mutation updateMatch($id: ID!, $input: UpdateMatchInput!) {
            updateMatch(id: $id, input: $input) {
                ...Match
            }
        }
        ${Fragments.match}
    `;

    public static readonly updateFrontPageTopSection: DocumentNode = gql`
        mutation updateFrontPageTopSection($contentIds: [ID!]!) {
            updateFrontPageTopSection(contentIds: $contentIds) {
                ...AnyContentListItem
            }
        }
        ${Fragments.anyContentListItem}
    `;

    public static readonly updateDecade: DocumentNode = gql`
        mutation updateDecade($id: ID!, $input: UpdateDecadeInput!) {
            updateDecade(id: $id, input: $input) {
                ...Decade
            }
        }
        ${Fragments.decade}
    `;

    public static readonly createGallery: DocumentNode = gql`
        mutation createGallery($input: CreateGalleryInput!) {
            createGallery(input: $input) {
                ...Gallery
            }
        }
        ${Fragments.gallery}
    `;

    public static readonly updateGallery: DocumentNode = gql`
        mutation updateGallery($id: ID!, $input: UpdateGalleryInput!) {
            updateGallery(id: $id, input: $input) {
                ...Gallery
            }
        }
        ${Fragments.gallery}
    `;

    public static readonly createInformation: DocumentNode = gql`
        mutation createInformation($input: CreateInformationInput!) {
            createInformation(input: $input) {
                ...Information
            }
        }
        ${Fragments.information}
    `;

    public static readonly updateInformation: DocumentNode = gql`
        mutation updateInformation($id: ID!, $input: UpdateInformationInput!) {
            updateInformation(id: $id, input: $input) {
                ...Information
            }
        }
        ${Fragments.information}
    `;

    public static readonly uploadDocument: DocumentNode = gql`
        mutation uploadDocument($file: Upload!) {
            uploadDocument(file: $file) {
                ...Document
            }
        }
        ${Fragments.document}
    `;
}

export { Mutations };
