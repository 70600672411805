import { ContentType, CreateContentInput, GalleryContent, TextContent, UpdateContentInput, VideoContent } from "./graphql/types";

export type AnyContent = TextContent | GalleryContent | VideoContent;

export const ContentTypePagePath = {
    text: ContentType.TEXT.toLowerCase(),
    gallery: ContentType.GALLERY.toLowerCase(),
    video: ContentType.VIDEO.toLowerCase(),
};

export const ContentTypeListPath = {
    all: "all",
    ...ContentTypePagePath,
};

export type ContentInput = CreateContentInput | UpdateContentInput;

export enum ContentTypename {
    TextContent = "TextContent",
    GalleryContent = "GalleryContent",
    VideoContent = "VideoContent",
}
