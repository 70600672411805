import React, { ReactNode } from "react";
import styled from "styled-components";

import { Color } from "theme/theme";
import { hexToRGB, H2 } from "theme/global";
import { SVGIconEdit, SVGIconSettings } from "components/SVGCollection";

interface Props {
    children?: ReactNode;
    headerText: string;
    headerTextIcon?: "draft" | "system";
    renderActionButtons?: ReactNode;
    renderSelect?: ReactNode;
    [x: string]: any;
}

class HeaderSection extends React.PureComponent<Props> {
    render(): JSX.Element {
        const { headerText, headerTextIcon, renderSelect, renderActionButtons, children } = this.props;

        return (
            <HeaderSectionWrapper {...this.props}>
                <FixedContainer>
                    <FixedHeader>
                        <div className="grid-x align-middle">
                            {headerTextIcon === "draft" && (
                                <HeaderTextIconWrapper className="cell shrink" aria-hidden="true">
                                    <SVGIconEdit width={31} height={31} focusable="false" />
                                </HeaderTextIconWrapper>
                            )}

                            {headerTextIcon === "system" && (
                                <HeaderTextIconWrapper className="cell shrink" aria-hidden="true">
                                    <SVGIconSettings width={32} height={32} focusable="false" />
                                </HeaderTextIconWrapper>
                            )}

                            <div className="cell auto">
                                <H2 as="h1">
                                    {headerTextIcon === "draft" && (
                                        <>
                                            <span className="show-for-sr">Draft version</span>{" "}
                                        </>
                                    )}

                                    {headerTextIcon === "system" && (
                                        <>
                                            <span className="show-for-sr">System version</span>{" "}
                                        </>
                                    )}

                                    {headerText}
                                </H2>
                            </div>

                            {renderSelect && <SelectContainer className="cell shrink">{renderSelect}</SelectContainer>}

                            {renderActionButtons && <ActionButtonsContainer className="cell shrink">{renderActionButtons}</ActionButtonsContainer>}
                        </div>
                    </FixedHeader>
                </FixedContainer>

                {children}
            </HeaderSectionWrapper>
        );
    }
}

const FixedContainer = styled.div`
    height: ${props => props.theme.box.headerSection.height}px;
`;

const FixedHeader = styled.div`
    z-index: 1050;
    left: 0;
    right: 0;
    padding: 0 30px;
    position: fixed;
    background-color: ${Color.grayL};
`;

const HeaderSectionWrapper = styled.div`
    padding-left: 30px;
    padding-right: 30px;

    ${H2} {
        margin-bottom: 17px;
        margin-top: 16px;
    }
`;

const HeaderTextIconWrapper = styled.div`
    padding-right: 17px;
`;

const ActionButtonsContainer = styled.div`
    padding-left: 20px;

    > .grid-x {
        margin-left: -10px;
        margin-right: -20px;

        > .cell {
            margin-left: 10px;
            margin-right: 10px;

            &.small-6 {
                width: calc(${(6 * 100) / 12}% - ${2 * 10}px);
            }
        }
    }

    button,
    .a--button {
        height: 44px;
        padding-left: 10px;
        padding-right: 10px;

        svg {
            transform-origin: center;
            transform: scale(1);
            transition: 125ms transform ease;
        }

        &:hover:not(:disabled) {
            color: ${Color.secondary};
            svg {
                transform: scale(1.35);
            }
        }

        &:active:not(:disabled) {
            svg {
                transform: scale(1.1);
            }
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.2;
        }
    }
`;

const SelectContainer = styled.div`
    > .grid-x {
        margin-left: -10px;
        margin-right: -10px;

        > .cell {
            margin-left: 10px;
            margin-right: 10px;

            &.small-6 {
                width: calc(${(6 * 100) / 12}% - ${2 * 10}px);
            }
        }
    }

    & + ${ActionButtonsContainer} {
        padding-left: 21px;
        position: relative;

        &::before {
            border-left: 1px solid rgba(${hexToRGB(Color.secondaryD)}, 0.2);
            content: "";
            height: 29px;
            left: 20px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
`;

export { HeaderSection };
