import React, { ChangeEvent } from "react";
import * as _ from "lodash";
import { Api } from "api/Api";
import { Gallery, listGalleriesVariables } from "api/graphql/types";
import { Button } from "components/Button";
import { Alert } from "components/cms/Alert/Alert";
import { Search } from "components/Inputs/Search";
import Modal, { StyledModalHeader, ModalInputWrapper, ButtonWrapper } from "components/Modals/Modal";
import { SSkeletonLine } from "components/Skeleton";
import { SVGIconSearch } from "components/SVGCollection";
import { Table as TableElement } from "components/TableElements";
import { EmptyList } from "components/Tables/Table";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { H2, CaptionText } from "theme/global";
import { Color } from "theme/theme";
import { StyledTableWrapper, ScrollableWrapper } from "./ContentModal";

type Props = {
    mounted: boolean;
    onGallerySelected: (gallery: Gallery) => void;
    onModalClose: () => void;
    selectedGallery?: string;
};

interface State {
    options: listGalleriesVariables;
    galleries: Gallery[];
    search: string;
    isLoading: boolean;
    hasMorePages: boolean;
    selected?: Gallery;
}

class GalleryModal extends React.Component<Props, State> {
    public state: State = {
        options: {
            count: Modal.DEFAULT_PAGE_SIZE,
            page: 1,
        },
        galleries: [],
        search: "",
        isLoading: false,
        hasMorePages: true,
    };

    public componentDidMount(): void {
        this.fetchGalleries();
    }

    private readonly onFilterChange = _.debounce(() => {
        const { search, options } = this.state;
        const isId = /^\d+$/.test(search);
        this.setState(
            {
                options: {
                    ...options,
                    filters: {
                        title: !isId ? search : undefined,
                        id: isId ? search : undefined,
                    },
                },
            },
            () => this.fetchGalleries(),
        );
    }, 500);

    private readonly onSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
        this.setState(
            {
                search: e.currentTarget.value,
            },
            () => this.onFilterChange(),
        );
    };

    private readonly onSearchClear = (): void => {
        this.setState(
            {
                search: "",
            },
            () => this.onFilterChange(),
        );
    };

    private readonly loadMoreGallery = () => {
        const { options, hasMorePages } = this.state;

        if (hasMorePages) {
            this.setState(
                {
                    options: {
                        ...options,
                        page: (options.page || 1) + 1,
                    },
                },
                () => this.fetchGalleries(false),
            );
        }
    };

    private fetchGalleries = (isRefresh: boolean = true): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { paginatorInfo, data } = await Api.listGalleries(this.state.options);

                    this.setState({
                        galleries: isRefresh ? data : this.state.galleries.concat(data),
                        hasMorePages: paginatorInfo.hasMorePages,
                        isLoading: false,
                        selected: this.props.selectedGallery && !this.state.selected ? data.find(gallery => gallery.id === this.props.selectedGallery) : undefined,
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ galleries: [], isLoading: false });
                }
            },
        );
    };

    private readonly isSelected = (gallery: Gallery): boolean => {
        const { selected } = this.state;
        return selected ? selected.id === gallery.id : false;
    };

    private readonly toggleSelected = (gallery: Gallery): void => {
        if (this.isSelected(gallery)) {
            this.setState({
                selected: undefined,
            });
            return;
        }

        this.setState({
            selected: gallery,
        });
    };

    private readonly renderSelectedTableBody = (galleries: Gallery[], selected?: Gallery): React.ReactElement | null => {
        const inGalleries: boolean = galleries.some(gallery => gallery.id === selected?.id);

        if (!selected || inGalleries) {
            return null;
        }

        return (
            <tr className={"clickable selected"} onClick={() => this.toggleSelected(selected)}>
                <td>{selected.title}</td>
                <td>{selected.image_count}</td>
                <td>{selected.updated_at}</td>
            </tr>
        );
    };

    private readonly renderTableBody = (galleries: Gallery[]): React.ReactElement[] => {
        return galleries.map(
            (gallery: Gallery, key): React.ReactElement => {
                return (
                    <tr key={key} className={`clickable ${this.isSelected(gallery) ? "selected" : ""}`} onClick={() => this.toggleSelected(gallery)}>
                        <td>{gallery.title}</td>
                        <td>{gallery.image_count}</td>
                        <td>{gallery.updated_at}</td>
                    </tr>
                );
            },
        );
    };

    private readonly dataLoadingView = (columnCount = 3, rowCount = 4) => {
        let i = 0;
        return (
            <>
                {new Array(rowCount).fill("1").map(() => {
                    return (
                        <tr key={`skeleton-${++i}`}>
                            {new Array(columnCount).fill("1").map(() => {
                                return (
                                    <td key={`skeleton-${++i}`}>
                                        <SSkeletonLine />
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </>
        );
    };

    private readonly handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.loadMoreGallery();
        }
    };

    private readonly returnSelectedGallery = (): void => {
        this.props.onGallerySelected(this.state.selected!);
        this.props.onModalClose();
    };

    public render(): React.ReactElement {
        const { isLoading, galleries, hasMorePages, selected } = this.state;

        return (
            <Modal mounted={this.props.mounted} onModalClose={() => this.props.onModalClose()} underlayColor={Color.modal} titleText={Intl.formatMessage({ id: "components.galleryModal.title" })}>
                <StyledModalHeader>
                    <H2 as="h5">{Intl.formatMessage({ id: "components.galleryModal.title" })}</H2>
                </StyledModalHeader>
                <ModalInputWrapper>
                    <Search ariaLabel={Intl.formatMessage({ id: "components.galleryModal.search" })} value={this.state.search} onChange={this.onSearchChange} onClearClick={this.onSearchClear} />
                </ModalInputWrapper>
                <SSkeletonLine />
                <StyledTableWrapper className={"p-0 mt-30"}>
                    <ScrollableWrapper onScroll={this.handleScroll}>
                        <TableElement>
                            <thead>
                                <th>{Intl.formatMessage({ id: "components.galleryModal.table.title" })}</th>
                                <th>{Intl.formatMessage({ id: "components.galleryModal.table.image_count" })}</th>
                                <th>{Intl.formatMessage({ id: "components.galleryModal.table.updated_at" })}</th>
                            </thead>
                            <tbody>
                                {this.renderSelectedTableBody(galleries, selected)}
                                {galleries.length && !isLoading ? (
                                    this.renderTableBody(galleries)
                                ) : isLoading ? (
                                    <>
                                        {this.renderTableBody(galleries)}
                                        {this.dataLoadingView()}
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan={100}>
                                            <EmptyList>
                                                <SVGIconSearch width={64} height={64} focusable="false" />
                                                <br />
                                                <span>{Intl.formatMessage({ id: "components.table.emptyList" })}</span>
                                            </EmptyList>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </TableElement>
                        {!hasMorePages && (
                            <CaptionText className={"text-align--center"} as="h2">
                                {Intl.formatMessage({ id: "components.galleryModal.notMorePages" })}
                            </CaptionText>
                        )}
                    </ScrollableWrapper>
                </StyledTableWrapper>
                <ButtonWrapper>
                    <div className="grid-x grid-margin-y-10">
                        <div className="cell auto grid-x align-right">
                            <Button btnLabel={Intl.formatMessage({ id: "common.cancel" })} secondary={true} className="align-right" onClick={this.props.onModalClose} />
                        </div>
                        <div className="cell auto grid-x align-left">
                            <Button btnLabel={Intl.formatMessage({ id: "common.insert" })} disabled={!selected} onClick={this.returnSelectedGallery} />
                        </div>
                    </div>
                </ButtonWrapper>
            </Modal>
        );
    }
}

export { GalleryModal };
