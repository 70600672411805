import React, { ReactNode } from "react";
import styled, { StyledComponentProps } from "styled-components";

import { Color } from "theme/theme";
import { hexToRGB, H5 } from "theme/global";
import { StyledButton, LabelWrapper } from "components/Button";
import { StyledInput } from "components/Inputs/Input";
import { StyledInputCDN, ShowButton } from "components/cms/InputCDN";
import { FakeLabel } from "components/Inputs/InputWrapper";
import { StyledDTC } from "./Tables/DragHandle";

type TableWrapperProps = {
    children?: ReactNode;
    renderBottomButtonContainer?: ReactNode;
} & StyledComponentProps<"div", {}, {}, never>;

export const TableWrapper: React.FC<TableWrapperProps> = props => {
    return (
        <StyledTableWrapper {...props}>
            {props.children}

            {props.renderBottomButtonContainer && (
                <TableWrapperBottomButtonContainer>
                    <TableWrapperBottomButtonFixedContainer>{props.renderBottomButtonContainer}</TableWrapperBottomButtonFixedContainer>
                </TableWrapperBottomButtonContainer>
            )}
        </StyledTableWrapper>
    );
};

export const MissingData: React.FC<{ customText?: string }> = ({ customText }) => <MissingDataContainer>{customText ?? "n/a"}</MissingDataContainer>;

const MissingDataContainer = styled.span`
    font-style: italic;
    opacity: 0.4;
`;

const StyledTableWrapper = styled.div`
    background-color: ${Color.white};
    /* overflow-x: auto; */
    padding: 40px 30px;

    th {
        &.th--sticky {
            background-color: ${Color.white};
            position: sticky;
            top: ${props => props.theme.box.appBar.height + props.theme.box.headerSection.height - 10}px;
            z-index: 3;

            &.sortable {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    > div {
        & + div {
            margin-top: 44px !important;
        }
    }
`;

const TableWrapperBottomButtonContainer = styled.div`
    height: 104px;
`;

const TableWrapperBottomButtonFixedContainer = styled.div`
    background-color: ${Color.gray};
    bottom: 0;
    left: 0;
    padding: 28px 30px;
    position: fixed;
    right: 0;

    .cell {
        &.shrink {
            width: 181px;
        }
    }
`;

export const TableActionButtonsContainer = styled.div`
    height: 38px;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition: opacity 150ms ease-in;
    width: 1px;

    &.comparing {
        width: auto;
        opacity: 1;
    }

    &.grid-x {
        flex-wrap: nowrap;
        margin-right: -18px;

        > .cell {
            margin-left: 9px;
            margin-right: 9px;
        }
    }

    button,
    .a--button {
        color: ${Color.secondaryD};
        height: 38px;
        padding-left: 9px;
        padding-right: 9px;

        svg {
            transform-origin: center;
            transform: scale(1);
            transition: 125ms transform ease;
        }

        &:hover {
            color: ${Color.secondary};

            svg {
                transform: scale(1.35);
            }
        }

        &:active {
            svg {
                transform: scale(1.1);
            }
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.2;
        }

        &.button--hover-icon-not-scale {
            &:active,
            &:hover {
                svg {
                    transform: none;
                }
            }
        }
    }

    &.action-buttons-container--is-absolute {
        align-items: center;
        background-color: ${props => props.theme.background.table.tr.hover.backgroundColor};
        bottom: 0;
        height: auto;
        position: absolute;
        right: ${props => props.theme.box.table.td.padding}px;
        top: 0;
    }
`;

export const TableActionButtonsCellContainer = styled.div`
    height: 38px;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition: opacity 150ms ease-in;
    width: 1px;

    &.grid-x {
        flex-wrap: nowrap;
        margin-right: -18px;

        > .cell {
            margin-left: 9px;
            margin-right: 9px;
        }
    }

    button,
    .a--button {
        color: ${Color.secondaryD};
        height: 38px;
        padding-left: 9px;
        padding-right: 9px;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.2;
        }
    }
`;

export const DraggableTableRow = styled.tr<{ isDragging: boolean }>`
    background-color: ${props => (!props.isDragging ? "transparent" : `${props.theme.background.table.tr.hover.backgroundColor} !important;`)};

    ${StyledDTC} {
        opacity: ${props => (!props.isDragging ? "0" : "1 !important")};
    }
`;

export const Table = styled.table<{ noWrapIds?: number[]; isDragging?: boolean }>`
    border-collapse: collapse;
    position: relative;
    width: 100%;

    caption,
    .caption {
        color: ${props => props.theme.typo.body.color};
        text-align: left;
        text-transform: none;

        ${H5} {
            margin-bottom: 28px;
            margin-top: 28px;
        }

        .cell {
            &.shrink {
                > svg {
                    margin-right: 12px;
                }
            }
        }
    }

    thead {
        border-bottom: 1px solid ${Color.grayD};
    }

    tbody {
        tr {
            &.clickable:hover {
                cursor: pointer;
            }
            &.selected {
                background-color: ${Color.grayD};
                &:hover {
                    background-color: ${Color.grayD} !important;
                    box-shadow: 0px 0.5px 0px 2px ${Color.secondary} inset;
                    border-radius: 5px;
                }
            }

            &.disabled {
                background-color: ${Color.grayD};
                opacity: 0.3;
                &:hover {
                    background-color: ${Color.grayD} !important;
                    cursor: not-allowed;
                }
            }
        }

        ${DraggableTableRow},
        tr {
            border-top: ${props => props.theme.box.table.td.borderTopWidth}px solid ${props => props.theme.box.table.td.borderTopColor};
            border-bottom: ${props => props.theme.box.table.td.borderTopWidth}px solid ${props => props.theme.box.table.td.borderTopColor};
            background-color: white;

            &.align-top {
                vertical-align: top;
            }

            ${StyledDTC} {
                opacity: 0;
            }

            &.clickable:hover {
                background-color: ${props => (props.isDragging ? "white" : props.theme.background.table.tr.hover.backgroundColor)};

                ${StyledDTC} {
                    opacity: ${props => (props.isDragging ? 0 : 1)};
                }

                ${TableActionButtonsContainer} {
                    opacity: ${props => (props.isDragging ? "0" : "1")};
                    width: ${props => (props.isDragging ? "1px" : "auto")};
                }
            }

            &.tr--bg-transparent {
                background-color: transparent !important;
            }

            &.tr--has-error {
                background-color: rgba(${hexToRGB(Color.redXL)}, 0.2) !important;
            }

            &.tr--is-emphasized {
                > td,
                > th {
                    background-color: ${Color.gray} !important;
                    color: ${Color.secondaryD};
                    position: relative;

                    &::before {
                        border-bottom: 2px solid ${Color.secondary};
                        border-top: 2px solid ${Color.secondary};
                        bottom: -2px;
                        content: "";
                        left: -2px;
                        pointer-events: none;
                        position: absolute;
                        right: -2px;
                        top: -2px;
                        z-index: 1;
                    }

                    &:first-of-type {
                        &::before {
                            border-bottom-left-radius: 5px;
                            border-left: 2px solid ${Color.secondary};
                            border-top-left-radius: 5px;
                        }
                    }

                    &:last-of-type {
                        &::before {
                            border-bottom-right-radius: 5px;
                            border-right: 2px solid ${Color.secondary};
                            border-top-right-radius: 5px;
                        }
                    }
                }
            }

            &.td--w-290 {
                max-width: 290px;
                width: 290px;
            }
        }

        td,
        .td {
            /* border-top: ${props => props.theme.box.table.td.borderTopWidth}px solid ${props => props.theme.box.table.td.borderTopColor}; */

            &.pointer,
            .pointer {
                cursor: pointer;
            }

            .nopointer {
                cursor: default;
            }

            &:hover {
                ${TableActionButtonsCellContainer} {
                    opacity: 1;
                    width: auto;
                }
            }

            &.td--shrink {
                width: 1px;
                white-space: nowrap;
            }
        }
    }

    th {
        color: ${props => props.theme.typo.table.th.color};
        font-size: ${props => props.theme.typo.table.th.fontSize}px;
        padding-bottom: ${props => props.theme.box.table.th.paddingTop}px;
        padding-left: ${props => props.theme.box.table.td.padding}px;
        padding-right: ${props => props.theme.box.table.td.padding}px;
        padding-top: ${props => props.theme.box.table.th.paddingTop}px;
        text-align: left;
        &:not(.no-text-transform) {
            text-transform: ${props => props.theme.typo.table.th.textTransform};
        }
    }

    td,
    .td {
        color: ${props => props.theme.typo.table.td.color};
        font-size: ${props => props.theme.typo.body.fontSize}px;
        font-weight: 400;
        height: ${props => props.theme.box.table.td.minHeight}px;
        max-width: 500px;
        padding: ${props => props.theme.box.table.td.padding}px;
        position: relative;
        text-transform: none;
        /* TODO: is this still needed? */
        /* ${props =>
            (props.noWrapIds || [])
                .map(
                    noWrapId => `&:nth-of-type(${noWrapId}) {
                white-space: nowrap;
                max-width: 180px;
                overflow: hidden;
                text-overflow: ellipsis;
                transition: max-width linear .2s;
                transition-delay: .5s;
                &:hover {
                    max-width: 500px;
                    position: relative;
                }

            }`,
                )
                .join("\n")} */

        .has-mobile-version__icon-wrapper {
            color: ${Color.secondaryL};
            height: ${2 * 17}px;
            padding-right: 12px;
        }

        &.td--w-120 {
            max-width: 120px;
            width: 120px;
        }

        &.td--w-220 {
            max-width: 220px;
            width: 220px;
        }

        &.td--w-290 {
            max-width: 290px;
            width: 290px;
        }

        &.td--w-468 {
            max-width: 468px;
            width: 468px;
        }
    }

    ${props =>
        (props.noWrapIds || [])
            .map(
                noWrapId => `td:nth-of-type(${noWrapId}) {
            white-space: nowrap;
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: max-width linear .2s;
            transition-delay: .5s;
            > span {
                display: block;
                width: 350px
            }
            &:hover {
                max-width: 500px;
                transition-delay: .2s;
            }
        }
        `,
            )
            .join("\n")}

    & + & {
        margin-top: 20px;
    }
`;

export const PropertyListTable = styled(Table as any)`
    table-layout: fixed;
    border: 1px solid ${Color.secondaryXXL};

    th,
    td {
        border: 1px solid ${Color.secondaryXXL};
    }

    td {
        height: auto;
    }

    th {
        text-align: center;

        &.shrink {
            text-align: left;
        }
    }

    td {
        text-align: right;
    }

    th.shrink {
        max-width: 0.1%;
        white-space: nowrap;
    }
`;

interface TDCommentTooltipProps {
    hasError?: boolean;
}

export const TDCommentTooltip = styled.div<TDCommentTooltipProps>`
    position: absolute;
    right: ${props => props.theme.box.table.td.padding}px;
    top: ${props => props.theme.box.table.td.padding}px;
    z-index: 2;

    svg {
        color: ${props => props.hasError && Color.red};
    }

    &:hover {
        z-index: 3;
        > .show-for-sr {
            background-color: ${props => (props.hasError ? Color.redXL : Color.secondaryXL)};
            border-radius: 5px 5px 5px 0;
            bottom: 100%;
            clip: auto;
            color: ${Color.white};
            height: auto;
            overflow: visible;
            padding: 17px 18px;
            white-space: normal;
            width: 252px;
        }
    }
`;

export const TfootLoadMoreWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5px;
    padding-top: 16px;
    text-align: center;
    width: 222px;

    button {
        color: ${Color.secondary};
        display: inline-block;

        + button {
            margin-top: 16px;
        }
    }
`;

export const TableFormWrapper = styled.div`
    border-radius: 10px;
    border: 1px solid ${Color.secondary};
    overflow: hidden;

    ${FakeLabel} {
        display: none;
    }

    ${StyledInput} {
        height: 40px;
        padding: 9px 20px;
    }

    ${StyledInputCDN} {
        ${StyledButton} {
            height: 40px;
        }

        ${ShowButton} {
            height: 40px;
        }

        ${LabelWrapper} {
            line-height: 38px;
        }
    }

    table {
        border-collapse: collapse;
        position: relative;
        width: 100%;
    }

    thead {
        background-color: ${Color.grayL};
    }

    th,
    td {
        padding: 10px 16px;
    }

    th {
        text-align: left;

        & + th {
            border-left: 1px solid ${Color.grayD};
        }
    }

    td {
        border-top: 1px solid ${Color.grayD};

        & + td {
            border-left: 1px solid ${Color.grayD};
        }
    }
`;
