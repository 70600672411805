import React, { ReactNode } from "react";
import styled from "styled-components";

import { hexToRGB } from "theme/global";
import { CheckboxWrapper, Input, CheckboxToggle } from "components/Inputs/Checkbox";
import { SVGIconFlag } from "components/SVGCollection";

interface Props {
    checkboxFlagAriaLabel: ReactNode;
    innerRef?: (ref: JSX.Element) => void;
    noPointer?: boolean;
    disabled?: boolean;
    [x: string]: any;
}

class CheckboxFlagComponent extends React.PureComponent<Props> {
    render(): JSX.Element {
        const { checkboxFlagAriaLabel, innerRef, ...otherProps } = this.props;

        return (
            <CheckboxWrapper as="label">
                <InputFlag ref={innerRef} className="show-for-sr" aria-label={checkboxFlagAriaLabel} type="checkbox" {...otherProps} />

                <CheckboxToggle aria-hidden="true">
                    <SVGIconFlag focusable="false" />
                </CheckboxToggle>
            </CheckboxWrapper>
        );
    }
}

export const InputFlag = styled(Input)<{ noPointer?: boolean }>`
    ~ ${CheckboxToggle} {
        ${props => (props.disabled ? "cursor: not-allowed;" : "")}
        ${props => (props.noPointer ? "cursor: default;" : "")}
    }
    &:checked {
        ~ ${CheckboxToggle} {
            background-color: ${props => props.theme.background.checkboxFlag.checked.backgroundColor};
            border: ${props => props.theme.box.checkbox.borderWidth}px solid ${props => props.theme.background.checkboxFlag.checked.backgroundColor};
            box-shadow: ${props => props.theme.box.checkboxFlag.checked.boxShadow}
                rgba(${props => hexToRGB(props.theme.box.checkboxFlag.checked.boxShadowColor)}, ${props => props.theme.box.checkboxFlag.checked.boxShadowOpacity});
        }
    }
`;

export const CheckboxFlag: React.ComponentClass<Props> = CheckboxFlagComponent;
