import React from "react";
import styled from "styled-components";

import { StyledInput } from "components/Inputs/Input";
import { InputWrapper } from "components/Inputs/InputWrapper";
import { SVGIconSelectArrowDown } from "components/SVGCollection";

export type SelectOption = {
    title: string;
    value: string | undefined;
    disabled?: boolean;
};

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    fakeLabel: string;
    errorMsg?: string;
    hasError?: boolean;
    innerRef?: (ref: HTMLSelectElement | null) => void;
    options?: SelectOption[];
}

class SelectComponent extends React.PureComponent<Props> {
    render(): JSX.Element {
        const { fakeLabel, errorMsg, innerRef, options, ...otherProps } = this.props;

        return (
            <RelativeInputWrapper fakeLabel={fakeLabel} errorMsg={errorMsg}>
                <StyledSelect as="select" ref={innerRef} aria-label={fakeLabel} required={true} {...otherProps}>
                    {(options || []).map(option => (
                        <option key={option.value || "$undefined$"} value={option.value} disabled={option.disabled || false}>
                            {option.title}
                        </option>
                    ))}
                </StyledSelect>

                <ArrowWrapper aria-hidden={true}>
                    <RotateWrapper>
                        <SVGIconSelectArrowDown focusable="false" />
                    </RotateWrapper>
                </ArrowWrapper>
            </RelativeInputWrapper>
        );
    }
}

const StyledSelect = styled(StyledInput)<{ hasError?: boolean }>`
    min-width: 180px;
    padding-left: ${props =>
        props.hasError
            ? props.theme.box.select.paddingLeft - (props.theme.box.input.hasError.borderWidth === 0 ? 0 : props.theme.box.input.hasError.borderWidth / 2)
            : props.theme.box.select.paddingLeft}px;
    padding-right: ${props => props.theme.box.select.paddingRight}px;
    -webkit-appearance: none;

    /* TODO: https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
    &:focus {
        border-color: ${props => (props.hasError ? props.theme.box.input.hasError.borderColor : props.theme.box.input.borderColor)};
    }

    background: none !important;

    &:disabled {
        cursor: not-allowed;
    }
`;

const RelativeInputWrapper = styled(InputWrapper)`
    display: block;
    position: relative;
`;

const ArrowWrapper = styled.div<{ hasError?: boolean }>`
    align-items: center;
    bottom: 0;
    color: ${props => (props.hasError ? props.theme.typo.input.hasError.color : props.theme.typo.input.color)};
    display: flex;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: ${props => props.theme.box.select.paddingRight}px;
`;

const RotateWrapper = styled.div`
    display: block;
    transform: rotate(0);
`;

export const Select = SelectComponent;
