import { ActionsUnion, createAction, ThunkUnion } from "./Helpers";
import { Dispatch } from "redux";
import { ApplicationState } from "reducers";
import * as GqlTypes from "api/graphql/types";
import { Api } from "api/Api";

export enum AuthActionTypes {
    SET_ADMIN = "SET_ADMIN",
    DESTROY_SESSION = "DESTROY_SESSION",
}

export const AuthActions = {
    setAdmin: (admin: GqlTypes.Admin) => createAction(AuthActionTypes.SET_ADMIN, { admin }),
    destroySession: () => createAction(AuthActionTypes.DESTROY_SESSION),
};

export const AuthThunks = {
    login: (email: string, password: string) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
        try {
            const result: GqlTypes.adminLogin_adminLogin = await Api.login(email, password);
            if (result.token && result.admin) {
                dispatch(AuthActions.setAdmin(result.admin));
            }
        } catch {
            // ...
        }
        return getState();
    },
};
export type AuthActionsType = ActionsUnion<typeof AuthActions>;
export type AuthThunksType = ThunkUnion<typeof AuthThunks>;
