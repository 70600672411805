import React, { ReactNode } from "react";

import { H2 } from "theme/global";
import { Button } from "components/Button";
import { Input } from "components/Inputs/Input";
import { InputWrapper } from "components/Inputs/InputWrapper";
import { ButtonWrapper, Form, ModalContainer, ModalHeader, ModalInputWrapper } from "components/Modals/ModalLocTool";
import { Intl } from "i18n/Intl";
import { SVGLogoMOLKupaLogin } from "../SVGCollection";
import styled from "styled-components";

interface Props {
    mounted?: boolean;
    renderButtonActivate?: ReactNode;
    email?: string;
    username?: string;
    onPasswordChange?: React.ChangeEventHandler<HTMLInputElement>;
    onEmailChange?: React.ChangeEventHandler<HTMLInputElement>;
    onLoginClick?: () => void;
    hasError?: boolean;
    [x: string]: any;
}

class ModalLogin extends React.Component<Props> {
    public render(): React.ReactElement {
        return (
            <ModalContainer
                mounted={this.props.mounted}
                titleText={Intl.formatMessage({ id: "pages.login.title" })}
                renderButtonActivate={this.props.renderButtonActivate}
                renderOutsideOfScrollableContainerTop={
                    <IlluWrapper>
                        <SVGLogoMOLKupaLogin width={140} height={140} />
                    </IlluWrapper>
                }
            >
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        this.props.onLoginClick && this.props.onLoginClick();
                    }}
                >
                    <ModalHeader>
                        <H2 as="h1">
                            {Intl.formatMessage({ id: "pages.login.title" })}
                            {/* Oops, something went wrong! */}
                        </H2>

                        <p>{Intl.formatMessage({ id: "pages.login.description" })}</p>
                    </ModalHeader>

                    <ModalInputWrapper>
                        <InputWrapper errorMsg="" fakeLabel={Intl.formatMessage({ id: "pages.login.form.email.name" })}>
                            <Input
                                ariaLabel={Intl.formatMessage({ id: "pages.login.form.email.name" })}
                                autoComplete="current-password"
                                hasError={false}
                                spellCheck="false"
                                type="email"
                                value={this.props.email}
                                onChange={this.props.onEmailChange}
                            />
                        </InputWrapper>

                        <InputWrapper
                            errorMsg={this.props.hasError ? Intl.formatMessage({ id: "pages.login.error" }) : undefined}
                            fakeLabel={Intl.formatMessage({ id: "pages.login.form.password.name" })}
                        >
                            <Input
                                ariaLabel={Intl.formatMessage({ id: "pages.login.form.password.name" })}
                                autoComplete="current-password"
                                hasError={this.props.hasError}
                                spellCheck="false"
                                type="password"
                                value={this.props.password}
                                onChange={this.props.onPasswordChange}
                            />
                        </InputWrapper>

                        <ButtonWrapper>
                            <Button btnLabel={Intl.formatMessage({ id: "pages.login.login" })} expanded={true} type="submit" />
                        </ButtonWrapper>
                    </ModalInputWrapper>
                </Form>
            </ModalContainer>
        );
    }
}
export const IlluWrapper = styled.div<Props>`
    position: absolute;
    width: 100%;
    transform: translateY(-50%);
    svg {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
`;
export { ModalLogin };
