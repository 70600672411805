import React, { ReactNode } from "react";
import styled from "styled-components";

// https://github.com/davidtheclark/react-aria-modal
import AriaModal from "react-aria-modal";

import { Color } from "theme/theme";
import { hexToRGB, H2, H4 } from "theme/global";
import { StyledButton } from "components/Button";
import { SVGIconClear } from "components/SVGCollection";
import { Intl } from "i18n/Intl";

interface State {
    modalActive: boolean;
}

interface Props {
    children: ReactNode;
    mounted?: boolean;
    onModalClose?: () => void;
    renderButtonActivate?: ReactNode;
    renderOutsideOfScrollableContainerBottom?: ReactNode;
    renderOutsideOfScrollableContainerTop?: ReactNode;
    titleText: string;
    underlayColor?: Color | string;
    [x: string]: any;
}

export default class Modal extends React.Component<Props, State> {
    public static readonly DEFAULT_PAGE_SIZE: number = 15;

    constructor(props: Props) {
        super(props);

        this.state = {
            modalActive: false,
        };

        this.activateModal = this.activateModal.bind(this);
        this.deactivateModal = this.deactivateModal.bind(this);
        this.getApplicationNode = this.getApplicationNode.bind(this);
    }

    activateModal = () => {
        this.setState({ modalActive: true });
    };

    deactivateModal = () => {
        this.setState({ modalActive: false });
        this.props.onModalClose && this.props.onModalClose();
    };

    getApplicationNode = (): any => document.getElementById("root");

    render(): JSX.Element {
        const { children, mounted, renderButtonActivate, renderOutsideOfScrollableContainerBottom, renderOutsideOfScrollableContainerTop, titleText, underlayColor, ...otherProps } = this.props;
        const { modalActive } = this.state;
        const ButtonActivate = renderButtonActivate && React.cloneElement(renderButtonActivate as React.ReactElement<any>, { onClick: this.activateModal });

        return (
            <>
                {ButtonActivate}

                {(modalActive || mounted) && (
                    <AriaModal
                        focusDialog={true}
                        getApplicationNode={this.getApplicationNode}
                        mounted={mounted}
                        onExit={this.deactivateModal}
                        titleText={titleText}
                        underlayColor={underlayColor || "transparent"}
                        underlayStyle={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            cursor: mounted ? "auto" : "pointer",
                        }}
                        verticallyCenter={true}
                    >
                        <StyledModalContainer {...otherProps}>
                            {renderOutsideOfScrollableContainerTop}

                            {/* TODO: add https://github.com/malte-wessel/react-custom-scrollbars */}
                            <StyledScrollableContainer>{children}</StyledScrollableContainer>

                            {renderOutsideOfScrollableContainerBottom}
                        </StyledModalContainer>
                    </AriaModal>
                )}
            </>
        );
    }
}

export const StyledModalContainer = styled.div`
    background-color: ${props => props.theme.background.modal.backgroundColor};
    border-radius: ${props => props.theme.box.modal.borderRadius}px;
    box-shadow: ${props => props.theme.box.modal.boxShadow} rgba(${props => hexToRGB(props.theme.box.modal.boxShadowColor)}, ${props => props.theme.box.modal.boxShadowOpacity});
    position: relative;
    width: ${props => props.theme.box.modal.width}px;
`;

export const StyledScrollableContainer = styled.div`
    height: 100%;
    overflow: auto;
    padding-bottom: ${props => props.theme.box.modal.paddingBottom}px;
    padding-left: ${props => props.theme.box.modal.paddingLeft}px;
    padding-right: ${props => props.theme.box.modal.paddingRight}px;
    padding-top: ${props => props.theme.box.modal.paddingTop}px;
`;

export const ModalContainer = styled(Modal);

export const Form = styled.form`
    margin-left: auto;
    margin-right: auto;
    width: 553px;
`;

export const ModalHeader = (props: { onCloseClick?: () => void; children: React.ReactNode }) => (
    <StyledModalHeader>
        {props.children}
        {props.onCloseClick && (
            <ModalHeaderButton type="button" onClick={props.onCloseClick}>
                <span className="show-for-sr">{Intl.formatMessage({ id: "common.close" })}</span>

                <span aria-hidden="true">
                    <SVGIconClear focusable="false" />
                </span>
            </ModalHeaderButton>
        )}
    </StyledModalHeader>
);

export const StyledModalHeader = styled.div`
    border-bottom: 1px solid ${Color.grayD};
    left: 0;
    padding-left: 90px;
    padding-right: 90px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;

    ${H2} {
        color: ${Color.secondaryXD};
        margin-bottom: 26px;
        margin-top: 30px;
    }
`;

const ModalHeaderButton = styled.button`
    align-items: center;
    color: ${Color.grayD};
    display: flex;
    height: 95px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 90px;

    &:hover {
        color: ${Color.secondaryXD};
    }
`;

export const ModalInputWrapper = styled.div``;

export const ButtonWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;

    ${StyledButton} {
        min-width: 182px;
    }

    > .grid-x {
        > .cell {
            & + .cell {
                padding-left: 30px;
            }
        }
    }
`;

export const ModalChangeInnerContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 538px;
`;

export const Modal386Container = styled(ModalChangeInnerContainer)`
    width: 386px;
`;

export const VersionContainer = styled.div`
    margin-bottom: 55px;
    margin-top: 55px;
`;

export const VersionWrapper = styled.div`
    & + & {
        margin-top: 27px;
    }
`;

export const ButtonGroupWrapper = styled.div`
    margin-left: -12px;
    margin-right: -12px;
    margin-top: 60px;

    > .cell {
        margin-left: 12px;
        margin-right: 12px;

        &.small-6 {
            width: calc(${(6 * 100) / 12}% - ${2 * 12}px);
        }
    }
`;

export const KeyWrapper = styled(H4)`
    color: ${Color.secondaryD};
    padding-left: 15px;
`;

export const ValueWrapper = styled(H4)`
    color: ${Color.black};
    padding-right: 13px;
    position: relative;
    font-variant-numeric: tabular-nums;
`;

export const VersionIconWrapper = styled.div`
    color: ${Color.secondaryD};
    padding-left: 13px;
`;

export const SVGIconArrowDownWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 27px;
    justify-content: center;
    left: -6.5px;
    pointer-events: none;
    position: absolute;
    right: 6.5px;
    top: -27px;
`;
