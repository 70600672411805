import { Reducer } from "redux";

import { AlertActions, AlertActionType } from "actions/AlertActions";
import { AlertOptions } from "components/cms/Alert/AlertTypes";

export type AlertStateType = AlertOptions[];

export const initialState: AlertStateType = [];

export const AlertReducer: Reducer<AlertStateType, AlertActions> = (state: AlertStateType | undefined = initialState, action: AlertActions): AlertStateType => {
    switch (action.type) {
        case AlertActionType.SHOW: {
            return [...state, action.payload];
        }
        case AlertActionType.HIDE: {
            const alerts = [...state];
            alerts.shift();
            return alerts;
        }
        default:
            return state;
    }
};
