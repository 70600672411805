import { Api } from "api/Api";
import { Admin, listAdminsVariables, AdminSortField, OrderDirection } from "api/graphql/types";
import { Button } from "components/Button";
import { Alert } from "components/cms/Alert/Alert";
import { Main } from "components/cms/MainElements";
import { SVGIconAdd, SVGIconSuccessTickCircle, SVGIconErrorClearRound } from "components/SVGCollection";
import { Column, Table } from "components/Tables/Table";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import * as _ from "lodash";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ObjectUtils } from "utils/ObjectUtils";
import { Path } from "utils/Path";

type Props = RouteComponentProps;

interface State {
    options: listAdminsVariables;
    admins: Admin[];
    total: number;
    pageCount: number;
    isLoading: boolean;
}

enum AdminListTableColumn {
    id = "id",
    name = "name",
    email = "email",
    is_active = "is_active",
}

class UserListPageComponent extends React.Component<Props, State> {
    public readonly state: State = {
        admins: [],
        isLoading: true,
        total: 0,
        pageCount: 0,
        options: {
            sortBy: {
                field: AdminSortField.ID,
                direction: OrderDirection.ASC,
            },
            count: Table.DEFAULT_PAGE_SIZE,
            page: 1,
        },
    };

    public componentDidMount(): void {
        this.refreshAdmins(this.state.options);
    }

    public componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<State>) {
        const { options } = this.state;
        if (!_.isEqual(prevState.options, options)) {
            this.refreshAdmins(options);
        }
    }

    private refreshAdmins = (options: listAdminsVariables): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { data, paginatorInfo } = await Api.listAdmin(options);
                    this.setState({
                        admins: data,
                        total: paginatorInfo.total,
                        pageCount: paginatorInfo.lastPage,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                    });
                    this.setState({
                        isLoading: false,
                    });
                }
            },
        );
    };

    private readonly columns: Array<Column<Admin>> = ObjectUtils.enumAsArray<AdminListTableColumn>(AdminListTableColumn).map(
        (columnName: AdminListTableColumn): Column<Admin> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `pages.users.list.table.${columnName}` }),
            accessor: columnName as keyof Admin,
            renderCell: (adminListItem: Admin): React.ReactElement<any> | null => {
                switch (columnName) {
                    case AdminListTableColumn.is_active:
                        return <>{adminListItem.is_active ? <SVGIconSuccessTickCircle /> : <SVGIconErrorClearRound />}</>;
                    default:
                        return null;
                }
            },
            isNonSortable: columnName === AdminListTableColumn.is_active,
        }),
    );

    private convertColumnIdToSortField = (columnId?: string): AdminSortField | undefined => {
        switch (columnId) {
            case AdminListTableColumn.id:
                return AdminSortField.ID;
            case AdminListTableColumn.name:
                return AdminSortField.NAME;
            case AdminListTableColumn.email:
                return AdminSortField.EMAIL;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: AdminSortField | null): keyof Admin | undefined => {
        switch (columnId) {
            case AdminSortField.ID:
                return AdminListTableColumn.id;
            case AdminSortField.NAME:
                return AdminListTableColumn.name;
            case AdminSortField.EMAIL:
                return AdminListTableColumn.email;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<Admin>, order?: OrderDirection): void => {
        const sortField = this.convertColumnIdToSortField(column ? column.id : undefined);
        if (sortField) {
            this.setState({
                options: {
                    ...this.state.options,
                    sortBy: {
                        field: sortField,
                        direction: order || OrderDirection.ASC,
                    },
                },
            });
        }
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        this.setState({
            options: {
                ...options,
                page: pageNum,
            },
        });
    };

    public render(): React.ReactElement {
        return (
            <>
                <Main.Heading headingText={Intl.formatMessage({ id: "pages.users.list.title" })}>
                    <Button
                        btnLabel={Intl.formatMessage({ id: "pages.users.list.add" })}
                        onClick={() => {
                            this.props.history.push(Path.createUser);
                        }}
                        renderIcon={<SVGIconAdd />}
                    />
                </Main.Heading>
                <Table
                    keyExtractor={(item: Admin, column?: Column<Admin>): string => {
                        return `${item.id}_${column?.id || ""}`;
                    }}
                    columns={this.columns}
                    sortBy={{
                        columnId: this.convertSortFieldToColumnId(this.state.options.sortBy?.field),
                        order: this.state.options.sortBy?.direction || undefined,
                    }}
                    data={this.state.admins}
                    count={this.state.total}
                    pageCount={this.state.pageCount}
                    currentPage={this.state.options.page || 1}
                    isSortable={true}
                    onSortOrderChange={this.onSortOrderChange}
                    onPageChange={this.onPageChange}
                    isPaginationEnabled={this.state.total > Table.DEFAULT_PAGE_SIZE}
                    isLoading={this.state.isLoading}
                    onRowClick={(adminListItem: Admin): void => {
                        this.props.history.push(Path.editUser(adminListItem.id));
                    }}
                    renderEmpty={() => ""}
                />
            </>
        );
    }
}

const UserListPage = withRouter(UserListPageComponent);
export { UserListPage };
