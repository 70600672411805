import * as GqlTypes from "./graphql/types";
import { ContentField, OrderDirection } from "./graphql/types";
import { GraphQLClient, OnProgress } from "./graphql/GraphQLClient";
import { Mutations } from "./graphql/queries/Mutations";
import { Queries } from "./graphql/queries/Queries";
import { ApiError, ApiErrorCode } from "./ApiError";
import { HIGHLIGHTS_NUMBER } from "utils/Constants";

class Api {
    public static async login(email: string, password: string): Promise<GqlTypes.adminLogin_adminLogin> {
        const response: GqlTypes.adminLogin = await GraphQLClient.mutate<GqlTypes.adminLogin, GqlTypes.adminLoginVariables>({
            mutation: Mutations.adminLogin,
            variables: { credentials: { email, password } },
        });

        return response.adminLogin;
    }

    public static async logout(): Promise<GqlTypes.adminLogout> {
        return await GraphQLClient.mutate<GqlTypes.adminLogout>({
            mutation: Mutations.adminLogout,
        });
    }

    public static async resetAdminPassword(input: GqlTypes.SetAdminPasswordInput): Promise<GqlTypes.resetAdminPassword_resetAdminPassword> {
        const response: GqlTypes.resetAdminPassword = await GraphQLClient.mutate<GqlTypes.resetAdminPassword, GqlTypes.resetAdminPasswordVariables>({
            mutation: Mutations.resetAdminPassword,
            variables: {
                input,
            },
        });

        return response.resetAdminPassword;
    }

    public static async sendAdminPasswordResetRequest(email: string): Promise<GqlTypes.adminPasswordReset> {
        return await GraphQLClient.mutate<GqlTypes.adminPasswordReset, GqlTypes.adminPasswordResetVariables>({
            mutation: Mutations.adminPasswordReset,
            variables: {
                email,
            },
        });
    }

    public static async me(): Promise<GqlTypes.adminMe> {
        return await GraphQLClient.query<GqlTypes.adminMe>({
            query: Queries.adminMe,
        });
    }

    public static async listAdmin(variables: GqlTypes.listAdminsVariables): Promise<GqlTypes.listAdmins_listAdmins> {
        const response = await GraphQLClient.query<GqlTypes.listAdmins, GqlTypes.listAdminsVariables>({
            query: Queries.listAdmins,
            variables,
        });

        if (!response.listAdmins) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }

        return response.listAdmins;
    }

    public static async getAdminById(id: string): Promise<GqlTypes.Admin> {
        const response = await GraphQLClient.query<GqlTypes.listAdmins, GqlTypes.listAdminsVariables>({
            query: Queries.listAdmins,
            variables: {
                filters: {
                    id,
                },
                count: 1,
            },
        });

        if (!response.listAdmins || response.listAdmins.data.length !== 1) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }

        return response.listAdmins.data[0];
    }

    public static async createAdmin(createData: GqlTypes.CreateAdminDataInput): Promise<GqlTypes.createAdmin_createAdmin> {
        const response = await GraphQLClient.mutate<GqlTypes.createAdmin, GqlTypes.createAdminVariables>({
            mutation: Mutations.createAdmin,
            variables: {
                createData,
            },
        });

        if (!response.createAdmin) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }

        return response.createAdmin;
    }

    public static async updateAdmin(input: GqlTypes.UpdateAdminDataInput): Promise<GqlTypes.updateAdmin_updateAdmin> {
        const response = await GraphQLClient.mutate<GqlTypes.updateAdmin, GqlTypes.updateAdminVariables>({
            mutation: Mutations.updateAdmin,
            variables: {
                input,
            },
        });

        if (!response.updateAdmin) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }

        return response.updateAdmin;
    }

    public static async getContents(variables: GqlTypes.getContentsListItemsVariables): Promise<GqlTypes.getContentsListItems_adminContents> {
        const response = await GraphQLClient.query<GqlTypes.getContentsListItems, GqlTypes.getContentsListItemsVariables>({
            query: Queries.getContentsListItems,
            variables,
        });

        if (!response.adminContents) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }

        return response.adminContents;
    }

    public static async getContentByUrl(url: string): Promise<GqlTypes.AnyContent | null> {
        const response = await GraphQLClient.query<GqlTypes.getContentByUrl, GqlTypes.getContentByUrlVariables>({
            query: Queries.getContentByUrl,
            variables: {
                filters: {
                    url,
                },
                count: 1,
            },
        });

        if (!response.adminContents?.data) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }

        return response.adminContents.data[0];
    }

    public static async createContent(input: GqlTypes.CreateContentInput): Promise<GqlTypes.createContent_createContent> {
        const response = await GraphQLClient.mutate<GqlTypes.createContent, GqlTypes.createContentVariables>({
            mutation: Mutations.createContent,
            variables: { input },
        });

        if (!response.createContent) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }

        return response.createContent;
    }

    public static async updateContent(id: string, input: GqlTypes.UpdateContentInput): Promise<GqlTypes.updateContent_updateContent> {
        const response = await GraphQLClient.mutate<GqlTypes.updateContent, GqlTypes.updateContentVariables>({
            mutation: Mutations.updateContent,
            variables: { id, input },
        });

        if (!response.updateContent) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }

        return response.updateContent;
    }

    public static async getSeasonsWithRounds(): Promise<GqlTypes.Season[]> {
        const response = await GraphQLClient.query<GqlTypes.seasons>({
            query: Queries.seasonsWithRounds,
        });
        return response.seasons;
    }

    public static async listMatches(variables: GqlTypes.listMatchesVariables): Promise<GqlTypes.listMatches_matches> {
        const response = await GraphQLClient.query<GqlTypes.listMatches, GqlTypes.listMatchesVariables>({
            query: Queries.listMatches,
            variables,
        });
        if (!response.matches) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }
        return response.matches;
    }

    public static async updateMatch(variables: GqlTypes.updateMatchVariables): Promise<GqlTypes.updateMatch> {
        return await GraphQLClient.mutate<GqlTypes.updateMatch, GqlTypes.updateMatchVariables>({
            mutation: Mutations.updateMatch,
            variables,
        });
    }

    public static async getFrontPageTopContents(): Promise<GqlTypes.AnyContentListItem[]> {
        const response = await GraphQLClient.query<GqlTypes.getContentsListItems, GqlTypes.getContentsListItemsVariables>({
            query: Queries.getContentsListItems,
            variables: {
                filters: {
                    is_front_page_top_content: true,
                },
                sortBy: {
                    field: ContentField.FRONT_PAGE_POSITION,
                    direction: OrderDirection.ASC,
                },
                count: HIGHLIGHTS_NUMBER,
            },
        });
        if (!response.adminContents) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }
        return response.adminContents.data;
    }

    public static async updateFrontPageTopSection(variables: GqlTypes.updateFrontPageTopSectionVariables): Promise<GqlTypes.AnyContentListItem[]> {
        const response = await GraphQLClient.mutate<GqlTypes.updateFrontPageTopSection, GqlTypes.updateFrontPageTopSectionVariables>({
            mutation: Mutations.updateFrontPageTopSection,
            variables,
        });

        return response.updateFrontPageTopSection;
    }

    public static async listDecades(variables: GqlTypes.listDecadesVariables): Promise<GqlTypes.listDecades_decades> {
        const response = await GraphQLClient.query<GqlTypes.listDecades, GqlTypes.listDecadesVariables>({
            query: Queries.listDecades,
            variables,
        });
        if (!response.decades) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }
        return response.decades;
    }

    public static async getDecade(id: string): Promise<GqlTypes.Decade> {
        const response = await GraphQLClient.query<GqlTypes.getDecade, GqlTypes.getDecadeVariables>({
            query: Queries.getDecade,
            variables: {
                id,
            },
        });
        if (!response.decadeById) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }
        return response.decadeById;
    }

    public static async updateDecade(variables: GqlTypes.updateDecadeVariables): Promise<GqlTypes.Decade> {
        const response = await GraphQLClient.mutate<GqlTypes.updateDecade, GqlTypes.updateDecadeVariables>({
            mutation: Mutations.updateDecade,
            variables,
        });
        return response.updateDecade;
    }

    public static async listCategories(): Promise<GqlTypes.Category[]> {
        const response = await GraphQLClient.query<GqlTypes.listCategories>({
            query: Queries.listCategories,
        });
        if (!response.categories) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }
        return response.categories;
    }

    public static async listTeams(variables: GqlTypes.listTeamsVariables): Promise<GqlTypes.listTeams_teams> {
        const response = await GraphQLClient.query<GqlTypes.listTeams, GqlTypes.listTeamsVariables>({
            query: Queries.listTeams,
            variables,
        });

        if (!response.teams) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }
        return response.teams;
    }

    public static async listGalleries(variables: GqlTypes.listGalleriesVariables): Promise<GqlTypes.listGalleries_galleries> {
        const response = await GraphQLClient.query<GqlTypes.listGalleries, GqlTypes.listGalleriesVariables>({
            query: Queries.listGalleries,
            variables,
        });

        if (!response.galleries) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }
        return response.galleries;
    }

    public static async createGallery(input: GqlTypes.CreateGalleryInput): Promise<GqlTypes.Gallery> {
        const response = await GraphQLClient.mutate<GqlTypes.createGallery, GqlTypes.createGalleryVariables>({
            mutation: Mutations.createGallery,
            variables: {
                input,
            },
        });

        return response.createGallery;
    }

    public static async updateGallery(id: string, input: GqlTypes.UpdateGalleryInput): Promise<GqlTypes.Gallery> {
        const response = await GraphQLClient.mutate<GqlTypes.updateGallery, GqlTypes.updateGalleryVariables>({
            mutation: Mutations.updateGallery,
            variables: {
                id,
                input,
            },
        });

        return response.updateGallery;
    }

    public static async listInformations(variables: GqlTypes.listInformationsVariables): Promise<GqlTypes.listInformations_adminInformations> {
        const response = await GraphQLClient.query<GqlTypes.listInformations, GqlTypes.listInformationsVariables>({
            query: Queries.listInformations,
            variables,
        });

        if (!response.adminInformations) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }
        return response.adminInformations;
    }

    public static async createInformation(input: GqlTypes.CreateInformationInput): Promise<GqlTypes.Information> {
        const response = await GraphQLClient.mutate<GqlTypes.createInformation, GqlTypes.createInformationVariables>({
            mutation: Mutations.createInformation,
            variables: {
                input,
            },
        });

        return response.createInformation;
    }

    public static async updateInformation(id: string, input: GqlTypes.UpdateInformationInput): Promise<GqlTypes.Information> {
        const response = await GraphQLClient.mutate<GqlTypes.updateInformation, GqlTypes.updateInformationVariables>({
            mutation: Mutations.updateInformation,
            variables: {
                id,
                input,
            },
        });

        return response.updateInformation;
    }

    public static async listDocuments(variables: GqlTypes.listDocumentsVariables): Promise<GqlTypes.listDocuments_documents> {
        const response = await GraphQLClient.query<GqlTypes.listDocuments, GqlTypes.listDocumentsVariables>({
            query: Queries.listDocuments,
            variables,
        });

        if (!response.documents) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }
        return response.documents;
    }

    public static async uploadDocument(file: File, onProgress?: OnProgress): Promise<void> {
        await GraphQLClient.upload<GqlTypes.uploadDocument, GqlTypes.uploadDocumentVariables>({
            mutation: Mutations.uploadDocument,
            variables: { file },
            onProgress,
            file,
        });
    }
}

export { Api };
