import React from "react";
import styled from "styled-components";

import { Color } from "theme/theme";
import { CaptionText, H1, H2, H3, H4, H5, HeroText, MainContainer } from "theme/global";
import AppBar from "components/AppBar";
import { Button } from "components/Button";
import { Checkbox } from "components/Inputs/Checkbox";
import { CheckboxFlag } from "components/Inputs/CheckboxFlag";
import ColorList, { StyledColorList } from "components/ColorList";
import { HeaderSection } from "components/HeaderSection";
import { Input } from "components/Inputs/Input";
import { InputWrapper } from "components/Inputs/InputWrapper";
import LoadingOverlay from "components/LoadingOverlay";
import Modal from "components/Modals/Modal";
import { ModalLogin } from "components/Modals/ModalLogin";
import { Radio } from "components/Inputs/Radio";
import { Search } from "components/Inputs/Search";
import { Select } from "components/Inputs/Select";
import SVGCollection from "components/SVGCollection";
import { Table, TableActionButtonsContainer, TableWrapper, TDCommentTooltip } from "components/TableElements";
import { Textarea } from "components/Inputs/Textarea";

// CMS
import { Menu } from "components/cms/MenuElements";

interface State {
    showLoadingOverlay: boolean;
}

class Sandbox extends React.Component<any, State> {
    public state: State = {
        showLoadingOverlay: false,
    };

    private readonly downHandler = ({ key }: any): void => {
        if (key === "L") {
            this.setState({ showLoadingOverlay: true });
        }
    };

    private readonly upHandler = ({ key }: any) => {
        if (key === "L") {
            this.setState({ showLoadingOverlay: false });
        }
    };

    public componentDidMount() {
        window.addEventListener("keydown", this.downHandler);
        window.addEventListener("keyup", this.upHandler);
        return () => {
            window.removeEventListener("keydown", this.downHandler);
            window.removeEventListener("keyup", this.upHandler);
        };
    }

    public render() {
        return (
            <div className="grid-x">
                <div className="cell shrink">
                    <Menu.Container>
                        <Menu.Li renderIcon={<>i</>} btnLabel="My tasks" isActive={false}>
                            <button type="button" onClick={() => {}}>
                                <span className="show-for-sr">My tasks</span>
                            </button>
                        </Menu.Li>
                        <Menu.Li renderIcon={<>i</>} btnLabel="Payments" isActive={true}>
                            <button type="button" onClick={() => {}}>
                                <span className="show-for-sr">Payments</span>
                            </button>
                        </Menu.Li>
                        <Menu.Li renderIcon={<>i</>} btnLabel="Menu" isActive={false}>
                            <button type="button" onClick={() => {}}>
                                <span className="show-for-sr">Menu</span>
                            </button>
                        </Menu.Li>
                        <Menu.LiFoldable renderIcon={<>i</>} btnLabel="Foldable menu">
                            <Menu.Li renderIcon={<>i</>} btnLabel="Submenu1" isActive={false}>
                                <button type="button" onClick={() => {}}>
                                    <span className="show-for-sr">Submenu1</span>
                                </button>
                            </Menu.Li>

                            <Menu.Li renderIcon={<>i</>} btnLabel="Submenu2" isActive={true}>
                                <button type="button" onClick={() => {}}>
                                    <span className="show-for-sr">Submenu2</span>
                                </button>
                            </Menu.Li>

                            <Menu.Li renderIcon={<>i</>} btnLabel="Submenu3" isActive={false}>
                                <button type="button" onClick={() => {}}>
                                    <span className="show-for-sr">Submenu3</span>
                                </button>
                            </Menu.Li>
                        </Menu.LiFoldable>
                        <li>
                            <a href="#app-bar">AppBar</a>
                        </li>
                        <li>
                            <a href="#button">Button</a>
                        </li>
                        <li>
                            <a href="#color-list">ColorList</a>
                        </li>
                        <li>
                            <a href="#header-section">HeaderSection</a>
                        </li>
                        <li>
                            <a href="#input">Input</a>
                        </li>
                        <li>
                            <a href="#loading-overlay">LoadingOverlay</a>
                        </li>
                        <li>
                            <a href="#modals">Modals</a>
                        </li>
                        <li>
                            <a href="#svgcollection-and-illustrations">SVGCollection and illustrations</a>
                        </li>
                        <li>
                            <a href="#table">Table</a>
                        </li>
                        <li>
                            <a href="#typography">Typography</a>
                        </li>
                    </Menu.Container>
                </div>

                <SandboxContainer className="cell auto">
                    <h1>SandboxPage</h1>

                    <div id="app-bar" className="sandbox-section">
                        <h2 className="sticky-header">AppBar</h2>

                        <code>Note: this component is fixed to the top</code>

                        <AppBar />

                        {/* Note: In order to send AppBar over Modal's overlay: zIndex: 1051 */}
                        {/* <AppBar style={{ zIndex: 1051 }} /> */}
                    </div>

                    <div id="button" className="sandbox-section">
                        <h2 className="sticky-header">Button</h2>
                        <Button btnLabel="Primary disabled" disabled={true} /> <Button btnLabel="Primary" />{" "}
                        <Button btnLabel="Primary icon left" renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} />{" "}
                        <Button btnLabel="Primary icon right" renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} iconRight={true} />
                        <hr />
                        <Button btnLabel="Primary disabled and expanded" disabled={true} expanded={true} />
                        <Button btnLabel="Primary expanded" expanded={true} />
                        <Button btnLabel="Primary icon left and expanded" renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} expanded={true} />
                        <Button btnLabel="Primary icon right and expanded" renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} iconRight={true} expanded={true} />
                        <Button btnLabel="Primary icon left, expanded, and justify center" renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} expanded={true} justifyContent="center" />
                        <Button
                            btnLabel="Primary icon right, expanded, and justify center"
                            renderIcon={<SVGCollection.SVGIconAdd focusable="false" />}
                            iconRight={true}
                            expanded={true}
                            justifyContent="center"
                        />
                        <hr />
                        <Button btnLabel="Secondary disabled" disabled={true} secondary={true} /> <Button btnLabel="Secondary" secondary={true} />{" "}
                        <Button btnLabel="Secondary icon left" secondary={true} renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} />{" "}
                        <Button btnLabel="Secondary icon right" secondary={true} renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} iconRight={true} />
                        <hr />
                        <Button btnLabel="Secondary disabled and expanded" disabled={true} secondary={true} expanded={true} />
                        <Button btnLabel="Secondary expanded" secondary={true} expanded={true} />
                        <Button btnLabel="Secondary icon left and expanded" secondary={true} renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} expanded={true} />
                        <Button btnLabel="Secondary icon right and expanded" secondary={true} renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} iconRight={true} expanded={true} />
                        <Button
                            btnLabel="Secondary icon left, expanded, and justify center"
                            secondary={true}
                            renderIcon={<SVGCollection.SVGIconAdd focusable="false" />}
                            expanded={true}
                            justifyContent="center"
                        />
                        <Button
                            btnLabel="Secondary icon right, expanded, and justify center"
                            secondary={true}
                            renderIcon={<SVGCollection.SVGIconAdd focusable="false" />}
                            iconRight={true}
                            expanded={true}
                            justifyContent="center"
                        />
                        <hr />
                        <Button btnLabel="Hollow disabled" disabled={true} hollow={true} /> <Button btnLabel="Hollow" hollow={true} />{" "}
                        <Button btnLabel="Hollow icon left" hollow={true} renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} />{" "}
                        <Button btnLabel="Hollow icon right" hollow={true} renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} iconRight={true} />
                        <hr />
                        <Button btnLabel="Hollow disabled and expanded" disabled={true} hollow={true} expanded={true} />
                        <Button btnLabel="Hollow expanded" hollow={true} expanded={true} />
                        <Button btnLabel="Hollow icon left and expanded" hollow={true} renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} expanded={true} />
                        <Button btnLabel="Hollow icon right and expanded" hollow={true} renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} iconRight={true} expanded={true} />
                        <Button
                            btnLabel="Hollow icon left, expanded, and justify center"
                            hollow={true}
                            renderIcon={<SVGCollection.SVGIconAdd focusable="false" />}
                            expanded={true}
                            justifyContent="center"
                        />
                        <Button
                            btnLabel="Hollow icon right, expanded, and justify center"
                            hollow={true}
                            renderIcon={<SVGCollection.SVGIconAdd focusable="false" />}
                            iconRight={true}
                            expanded={true}
                            justifyContent="center"
                        />
                    </div>

                    <div id="color-list" className="sandbox-section">
                        <h2 className="sticky-header">ColorList</h2>

                        <ColorList />
                    </div>

                    <div id="header-section" className="sandbox-section">
                        <h2 className="sticky-header">HeaderSection</h2>

                        <HeaderSection headerText="Webapp translation versions" />

                        <HeaderSection headerText="Webapp ID: 5479652477001" headerTextIcon="draft" />

                        <HeaderSection headerText="Webapp ID: 5479652477001" headerTextIcon="system" />

                        <HeaderSection headerText="Webapp ID: 5479652477001" headerTextIcon="system" renderSelect={<Select fakeLabel="Select" />} />

                        <HeaderSection
                            headerText="Webapp ID: 5479652477001"
                            headerTextIcon="system"
                            renderSelect={
                                <div className="grid-x align-middle">
                                    <div className="cell small-6">
                                        <Select fakeLabel="Select" />
                                    </div>

                                    <div className="cell small-6">
                                        <Select fakeLabel="Select" />
                                    </div>
                                </div>
                            }
                        />

                        <HeaderSection
                            headerText="Webapp translation versions"
                            renderSelect={<Select fakeLabel="Select" />}
                            renderActionButtons={
                                <div className="grid-x">
                                    <div className="cell">
                                        <button type="button" disabled={true}>
                                            <span className="show-for-sr">Compare</span>

                                            <span aria-hidden="true">
                                                <SVGCollection.SVGIconCompare width={31} height={28} focusable="false" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            }
                        />

                        <HeaderSection
                            headerText="Webapp translation versions"
                            renderSelect={<Select fakeLabel="Select" />}
                            renderActionButtons={
                                <div className="grid-x">
                                    <div className="cell">
                                        <button type="button">
                                            <span className="show-for-sr">Compare</span>

                                            <span aria-hidden="true">
                                                <SVGCollection.SVGIconCompare width={31} height={28} focusable="false" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            }
                        />

                        <HeaderSection
                            headerText="Webapp ID: 5479652477001"
                            headerTextIcon="draft"
                            renderActionButtons={
                                <div className="grid-x">
                                    <div className="cell shrink">
                                        <button type="button">
                                            <span className="show-for-sr">TODO</span>

                                            <span aria-hidden="true">
                                                <SVGCollection.SVGIconLogIn width={24} height={24} focusable="false" />
                                            </span>
                                        </button>
                                    </div>

                                    <div className="cell shrink">
                                        <button type="button">
                                            <span className="show-for-sr">TODO</span>

                                            <span aria-hidden="true">
                                                <SVGCollection.SVGIconSettings width={23} height={24} focusable="false" />
                                            </span>
                                        </button>
                                    </div>

                                    <div className="cell shrink">
                                        <button type="button">
                                            <span className="show-for-sr">Download</span>

                                            <span aria-hidden="true">
                                                <SVGCollection.SVGIconDownload width={19} height={23} focusable="false" />
                                            </span>
                                        </button>
                                    </div>

                                    <div className="cell shrink">
                                        <button type="button">
                                            <span className="show-for-sr">Delete</span>

                                            <span aria-hidden="true">
                                                <SVGCollection.SVGIconDelete width={19} height={24} focusable="false" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div id="input" className="sandbox-section">
                        <h2 className="sticky-header">Input</h2>

                        <form>
                            <Checkbox checkboxLabel="Mobile version" />

                            <Checkbox checkboxLabel="Mobile version" errorMsg="Error" hasError={true} />

                            <Checkbox checkboxLabel="Mobile version" defaultChecked={true} />

                            <CheckboxFlag checkboxFlagAriaLabel="INT" />

                            <CheckboxFlag checkboxFlagAriaLabel="INT" defaultChecked={true} />

                            <InputWrapper fakeLabel="Text input">
                                <Input type="text" ariaLabel="Text input" />
                            </InputWrapper>

                            {/* Note: when an input, textarea, etc is disabled, it should not have value. If it has to have value and in should be not editable, please use readOnly! */}
                            <InputWrapper fakeLabel="Disabled input">
                                <Input type="text" ariaLabel="Disabled input" disabled={true} />
                            </InputWrapper>

                            {/* Note: readOnly inputs' value should never be empty (""). */}
                            <InputWrapper fakeLabel="Readonly input">
                                <Input type="text" ariaLabel="Readonly input" readOnly={true} defaultValue="Readonly input" />
                            </InputWrapper>

                            <InputWrapper fakeLabel="Input has error" errorMsg="Wrong email address or password.">
                                <Input ariaLabel="Input has error" hasError={true} type="text" />
                            </InputWrapper>

                            <InputWrapper fakeLabel="Textarea">
                                <Textarea ariaLabel="Textarea" />
                            </InputWrapper>

                            <Radio name="radio-0" radioLabel="Lorem ipsum dolor sit amet, consectetur adipiscing" value="radio-0-0" />

                            <Radio name="radio-0" radioLabel="Lorem ipsum dolor sit amet, consectetur" value="radio-0-1" />

                            <Radio errorMsg="Error" hasError={true} name="radio-1" radioLabel="Lorem ipsum dolor sit amet, consectetur" value="radio-1-0" />

                            <Radio defaultChecked={true} errorMsg="Error" hasError={true} name="radio-1" radioLabel="Lorem ipsum dolor sit amet, consectetur" value="radio-1-1" />

                            <Search ariaLabel="Filter by keyword" />

                            <Search ariaLabel="Filter by keyword" />

                            <Search ariaLabel="Filter by keyword" errorMsg="Error" hasError={true} />

                            <Select fakeLabel="Select" />

                            <Select fakeLabel="Select" disabled={true} />

                            <Select fakeLabel="Select" errorMsg="Error" hasError={true} />
                        </form>
                    </div>

                    <div id="loading-overlay" className="sandbox-section">
                        <h2 className="sticky-header">LoadingOverlay</h2>

                        <code>
                            Note: keep pressed <kbd>Shift</kbd> + <kbd>L</kbd>
                        </code>

                        {this.state.showLoadingOverlay && <LoadingOverlay />}
                    </div>

                    <div id="modals" className="sandbox-section">
                        <h2 className="sticky-header">Modals</h2>
                        <Modal
                            // mounted={true}
                            titleText="Default Modal"
                            renderButtonActivate={<Button btnLabel="Show default Modal" />}
                        >
                            Default Modal
                        </Modal>{" "}
                        <ModalLogin
                            // mounted={true}
                            renderButtonActivate={<Button btnLabel="Show login Modal" />}
                        />{" "}
                    </div>

                    <div id="svgcollection-and-illustrations" className="sandbox-section">
                        <h2 className="sticky-header">SVGCollection and illustrations</h2>

                        <StyledSVGList>
                            {Object.keys(SVGCollection).map((key: string) => {
                                const SVG = SVGCollection[key];

                                return (
                                    <li key={key}>
                                        <div>
                                            <SVG width={20} />
                                        </div>

                                        <div>{key}</div>
                                    </li>
                                );
                            })}
                        </StyledSVGList>
                    </div>

                    <div id="table" className="sandbox-section">
                        <h2 className="sticky-header">Table</h2>

                        <TableWrapper>
                            <Table>
                                <caption>
                                    <H5 as="h2">Keys</H5>
                                </caption>

                                <thead>
                                    <tr>
                                        <th className="th--sticky" scope="col">
                                            translation key
                                        </th>

                                        <th className="th--sticky" scope="col">
                                            Language
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>common.formError.invalidAmount</td>

                                        <td>HU</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <Table>
                                <caption>
                                    <div className="grid-x align-middle">
                                        <div className="cell shrink" aria-hidden="true">
                                            <SVGCollection.SVGIconEdit width={23} height={23} focusable="false" />
                                        </div>

                                        <div className="cell auto">
                                            <H5 as="h2">Draft version</H5>
                                        </div>
                                    </div>
                                </caption>

                                {/* Note: only one caption per table */}
                                <caption>
                                    <div className="grid-x align-middle">
                                        <div className="cell shrink" aria-hidden="true">
                                            <SVGCollection.SVGIconSettings width={22} height={22} focusable="false" />
                                        </div>

                                        <div className="cell auto">
                                            <H5 as="h2">System version</H5>
                                        </div>
                                    </div>
                                </caption>

                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>

                                        <th scope="col">Saved</th>

                                        <th scope="col">Source id</th>

                                        <th scope="col">Key&nbsp;#</th>

                                        <th scope="col">HU&nbsp;%</th>

                                        <th scope="col">EN&nbsp;%</th>

                                        <th scope="col">DE&nbsp;%</th>

                                        <th scope="col">Comment</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td className="td--w-220 text-overflow-ellipsis" title="5479652477010">
                                            5479652477010
                                        </td>

                                        <td>2019.08.13.</td>

                                        <td>5479652477001</td>

                                        <td>624</td>

                                        <td>100</td>

                                        <td>78</td>

                                        <td>53</td>

                                        <td className="td--w-468">
                                            <div className="grid-x align-middle">
                                                <div className="cell auto text-overflow-ellipsis">
                                                    Grammar correction done on proposal page correction done on proposal page correction done on proposal page correction done on proposal page
                                                </div>

                                                <div className="cell shrink">
                                                    <TableActionButtonsContainer className="grid-x">
                                                        <div className="cell shrink">
                                                            <button type="button">
                                                                <span className="show-for-sr">TODO</span>

                                                                <span aria-hidden="true">
                                                                    <SVGCollection.SVGIconLogIn width={18} height={18} focusable="false" />
                                                                </span>
                                                            </button>
                                                        </div>

                                                        <div className="cell shrink">
                                                            <button type="button">
                                                                <span className="show-for-sr">TODO</span>

                                                                <span aria-hidden="true">
                                                                    <SVGCollection.SVGIconSettings width={18} height={19} focusable="false" />
                                                                </span>
                                                            </button>
                                                        </div>

                                                        <div className="cell shrink">
                                                            <button type="button">
                                                                <span className="show-for-sr">Download</span>

                                                                <span aria-hidden="true">
                                                                    <SVGCollection.SVGIconDownload width={14} height={17} focusable="false" />
                                                                </span>
                                                            </button>
                                                        </div>

                                                        <div className="cell shrink">
                                                            <button type="button">
                                                                <span className="show-for-sr">Delete</span>

                                                                <span aria-hidden="true">
                                                                    <SVGCollection.SVGIconDelete width={14} height={18} focusable="false" />
                                                                </span>
                                                            </button>
                                                        </div>

                                                        <div className="cell shrink">
                                                            <button type="button">
                                                                <span className="show-for-sr">Compare</span>

                                                                <span aria-hidden="true">
                                                                    <SVGCollection.SVGIconCompare width={20} height={18} focusable="false" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </TableActionButtonsContainer>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>

                            <Table>
                                <caption className="show-for-sr">
                                    <H5 as="h2">Translations</H5>
                                </caption>

                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <Search ariaLabel="lorem ipsum" />
                                        </th>

                                        <th colSpan={2}>
                                            <div className="grid-x align-middle grid-margin-x-20">
                                                <div className="cell shrink">
                                                    <Checkbox checkboxLabel="Incomplete only" />
                                                </div>

                                                <div className="cell shrink">
                                                    <Checkbox checkboxLabel="Modified only" defaultChecked={true} />
                                                </div>

                                                <div className="cell shrink">
                                                    <Checkbox checkboxLabel="Mobile version only" />
                                                </div>

                                                <div className="cell auto">
                                                    <div className="grid-x align-right grid-margin-x-10">
                                                        <div className="cell shrink">
                                                            <Select fakeLabel="Select" />
                                                        </div>

                                                        <div className="cell shrink">
                                                            <Button btnLabel="New Key" renderIcon={<SVGCollection.SVGIconAdd focusable="false" />} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>

                                    <tr>
                                        <th className="caption" colSpan={3} aria-hidden="true">
                                            <H5 as="div">Translations</H5>
                                        </th>
                                    </tr>

                                    <tr>
                                        <th scope="col">Translation Key</th>

                                        <th scope="col">Hungarian</th>

                                        <th scope="col">English</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td className="td--w-290 text-overflow-ellipsis">common.formError.invalidAmountcommon.formError.invalidAmount</td>

                                        <td className="text-overflow-ellipsis color-red ">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>

                                        <td>
                                            <div className="grid-x align-middle">
                                                <div className="cell auto text-overflow-ellipsis">Vestibulum sodales cuat sodales</div>

                                                <div className="cell shrink">
                                                    <TableActionButtonsContainer className="grid-x">
                                                        <div className="cell shrink">
                                                            <button type="button">
                                                                <span className="show-for-sr">Edit</span>

                                                                <span aria-hidden="true">
                                                                    <SVGCollection.SVGIconPencil width={18} height={18} focusable="false" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </TableActionButtonsContainer>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        {/* Note: if td has TDCommentTooltip, .text-overflow-ellipsis can not be used. Use td > div.text-overflow-ellipsis instead */}
                                        <td>
                                            <div className="text-overflow-ellipsis">common.formError.invalidAmount</div>

                                            <TDCommentTooltip>
                                                <div aria-hidden="true">
                                                    <SVGCollection.SVGIconToggleComment focusable="false" />
                                                </div>

                                                <div className="show-for-sr">Text of error message if post code is not standard</div>
                                            </TDCommentTooltip>
                                        </td>

                                        <td className="text-overflow-ellipsis color-red ">Vestibulum sodales consequat sodales</td>

                                        <td>
                                            <div className="grid-x align-middle">
                                                <div className="cell auto text-overflow-ellipsis">
                                                    <strong>Lorem ipsum</strong> dolor sit amet, consectetur adipiscing elit sodales
                                                </div>

                                                <div className="cell shrink">
                                                    <TableActionButtonsContainer className="grid-x">
                                                        <div className="cell shrink">
                                                            <button type="button">
                                                                <span className="show-for-sr">Edit</span>

                                                                <span aria-hidden="true">
                                                                    <SVGCollection.SVGIconPencil width={18} height={18} focusable="false" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </TableActionButtonsContainer>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        {/* Note: if td has TDCommentTooltip, .text-overflow-ellipsis can not be used. Use td > div.text-overflow-ellipsis instead */}
                                        <td>
                                            <div className="text-overflow-ellipsis">common.formError.invalidAmount</div>

                                            <TDCommentTooltip hasError={true}>
                                                <div aria-hidden="true">
                                                    <SVGCollection.SVGIconToggleComment focusable="false" />
                                                </div>

                                                <div className="show-for-sr">Text of error message if post code is not standard</div>
                                            </TDCommentTooltip>
                                        </td>

                                        <td className="text-overflow-ellipsis color-red ">Vestibulum sodales consequat sodales</td>

                                        <td>
                                            <div className="grid-x align-middle">
                                                <div className="cell auto text-overflow-ellipsis">
                                                    <strong>Lorem ipsum</strong> dolor sit amet, consectetur adipiscing elit sodales
                                                </div>

                                                <div className="cell shrink">
                                                    <TableActionButtonsContainer className="grid-x">
                                                        <div className="cell shrink">
                                                            <button type="button">
                                                                <span className="show-for-sr">Edit</span>

                                                                <span aria-hidden="true">
                                                                    <SVGCollection.SVGIconPencil width={18} height={18} focusable="false" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </TableActionButtonsContainer>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="align-top">
                                        {/* Note: if td has TDCommentTooltip, .text-overflow-ellipsis can not be used. Use td > div.text-overflow-ellipsis instead */}
                                        <td>
                                            <div className="text-overflow-ellipsis">common.formError.invalidAmount</div>

                                            <TDCommentTooltip>
                                                <div aria-hidden="true">
                                                    <SVGCollection.SVGIconToggleComment focusable="false" />
                                                </div>

                                                <div className="show-for-sr">Text of error message if post code is not standard</div>
                                            </TDCommentTooltip>
                                        </td>

                                        <td>
                                            Mauris auctor finibus nisi. Praesent elementum tincidunt turpis eget bibendum. Donec facilisis nisl eget tempus volutpat. Interdum et malesuada fames ac
                                            ante ipsum
                                        </td>

                                        <td>
                                            <div className="grid-x align-middle">
                                                <div className="cell auto">
                                                    <strong>Lorem ipsum</strong> mauris auctor finibus nisi. Praesent elementum tincidunt turpis eget bibendum. Donec facilisis nisl eget tempus
                                                    volutpat. Interdum et malesuada fames ac ante ipsum
                                                </div>

                                                <div className="cell shrink">
                                                    <TableActionButtonsContainer className="grid-x action-buttons-container--is-absolute">
                                                        <div className="cell shrink">
                                                            <button type="button">
                                                                <span className="show-for-sr">Edit</span>

                                                                <span aria-hidden="true">
                                                                    <SVGCollection.SVGIconPencil width={18} height={18} focusable="false" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </TableActionButtonsContainer>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        {/* Note: if td has TDCommentTooltip, .text-overflow-ellipsis can not be used. Use td > div.text-overflow-ellipsis instead */}
                                        <td>
                                            <div className="grid-x align-middle">
                                                <div className="cell shrink has-mobile-version__icon-wrapper" aria-hidden="true">
                                                    <SVGCollection.SVGIconBoxDrawingUpAndRight focusable="false" />
                                                </div>

                                                <div className="cell auto text-overflow-ellipsis">common.formError.invalidAmount:mobile</div>
                                            </div>

                                            <TDCommentTooltip>
                                                <div aria-hidden="true">
                                                    <SVGCollection.SVGIconToggleComment focusable="false" />
                                                </div>

                                                <div className="show-for-sr">Text of error message if post code is not standard</div>
                                            </TDCommentTooltip>
                                        </td>

                                        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit sodales</td>

                                        <td>
                                            <div className="grid-x align-middle">
                                                <div className="cell auto">Vestibulum sodales consequat sodales</div>

                                                <div className="cell shrink">
                                                    <TableActionButtonsContainer className="grid-x">
                                                        <div className="cell shrink">
                                                            <button type="button">
                                                                <span className="show-for-sr">Edit</span>

                                                                <span aria-hidden="true">
                                                                    <SVGCollection.SVGIconPencil width={18} height={18} focusable="false" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </TableActionButtonsContainer>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="tr--is-emphasized">
                                        {/* Note: if td has TDCommentTooltip, .text-overflow-ellipsis can not be used. Use td > div.text-overflow-ellipsis instead */}
                                        <td>
                                            <div className="grid-x align-middle">
                                                <div className="cell shrink has-mobile-version__icon-wrapper" aria-hidden="true">
                                                    <SVGCollection.SVGIconBoxDrawingUpAndRight focusable="false" />
                                                </div>

                                                <div className="cell auto text-overflow-ellipsis">common.formError.invalidAmount:mobile</div>
                                            </div>

                                            <TDCommentTooltip>
                                                <div aria-hidden="true">
                                                    <SVGCollection.SVGIconToggleComment focusable="false" />
                                                </div>

                                                <div className="show-for-sr">Text of error message if post code is not standard</div>
                                            </TDCommentTooltip>
                                        </td>

                                        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit sodales</td>

                                        <td>
                                            <div className="grid-x align-middle">
                                                <div className="cell auto">Vestibulum sodales consequat sodales</div>

                                                <div className="cell shrink">
                                                    <TableActionButtonsContainer className="grid-x">
                                                        <div className="cell shrink">
                                                            <button type="button">
                                                                <span className="show-for-sr">Edit</span>

                                                                <span aria-hidden="true">
                                                                    <SVGCollection.SVGIconPencil width={18} height={18} focusable="false" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </TableActionButtonsContainer>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </TableWrapper>
                    </div>

                    <div id="typography" className="sandbox-section">
                        <h2 className="sticky-header">Typography</h2>

                        <HeroText title="HeroText">Szia! Emma vagyok a személyes asszisztensed.</HeroText>

                        <H1 title="H1">Szia! Emma vagyok a személyes asszisztensed.</H1>

                        <H2 title="H2">Szia! Emma vagyok a személyes asszisztensed.</H2>

                        <H3 title="H3">Szia! Emma vagyok a személyes asszisztensed.</H3>

                        <H4 title="H4">Szia! Emma vagyok a személyes asszisztensed.</H4>

                        <H5 title="H5">Szia! Emma vagyok a személyes asszisztensed.</H5>

                        <p title="body text">Szia! Emma vagyok a személyes asszisztensed.</p>

                        <CaptionText title="CaptionText">Szia! Emma vagyok a személyes asszisztensed.</CaptionText>
                    </div>
                </SandboxContainer>
            </div>
        );
    }
}

// Note: Sandbox styles
const SandboxContainer = styled(MainContainer)`
    .sandbox-section {
        > .sticky-header {
            align-items: center;
            background-color: ${props => props.theme.background.body.backgroundColor};
            display: flex;
            height: ${props => props.theme.box.appBar.height}px;
            margin: 0;
            position: sticky;
            top: ${props => props.theme.box.appBar.height}px;
            z-index: 2;
        }
    }
`;

const StyledSVGList = styled(StyledColorList)`
    li {
        text-align: center;
        background-color: ${Color.gray};
        word-break: break-all;

        &:active,
        &:hover {
            color: ${Color.white};
        }
    }

    svg {
        margin-left: auto;
        margin-right: auto;
    }
`;

export { Sandbox };
