import { combineReducers } from "redux";
import { AuthReducer, AuthStateType, initialState as authInitialState } from "./AuthReducer";
import { AlertStateType, AlertReducer, initialState as alertInitialState } from "./AlertReducer";

export interface ApplicationState {
    auth: AuthStateType;
    alerts: AlertStateType;
}

const rootReducer = combineReducers<ApplicationState>({
    auth: AuthReducer,
    alerts: AlertReducer,
});

export const initialRootReducerState: ApplicationState = {
    auth: authInitialState,
    alerts: alertInitialState,
};

export default rootReducer;
