import React from "react";
import { Switch, Route } from "react-router-dom";
import LoadingOverlay from "components/LoadingOverlay";
import { LoginPage } from "pages/LoginPage";
import { Api } from "api/Api";
import { AuthActions } from "actions/AuthActions";
import { DispatchProp, connect } from "react-redux";
import { LayoutWrapper } from "routes/LayoutWrapper";
import { LoggedInLayout } from "routes/LoggedInLayout";
import LogoutPage from "pages/LogoutPage";
import { AppPath, Path } from "utils/Path";
import { Pages } from "pages/Pages";
import { Error404Page } from "pages/Error404Page";
import { SandboxPage } from "pages/SandboxPage";
import { UserListPage } from "pages/Users/UserListPage";
import { HighlightsPage } from "pages/Highlights/HighlightsPage";
import { Permission, Admin } from "api/graphql/types";
import { UserCreatePage } from "pages/Users/UserCreatePage";
import { UserEditPage } from "pages/Users/UserEditPage";
import { ActivatePage } from "pages/ActivatePage";
import { ApplicationState } from "reducers";
import { AuthSelectors } from "selectors/AuthSelectors";

interface ReduxProps {
    isLoggedIn: boolean;
    admin: Admin | null;
}

type Props = DispatchProp & ReduxProps;

type State = {
    isLoading: boolean;
};

class AppComponent extends React.Component<Props, State> {
    readonly state: State = {
        isLoading: this.props.isLoggedIn,
    };

    async componentDidMount(): Promise<void> {
        if (this.props.isLoggedIn) {
            this.refreshAdmin();
        }
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.isLoggedIn && !prevProps.isLoggedIn) {
            this.refreshAdmin();
        }
    }

    private refreshAdmin = () => {
        this.setState({ isLoading: true }, async () => {
            try {
                const result = await Api.me();
                this.props.dispatch(AuthActions.setAdmin(result.adminMe!));
                this.setState({ isLoading: false });
            } catch {
                this.setState({ isLoading: false });
                // TODO: handle error
            }
        });
    };

    render(): React.ReactNode {
        if (this.state.isLoading) {
            return <LoadingOverlay />;
        }

        return (
            <Switch>
                <Route exact path={Path.login} component={LoginPage} />
                <Route exact path={Path.logout} component={LogoutPage} />
                <Route exact path={Path.activate(":email", ":token")} component={ActivatePage} />
                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.dashboard} component={Pages.dashboard} />
                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.highlights} component={HighlightsPage} permission={Permission.MANAGE_CONTENT} />

                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.userList} component={UserListPage} permission={Permission.MANAGE_ADMINS} />
                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.createUser} component={UserCreatePage} permission={Permission.MANAGE_ADMINS} />
                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.editUser} component={UserEditPage} permission={Permission.MANAGE_ADMINS} />

                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.contentList} component={Pages.contentList} permission={Permission.MANAGE_CONTENT} />
                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.createContent} component={Pages.content} permission={Permission.MANAGE_CONTENT} />
                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.editContent} component={Pages.content} permission={Permission.MANAGE_CONTENT} />

                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.matchList} component={Pages.matchList} permission={Permission.MANAGE_MATCHES} />

                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.decadeList} component={Pages.decadeList} permission={Permission.MANAGE_DECADES} />
                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.editDecade} component={Pages.editDecade} permission={Permission.MANAGE_DECADES} />

                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.galleryList} component={Pages.galleryList} permission={Permission.MANAGE_GALLERIES} />
                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.createGallery} component={Pages.gallery} permission={Permission.MANAGE_GALLERIES} />
                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.editGallery} component={Pages.gallery} permission={Permission.MANAGE_GALLERIES} />

                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.informationList} component={Pages.informationList} permission={Permission.MANAGE_INFORMATIONS} />
                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.createInformation} component={Pages.information} permission={Permission.MANAGE_INFORMATIONS} />
                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.editInformation} component={Pages.information} permission={Permission.MANAGE_INFORMATIONS} />

                <LayoutWrapper layout={LoggedInLayout} exact={true} path={AppPath.documents} component={Pages.documents} permission={Permission.MANAGE_DOCUMENTS} />

                {process.env.NODE_ENV === "development" && <Route exact path={AppPath.sandbox} component={SandboxPage} />}

                <Route exact={false} path="*" component={Error404Page} />
            </Switch>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    isLoggedIn: AuthSelectors.isLoggedIn(state),
    admin: state.auth.admin,
});

const App = connect(mapStateToProps)(AppComponent);

export { App };
