import { ObjectUtils } from "utils/ObjectUtils";

class ApiError<T> extends Error {
    public readonly intlKey?: string;
    public readonly code?: ApiErrorCode;
    public readonly validation?: T;

    constructor(message: ApiErrorCode | string, validation?: T) {
        super(message);
        if (ObjectUtils.isEnumContains(ApiErrorCode, message)) {
            this.intlKey = `error.api.${message}`;
            this.code = message as ApiErrorCode;
        }
        this.validation = validation;
    }
}

/**
 * Error messages from Ai excluded 'E_' started codes
 */
enum ApiErrorCode {
    UNKNOWN = "UNKNOWN",
    NETWORK_ERROR = "NETWORK_ERROR",
    REQUEST_TIMEOUT = "REQUEST_TIMEOUT",
    INVALID_RESPONSE = "INVALID_RESPONSE",
    INVALID_INPUT = "INVALID_INPUT",
}

export { ApiError, ApiErrorCode };
