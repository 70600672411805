import React, { PureComponent } from "react";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button";
import styled from "styled-components";
import { Color } from "theme/theme";

interface Props {
    currentPage: number;
    pagesCount: number;
    onPageChange: (newPage: number) => void;
}

class TableFooter extends PureComponent<Props> {
    private static readonly ELLIPSIS: string = "…";

    private onPageChange = (pageNum: number) => (): void => {
        this.props.onPageChange(pageNum);
    };

    /**
     * Calculating pages and ellipseses and rendering corresponding buttons
     */
    private getPages = (): Array<number | string> => {
        const { currentPage, pagesCount } = this.props;

        if (!this.props.currentPage || !this.props.pagesCount) {
            return [];
        }

        let pagesArray: number[] = [];
        let finalArray: Array<number | string> = [];

        if (currentPage && currentPage >= 1 && pagesCount && pagesCount >= 1) {
            finalArray = [1];
            if (pagesCount >= 8) {
                let startWithEllipsis = false;
                let endWithEllipsis = false;

                pagesArray.push(1);

                if (currentPage >= 5) {
                    startWithEllipsis = true;
                    pagesArray = [1, currentPage - 2, currentPage - 1];
                } else {
                    pagesArray = [1, 2, 3, 4, 5, 6];
                }

                pagesArray.push(currentPage);

                if (currentPage <= pagesCount - 4) {
                    endWithEllipsis = true;
                    pagesArray = [...pagesArray, currentPage + 1, currentPage + 2];
                } else {
                    pagesArray = [...pagesArray, pagesCount - 5, pagesCount - 4, pagesCount - 3, pagesCount - 2, pagesCount - 1];
                }

                pagesArray.push(pagesCount);

                finalArray = pagesArray.filter((value: number, index: number) => pagesArray.indexOf(value) === index).sort((a: number, b: number) => a - b);

                if (startWithEllipsis) {
                    const firstPageNum: number | string = finalArray[0];
                    finalArray.shift();
                    finalArray.unshift(firstPageNum, TableFooter.ELLIPSIS);
                }

                if (endWithEllipsis) {
                    const lastPageNum: number | string = finalArray[finalArray.length - 1];
                    finalArray.pop();
                    finalArray.push(TableFooter.ELLIPSIS, lastPageNum);
                }
            } else if (pagesCount >= 2) {
                for (let i = 2; i <= pagesCount; i++) {
                    finalArray.push(i);
                }
            }
        }
        return finalArray;
    };

    public render(): React.ReactElement<any> {
        const { currentPage } = this.props;
        const pageNumbers = this.getPages();
        return (
            <TableFoot>
                <tr>
                    <td>
                        <Button
                            className="btn-pagination table-footer-scroll-button"
                            btnLabel={Intl.formatMessage({ id: "components.table.previousPage" })}
                            onClick={this.onPageChange(this.props.currentPage - 1)}
                            disabled={this.props.currentPage - 1 === 0}
                        />
                        {pageNumbers.map(
                            (pageNumber: string | number, index: number): JSX.Element => {
                                const parsedPageNum = String(pageNumber);
                                const isCurrentPageActive: boolean = !!currentPage && String(currentPage) === String(pageNumber);
                                const key = `${parsedPageNum}-${index}`;

                                return (
                                    <Button
                                        key={key}
                                        className={`btn-pagination btn-round ${parsedPageNum === TableFooter.ELLIPSIS ? "btn-none" : ""}`}
                                        disabled={isCurrentPageActive || parsedPageNum === TableFooter.ELLIPSIS}
                                        btnLabel={parsedPageNum}
                                        onClick={this.onPageChange(Number(pageNumber))}
                                    />
                                );
                            },
                        )}
                        <Button
                            className="table-footer-scroll-button btn-pagination"
                            btnLabel={Intl.formatMessage({ id: "components.table.nextPage" })}
                            onClick={this.onPageChange(this.props.currentPage + 1)}
                            disabled={this.props.currentPage === this.props.pagesCount}
                        />
                    </td>
                </tr>
            </TableFoot>
        );
    }
}

const TableFoot = styled.tfoot`
    display: flex;
    position: absolute;
    justify-content: center;
    width: 100%;

    tr {
        td {
            display: flex;
            max-width: unset;
            justify-content: center;

            button {
                margin: 10px 5px;
                &.btn-pagination.btn-round {
                    width: 48px;
                }
            }
        }
    }

    .table-footer-scroll-button {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .btn-pagination {
        background: ${Color.white};
        border: none;
        transition: ease-in-out 300ms;
        color: ${Color.secondaryXD};
        opacity: 0.5;

        &:hover,
        &:disabled {
            opacity: 1;
            box-shadow: 0px 6px 12px rgba(217, 225, 255, 0.5);
        }
        &:hover {
            cursor: pointer;
        }
        &.btn-none {
            box-shadow: none;
            &:hover {
                cursor: auto;
            }
        }
    }
`;

export { TableFooter };
