import { FakeLabel } from "components/Inputs/InputWrapper";
import React from "react";
import styled from "styled-components";
import { keyframesBlinkInputShadow, hexToRGB } from "theme/global";

import { Color } from "theme/theme";

interface Props {
    ariaLabel: string;
    hasError?: boolean;
    innerRef?: (ref: HTMLInputElement | null) => void;
    [x: string]: any;
}

class InputComponent extends React.PureComponent<Props> {
    render(): JSX.Element {
        const { ariaLabel, innerRef, ...otherProps } = this.props;

        return (
            // Note: in order to the "floating label" CSS effect work, required needs to be true. If required can not be true, please use InputWrapper's "floatUpFakeLabel" emergency switch (value !== "").
            <StyledInput ref={innerRef} aria-label={ariaLabel} required={true} {...otherProps} />
        );
    }
}

// TODO: make ternary operator hell more readable... And it would be nice if VSCode's Document Format won't mess it up.
export const StyledInput = styled.input<Props>`
    background-color: ${props => (props.isBackgroundBlue ? "#F8F9FF" : props.theme.background.input.backgroundColor)};
    border-radius: ${props => props.theme.box.input.borderRadius}px;
    border: ${props => (props.hasError ? props.theme.box.input.hasError.borderWidth : props.theme.box.input.borderWidth)}px solid
        ${props => (props.hasError ? props.theme.box.input.hasError.borderColor : props.theme.box.input.borderColor)};
    caret-color: ${props => props.theme.typo.input.color};
    color: ${props => (props.hasError ? props.theme.typo.input.hasError.color : props.theme.typo.input.color)};
    display: block;
    font-family: inherit;
    font-size: ${props => props.theme.typo.input.fontSize}px;
    font-weight: ${props => props.theme.typo.input.fontWeight};
    height: ${props => props.theme.box.input.height}px;
    line-height: ${props => props.theme.box.input.height - props.theme.box.input.paddingTop - props.theme.box.input.paddingBottom - props.theme.box.input.borderWidth * 2}px;
    overflow: ${props => props.hasError && "hidden"};
    padding-bottom: ${props =>
        props.hasError
            ? props.theme.box.input.paddingTop - (props.theme.box.input.hasError.borderWidth === 0 ? 0 : props.theme.box.input.hasError.borderWidth / 2)
            : props.theme.box.input.paddingBottom}px;
    padding-left: ${props =>
        props.hasError
            ? props.theme.box.input.paddingLeft - (props.theme.box.input.hasError.borderWidth === 0 ? 0 : props.theme.box.input.hasError.borderWidth / 2)
            : props.theme.box.input.paddingLeft}px;
    padding-right: ${props => (props.hasError ? props.theme.box.input.height + 2 * 8 : props.theme.box.input.paddingRight)}px;
    padding-top: ${props =>
        props.hasError
            ? props.theme.box.input.paddingTop - (props.theme.box.input.hasError.borderWidth === 0 ? 0 : props.theme.box.input.hasError.borderWidth / 2)
            : props.theme.box.input.paddingTop}px;
    text-overflow: ${props => props.hasError && "ellipsis"};
    white-space: ${props => props.hasError && "nowrap"};
    width: 100%;

    & ~ ${FakeLabel} {
        color: ${props => props.hasError && props.theme.typo.input.hasError.color};
    }

    &:focus {
        border-color: ${props => (props.hasError ? props.theme.box.input.hasError.borderColor : props.theme.box.input.borderColor)};
        outline: 0;

        &:not(:focus-visible) {
            box-shadow: none;
        }
    }

    &:focus-visible {
        animation: ${keyframesBlinkInputShadow} 1s 1;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &:reade-only {
        cursor: default;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: rgba(${hexToRGB(Color.secondary)}, 0.1);
    }

    &:not([disabled]) {
        &:focus {
            & ~ ${FakeLabel} {
                /* Note: scale = CaptionText.fontSize / input.fontSize; translateY = Math.floor(lineHeight + lineHeight / 2 * scale) * -1) */
                color: ${Color.secondaryD};
                transform: scale(${props => props.theme.typo.CaptionText.fontSize / props.theme.typo.input.fontSize})
                    translateY(
                        -${props => Math.floor(props.theme.box.input.height - props.theme.box.input.paddingTop - props.theme.box.input.paddingBottom - props.theme.box.input.borderWidth * 2 + ((props.theme.box.input.height - props.theme.box.input.paddingTop - props.theme.box.input.paddingBottom - props.theme.box.input.borderWidth * 2) / 2) * (props.theme.typo.CaptionText.fontSize / props.theme.typo.input.fontSize))}px
                    );
            }
        }
    }

    &:required {
        &:read-only,
        &:valid {
            & ~ ${FakeLabel} {
                color: ${Color.secondaryD};
                /* Note: scale = CaptionText.fontSize / input.fontSize; translateY = Math.floor(lineHeight + lineHeight / 2 * scale) * -1) */
                transform: scale(${props => props.theme.typo.CaptionText.fontSize / props.theme.typo.input.fontSize})
                    translateY(
                        -${props => Math.floor(props.theme.box.input.height - props.theme.box.input.paddingTop - props.theme.box.input.paddingBottom - props.theme.box.input.borderWidth * 2 + ((props.theme.box.input.height - props.theme.box.input.paddingTop - props.theme.box.input.paddingBottom - props.theme.box.input.borderWidth * 2) / 2) * (props.theme.typo.CaptionText.fontSize / props.theme.typo.input.fontSize))}px
                    );
            }
        }
    }
`;

export const Input: React.ComponentClass<Props> = InputComponent;
