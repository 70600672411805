import React from "react";
import { Sandbox } from "../Sandbox";

class SandboxPage extends React.PureComponent {
    public render(): React.ReactElement {
        return <Sandbox />;
    }
}

export { SandboxPage };
