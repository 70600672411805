import { Dispatch, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "reducers";

export interface Action<T extends string> {
    type: T;
}

export interface ActionWithPayload<T extends string, P> extends Action<T> {
    payload: P;
}

type FunctionType = (...args: any[]) => any;
interface ActionCreatorsMapObject {
    [actionCreator: string]: FunctionType;
}
interface ThunkMapObject {
    [thunkName: string]: PromiseThunkReturningWithState;
}

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;
export type ThunkUnion<A extends ThunkMapObject> = A[keyof A];

export function createAction<T extends string>(type: T): Action<T>;
/* eslint-disable no-redeclare */
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P): ActionWithPayload<T, P> | Action<T> {
    return payload === undefined ? { type } : { type, payload };
}
/* eslint-enable no-redeclare */

export type CustomThunkDispatch = ThunkDispatch<ApplicationState, never, AnyAction>;
export interface ThunkDispatchProp {
    dispatch: CustomThunkDispatch;
}
export type PromiseThunkReturningWithState = (...params: any[]) => (dispatch: Dispatch, getState: () => ApplicationState) => Promise<ApplicationState>;
// export type ThunkWithCallback<T extends any[]> = (cb: () => void, ...rest: T) => (dispatch: Dispatch, getState: () => ReduxStoreType) => any;

// export function promisifyThunk<T extends any[]>(dispatch: CustomThunkDispatch, thunk: ThunkWithCallback<T>, ...rest: T): Promise<void> {
//   return new Promise(resolve => dispatch(thunk(resolve, ...rest)));
// }
