import React from "react";

type PropType = {
    isDragOccurring: boolean;
} & React.HTMLProps<HTMLTableCellElement>;

type TableCellSnapshot = {
    width: number;
    height: number;
};

export default class DraggableTableCell extends React.Component<PropType> {
    ref: React.RefObject<HTMLTableDataCellElement> = React.createRef();

    getSnapshotBeforeUpdate(prevProps: PropType): TableCellSnapshot | null {
        if (!this.ref || !this.ref.current) {
            return null;
        }

        const isDragStarting: boolean = this.props.isDragOccurring && !prevProps.isDragOccurring;

        if (!isDragStarting) {
            return null;
        }

        const { width, height } = this.ref.current.getBoundingClientRect();

        const snapshot: TableCellSnapshot = {
            width,
            height,
        };

        return snapshot;
    }

    componentDidUpdate(_prevProps: PropType, _prevState: any, snapshot: TableCellSnapshot | null) {
        if (!this.ref || !this.ref.current) {
            return null;
        }

        if (snapshot) {
            if (this.ref.current.style.width === `${snapshot.width}`) {
                return;
            }
            this.ref.current.style.width = `${snapshot.width}px`;
            this.ref.current.style.height = `${snapshot.height}px`;
            return;
        }

        if (this.props.isDragOccurring) {
            return;
        }

        // inline styles not applied
        if (this.ref.current.style.width === null) {
            return;
        }

        // no snapshot and drag is finished - clear the inline styles
        this.ref.current.style.removeProperty("height");
        this.ref.current.style.removeProperty("width");
        return;
    }

    render() {
        const { children, isDragOccurring, ...otherProps } = this.props;
        return (
            <td ref={this.ref} {...otherProps}>
                {children}
            </td>
        );
    }
}
