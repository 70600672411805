import { GalleryPage } from "./Gallery/GalleryPage";
import { GalleryListPage } from "./Gallery/GalleryListPage";
import { InformationListPage } from "./Informations/InformationListPage";
import { InformationPage } from "./Informations/InformationPage";
import { DocumentsPage } from "./Documents/DocumentsPage";
import { LoginPage } from "./LoginPage";
import LogoutPage from "./LogoutPage";
import { ContentList } from "./ContentPage/ContentList";
import { ContentPage } from "./ContentPage/ContentPage";
import { Dashboard } from "./Dashboard";
import { MatchListPage } from "pages/Matches/MatchListPage";
import { DecadeListPage } from "pages/Decades/DecadeListPage";
import { DecadeEditPage } from "pages/Decades/DecadeEditPage";

class Pages {
    public static readonly login = LoginPage;
    public static readonly logout = LogoutPage;
    public static readonly dashboard = Dashboard;
    public static readonly contentList = ContentList;
    public static readonly content = ContentPage;
    public static readonly matchList = MatchListPage;
    public static readonly decadeList = DecadeListPage;
    public static readonly editDecade = DecadeEditPage;
    public static readonly galleryList = GalleryListPage;
    public static readonly gallery = GalleryPage;
    public static readonly informationList = InformationListPage;
    public static readonly information = InformationPage;
    public static readonly documents = DocumentsPage;
}

export { Pages };
