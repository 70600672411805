import { Intl } from "i18n/Intl";

export const ENVIRONMENT_DATA_FRESH_TIME = 10 * 60 * 1000; // 10 minutes
export function isDataStale(timestamp: number): boolean {
    return Date.now() > timestamp + ENVIRONMENT_DATA_FRESH_TIME;
}

export const SYSTEM_MINIMUM_COMMENT_LENGTH = 5;

export type DeploymentEnvironment = "development" | "production" | "uat" | "int";

export const DEPLOYMENT_ENV_MAP: { [s in DeploymentEnvironment]: string | null } = {
    development: "Development env",
    int: "Integration Test",
    uat: "User Acceptance Test",
    production: null,
};

export const getDeploymentEnvironmentText = () => DEPLOYMENT_ENV_MAP[process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT as DeploymentEnvironment] || null;

export const MAX_ACTIVE_CUSTOMER_FEEDBACK_COUNT = 6;
export const MIN_ACTIVE_CUSTOMER_FEEDBACK_COUNT = 2;

export const HIGHLIGHTS_NUMBER = 5;

export const CKEditorDefaultConfig = {
    gallery: {
        toolbarLabel: Intl.formatMessage({ id: "CKEditor.defaultConfig.gallery.toolbarLabel" }),
        editButtonLabel: Intl.formatMessage({ id: "CKEditor.defaultConfig.gallery.editButtonLabel" }),
        callbackName: "galleryCallback",
    },
    embeddedPost: {
        toolbarLabel: Intl.formatMessage({ id: "CKEditor.defaultConfig.embeddedPost.toolbarLabel" }),
        editButtonLabel: Intl.formatMessage({ id: "CKEditor.defaultConfig.embeddedPost.editButtonLabel" }),
        selectedIDLabel: Intl.formatMessage({ id: "CKEditor.defaultConfig.embeddedPost.selectedIDLabel" }),
        callbackName: "embeddedPostCallback",
    },
    cdnImage: {
        toolbarLabel: Intl.formatMessage({ id: "CKEditor.defaultConfig.cdnImage.toolbarLabel" }),
        callbackName: "cdnImageCallback",
    },
    infoImage: {
        toolbarLabel: Intl.formatMessage({ id: "CKEditor.defaultConfig.infoImage.toolbarLabel" }),
        callbackName: "infoImageCallback",
        descriptionLabel: Intl.formatMessage({ id: "CKEditor.defaultConfig.infoImage.descriptionLabel" }),
        authorLabel: Intl.formatMessage({ id: "CKEditor.defaultConfig.infoImage.authorLabel" }),
        sourceLabel: Intl.formatMessage({ id: "CKEditor.defaultConfig.infoImage.sourceLabel" }),
    },
    toolbar: {
        items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "blockQuote",
            "mediaEmbed",
            "undo",
            "redo",
            "htmlEmbed",
            "gallery",
            "embeddedPost",
            "infoImage",
        ],
    },
    heading: {
        options: [
            { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
            { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
            { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
            { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
            {
                model: "highlighted",
                view: {
                    name: "p",
                    classes: "highlighted",
                },
                class: "highlighted",
                converterPriority: "high",
                title: Intl.formatMessage({ id: "CKEditor.defaultConfig.heading.options.highlighted.title" }),
            },
        ],
    },
    mediaEmbed: {
        previewsInData: true,
    },
    htmlEmbed: {
        sanitizeHtml(inputHtml: string) {
            return { html: inputHtml };
        },
    },
    image: {
        toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
    },
};
