import { Log } from "./Log";
import { PageType } from "./TypeUtils";

export class Path {
    public static readonly sandbox = "/sandbox";
    public static readonly sandboxCMS = "/sandboxcms";
    public static readonly login = "/login";
    public static readonly logout = "/logout";
    public static readonly activate = (email: string, token: string) => {
        return `/activate/${email}/${token}`;
    };
    public static readonly dashboard = "/";

    public static readonly highlights = "/highlights";

    public static readonly userList: string = "/users";
    public static readonly createUser: string = "/users/create";
    public static readonly editUser = (userId: string): string => {
        return `/users/${userId}/edit`;
    };

    public static readonly contentList = (contentType: string): string => {
        return `/contents/${contentType}`;
    };
    public static readonly createContent = (contentType: string): string => {
        return `/contents/${contentType}/create`;
    };
    public static readonly editContent = (contentUrl: string): string => {
        return `/contents/${contentUrl}/edit`;
    };

    public static readonly matchList: string = "/matches";

    public static readonly decadeList: string = "/decades";
    public static readonly editDecade = (id: string): string => {
        return `/decades/${id}/edit`;
    };

    public static readonly galleryList: string = "/galleries";
    public static readonly createGallery: string = "/galleries/create";
    public static readonly editGallery = (id: string): string => {
        return `/galleries/${id}/edit`;
    };

    public static readonly informationList: string = "/informations";
    public static readonly createInformation: string = "/informations/create";
    public static readonly editInformation = (id: string): string => {
        return `/informations/${id}/edit`;
    };

    public static readonly documents: string = "/documents";
}

export class AppPath {
    public static readonly sandbox: string = Path.sandbox;
    public static readonly sandboxCMS: string = Path.sandboxCMS;
    public static readonly login: string = Path.login;
    public static readonly logout: string = Path.logout;
    public static readonly activate: string = Path.activate(":email", ":token");
    public static readonly dashboard: string = Path.dashboard;

    public static readonly highlights: string = Path.highlights;

    public static readonly userList: string = Path.userList;
    public static readonly createUser: string = Path.createUser;
    public static readonly editUser: string = Path.editUser(":userId");

    public static readonly contentList: string = Path.contentList(":contentType");
    public static readonly createContent: string = Path.createContent(":contentType");
    public static readonly editContent: string = Path.editContent(":contentUrl");

    public static readonly matchList: string = Path.matchList;

    public static readonly decadeList: string = Path.decadeList;
    public static readonly editDecade: string = Path.editDecade(":id");

    public static readonly galleryList: string = Path.galleryList;
    public static readonly createGallery: string = Path.createGallery;
    public static readonly editGallery: string = Path.editGallery(":id");

    public static readonly informationList: string = Path.informationList;
    public static readonly createInformation: string = Path.createInformation;
    public static readonly editInformation: string = Path.editInformation(":id");

    public static getPageType(path: string): PageType {
        switch (path) {
            case AppPath.createContent:
            case AppPath.createInformation:
                return PageType.create;

            case AppPath.editContent:
            case AppPath.editInformation:
                return PageType.edit;

            default:
                Log.debug("Unknown path", path, "returning PageType.view");
                return PageType.view;
        }
    }

    public static readonly documents: string = Path.documents;
}
