import React from "react";
import styled from "styled-components";

import { Color } from "theme/theme";
import { StyledButton, LabelWrapper } from "components/Button";
import { StyledInput } from "components/Inputs/Input";
import { SVGIconEye, SVGIconDelete, SVGIconClear } from "components/SVGCollection";
import Modal from "../Modals/Modal";
import { StyledScrollableContainer } from "../Modals/Modal";

type Props = {
    browseButton: JSX.Element;
    showRemove?: boolean;
    onRemove?: () => void;
    hideShowButton?: boolean;
    previewURL?: string;
    showButtonClassName?: string;
};

interface State {
    showPreview: boolean;
}

export class InputCDN extends React.PureComponent<Props, State> {
    public state: State = {
        showPreview: false,
    };

    private readonly onTogglePreview = (): void => {
        this.setState({
            showPreview: !this.state.showPreview,
        });
    };

    public render(): JSX.Element {
        return (
            <StyledInputCDN className="grid-x">
                <div className="cell auto">{this.props.children}</div>

                {!this.props.hideShowButton && this.props.previewURL && (
                    <div className="cell shrink">
                        <ShowButton type="button" className={this.props.showButtonClassName} title="Preview" onClick={() => this.onTogglePreview()}>
                            <span aria-hidden="true">
                                <SVGIconEye focusable="false" />
                            </span>
                        </ShowButton>
                        <PreviewModal
                            mounted={this.state.showPreview}
                            titleText="Default Modal"
                            style={{
                                width: "100%",
                                padding: "10px",
                                margin: "0 auto",
                                border: `1px solid ${Color.secondary}`,
                            }}
                            underlayColor={Color.modal}
                            onModalClose={this.onTogglePreview}
                        >
                            <PreviewModalClose onClick={() => this.onTogglePreview()}>
                                <SVGIconClear />
                            </PreviewModalClose>
                            <img src={this.props.previewURL} alt="preview" />
                        </PreviewModal>
                    </div>
                )}

                {this.props.showRemove === true && (
                    <div className="cell shrink">
                        <ShowButton onClick={this.props.onRemove} type="button" className={this.props.showButtonClassName} title="Remove">
                            <span aria-hidden="true">
                                <SVGIconDelete focusable="false" />
                            </span>
                        </ShowButton>
                    </div>
                )}
                <div className="cell shrink">{this.props.browseButton}</div>
            </StyledInputCDN>
        );
    }
}

const PreviewModal = styled(Modal)`
    ${StyledScrollableContainer} {
        padding: 10px;
    }
    img {
        max-width: 800px;
    }
`;

export const StyledInputCDN = styled.div`
    ${StyledInput} {
        border-bottom-right-radius: 0;
        border-right-color: transparent;
        border-top-right-radius: 0;
        border-right: 0;

        &:read-only {
            background: none !important;
        }
    }

    ${StyledButton} {
        background-color: ${Color.secondary};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-color: ${Color.secondary};
        height: 50px;
        min-width: 128px;

        &:active,
        &:hover {
            background-color: ${Color.secondaryL};
            border-left-color: ${Color.secondaryL};
        }

        &:not(:last-of-type) {
            border-radius: 0;
        }
    }

    ${LabelWrapper} {
        color: ${Color.white};
        font-size: 14px;
        line-height: 48px;
    }
`;

const PreviewModalClose = styled.button`
    height: 20px;
    width: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
`;

export const ShowButton = styled.button`
    align-items: center;
    border-bottom: 1px solid ${Color.secondary};
    border-top: 1px solid ${Color.secondary};
    color: ${Color.secondary};
    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;

    &:hover {
        color: ${Color.secondaryL};
    }

    &:active {
        color: ${Color.primary};
    }
`;
