import React from "react";
import { SVGIconAddFull, SVGIconDelete, SVGIconPencil } from "components/SVGCollection";
import styled from "styled-components";
import { Color } from "theme/theme";

export type TableLinkType = {
    id: string;
    icon?: JSX.Element;
    content: string | null;
};

type Props = {
    title: string;
    links?: TableLinkType[];
    multiple?: boolean;
    onAddClick: () => void;
    onEditClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
};

class TableLink extends React.PureComponent<Props> {
    public render(): React.ReactElement {
        const { title, links, multiple } = this.props;
        return (
            <TableLinkWrapper hasLink={!!links}>
                {links ? (
                    <>
                        {links.map((link, key) => {
                            return (
                                <TableLinkContainer key={key} hasIcon={!!link.icon}>
                                    <TableLinkInfo>
                                        {link.icon && link.icon}
                                        {link.content}
                                    </TableLinkInfo>
                                    <TableLinkTooltip>
                                        <a onClick={() => this.props.onEditClick(link.id)}>
                                            <SVGIconPencil />
                                        </a>
                                        <a onClick={() => this.props.onDeleteClick(link.id)}>
                                            <SVGIconDelete />
                                        </a>
                                    </TableLinkTooltip>
                                </TableLinkContainer>
                            );
                        })}
                        {multiple && (
                            <TableLinkAddWrapper onClick={this.props.onAddClick}>
                                <SVGIconAddFull />
                                <span>{title}</span>
                            </TableLinkAddWrapper>
                        )}
                    </>
                ) : (
                    <TableLinkAddWrapper onClick={this.props.onAddClick}>
                        <SVGIconAddFull />
                        <span>{title}</span>
                    </TableLinkAddWrapper>
                )}
            </TableLinkWrapper>
        );
    }
}

const TableLinkInfo = styled.span`
    line-height: 20px;
    svg {
        width: 20px;
        height: 20px;
    }
`;

const TableLinkTooltip = styled.div`
    position: absolute;
    display: none;
    height: 34px;
    min-width: 50px;
    background-color: ${Color.secondaryXL};
    border-radius: 5px;
    top: -34px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;

    &:after {
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 6px 5.5px 0 5.5px;
        border-color: ${Color.secondaryXL} transparent transparent transparent;
        bottom: 0;
        left: 50%;
        transform: translate3d(-50%, 100%, 0);
        position: absolute;
    }

    a {
        flex: 1;
        color: ${Color.white};
        padding: 8px;

        svg {
            width: 18px;
            height: 18px;
            margin: 0;
        }

        &:hover {
            color: ${Color.grayL};
        }
    }
`;

export const TableLinkContainer = styled.div<{ hasIcon: boolean }>`
    display: table;
    position: relative;
    font-weight: ${props => (props.hasIcon ? "normal" : "bold")};
    font-size: ${props => (props.hasIcon ? "16px" : "11px")};
    line-height: ${props => (props.hasIcon ? "19px" : "13px")};
    text-decoration: ${props => (props.hasIcon ? "none" : "underline")};
    margin-bottom: 5px;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
        ${TableLinkTooltip} {
            display: flex;
        }
    }
`;

const TableLinkWrapper = styled.div<{ hasLink?: boolean }>`
    color: ${Color.secondaryXD};
    position: relative;

    svg {
        display: inline-block;
        margin-bottom: -3px;
        margin-right: 5px;
    }

    span {
        font-size: 10px;
        line-height: 15px;
        text-decoration: ${props => (props.hasLink ? "underline" : "none")};

        &:hover {
            cursor: pointer;
        }
    }

    ${TableLinkContainer} {
        span {
            font-size: unset;
            line-height: unset;
            text-decoration: unset;
        }
    }
`;

const TableLinkAddWrapper = styled.span`
    font-size: 10px !important;
    line-height: 15px !important;
    text-decoration: none !important;

    span {
        text-decoration: none !important;
    }
`;

export { TableLink };
