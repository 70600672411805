import React, { ReactNode } from "react";

import { H2 } from "theme/global";
import { Button } from "components/Button";
import { Input } from "components/Inputs/Input";
import { InputWrapper } from "components/Inputs/InputWrapper";
import { ButtonWrapper, Form, ModalContainer, ModalHeader, ModalInputWrapper } from "components/Modals/ModalLocTool";
import { Intl } from "i18n/Intl";
import { SVGLogoMOLKupaLogin } from "../SVGCollection";
import { IlluWrapper } from "./ModalLogin";

interface Props {
    mounted?: boolean;
    renderButtonActivate?: ReactNode;
    password?: string;
    passwordConfirmation?: string;
    onPasswordChange?: React.ChangeEventHandler<HTMLInputElement>;
    onPasswordConfirmationChange?: React.ChangeEventHandler<HTMLInputElement>;
    onActivateClick?: () => void;
    errors: {
        password: string | null;
        passwordConfirmation: string | null;
    };
    [x: string]: any;
}

interface State {
    showError: boolean;
}

class ModalActivate extends React.Component<Props, State> {
    public readonly state: State = {
        showError: false,
    };

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.formError !== this.props.formError) {
            this.setState(
                {
                    showError: true,
                },
                () => setInterval(() => this.setState({ showError: false }), 5000),
            );
        }
    }

    public render(): React.ReactElement {
        return (
            <ModalContainer
                mounted={this.props.mounted}
                titleText={Intl.formatMessage({ id: "pages.activate.title" })}
                renderButtonActivate={this.props.renderButtonActivate}
                renderOutsideOfScrollableContainerTop={
                    <IlluWrapper>
                        <SVGLogoMOLKupaLogin width={140} height={140} />
                    </IlluWrapper>
                }
            >
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        this.props.onLoginClick && this.props.onLoginClick();
                    }}
                >
                    <ModalHeader>
                        <H2 as="h1">
                            {Intl.formatMessage({ id: "pages.activate.title" })}
                            {/* Oops, something went wrong! */}
                        </H2>

                        <p>{Intl.formatMessage({ id: "pages.activate.description" })}</p>
                    </ModalHeader>

                    <ModalInputWrapper>
                        <InputWrapper errorMsg={this.props.errors?.password || undefined} fakeLabel={Intl.formatMessage({ id: "pages.activate.form.password.name" })}>
                            <Input
                                ariaLabel={Intl.formatMessage({ id: "pages.activate.form.password.name" })}
                                autoComplete="no"
                                hasError={!!this.props.errors?.password}
                                spellCheck="false"
                                type="password"
                                value={this.props.password}
                                onChange={this.props.onPasswordChange}
                            />
                        </InputWrapper>

                        <InputWrapper errorMsg={this.props.errors?.passwordConfirmation || undefined} fakeLabel={Intl.formatMessage({ id: "pages.activate.form.passwordConfirmation.name" })}>
                            <Input
                                ariaLabel={Intl.formatMessage({ id: "pages.activate.form.passwordConfirmation.name" })}
                                autoComplete="no"
                                hasError={!!this.props.errors?.passwordConfirmation}
                                spellCheck="false"
                                type="password"
                                value={this.props.passwordConfirmation}
                                onChange={this.props.onPasswordConfirmationChange}
                            />
                        </InputWrapper>

                        <p>
                            <small>{Intl.formatMessage({ id: "pages.activate.hint" })}</small>
                        </p>

                        <ButtonWrapper>
                            <Button btnLabel={Intl.formatMessage({ id: "pages.activate.activate" })} expanded={true} type="submit" />
                        </ButtonWrapper>
                    </ModalInputWrapper>
                </Form>
            </ModalContainer>
        );
    }
}
export { ModalActivate };
