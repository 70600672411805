import React from "react";
import { ThunkDispatchProp } from "actions/Helpers";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { LoadingPage } from "./LoadingPage";
import { Path } from "utils/Path";
import { Api } from "api/Api";
import { AuthActions } from "actions/AuthActions";

type Props = ThunkDispatchProp & RouteComponentProps;

class LogoutPage extends React.PureComponent<Props> {
    public componentDidMount = async () => {
        await Api.logout().then(() => {
            this.props.dispatch(AuthActions.destroySession());
            this.props.history.push(Path.login);
        });
    };

    public render(): React.ReactElement {
        return <LoadingPage />;
    }
}

export default withRouter(connect()(LogoutPage));
