import React from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LoadingOverlay from "components/LoadingOverlay";
import styled from "styled-components";
import { Path } from "utils/Path";
import { Main } from "components/cms/MainElements";
import { TableWrapper } from "components/TableElements";
import { Intl } from "i18n/Intl";
import { Api } from "api/Api";
import { CreateGalleryInput, UpdateGalleryInput, GalleryImageInput, Gallery } from "api/graphql/types";
import { Button } from "components/Button";
import { Alert } from "components/cms/Alert/Alert";
import { Input } from "components/Inputs/Input";
import { Textarea } from "components/Inputs/Textarea";
import { InputWrapper, FakeLabel, InputWrapperInnerWrapper } from "components/Inputs/InputWrapper";
import { SVGIconAdd, SVGIconKeyboardArrowUp, SVGIconKeyboardArrowDown, SVGIconTrash } from "components/SVGCollection";
import { IntlHelpers } from "i18n/IntlHelpers";
import { hexToRGB } from "theme/global";
import { Color } from "theme/theme";
import { Env } from "utils/Env";
import { isNil } from "lodash";

interface RouteProps {
    id?: string;
}

enum GalleryPageType {
    create = "create",
    edit = "edit",
}

type Props = RouteComponentProps<RouteProps>;

interface State {
    isLoading: boolean;
    pageType: GalleryPageType;
    input: CreateGalleryInput | UpdateGalleryInput;
}

class GalleryPageComponent extends React.Component<Props, State> {
    public state: State = {
        isLoading: !!this.props.match.params.id,
        pageType: this.props.match.params.id ? GalleryPageType.edit : GalleryPageType.create,
        input: {
            title: "",
            images: [],
        },
    };

    componentDidMount() {
        if (this.props.match.params.id) {
            this.fetchGallery();
        }
    }

    private readonly getUpdateInput = (gallery: Gallery): UpdateGalleryInput => {
        return {
            title: gallery.title,
            images: gallery.images.map(image => {
                return {
                    url: image.url,
                    caption: image.caption,
                    credit: image.credit,
                };
            }),
        };
    };

    private readonly fetchGallery = (): void => {
        this.setState({ isLoading: true }, async () => {
            try {
                const response = await Api.listGalleries({ filters: { id: this.props.match.params.id! }, count: 1 });
                if (response.data.length === 0) {
                    Alert.error({ title: Intl.formatMessage({ id: "pages.galleries.messages.notFound" }) });
                    this.props.history.push(Path.galleryList);
                }
                this.setState({
                    isLoading: false,
                    input: this.getUpdateInput(response.data[0]),
                });
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.props.history.push(Path.galleryList);
            }
        });
    };

    private readonly onBackClick = (): void => {
        this.props.history.push(Path.galleryList);
    };

    private readonly onTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            input: {
                ...this.state.input,
                title: e.currentTarget.value,
            },
        });
    };

    private readonly onGalleryImageSelect = (): void => {
        window.bfml.openLibrary({
            embedAuthToken: "auth",
            onSelection: (assets: string[]) => {
                this.setState({
                    input: {
                        ...this.state.input,
                        images: [
                            ...(this.state.input.images || []),
                            ...assets.map(asset => {
                                return {
                                    url: asset,
                                    caption: null,
                                    credit: null,
                                };
                            }),
                        ],
                    },
                });
            },
        });
    };

    private readonly resortImages = (source: number, destination: number) => {
        const imageList = this.state.input.images;
        if (!imageList) {
            return;
        }

        const newImageList: GalleryImageInput[] = [...imageList];

        const [removed] = newImageList.splice(source, 1);
        newImageList.splice(destination, 0, removed);

        this.setState({
            input: {
                ...this.state.input,
                images: newImageList,
            },
        });
    };

    private readonly onImageDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }

        this.resortImages(result.source.index, result.destination.index);
    };

    private readonly onImageCaptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>, idx: number) => {
        let newCurrentImages: GalleryImageInput[] = [];

        if (this.state.input.images) {
            newCurrentImages = [...this.state.input.images];
            if (!isNil(newCurrentImages[idx])) {
                newCurrentImages[idx].caption = e.currentTarget.value;
            }
        }

        this.setState({
            input: {
                ...this.state.input,
                images: newCurrentImages,
            },
        });
    };

    private readonly onImageCreditChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        let newCurrentImages: GalleryImageInput[] = [];

        if (this.state.input.images) {
            newCurrentImages = [...this.state.input.images];
            if (!isNil(newCurrentImages[idx])) {
                newCurrentImages[idx].credit = e.currentTarget.value;
            }
        }

        this.setState({
            input: {
                ...this.state.input,
                images: newCurrentImages,
            },
        });
    };

    private readonly onImageRemove = (idx: number) => {
        this.setState({
            input: {
                ...this.state.input,
                images: this.state.input.images?.filter((_item, key) => key !== idx),
            },
        });
    };

    private readonly renderGalleryItems = (images: GalleryImageInput[]): React.ReactElement[] => {
        return images.map(
            (image: GalleryImageInput, key: number): React.ReactElement => {
                return (
                    <Draggable draggableId={key.toString()} index={key} key={key}>
                        {(provided, snapshot) => (
                            <GalleryDroppableRowItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} isDragging={snapshot.isDragging}>
                                <GalleryDroppableRowNavButtons>
                                    {key === images.length - 1 && images.length !== 1 && (
                                        <StyledGalleryDroppableButton
                                            className="button-up"
                                            ariaLabel="Up"
                                            onlyIcon={true}
                                            renderIcon={<SVGIconKeyboardArrowUp />}
                                            onClick={() => this.resortImages(key, 0)}
                                        />
                                    )}
                                    {key === 0 && images.length !== 1 && (
                                        <StyledGalleryDroppableButton
                                            className="button-down"
                                            ariaLabel="Down"
                                            onlyIcon={true}
                                            renderIcon={<SVGIconKeyboardArrowDown />}
                                            onClick={() => this.resortImages(key, images.length - 1)}
                                        />
                                    )}
                                </GalleryDroppableRowNavButtons>
                                <GalleryDroppableRowImage src={`${Env.mediaLibraryAssetUrl}/${image.url}`} alt={image.url} />
                                <StyledGalleryDroppableRowDetails>
                                    <h2>{image.url}</h2>
                                    {/*
                                    TODO(implement when bfmedlib returns all media info)
                                    <div>
                                        <div>
                                            <SVGIconImageThumbnail />
                                            <span>{props.format}</span>
                                        </div>
                                        <span className="size">{props.size}</span>
                                        <span>{props.dimension}</span>
                                    </div>
                                    */}
                                </StyledGalleryDroppableRowDetails>
                                <GalleryDroppableRowInputWrapper fakeLabel={Intl.formatMessage({ id: "pages.galleries.form.caption.title" })}>
                                    <Textarea ariaLabel="textarea" value={image.caption || ""} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.onImageCaptionChange(e, key)} />
                                </GalleryDroppableRowInputWrapper>
                                <GalleryDroppableRowInputWrapper fakeLabel={Intl.formatMessage({ id: "pages.galleries.form.credit.title" })} className="credit">
                                    <Input ariaLabel="input" value={image.credit || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onImageCreditChange(e, key)} />
                                </GalleryDroppableRowInputWrapper>
                                <GalleryDroppableButtonWrapper>
                                    <StyledGalleryDroppableButton ariaLabel="Up" onlyIcon={true} renderIcon={<SVGIconTrash />} onClick={() => this.onImageRemove(key)} />
                                </GalleryDroppableButtonWrapper>
                            </GalleryDroppableRowItem>
                        )}
                    </Draggable>
                );
            },
        );
    };

    private readonly onSubmit = (): void => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    if (this.state.pageType === GalleryPageType.create) {
                        const response = await Api.createGallery(this.state.input as CreateGalleryInput);
                        this.setState({
                            isLoading: false,
                            input: this.getUpdateInput(response),
                            pageType: GalleryPageType.edit,
                        });
                        this.props.history.push(Path.editGallery(response.id));
                        Alert.success({ title: Intl.formatMessage({ id: "pages.galleries.messages.createSuccess" }) });
                    } else {
                        const response = await Api.updateGallery(this.props.match.params.id!, this.state.input as UpdateGalleryInput);
                        this.setState({
                            isLoading: false,
                            input: this.getUpdateInput(response),
                        });
                        Alert.success({ title: Intl.formatMessage({ id: "pages.galleries.messages.updateSuccess" }) });
                    }
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({
                        isLoading: false,
                    });
                }
            },
        );
    };

    public render(): React.ReactElement {
        if (this.state.isLoading) {
            return <LoadingOverlay />;
        }
        return (
            <>
                <Main.Heading headingText={Intl.formatMessage({ id: `pages.galleries.${this.state.pageType}.title` })} backButtonClick={this.onBackClick}>
                    <Button secondary btnLabel={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onBackClick} style={{ marginRight: "10px" }} />
                    <Button secondary btnLabel={Intl.formatMessage({ id: "common.save" })} onClick={this.onSubmit} disabled={!this.state.input.title || this.state.isLoading} />
                </Main.Heading>
                <TableWrapper>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell medium-6 auto">
                            <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.galleries.form.title.title" })}>
                                <Input ariaLabel={Intl.formatMessage({ id: "pages.galleries.form.title.title" })} value={this.state.input.title} onChange={this.onTitleChange} />
                            </InputWrapper>
                        </div>
                        <div className="cell medium-6 auto">
                            <Button secondary btnLabel={Intl.formatMessage({ id: "pages.galleries.form.add.title" })} renderIcon={<SVGIconAdd />} onClick={this.onGalleryImageSelect} />
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <DragDropContext onDragEnd={result => this.onImageDragEnd(result)}>
                            <Droppable droppableId={"droppable"} direction="vertical">
                                {provided => (
                                    <GalleryDroppable {...provided.droppableProps} ref={provided.innerRef}>
                                        {this.renderGalleryItems(this.state.input.images || [])}
                                    </GalleryDroppable>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </TableWrapper>
            </>
        );
    }
}

const GalleryPage = withRouter(GalleryPageComponent);

const GalleryDroppable = styled.div`
    display: block;
    width: 100%;
`;

const GalleryDroppableRowItem = styled.div<{ isDragging: boolean }>`
    display: flex;
    width: 100%;
    padding: 10px 15px;
    background-color: ${Color.white};
    border-radius: 5px;
    border-bottom: 1px solid rgba(${hexToRGB(Color.grayD)}, 0.25);
    border-top: 1px solid rgba(${hexToRGB(Color.grayD)}, 0.25);

    box-shadow: ${props => (props.isDragging ? "0px 20px 90px rgba(0, 0, 0, 0.12)" : "none")};
`;

const GalleryDroppableRowNavButtons = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    min-width: 35px;
    position: relative;
`;

const GalleryDroppableButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledGalleryDroppableButton = styled(Button)`
    min-width: 35px;
    height: 35px;
    padding: 0;
    opacity: 0.3;

    &:hover {
        opacity: 1;
    }

    &.button-down {
        bottom: 0;
        position: absolute;
    }
`;

const GalleryDroppableRowImage = styled.img`
    width: 70px;
    height: 70px;
    margin-right: 20px;
    object-fit: cover;
`;

const StyledGalleryDroppableRowDetails = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    min-width: 175px;
    max-width: 175px;
    margin-right: 20px;

    h2 {
        padding-bottom: 20px;
        margin: 0 0 auto 0;
        word-break: break-word;
        font-size: 12px;
    }

    div {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: px;

        svg {
            margin-right: 5px;
        }
    }

    .size {
        margin: 0 10px;
    }
`;

const GalleryDroppableRowInputWrapper = styled(InputWrapper)`
    display: flex;
    width: 100%;
    align-items: center;

    ${InputWrapperInnerWrapper} {
        width: 100%;
    }

    &.credit {
        width: 340px;
        margin: 0 20px;
    }

    input,
    textarea {
        min-width: 200px;
        font-size: 12px;
    }

    textarea {
        max-height: 90px;
    }

    ${FakeLabel} {
        font-size: 12px;
    }
`;

export { GalleryPage };
