import { Reducer } from "redux";

import { AuthActionsType, AuthActionTypes } from "actions/AuthActions";
import * as GqlTypes from "api/graphql/types";

export interface AuthStateType {
    admin: GqlTypes.Admin | null;
}

export const initialState: AuthStateType = {
    admin: null,
};

export const AuthReducer: Reducer<AuthStateType, AuthActionsType> = (state: AuthStateType | undefined = initialState, action: AuthActionsType): AuthStateType => {
    switch (action.type) {
        case AuthActionTypes.SET_ADMIN: {
            return { ...state, admin: action.payload.admin };
        }
        case AuthActionTypes.DESTROY_SESSION: {
            return initialState;
        }
        default:
            return state;
    }
};
