import React from "react";

import { Color } from "theme/theme";
import { H2, hexToRGB } from "theme/global";
import { Button } from "components/Button";
import { Link } from "react-router-dom";
import { Intl } from "i18n/Intl";
import { ModalContainer, ModalHeader, ButtonGroupWrapper, Modal386Container, ModalChangeInnerContainer } from "components/Modals/ModalLocTool";
import { Path } from "utils/Path";

class ModalError404 extends React.PureComponent {
    public render(): React.ReactElement {
        return (
            <ModalContainer mounted={true} titleText={Intl.formatMessage({ id: "pages.404.title" })} underlayColor={`rgba(${hexToRGB(Color.gray)}, .7)`}>
                <ModalChangeInnerContainer>
                    <ModalHeader>
                        <H2 as="h1">{Intl.formatMessage({ id: "pages.404.title" })}</H2>

                        <p>{Intl.formatMessage({ id: "pages.404.description" })}</p>
                    </ModalHeader>

                    <Modal386Container>
                        <ButtonGroupWrapper className="grid-x">
                            <div className="cell auto">
                                <Link to={Path.dashboard}>
                                    <Button btnLabel={Intl.formatMessage({ id: "pages.404.back" })} expanded={true} />
                                </Link>
                            </div>
                        </ButtonGroupWrapper>
                    </Modal386Container>
                </ModalChangeInnerContainer>
            </ModalContainer>
        );
    }
}

export { ModalError404 };
