import React from "react";
import { connect, DispatchProp } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import LoadingOverlay from "components/LoadingOverlay";
import AppBar from "../components/AppBar";
import { ModalActivate } from "components/Modals/ModalActivate";
import { Validator } from "utils/Validator";
import { IntlHelpers } from "i18n/IntlHelpers";
import { SetAdminPasswordInput } from "api/graphql/types";
import { Api } from "api/Api";
import { AuthActions } from "actions/AuthActions";
import { Alert } from "components/cms/Alert/Alert";
import { AlertContainer } from "components/cms/Alert/AlertContainer";
import { Intl } from "i18n/Intl";
import { Path } from "utils/Path";
import { ApplicationState } from "reducers";
import { AuthSelectors } from "selectors/AuthSelectors";

interface RouteProps {
    email: string;
    token: string;
}

interface ReduxProps {
    isLoggedIn: boolean;
}

type Props = ReduxProps & RouteComponentProps<RouteProps> & DispatchProp;

interface State {
    password: string;
    passwordConfirmation: string;
    isLoading: boolean;
    errors: {
        password: string | null;
        passwordConfirmation: string | null;
    };
}

class ActivatePageComponent extends React.Component<Props, State> {
    public static readonly getInitialState = (): State => {
        return {
            password: "",
            passwordConfirmation: "",
            isLoading: false,
            errors: {
                password: null,
                passwordConfirmation: null,
            },
        };
    };

    public readonly state: State = ActivatePageComponent.getInitialState();

    private readonly onSubmit = async () => {
        if (this.state.errors.password || this.state.errors.passwordConfirmation) {
            return;
        }
        this.setState({ isLoading: true }, async () => {
            try {
                const { email, token } = this.props.match.params;
                const formData: SetAdminPasswordInput = {
                    email,
                    token,
                    password: this.state.password,
                };
                const response = await Api.resetAdminPassword(formData);
                if (response.admin && response.token) {
                    Alert.success({ title: Intl.formatMessage({ id: "pages.users.messages.activateSuccess" }) });
                    this.props.dispatch(AuthActions.setAdmin(response.admin));
                    this.props.history.push(Path.dashboard);
                }
                this.setState({
                    isLoading: false,
                });
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.setState({
                    isLoading: false,
                });
            }
        });
    };

    private readonly validateForm = () => {
        const { password, passwordConfirmation } = this.state;
        this.setState({
            errors: {
                password: IntlHelpers.getValidationError(Validator.validatePassword(password)),
                passwordConfirmation: IntlHelpers.getValidationError(Validator.validatePasswordRepeat(password, passwordConfirmation)),
            },
        });
    };

    private readonly onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState(
            {
                password: event.currentTarget.value,
            },
            this.validateForm,
        );
    };

    private readonly onPasswordConfirmationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState(
            {
                passwordConfirmation: event.currentTarget.value,
            },
            this.validateForm,
        );
    };

    public render(): React.ReactElement {
        if (this.props.isLoggedIn || !(this.props.match.params.email || this.props.match.params.token)) {
            return <Redirect to={Path.dashboard} />;
        }

        // @TODO("Az Appbart eltakarja a modal activate")
        return (
            <>
                <AppBar />

                {this.state.isLoading && <LoadingOverlay />}

                <ModalActivate
                    mounted={true}
                    password={this.state.password}
                    passwordConfirmation={this.state.passwordConfirmation}
                    onPasswordChange={this.onPasswordChange}
                    onPasswordConfirmationChange={this.onPasswordConfirmationChange}
                    onLoginClick={this.onSubmit}
                    errors={this.state.errors}
                />

                <AlertContainer />
            </>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    isLoggedIn: AuthSelectors.isLoggedIn(state),
});

const ActivatePage = withRouter(connect(mapStateToProps)(ActivatePageComponent));

export { ActivatePage };
