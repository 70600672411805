import React, { ChangeEvent, Component } from "react";
import { ContentTypename } from "api/ApiTypes";
import { getContentsListItemsVariables, AnyContentListItem } from "api/graphql/types";
import Modal, { ButtonWrapper, ModalInputWrapper, StyledModalHeader } from "components/Modals/Modal";
import { CaptionText, H2 } from "theme/global";
import { Intl } from "i18n/Intl";
import { Color } from "theme/theme";
import { Search } from "components/Inputs/Search";
import { TableWrapper, Table as TableElement } from "components/TableElements";
import { SVGContentGallery, SVGContentText, SVGContentVideo, SVGIconSearch } from "components/SVGCollection";
import { Api } from "api/Api";
import { Alert } from "components/cms/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import styled from "styled-components";
import { SSkeletonLine } from "components/Skeleton";
import * as _ from "lodash";
import { EmptyList } from "components/Tables/Table";
import { Button } from "components/Button";

type Props = {
    mounted: boolean;
    onContentSelected: (content: AnyContentListItem[]) => void;
    onModalClose: () => void;
    selectedContents?: AnyContentListItem[] | AnyContentListItem;
    disabledContents?: AnyContentListItem[];
    single?: boolean;
};

interface State {
    options: getContentsListItemsVariables;
    contents: AnyContentListItem[];
    search: string;
    isLoading: boolean;
    hasMorePages: boolean;
    selected: AnyContentListItem[];
}

class ContentModal extends Component<Props, State> {
    public state: State = ContentModal.getInitialStateFromProps(this.props);

    public static readonly getInitialStateFromProps = (props: Props): State => {
        let selected: AnyContentListItem[];
        if (props.selectedContents) {
            selected = Array.isArray(props.selectedContents) ? props.selectedContents : [props.selectedContents];
        } else {
            selected = [];
        }
        return {
            options: {
                count: Modal.DEFAULT_PAGE_SIZE,
                page: 1,
            },
            contents: [],
            selected,
            search: "",
            isLoading: false,
            hasMorePages: true,
        };
    };

    public componentDidMount(): void {
        this.fetchContents(true);
    }

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.mounted !== prevProps.mounted) {
            this.setState(ContentModal.getInitialStateFromProps(this.props), () => this.fetchContents());
        }
    }

    private readonly onFilterChange = _.debounce(() => {
        const { search, options } = this.state;
        const isId = /^\d+$/.test(search);
        this.setState(
            {
                options: {
                    ...options,
                    filters: {
                        title: !isId ? search : undefined,
                        id: isId ? search : undefined,
                    },
                },
            },
            () => this.fetchContents(),
        );
    }, 500);

    private readonly onSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
        this.setState(
            {
                search: e.currentTarget.value,
            },
            () => this.onFilterChange(),
        );
    };

    private readonly onSearchClear = (): void => {
        this.setState(
            {
                search: "",
            },
            () => this.onFilterChange(),
        );
    };

    private readonly loadMoreContent = () => {
        const { options, hasMorePages } = this.state;

        if (hasMorePages) {
            this.setState(
                {
                    options: {
                        ...options,
                        page: (options.page || 1) + 1,
                    },
                },
                () => this.fetchContents(false),
            );
        }
    };

    private fetchContents = (isRefresh: boolean = true): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { paginatorInfo, data } = await Api.getContents(this.state.options);

                    this.setState({
                        contents: isRefresh ? data : this.state.contents.concat(data),
                        hasMorePages: paginatorInfo.hasMorePages,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ contents: [], isLoading: false });
                }
            },
        );
    };

    private getTypeIcon = (typename: string): React.ReactElement => {
        switch (typename) {
            case ContentTypename.TextContent:
                return <SVGContentText width={30} height={30} focusable="false" />;
            case ContentTypename.GalleryContent:
                return <SVGContentGallery width={30} height={30} focusable="false" />;
            case ContentTypename.VideoContent:
                return <SVGContentVideo width={30} height={30} focusable="false" />;
            default:
                return <></>;
        }
    };

    private readonly isSelected = (content: AnyContentListItem): boolean => {
        const { selected } = this.state;
        return selected.some(select => select.id === content.id);
    };

    private readonly isDisabled = (content: AnyContentListItem): boolean => {
        const { disabledContents } = this.props;
        return disabledContents ? disabledContents.some(disabled => disabled.id === content.id) : false;
    };

    private readonly toggleSelected = (content: AnyContentListItem) => {
        const currentSelected = this.state.selected;
        if (this.isDisabled(content)) {
            return;
        }
        if (this.isSelected(content)) {
            this.setState({
                selected: currentSelected.filter(select => select.id !== content.id),
            });
            return;
        }
        this.setState({
            selected: this.props.single ? [content] : [...currentSelected, content],
        });
    };

    private readonly renderSelectedTableBody = (selected: AnyContentListItem[], contents: AnyContentListItem[]): React.ReactElement[] => {
        const contentIds: string[] = contents.map(content => content.id);
        return selected
            .filter(select => !contentIds.includes(select.id))
            .map((content, key) => {
                return (
                    <tr key={key} className={`clickable ${this.isSelected(content) ? "selected" : ""}`} onClick={() => this.toggleSelected(content)}>
                        <td>{this.getTypeIcon(content.__typename)}</td>
                        <td>{content.title}</td>
                        <td>{content.updated_at}</td>
                        <td>{content.id}</td>
                    </tr>
                );
            });
    };

    private readonly renderTableBody = (contents: AnyContentListItem[]): React.ReactElement[] => {
        return contents.map(
            (content: AnyContentListItem, key): React.ReactElement => {
                return (
                    <tr key={key} className={`clickable ${this.isSelected(content) ? "selected" : ""} ${this.isDisabled(content) ? "disabled" : ""}`} onClick={() => this.toggleSelected(content)}>
                        <td>{this.getTypeIcon(content.__typename)}</td>
                        <td>{content.title}</td>
                        <td>{content.updated_at}</td>
                        <td>{content.id}</td>
                    </tr>
                );
            },
        );
    };

    private readonly dataLoadingView = (columnCount = 4, rowCount = 4) => {
        let i = 0;
        return (
            <>
                {new Array(rowCount).fill("1").map(() => {
                    return (
                        <tr key={`skeleton-${++i}`}>
                            {new Array(columnCount).fill("1").map(() => {
                                return (
                                    <td key={`skeleton-${++i}`}>
                                        <SSkeletonLine />
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </>
        );
    };

    private readonly handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.loadMoreContent();
        }
    };

    private readonly returnSelectedContents = () => {
        this.props.onContentSelected(this.state.selected);
        this.props.onModalClose();
    };

    public render(): React.ReactElement {
        const { isLoading, contents, hasMorePages, selected } = this.state;
        return (
            <Modal mounted={this.props.mounted} onModalClose={() => this.props.onModalClose()} underlayColor={Color.modal} titleText={Intl.formatMessage({ id: "components.contentModal.title" })}>
                <StyledModalHeader>
                    <H2 as="h5">{Intl.formatMessage({ id: "components.contentModal.title" })}</H2>
                </StyledModalHeader>
                <ModalInputWrapper>
                    <Search ariaLabel={Intl.formatMessage({ id: "components.contentModal.search" })} value={this.state.search} onChange={this.onSearchChange} onClearClick={this.onSearchClear} />
                </ModalInputWrapper>
                <SSkeletonLine />
                <StyledTableWrapper className={"p-0 mt-30"}>
                    <ScrollableWrapper onScroll={this.handleScroll}>
                        <TableElement>
                            <thead>
                                <th />
                                <th>{Intl.formatMessage({ id: "components.contentModal.table.title" })}</th>
                                <th>{Intl.formatMessage({ id: "components.contentModal.table.createdAt" })}</th>
                                <th>{Intl.formatMessage({ id: "components.contentModal.table.id" })}</th>
                            </thead>
                            <tbody>
                                {this.renderSelectedTableBody(selected, contents)}
                                {contents.length && !isLoading ? (
                                    this.renderTableBody(contents)
                                ) : isLoading ? (
                                    <>
                                        {this.renderTableBody(contents)}
                                        {this.dataLoadingView()}
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan={100}>
                                            <EmptyList>
                                                <SVGIconSearch width={64} height={64} focusable="false" />
                                                <br />
                                                <span>{Intl.formatMessage({ id: "components.table.emptyList" })}</span>
                                            </EmptyList>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </TableElement>
                        {!hasMorePages && (
                            <CaptionText className={"text-align--center"} as="h2">
                                {Intl.formatMessage({ id: "components.contentModal.notMorePages" })}
                            </CaptionText>
                        )}
                    </ScrollableWrapper>
                </StyledTableWrapper>
                <ButtonWrapper>
                    <div className="grid-x grid-margin-y-10">
                        <div className="cell auto grid-x align-right">
                            <Button btnLabel={Intl.formatMessage({ id: "common.cancel" })} secondary={true} className="align-right" onClick={this.props.onModalClose} />
                        </div>
                        <div className="cell auto grid-x align-left">
                            <Button
                                btnLabel={`${selected.length && !this.props.single ? "(" + selected.length + ")" : ""} ${Intl.formatMessage({ id: "common.insert" })}`}
                                disabled={!selected.length}
                                onClick={this.returnSelectedContents}
                            />
                        </div>
                    </div>
                </ButtonWrapper>
            </Modal>
        );
    }
}

export const StyledTableWrapper = styled(TableWrapper)`
    overflow: hidden;
`;

export const ScrollableWrapper = styled.div`
    max-height: 400px;
    overflow: auto;
`;

export { ContentModal };
