import React from "react";
import { Field, FieldProps, FormikProps } from "formik";
import { AnyContentListItem, Gallery, Gallery_images, UpdateDecadeInput } from "api/graphql/types";
import { Main } from "components/cms/MainElements";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button";
import CKEditor from "@ckeditor/ckeditor5-react";
import { TableFormWrapper, TableWrapper } from "components/TableElements";
import { ContentGalleryImage, TopInfo } from "pages/ContentPage/ContentForm";
import { InputWrapper } from "components/Inputs/InputWrapper";
import { Input } from "components/Inputs/Input";
import { ValidatorConstants } from "utils/Validator";
import { CaptionText } from "theme/global";
import { cleanupFormikErrors } from "utils/misc";
import { InputCDN } from "../../components/cms/InputCDN";
import { Env } from "../../utils/Env";
import { GalleryModal } from "pages/common/GalleryModal";
import { ContentModal } from "pages/common/ContentModal";
import { CKEditorDefaultConfig } from "utils/Constants";

// @ts-ignore
const CustomEditor = ClassicEditor;

type Props<T> = {
    decadeId: string | null;
    updatedAt: any | null;
    url: string | null;
    years: string | null;
    formProps: FormikProps<T>;
    onBackClick: () => void;
    isLoading: boolean;
};

export const DecadeValidator = (_values: UpdateDecadeInput) => {
    const errors: { [key in keyof UpdateDecadeInput]?: string } = {};
    return cleanupFormikErrors<UpdateDecadeInput>(errors);
};

interface State {
    showGalleryModal: boolean;
    contentGalleryModal: {
        show: boolean;
        callback?: (gallery: Gallery) => void;
    };
    contentModal: {
        show: boolean;
        callback?: (content: AnyContentListItem[]) => void;
    };
}

class DecadeForm extends React.Component<Props<UpdateDecadeInput>, State> {
    public state: State = {
        showGalleryModal: false,
        contentGalleryModal: {
            show: false,
        },
        contentModal: {
            show: false,
        },
    };

    private readonly onPageImageSelect = (field: string): void => {
        window.bfml.openLibrary({
            embedAuthToken: "auth",
            maximumSelectableAsset: 1,
            onSelection: (assets: string[]) => {
                this.props.formProps.setFieldValue(field, assets[0], true);
            },
        });
    };

    public componentDidMount = () => {
        // @ts-ignore
        window.galleryCallback = (addedImages, callBack: (urls: ContentGalleryImage[]) => void) => {
            this.toggleContentGalleryModal((gallery: Gallery) => {
                callBack(
                    gallery.images.map(
                        (image: Gallery_images): ContentGalleryImage => {
                            return {
                                url: image.url,
                                dataAssetURL: `${process.env.REACT_APP_MEDIA_LIBRARY_ASSET_URL}/${image.url}`,
                                caption: image.caption || "",
                                credit: image.credit || "",
                            };
                        },
                    ),
                );
            });
        };
        // @ts-ignore
        window.embeddedPostCallback = (addedEmbeddedPost, callBack: (embeddedPost: EmbeddedPost) => void) => {
            this.toggleContentModal((contents: AnyContentListItem[]) => {
                callBack({
                    id: contents[0].id,
                    title: contents[0].title,
                    url: contents[0].url,
                });
            });
        };
        // @ts-ignore
        window.infoImageCallback = (callBack: (image: { fileName: string; dataAssetURL: string }) => void) => {
            window.bfml.openLibrary({
                embedAuthToken: "auth",
                maximumSelectableAsset: 1,
                onSelection: (assets: string[]) => {
                    if (assets.length > 0) {
                        callBack({
                            fileName: assets[0],
                            dataAssetURL: `${process.env.REACT_APP_MEDIA_LIBRARY_ASSET_URL}/${assets[0]}`,
                        });
                    }
                },
            });
        };
    };

    private readonly toggleContentGalleryModal = (callback?: (gallery: Gallery) => void): void => {
        this.setState({
            contentGalleryModal: {
                show: !this.state.contentGalleryModal.show,
                callback,
            },
        });
    };

    private readonly toggleContentModal = (callback?: (content: AnyContentListItem[]) => void): void => {
        this.setState({
            contentModal: {
                show: !this.state.contentModal.show,
                callback,
            },
        });
    };

    public render(): React.ReactElement {
        const props = this.props.formProps;
        return (
            <form onSubmit={props.handleSubmit} noValidate>
                <Main.Heading headingText={Intl.formatMessage({ id: "pages.decades.edit.title" })} backButtonClick={this.props.onBackClick}>
                    <Button btnLabel={Intl.formatMessage({ id: "common.save" })} type="submit" disabled={this.props.isLoading} />
                </Main.Heading>
                <TableWrapper>
                    <div className="grid-x">
                        <div className="cell">
                            <TopInfo>
                                <span>{Intl.formatMessage({ id: "pages.decades.form.topInfo.id" }, { id: this.props.decadeId })}</span>
                                <span>{Intl.formatMessage({ id: "pages.decades.form.topInfo.year" }, { year: this.props.years })}</span>
                                <span>{Intl.formatMessage({ id: "pages.decades.form.topInfo.updatedAt" }, { updatedAt: new Date(this.props.updatedAt).toLocaleString("hu") })}</span>
                            </TopInfo>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6 auto">
                            <Field name="title">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.decades.form.title.title" })} errorMsg={meta.error}>
                                        <Input type={"text"} ariaLabel={Intl.formatMessage({ id: "pages.decades.form.title.title" })} {...field} hasError={!!meta.error} />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                        <div className="cell small-6 auto">
                            <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.decades.form.url.title" })}>
                                <Input type={"text"} ariaLabel={Intl.formatMessage({ id: "pages.decades.form.url.title" })} value={this.props.url} hasError={false} onChange={() => void 0} />
                            </InputWrapper>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6 auto">
                            <Field name="content_title">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper
                                        fakeLabel={Intl.formatMessage({ id: "pages.decades.form.content_title.title" }, { maxCharacters: ValidatorConstants.MAX_CONTENT_TITLE_LENGTH })}
                                        errorMsg={meta.error}
                                    >
                                        <Input
                                            type="text"
                                            ariaLabel={Intl.formatMessage({ id: "pages.decades.form.content_title.title" }, { maxCharacters: ValidatorConstants.MAX_CONTENT_TITLE_LENGTH })}
                                            {...field}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                        <div className="cell small-6 auto">
                            <Field name="lead_image">
                                {({ field, form, meta }: FieldProps) => (
                                    <InputCDN
                                        browseButton={<Button onClick={() => this.onPageImageSelect("lead_image")} btnLabel={Intl.formatMessage({ id: "common.upload" })} />}
                                        showRemove={!!field.value && field.value !== ""}
                                        onRemove={() => {
                                            form.setFieldValue("lead_image", "", true);
                                        }}
                                        hideShowButton={!field.value}
                                        previewURL={`${Env.mediaLibraryAssetUrl}/${field.value}`}
                                    >
                                        <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.decades.form.lead_image.title" })} errorMsg={meta.error}>
                                            <Input
                                                type="text"
                                                readOnly={true}
                                                ariaLabel={Intl.formatMessage({
                                                    id: "pages.decades.form.lead_image.title",
                                                })}
                                                {...field}
                                            />
                                        </InputWrapper>
                                    </InputCDN>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6 auto">
                            <Field name="front_page_title">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper
                                        fakeLabel={Intl.formatMessage({ id: "pages.decades.form.front_page_title.title" }, { maxCharacters: ValidatorConstants.MAX_CONTENT_FRONT_PAGE_LEAD_LENGTH })}
                                        errorMsg={meta.error}
                                    >
                                        <Input
                                            type="text"
                                            ariaLabel={Intl.formatMessage(
                                                { id: "pages.decades.form.front_page_title.title" },
                                                { maxCharacters: ValidatorConstants.MAX_CONTENT_FRONT_PAGE_LEAD_LENGTH },
                                            )}
                                            {...field}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                        <div className="cell small-6 auto">
                            <Field name="front_page_image">
                                {({ field, form, meta }: FieldProps) => (
                                    <InputCDN
                                        browseButton={<Button onClick={() => this.onPageImageSelect("front_page_image")} btnLabel={Intl.formatMessage({ id: "common.upload" })} />}
                                        showRemove={!!field.value && field.value !== ""}
                                        onRemove={() => {
                                            form.setFieldValue("front_page_image", "", true);
                                        }}
                                        hideShowButton={!field.value}
                                        previewURL={`${Env.mediaLibraryAssetUrl}/${field.value}`}
                                    >
                                        <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.decades.form.front_page_image.title" })} errorMsg={meta.error}>
                                            <Input
                                                type="text"
                                                readOnly={true}
                                                ariaLabel={Intl.formatMessage({
                                                    id: "pages.decades.form.front_page_image.title",
                                                })}
                                                {...field}
                                            />
                                        </InputWrapper>
                                    </InputCDN>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell auto">
                            <Field name="front_page_lead">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper
                                        fakeLabel={Intl.formatMessage({ id: "pages.decades.form.front_page_lead.title" }, { maxCharacters: ValidatorConstants.MAX_CONTENT_FRONT_PAGE_LEAD_LENGTH })}
                                        errorMsg={meta.error}
                                    >
                                        <Input
                                            type="text"
                                            ariaLabel={Intl.formatMessage({ id: "pages.decades.form.front_page_lead.title" }, { maxCharacters: ValidatorConstants.MAX_CONTENT_FRONT_PAGE_LEAD_LENGTH })}
                                            {...field}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell auto">
                            <Field name="lead">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper
                                        fakeLabel={Intl.formatMessage({ id: "pages.decades.form.lead.title" }, { maxCharacters: ValidatorConstants.MAX_CONTENT_FRONT_PAGE_LEAD_LENGTH })}
                                        errorMsg={meta.error}
                                    >
                                        <Input
                                            type="text"
                                            ariaLabel={Intl.formatMessage({ id: "pages.decades.form.lead.title" }, { maxCharacters: ValidatorConstants.MAX_CONTENT_FRONT_PAGE_LEAD_LENGTH })}
                                            {...field}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell auto">
                            <Field name="content">
                                {({ meta }: FieldProps) => (
                                    <>
                                        <CaptionText as="h2" thStyle={true} className="mt-0">
                                            {Intl.formatMessage({ id: "pages.decades.form.content.title" })}
                                        </CaptionText>
                                        <InputWrapper hideFakeLabel fakeLabel={Intl.formatMessage({ id: "pages.decades.form.content.title" })} errorMsg={meta.error}>
                                            <CKEditor
                                                editor={CustomEditor}
                                                config={CKEditorDefaultConfig}
                                                data={this.props.formProps.values.content}
                                                onChange={(_event: any, editor: any) => {
                                                    this.props.formProps.setFieldValue("content", editor.getData());
                                                }}
                                            />
                                        </InputWrapper>
                                    </>
                                )}
                            </Field>
                        </div>
                    </div>
                    <TableFormWrapper>
                        <table>
                            <thead>
                                <tr>
                                    <th>{Intl.formatMessage({ id: "pages.decades.form.metaTable.headers.name" })}</th>

                                    <th>{Intl.formatMessage({ id: "pages.decades.form.metaTable.headers.value" })}</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td className="w-190">{Intl.formatMessage({ id: "pages.decades.form.metaTable.meta_title.title" })}</td>

                                    <td>
                                        <Field name="meta_title">
                                            {({ field, meta, form }: FieldProps) => (
                                                <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.decades.form.metaTable.meta_title.title" })} errorMsg={meta.error}>
                                                    <Input
                                                        type="text"
                                                        ariaLabel={Intl.formatMessage({ id: "pages.decades.form.metaTable.meta_title.title" })}
                                                        {...field}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            form.setFieldValue("meta_title", e.currentTarget.value);
                                                        }}
                                                    />
                                                </InputWrapper>
                                            )}
                                        </Field>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="w-190">{Intl.formatMessage({ id: "pages.decades.form.metaTable.meta_description.title" })}</td>

                                    <td>
                                        <Field name="meta_description">
                                            {({ field, meta, form }: FieldProps) => (
                                                <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.decades.form.metaTable.meta_description.title" })} errorMsg={meta.error}>
                                                    <Input
                                                        type="text"
                                                        ariaLabel={Intl.formatMessage({ id: "pages.decades.form.metaTable.meta_description.title" })}
                                                        {...field}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            form.setFieldValue("meta_description", e.currentTarget.value);
                                                        }}
                                                    />
                                                </InputWrapper>
                                            )}
                                        </Field>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="w-190">{Intl.formatMessage({ id: "pages.decades.form.metaTable.meta_keywords.title" })}</td>

                                    <td>
                                        <Field name="meta_keywords">
                                            {({ field, meta, form }: FieldProps) => (
                                                <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.decades.form.metaTable.meta_keywords.title" })} errorMsg={meta.error}>
                                                    <Input
                                                        type="text"
                                                        ariaLabel={Intl.formatMessage({ id: "pages.decades.form.metaTable.meta_keywords.title" })}
                                                        {...field}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            form.setFieldValue("meta_keywords", e.currentTarget.value);
                                                        }}
                                                    />
                                                </InputWrapper>
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </TableFormWrapper>
                    {this.state.contentGalleryModal.show && this.state.contentGalleryModal.callback && (
                        <GalleryModal mounted={true} onGallerySelected={this.state.contentGalleryModal.callback} onModalClose={this.toggleContentGalleryModal} />
                    )}
                    {this.state.contentModal.show && this.state.contentModal.callback && (
                        <ContentModal mounted={true} onContentSelected={this.state.contentModal.callback} onModalClose={this.toggleContentModal} single={true} />
                    )}
                </TableWrapper>
            </form>
        );
    }
}

export { DecadeForm };
