import * as _ from "lodash";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Api } from "api/Api";
import { Gallery, listGalleriesVariables, GalleryField, OrderDirection } from "api/graphql/types";
import { Button } from "components/Button";
import { Alert } from "components/cms/Alert/Alert";
import { Main } from "components/cms/MainElements";
import { SVGIconAdd } from "components/SVGCollection";
import { Table, Column } from "components/Tables/Table";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ObjectUtils } from "utils/ObjectUtils";
import { Path } from "utils/Path";

type Props = RouteComponentProps;

interface State {
    isLoading: boolean;
    options: listGalleriesVariables;
    galleries: Gallery[];
    total: number;
    pageCount: number;
}

enum GalleryListTableColumn {
    title = "title",
    image_count = "image_count",
    updated_at = "updated_at",
}

class GalleryListPageComponent extends React.Component<Props, State> {
    public state: State = {
        isLoading: true,
        galleries: [],
        options: {
            count: Table.DEFAULT_PAGE_SIZE,
            page: 1,
        },
        total: 0,
        pageCount: 0,
    };

    componentDidMount() {
        this.fetchGalleries();
    }

    public componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<State>) {
        const { options } = this.state;
        if (!_.isEqual(prevState.options, options)) {
            this.fetchGalleries();
        }
    }

    private readonly fetchGalleries = (): void => {
        this.setState({ isLoading: true }, async () => {
            try {
                const { data, paginatorInfo } = await Api.listGalleries(this.state.options);
                this.setState({
                    galleries: data,
                    total: paginatorInfo.total,
                    pageCount: paginatorInfo.lastPage,
                    isLoading: false,
                });
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    private readonly columns: Array<Column<Gallery>> = ObjectUtils.enumAsArray<GalleryListTableColumn>(GalleryListTableColumn).map(
        (columnName: GalleryListTableColumn): Column<Gallery> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `pages.galleries.list.table.${columnName}` }),
            accessor: columnName as keyof Gallery,
            renderCell: (galleryListItem: Gallery): React.ReactElement<any> | null => {
                switch (columnName) {
                    case GalleryListTableColumn.title:
                        return <>{galleryListItem.title}</>;
                    case GalleryListTableColumn.image_count:
                        return <>{galleryListItem.image_count}</>;
                    case GalleryListTableColumn.updated_at:
                        return <>{new Date(galleryListItem.updated_at).toLocaleString("hu")}</>;
                    default:
                        return null;
                }
            },
            isNonSortable: [GalleryListTableColumn.image_count].includes(columnName),
        }),
    );

    private convertColumnIdToSortField = (columnId?: string): GalleryField | undefined => {
        switch (columnId) {
            case GalleryListTableColumn.title:
                return GalleryField.TITLE;
            case GalleryListTableColumn.updated_at:
                return GalleryField.UPDATED_AT;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: GalleryField | null): keyof Gallery | undefined => {
        switch (columnId) {
            case GalleryField.TITLE:
                return GalleryListTableColumn.title;
            case GalleryField.UPDATED_AT:
                return GalleryListTableColumn.updated_at;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<Gallery>, order?: OrderDirection): void => {
        const sortField = this.convertColumnIdToSortField(column ? column.id : undefined);
        if (sortField) {
            this.setState({
                options: {
                    ...this.state.options,
                    sortBy: {
                        field: sortField,
                        direction: order || OrderDirection.ASC,
                    },
                },
            });
        }
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        this.setState({
            options: {
                ...options,
                page: pageNum,
            },
        });
    };

    public render(): React.ReactElement {
        return (
            <>
                <Main.Heading headingText={Intl.formatMessage({ id: "pages.galleries.list.title" })}>
                    <Button
                        btnLabel={Intl.formatMessage({ id: "pages.galleries.list.add" })}
                        onClick={() => {
                            this.props.history.push(Path.createGallery);
                        }}
                        renderIcon={<SVGIconAdd />}
                    />
                </Main.Heading>
                <Table
                    keyExtractor={(item: Gallery, column?: Column<Gallery>): string => {
                        return `${item.id}_${column?.id || ""}`;
                    }}
                    columns={this.columns}
                    sortBy={{
                        columnId: this.convertSortFieldToColumnId(this.state.options.sortBy?.field),
                        order: this.state.options.sortBy?.direction || undefined,
                    }}
                    data={this.state.galleries}
                    count={this.state.total}
                    pageCount={this.state.pageCount}
                    currentPage={this.state.options.page || 1}
                    isSortable={true}
                    onSortOrderChange={this.onSortOrderChange}
                    onPageChange={this.onPageChange}
                    isPaginationEnabled={this.state.total > Table.DEFAULT_PAGE_SIZE}
                    onRowClick={(galleryListItem: Gallery) => {
                        this.props.history.push(Path.editGallery(galleryListItem.id));
                    }}
                    isLoading={this.state.isLoading}
                    renderEmpty={() => ""}
                />
            </>
        );
    }
}

const GalleryListPage = withRouter(GalleryListPageComponent);

export { GalleryListPage };
