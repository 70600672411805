import styled, { createGlobalStyle, keyframes } from "styled-components";
import { Color, theme } from "theme/theme";

export function hexToRGB(h: Color): string {
    let r = "";
    let g = "";
    let b = "";

    // Note: 3 digits
    if (h.length === 4) {
        r = `0x${h[1]}${h[1]}`;
        g = `0x${h[2]}${h[2]}`;
        b = `0x${h[3]}${h[3]}`;

        // Note: 6 digits
    } else if (h.length === 7) {
        r = `0x${h[1]}${h[2]}`;
        g = `0x${h[3]}${h[4]}`;
        b = `0x${h[5]}${h[6]}`;
    }

    return `${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}`;
}

export const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    /* TODO: remember to define focus styles! http://www.outlinenone.com/ */
    /* :focus {
        outline: 0;
    } */

    body {
        background-color: ${theme.background.body.backgroundColor};
        color: ${theme.typo.body.color};
        font-family: ${theme.typo.body.fontFamily};
        font-size: ${theme.typo.body.fontSize}px;
        line-height: ${theme.typo.body.lineHeight}px;
        margin: 0;
        min-height: 100vh;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
    }

    a {
        text-decoration: ${theme.typo.a.textDecoration};
        color: ${theme.typo.a.color};

        &:focus {
            box-shadow: 0 0 ${theme.box.a.focus.outlineOffset + 1}px ${theme.box.a.focus.outlineWidth * 2}px ${theme.box.a.focus.outlineColor};
            color: ${theme.typo.a.focus.color};
            outline-color: ${theme.background.body.backgroundColor};
            outline-offset: ${theme.box.a.focus.outlineOffset};
            outline-style: ${theme.box.a.focus.outlineStyle};
            outline-width: ${theme.box.a.focus.outlineWidth}px;
        }

        &:active {
            box-shadow: none;
            outline: 0;
        }

        &:hover {
            color: ${theme.typo.a.hover.color};
        }
    }

    button,
    .a--button {
        background-color: transparent;
        border: 0;
        color: inherit;
        cursor: pointer;
        display: block;
        font-family: inherit;
        padding: 0;

        /* TODO: https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
        &:focus {
            outline: 0;

            &:not(:focus-visible) {
                box-shadow: none;
            }
        }

        &:focus-visible {
            box-shadow: 0 0 ${theme.box.button.focus.outlineOffset}px ${theme.box.button.focus.outlineWidth}px ${theme.box.button.focus.outlineColor};
        }

        &:active {
            box-shadow: none;
        }
    }

    .a--button {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    /* fieldset {
        border: 0;
        margin: 0;
        padding: 0;
    } */

    svg {
        display: block;
    }

    .grid-x {
        display: flex;
        flex-flow: row wrap;

        &.align-center {
            justify-content: center;
        }

        &.align-right {
            justify-content: flex-end;
        }

        &.align-middle {
            align-items: center;
        }

        &.align-bottom {
            align-items: flex-end;
        }
        
        &.justify-center {
            justify-content: center;
        }

        &.grid-margin-x-10 {
            flex-flow: nowrap;
            margin-left: -10px;
            margin-right: -10px;

            > .cell {
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        &.grid-margin-x-12 {
            flex-flow: nowrap;
            margin-left: -12px;
            margin-right: -12px;

            > .cell {
                margin-left: 12px;
                margin-right: 12px;
            }
        }

        &.grid-margin-x-15 {
            flex-flow: nowrap;
            margin-left: -15px;
            margin-right: -15px;

            > .cell {
                margin-left: 15px;
                margin-right: 15px;
            }
        }

        &.grid-margin-x-20 {
            flex-flow: nowrap;
            margin-left: -20px;
            margin-right: -20px;

            > .cell {
                margin-left: 20px;
                margin-right: 20px;
            }
        }
    }

    .grid-y {
        display: flex;
        flex-flow: column nowrap;
    }

    .cell {
        flex: 0 0 auto;
        min-height: 0px;
        min-width: 0px;
        width: 100%;

        &.auto {
            flex: 1 1 0px;
            width: auto;
        }

        &.shrink {
            flex: 0 0 auto;
            width: auto;
        }

        &.small-4 {
            width: ${(4 * 100) / 12}%;
        }

        &.small-6 {
            width: ${(6 * 100) / 12}%;
        }

        &.small-8 {
            width: ${(8 * 100) / 12}%;
        }

        .date-input-max-width {
            max-width: 265px;
        }
    }

    .show-for-sr {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        white-space: nowrap;
        width: 1px;
    }

    .text-overflow-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &td {
            max-width: 0;
        }
    }

    .text-decoration--underline {
        text-decoration: underline;
    }

    .text-align--center {
        text-align: center;
    }

    .text-align--right {
        text-align: right;
    }

    .break-all {
        word-break: break-word;
        word-wrap: break-word;
    }

    .color-red {
        color: ${Color.red} !important;
    }

    .color-green {
        color: ${Color.primaryXD} !important;
    }

    .bt-0 {
        border-top: 0 !important;
    }

    .p-15 {
        padding: 15px !important;
    }

    .pl-15 {
        padding-left: 15px !important;
    }

    .pr-8 {
        padding-right: 8px !important;
    }

    .pr-15 {
        padding-right: 15px !important;
    }

    .pb-30 {
        padding-bottom: 30px !important;
    }

    .pb-40 {
        padding-bottom: 40px !important;
    }

    .pt-10 {
        padding-top: 10px !important;
    }

    .p-0 {
        padding: 0 !important;
    }

    .mt-0 {
        margin-top: 0 !important;
    }

    .mt-15 {
        margin-top: 15px !important;
    }

    .mt-30 {
        margin-top: 30px !important;
    }

    .mt-35 {
        margin-top: 35px !important;
    }

    .mb-24 {
        margin-bottom: 24px !important;
    }

    .w-178 {
        width: 178px !important;
    }

    .w-190 {
        width: 190px !important;
    }

    .w-435 {
        width: 435px !important;
    }

    .mw-auto {
        min-width: auto !important;
    }

    .mh-auto {
        min-height: auto !important;
    }

    .h-40 {
        height: 40px !important;
    }

    .ck.ck-editor {
        margin-top: 8px !important;
    }

    .ck.ck-editor__editable_inline {
        max-height: calc(100vh - 300px);
        min-height: 400px;
    }

    :root {
        --ck-color-text: ${Color.secondaryXD};
        --ck-border-radius: 10px;
        --ck-color-base-border: ${Color.secondary};
        --ck-color-toolbar-background: ${Color.gray};
        --ck-color-button-default-hover-background: ${Color.grayD};
        --ck-color-button-on-background: ${Color.grayD};
        --ck-color-button-on-hover-background: ${Color.secondaryXXL};
        --ck-color-base-focus: ${Color.secondaryXL};
    }

    div.ReactTags__tags.react-tags-wrapper {
        border: 1px solid ${theme.box.input.borderColor};
        border-radius: ${theme.box.input.borderRadius}px;
        padding: 6px 14px 6px 14px;

        .ReactTags__tagInput {
            position: relative;
            display: inline-block;
            padding: 0px;
            margin: 4px;
        }

        .ReactTags__selected {
            display: inline-block;
        }

        .ReactTags__suggestions {
            ul {
                max-height: 250px;
                overflow: scroll;
            }
        }
    }

    input.ReactTags__tagInputField,
    input.ReactTags__tagInputField:focus,
    input.ReactTags__tagInputField:active {
        caret-color: ${theme.typo.input.color};
        color: ${theme.typo.input.color};
        display: block;
        font-family: inherit;
        font-size: ${theme.typo.input.fontSize}px;
        font-weight: ${theme.typo.input.fontWeight};
        line-height: ${theme.box.input.height - theme.box.input.paddingTop - theme.box.input.paddingBottom - theme.box.input.borderWidth * 2}px;
        border: 1px solid ${Color.grayD};
        padding: 4px 10px 4px 8px;
        border-radius: 12px;
        outline: none;
    }

    input.ReactTags__tagInputField::placeholder {
        color: ${theme.typo.input.color};
        font-style: italic;
    }

    span.tag-wrapper.ReactTags__tag {
        position: relative;
        display: inline-block;
        margin: 4px;
        padding: 6px 16px 6px 6px;
        border-radius: 12px;
        background-color: ${Color.gray};
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 180px;
        vertical-align: bottom;

        a {
            position: absolute;
            right: 4px;
            top: 7px;
            cursor: pointer;
        }
    }

    div.ReactTags__suggestions {
        position: absolute;
        z-index: 1000;
    }
    div.ReactTags__suggestions ul {
        list-style-type: none;
        box-shadow: 0 4px 24px 0 rgba(${hexToRGB(Color.grayD)}, 0.4);
        background-color: ${Color.white};
        width: 240px;
        border: 1px solid ${Color.grayD};
        border-radius: 16px;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
        overflow: hidden;
    }
    div.ReactTags__suggestions li {
        box-shadow: 0 -1px 0 0 ${Color.grayD};
        padding: 16px 15px;
        margin: 0;
        color: ${Color.secondary};
    }
    div.ReactTags__suggestions li mark {
        color: ${Color.secondary};
        text-decoration: underline;
        background: none;
        font-weight: 700;
    }
    div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
        background-color: ${Color.grayL};
        cursor: pointer;
    }
`;

export const MainContainer = styled.main`
    padding: ${props => props.theme.box.appBar.height}px 30px;
`;

export const IconArrowRotateWrapper = styled.div<{ rotateDeg?: number }>`
    display: block;
    transform-origin: center;
    transform: ${props => (props.rotateDeg !== undefined ? `rotate(${props.rotateDeg}deg)` : "rotate(-90deg)")};
`;

export const HeroText = styled.h1`
    font-size: ${theme.typo.heroText.fontSize}px;
    font-weight: ${theme.typo.heroText.fontWeight};
    line-height: ${theme.typo.heroText.lineHeight}px;
`;

export const H1 = styled.h1`
    font-size: ${theme.typo.h1.fontSize}px;
    font-weight: ${theme.typo.h1.fontWeight};
    line-height: ${theme.typo.h1.lineHeight}px;
`;

export const H2 = styled.h2`
    font-size: ${theme.typo.h2.fontSize}px;
    font-weight: ${theme.typo.h2.fontWeight};
    line-height: ${theme.typo.h2.lineHeight}px;
`;

export const H3 = styled.h3`
    font-size: ${theme.typo.h3.fontSize}px;
    font-weight: ${theme.typo.h3.fontWeight};
    line-height: ${theme.typo.h3.lineHeight}px;
`;

export const H4 = styled.h4`
    font-size: ${theme.typo.h4.fontSize}px;
    font-weight: ${theme.typo.h4.fontWeight};
    line-height: ${theme.typo.h4.lineHeight}px;
`;

export const H5 = styled.h5`
    font-size: ${theme.typo.h5.fontSize}px;
    font-weight: ${theme.typo.h5.fontWeight};
    line-height: ${theme.typo.h5.lineHeight}px;
`;

export const EmbedCode = styled.code`
    display: block;
    word-break: break-all;
    padding: 20px;
    border-radius: 25px;
    font-size: 14px;
    border: 1px solid ${Color.secondary};
`;

export const CaptionText = styled.div<{ thStyle?: boolean }>`
    ${props =>
        props.thStyle
            ? `
        color: ${theme.typo.table.th.color};
        font-size: ${theme.typo.table.th.fontSize}px;
        // padding-bottom: ${theme.box.table.th.paddingTop}px;
        // padding-left: ${theme.box.table.td.padding}px;
        // padding-right: ${theme.box.table.td.padding}px;
        // padding-top: ${theme.box.table.th.paddingTop}px;
        h2& {
            margin-bottom: 14px;
            margin-top: 30px;
            text-transform: uppercase;
        }
    `
            : `
        font-size: ${theme.typo.CaptionText.fontSize}px;
        line-height: ${theme.typo.CaptionText.lineHeight}px;
    `}
    span.error {
        color: ${theme.typo.input.hasError.color};
    }
`;

export const keyframesBlinkInputShadow = keyframes`
    0% {
        box-shadow: 0 0 ${theme.box.input.focus.outlineOffset}px ${theme.box.input.focus.outlineWidth}px ${theme.box.input.focus.outlineColor};
    }
    49% {
        box-shadow: none;
    }
    50% {
        box-shadow: none;
    }
`;

export const keyframesRotate360 = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const keyframesSlideUpAndBackWithOpacity = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    10% {
        opacity: 1;
        transform: translateX(0);
    }
    90% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateY(100%);
    }
`;
