import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { Location } from "history";
import { Path } from "utils/Path";

type Props = RouteComponentProps;

export interface RedirectBackState {
    redirectBack: Location<any>;
}

const RedirectToLogin: React.FC<Props> = ({ location }) => <Redirect to={{ pathname: Path.login, state: { redirectBack: location } as RedirectBackState }} />;

export default RedirectToLogin;
