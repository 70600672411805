import React from "react";
import { Formik, FormikHelpers } from "formik";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Api } from "../../api/Api";
import { ApiError } from "../../api/ApiError";
import { ContentInput } from "../../api/ApiTypes";
import { GraphQLClient } from "../../api/graphql/GraphQLClient";
import { CreateInformationInput, UpdateInformationInput, Information } from "../../api/graphql/types";
import { Alert } from "../../components/cms/Alert/Alert";
import LoadingOverlay from "../../components/LoadingOverlay";
import { Intl } from "../../i18n/Intl";
import { IntlHelpers } from "../../i18n/IntlHelpers";
import { DateUtils, DateFormat } from "../../utils/DateUtils";
import { cleanupFormikErrors } from "../../utils/misc";
import { AppPath, Path } from "../../utils/Path";
import { PageType } from "../../utils/TypeUtils";
import { Validator } from "../../utils/Validator";
import { InformationForm } from "./InformationForm";

export type InformationPageExtraInfo = {
    id: string | null;
    url: string | null;
    updatedAt: any | null;
    lastPublishedAt: any | null;
};

interface RouteProps {
    id: string;
}

type InformationInput = CreateInformationInput | UpdateInformationInput;

type Props = RouteComponentProps<RouteProps>;

interface State {
    isLoading: boolean;
    input: CreateInformationInput | UpdateInformationInput;
    extraInfo?: InformationPageExtraInfo;
}

class InformationPageComponent extends React.Component<Props, State> {
    public state: State = {
        isLoading: false,
        input: {
            url: "",
            title: "",
            front_page_title: "",
            front_page_lead: "",
            lead: "",
            lead_image: "",
            front_page_image: "",
            meta_image: "",
            content: "",
            meta_title: "",
            meta_keywords: "",
            meta_description: "",
            author: "",
            is_active: false,
            active_from: null,
            active_to: null,
        },
    };

    public componentDidMount(): void {
        if (this.props.match.params.id) {
            this.fetchInformation();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.match.params.id !== this.props.match.params.id && this.props.match.params.id) {
            this.fetchInformation();
        }
    }

    private getPageType = (): PageType => {
        return AppPath.getPageType(this.props.match.path);
    };

    /**
     * If the embedded <iframe> is within <figure> tags, the editor doesn't show it, so we replace it with <div>s.
     * @param content source content possibly containing <figure>
     */
    private replaceFigure = (content: string): string => {
        let newContent: string = content;
        newContent = newContent.replace(/<figure class="media"><div data-oembed-url/g, "<div data-oembed-url");
        newContent = newContent.replace(/<\/iframe><\/div><\/div><\/figure>/g, "</iframe></div></div>");
        return newContent;
    };

    private readonly getInformation = (information: Information): UpdateInformationInput => {
        return {
            title: information.title,
            front_page_title: information.front_page_title,
            front_page_lead: information.front_page_lead,
            front_page_image: information.front_page_image,
            lead: information.lead,
            lead_image: information.lead_image,
            content: information.content,
            meta_title: information.meta_title,
            meta_keywords: information.meta_keywords,
            meta_description: information.meta_description,
            meta_image: information.meta_image,
            author: information.author,
            is_active: information.is_active,
            active_from: information.active_from ? new Date(information.active_from) : this.state.input.active_from,
            active_to: information.active_to ? new Date(information.active_to) : this.state.input.active_to,
        };
    };

    private readonly getCreateInput = (information: InformationInput): CreateInformationInput => {
        return {
            ...information,
            url: "url" in information ? information.url : "",
            title: information.title || "",
            content: information.content ? this.replaceFigure(information.content) : information.content,
            is_active: information.is_active || false,
            active_from: information.active_from ? DateUtils.format(information.active_from, DateFormat.dateTimeDashed) : null,
            active_to: information.active_to ? DateUtils.format(information.active_to, DateFormat.dateTimeDashed) : null,
        };
    };

    private readonly getUpdateInput = (information: InformationInput): UpdateInformationInput => {
        return {
            title: information.title,
            front_page_title: information.front_page_title,
            front_page_lead: information.front_page_lead,
            front_page_image: information.front_page_image,
            lead: information.lead,
            lead_image: information.lead_image,
            content: information.content ? this.replaceFigure(information.content) : information.content,
            meta_title: information.meta_title,
            meta_keywords: information.meta_keywords,
            meta_description: information.meta_description,
            meta_image: information.meta_image,
            author: information.author,
            is_active: information.is_active,
            active_from: information.active_from ? DateUtils.format(information.active_from, DateFormat.dateTimeDashed) : null,
            active_to: information.active_to ? DateUtils.format(information.active_to, DateFormat.dateTimeDashed) : null,
        };
    };

    private readonly fetchInformation = (): void => {
        this.setState({ isLoading: true }, async () => {
            try {
                const response = await Api.listInformations({ filters: { id: this.props.match.params.id! }, count: 1 });
                if (response.data.length === 0) {
                    Alert.error({ title: Intl.formatMessage({ id: "pages.information.edit.notFound" }) });
                    this.props.history.push(Path.informationList);
                }

                const information = response.data[0];

                this.setState({
                    isLoading: false,
                    input: this.getInformation(information),
                    extraInfo: {
                        id: information.id,
                        updatedAt: information.updated_at,
                        lastPublishedAt: information.last_published_at,
                        url: information.url,
                    },
                });
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.props.history.push(Path.informationList);
            }
        });
    };

    private readonly onBackClick = () => {
        this.props.history.push(Path.informationList);
    };

    private formValidator = (values: InformationInput) => {
        const errors: { [key in keyof InformationInput]?: string } = {};
        errors.title = IntlHelpers.getValidationError(Validator.validateNonEmpty(values.title || "")) || undefined;
        return cleanupFormikErrors<InformationInput>(errors);
    };

    private readonly onSubmit = async (information: InformationInput, formikHelpers: FormikHelpers<ContentInput>): Promise<void> => {
        try {
            if (this.getPageType() === PageType.create) {
                const result = await Api.createInformation(this.getCreateInput(information));
                if (result) {
                    Alert.success({ title: Intl.formatMessage({ id: "pages.contents.page.create.success" }) });
                    this.props.history.push(Path.editInformation(result.id));
                }
            } else if (this.getPageType() === PageType.edit) {
                const result = await Api.updateInformation(this.props.match.params.id, this.getUpdateInput(information));
                if (result) {
                    Alert.success({ title: Intl.formatMessage({ id: "pages.contents.page.edit.success" }) });
                    this.props.history.push(Path.editInformation(this.props.match.params.id));
                }
            } else {
                throw new Error("View cannot submit!");
            }
        } catch (error) {
            if (error instanceof ApiError) {
                if (error.validation?.input) {
                    const errors = GraphQLClient.parseValidationErrors<InformationInput>(error.validation.input);
                    formikHelpers.setErrors({
                        title: errors?.title,
                        active_from: errors?.active_from,
                        active_to: errors?.active_to,
                        front_page_title: errors?.front_page_title,
                        front_page_lead: errors?.front_page_lead,
                        author: errors?.author,
                        meta_title: errors?.meta_title,
                        meta_description: errors?.meta_description,
                        lead: errors?.lead,
                        content: errors?.content,
                    });
                }
            }
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement {
        if (this.state.isLoading) {
            return <LoadingOverlay />;
        }

        return (
            <Formik initialValues={this.state.input} validate={this.formValidator} validateOnBlur={true} validateOnChange={false} onSubmit={this.onSubmit}>
                {props => <InformationForm pageType={this.getPageType()} formProps={props} extraInfo={this.state.extraInfo} onBackClick={this.onBackClick} />}
            </Formik>
        );
    }
}

const InformationPage = withRouter(InformationPageComponent);

export { InformationPage };
