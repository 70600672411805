import React from "react";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { UserForm, UserValidator, FormValues } from "./UserForm";
import { Formik, FormikHelpers } from "formik";
import { Admin, UpdateAdminDataInput } from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "components/cms/Alert/Alert";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import LoadingOverlay from "components/LoadingOverlay";
import { Path } from "utils/Path";
import { GraphQLClient } from "api/graphql/GraphQLClient";

interface RouteParams {
    userId: string;
}

interface State {
    currentAdmin: Admin | null;
    isLoading: boolean;
    errors: { [key in keyof FormValues]?: string[] };
}

type Props = RouteComponentProps<RouteParams>;

class UserEditPageComponent extends React.Component<Props, State> {
    public readonly state: State = {
        currentAdmin: null,
        isLoading: true,
        errors: {},
    };

    componentDidMount() {
        this.fetchAdmin();
    }

    private readonly fetchAdmin = async (): Promise<void> => {
        try {
            const response: Admin = await Api.getAdminById(this.props.match.params.userId);
            this.setState({
                currentAdmin: response,
                isLoading: false,
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            this.props.history.push(Path.userList);
        }
    };

    private readonly onAdminPasswordReset = async (email: string): Promise<void> => {
        this.setState({ isLoading: true }, async () => {
            try {
                const response = await Api.sendAdminPasswordResetRequest(email);
                if (response.adminPasswordReset) {
                    Alert.success({ title: Intl.formatMessage({ id: "pages.users.messages.adminPasswordResetSuccess" }) });
                }
                this.setState({ isLoading: false });
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    private readonly onUpdate = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>): Promise<void> => {
        this.setState({ isLoading: true }, async () => {
            try {
                const formData: UpdateAdminDataInput = {
                    id: this.props.match.params.userId,
                    name: values.name,
                    email: values.email,
                    is_active: values.isActive,
                    permissions: values.permissions,
                };
                const response = await Api.updateAdmin(formData);
                if (response) {
                    await this.fetchAdmin();
                    Alert.success({ title: Intl.formatMessage({ id: "pages.users.messages.updateSuccess" }) });
                }
                this.setState({ isLoading: false });
            } catch (error) {
                if (error.validation) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            const errors = GraphQLClient.parseValidationErrors<UpdateAdminDataInput>(error.validation.input);
                            formikHelpers.setErrors({
                                email: errors?.email,
                                name: errors?.name,
                                isActive: errors?.is_active,
                                permissions: errors?.permissions,
                            });
                        },
                    );
                } else {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            }
        });
    };

    public render(): React.ReactElement {
        const { currentAdmin } = this.state;
        if (!this.props.match.params.userId) {
            return <Redirect to={Path.userList} />;
        }
        if (!currentAdmin) {
            return <LoadingOverlay />;
        }

        return (
            <Formik
                initialValues={{
                    email: currentAdmin.email,
                    name: currentAdmin.name,
                    isActive: currentAdmin.is_active,
                    permissions: currentAdmin.granted_permissions,
                }}
                validate={UserValidator}
                validateOnBlur={true}
                validateOnChange={false}
                onSubmit={this.onUpdate}
            >
                {props => (
                    <UserForm
                        onBackClick={() => this.props.history.push(Path.userList)}
                        formProps={props}
                        formMode="edit"
                        isLoading={this.state.isLoading}
                        onAdminPasswordReset={this.onAdminPasswordReset}
                    />
                )}
            </Formik>
        );
    }
}

const UserEditPage = withRouter(UserEditPageComponent);

export { UserEditPage };
