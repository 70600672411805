import { Intl } from "i18n/Intl";
import React from "react";
import { Color } from "../../theme/theme";
import { Button } from "../Button";
import Modal, { ModalHeader, ButtonWrapper } from "./Modal";
import { H2 } from "../../theme/global";

type Props = {
    mounted: boolean;
    title?: string;
    description?: boolean;
    onConfirm: () => void;
    onClose: () => void;
};

class ConfirmModal extends React.Component<Props> {
    public render(): React.ReactElement {
        const { title, description } = this.props;
        return (
            <Modal titleText={title || Intl.formatMessage({ id: "components.confirmModal.title" })} mounted={this.props.mounted} underlayColor={Color.modal} onModalClose={this.props.onClose}>
                <ModalHeader onCloseClick={this.props.onClose}>
                    <H2>{title || Intl.formatMessage({ id: "components.confirmModal.title" })}</H2>
                    <p>{description || Intl.formatMessage({ id: "components.confirmModal.description" })}</p>
                </ModalHeader>
                <ButtonWrapper>
                    <div className="grid-x grid-margin-y-10">
                        <div className="cell auto grid-x align-right">
                            <Button btnLabel={Intl.formatMessage({ id: "common.cancel" })} secondary={true} className="align-right" onClick={this.props.onClose} />
                        </div>
                        <div className="cell auto grid-x align-left">
                            <Button btnLabel={Intl.formatMessage({ id: "common.delete" })} onClick={this.props.onConfirm} />
                        </div>
                    </div>
                </ButtonWrapper>
            </Modal>
        );
    }
}

export { ConfirmModal };
