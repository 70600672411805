import React from "react";
import { Api } from "api/Api";
import { Document, listDocumentsVariables } from "api/graphql/types";
import { Button } from "components/Button";
import { Alert } from "components/cms/Alert/Alert";
import { Main } from "components/cms/MainElements";
import LoadingOverlay from "components/LoadingOverlay";
import { SVGIconCopy, SVGIconAdd } from "components/SVGCollection";
import { Table, Column, TableButtonWrapper, TableButton } from "components/Tables/Table";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ObjectUtils } from "utils/ObjectUtils";
import { ValidatorConstants } from "utils/Validator";
import clipboardCopy from "clipboard-copy/index";
import * as _ from "lodash";

interface State {
    isLoading: boolean;
    options: listDocumentsVariables;
    documents: Document[];
    total: number;
    pageCount: number;
}

enum DocumentListTableColumn {
    file_name = "file_name",
    url = "url",
    actions = "actions",
}

class DocumentsPage extends React.Component<{}, State> {
    private uploadInput: HTMLInputElement | null = null;

    public state: State = {
        isLoading: false,
        options: {
            count: Table.DEFAULT_PAGE_SIZE,
            page: 1,
        },
        documents: [],
        total: 0,
        pageCount: 0,
    };

    public componentDidMount(): void {
        this.fetchDocuments();
    }

    public componentDidUpdate(_prevProps: Readonly<{}>, prevState: Readonly<State>) {
        const { options } = this.state;
        if (!_.isEqual(prevState.options, options)) {
            this.fetchDocuments();
        }
    }

    private readonly onUrlCopy = (url: string): void => {
        clipboardCopy(url).catch(() => {});
        Alert.success({ title: Intl.formatMessage({ id: "pages.documents.onCopySuccess" }) });
    };

    private readonly fetchDocuments = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { data, paginatorInfo } = await Api.listDocuments(this.state.options);
                    this.setState({
                        documents: data,
                        total: paginatorInfo.total,
                        pageCount: paginatorInfo.lastPage,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                    });
                    this.setState({
                        isLoading: false,
                    });
                }
            },
        );
    };

    private readonly columns: Array<Column<Document>> = ObjectUtils.enumAsArray<DocumentListTableColumn>(DocumentListTableColumn).map(
        (columnName: DocumentListTableColumn): Column<Document> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `pages.documents.table.${columnName}` }),
            accessor: columnName as keyof Document,
            renderCell: (documentListItem: Document): React.ReactElement<any> | null => {
                switch (columnName) {
                    case DocumentListTableColumn.file_name:
                        return <>{documentListItem.file_name}</>;
                    case DocumentListTableColumn.url:
                        return <>{documentListItem.url}</>;
                    case DocumentListTableColumn.actions:
                        return (
                            <TableButtonWrapper>
                                <TableButton onClick={() => this.onUrlCopy(documentListItem.url)}>
                                    <SVGIconCopy />
                                </TableButton>
                            </TableButtonWrapper>
                        );
                    default:
                        return null;
                }
            },
            isNonSortable: true,
        }),
    );

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        this.setState({
            options: {
                ...options,
                page: pageNum,
            },
        });
    };

    private readonly onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (!files || files.length === 0) {
            return;
        }

        this.onUpload(files[0]);
    };

    private readonly onUpload = async (file: File) => {
        try {
            await Api.uploadDocument(file);
            Alert.success({ title: Intl.formatMessage({ id: "pages.documents.uploadSuccess" }) });
            this.fetchDocuments();
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement {
        if (this.state.isLoading) {
            return <LoadingOverlay />;
        }

        return (
            <>
                <Main.Heading headingText={Intl.formatMessage({ id: "pages.documents.title" })}>
                    <Button btnLabel={Intl.formatMessage({ id: "pages.documents.upload" })} renderIcon={<SVGIconAdd />} onClick={() => (this.uploadInput ? this.uploadInput.click() : void 0)} />
                    <input id="fileButton" type="file" hidden ref={input => (this.uploadInput = input)} onChange={this.onFileUpload} accept={ValidatorConstants.ACCEPTED_DOCUMENT_MIME_TYPES} />
                </Main.Heading>
                <Table
                    keyExtractor={(item: Document, column?: Column<Document>): string => {
                        return `${item.id}_${column?.id || ""}`;
                    }}
                    columns={this.columns}
                    data={this.state.documents}
                    count={this.state.total}
                    pageCount={this.state.pageCount}
                    currentPage={this.state.options.page || 1}
                    onPageChange={this.onPageChange}
                    isPaginationEnabled={this.state.total > Table.DEFAULT_PAGE_SIZE}
                    isLoading={this.state.isLoading}
                    renderEmpty={() => ""}
                />
            </>
        );
    }
}

export { DocumentsPage };
