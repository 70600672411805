enum RunMode {
    prod,
    test,
    dev,
}

let runMode: RunMode = RunMode.dev;
const NODE_ENV: string = process.env.NODE_ENV || "";

if (NODE_ENV.includes("dev")) {
    runMode = RunMode.dev;
} else if (NODE_ENV.includes("test")) {
    runMode = RunMode.test;
} else {
    runMode = RunMode.prod;
}

class Env {
    public static readonly runMode: RunMode = runMode;
    public static readonly graphqlApiUrl?: string = process.env.REACT_APP_GRAPHQL_API_URL;
    public static readonly debugMode: boolean = !!process.env.REACT_APP_DEBUG_MODE || false;
    public static readonly sentryEnabled: boolean = process.env.REACT_APP_SENTRY_ENABLED === "true";
    public static readonly sentryDSN?: string = process.env.REACT_APP_SENTRY_DSN;
    public static readonly mediaLibraryAssetUrl?: string = process.env.REACT_APP_MEDIA_LIBRARY_ASSET_URL;
    public static readonly packageJson: any = require("../../package.json");
}

if (Env.debugMode) {
    window.env = Object.entries(Env).reduce((prevState: { [key: string]: string }, current: [string, any]): { [key: string]: string } => {
        prevState[current[0]] = current[1];
        return prevState;
    }, {});
}

export { Env, RunMode };
