import { addLocaleData, IntlProvider } from "react-intl";
import hu from "react-intl/locale-data/hu";
import { localeHu } from "i18n/locales/hu";

export enum Language {
    hu = "hu",
}

interface Translation {
    [key: string]: string | Translation;
}

interface IntlMessageObject {
    [key: string]: string;
}

addLocaleData([...hu]);

export class Intl {
    private static readonly messages: { [key: string]: IntlMessageObject } = {
        [Language.hu]: Intl.flattenMessages(localeHu),
    };

    private static readonly intlProvider: { intl: ReactIntl.InjectedIntl } = new IntlProvider({ locale: Language.hu, messages: Intl.messages[Language.hu] }).getChildContext();
    private static readonly providedIntl: ReactIntl.InjectedIntl = Intl.intlProvider.intl;

    private static flattenMessages(nestedMessages: Translation, prefix: string = ""): IntlMessageObject {
        return Object.keys(nestedMessages).reduce((messages: IntlMessageObject, key: string) => {
            const value: Translation | string = nestedMessages[key];
            const prefixedKey: string = prefix ? `${prefix}.${key}` : key;

            if (typeof value === "string") {
                messages[prefixedKey] = value;
            } else {
                Object.assign(messages, Intl.flattenMessages(value, prefixedKey));
            }

            return messages;
        }, {});
    }

    public static readonly formatMessage = Intl.providedIntl.formatMessage;
}
