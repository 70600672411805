import { ValidatorConstants } from "utils/Validator";

export const localeHu = {
    common: {
        save: "Mentés",
        cancel: "Mégsem",
        back: "Vissza",
        insert: "Beillesztés",
        login: "Bejelentkezés",
        logout: "Kijelentkezés",
        upload: "Kiválasztás",
        browse: "Tallózás...",
        publish: "Publikálás",
        delete: "Törlés",
    },
    pages: {
        login: {
            title: "Szia, kérlek jelentkezz be",
            description: "A bejelentkezéshez add meg e-mail címed és jelszavad",
            form: {
                email: {
                    name: "E-mail cím",
                },
                password: {
                    name: "Jelszó",
                },
            },
            login: "Bejelentkezés",
            error: "Hibás felhasználónév, vagy jelszó",
        },
        contents: {
            list: {
                title: {
                    all: "Összes cikk listája",
                    text: "Szöveges cikkek listája",
                    gallery: "Galériás cikkek listája",
                    video: "Videós cikkek listája",
                },
                createDropdown: {
                    default: "Új cikk",
                    text: "Szöveges cikk",
                    gallery: "Galériás cikk",
                    video: "Videós cikk",
                },
                createContentButton: {
                    text: "ÚJ SZÖVEGES CIKK",
                    gallery: "ÚJ GALÉRIÁS CIKK",
                    video: "ÚJ VIDEÓS CIKK",
                },
                table: {
                    __typename: "TÍPUS",
                    title: "CÍM",
                    id: "ID",
                    parameters: "PARAMÉTEREK",
                    updated_at: "UTOLSÓ MÓDOSÍTÁS",
                    is_active: "PUBLIKÁLVA",
                },
                search: "Keresés ID-ra vagy címre",
            },
            page: {
                create: {
                    title: {
                        text: "Szöveges cikk felvétele",
                        gallery: "Galériás cikk felvétele",
                        video: "Videós cikk felvétele",
                    },
                    success: "Cikk létrehozása sikeres!",
                },
                edit: {
                    title: {
                        text: "Szöveges cikk szerkesztése",
                        gallery: "Galériás cikk szerkesztése",
                        video: "Videós cikk szerkesztése",
                    },
                    success: "Cikk módosítása sikeres!",
                },
                url: {
                    title: "Közvetlen hivatkozás",
                },
                active_from: {
                    title: "Megjelenés dátuma",
                },
                active_to: {
                    title: "Lejárat dátuma (opcionális)",
                },
                title: {
                    title: "Cím (max. {maxCharacters} karakter)",
                },
                // TODO
                lead_image: {
                    title: "Vezérkép",
                },
                front_page_title: {
                    title: "Címlap cím (opcionális)",
                },
                front_page_image: {
                    title: "Címlap kép (opcionális)",
                },
                front_page_lead: {
                    title: "Címlap Lead (kb. {maxCharacters} karakter)",
                },
                category: {
                    title: "Rovat",
                    placeholder: "Adj meg egy rovatot",
                },
                team: {
                    title: "Csapat",
                },
                decade: {
                    title: "Évtized",
                    placeholder: "Adj meg egy évtizedet",
                },
                season: {
                    title: "Szezon / forduló",
                },
                author: {
                    title: "Szerző",
                },
                metaTable: {
                    headers: {
                        name: "Név",
                        value: "Érték",
                    },
                    meta_title: {
                        title: "og:title",
                    },
                    meta_description: {
                        title: "og:description",
                    },
                    meta_image: {
                        title: "og:image",
                    },
                },
                gallery_id: {
                    title: "Galéria ID",
                },
                video_url: {
                    title: "YouTube videó",
                },
                content: {
                    title: "Tartalom",
                },
                lead: {
                    title: "Lead",
                },
                topInfo: {
                    id: "ID: {id}",
                    updatedAt: "Utoljára mentve: {updatedAt}",
                    publishedAt: "Utoljára publikálva: {publishedAt}",
                },
                seasonSuggestionName: "{season} - {round}. forduló",
            },
        },
        activate: {
            title: "Fiók aktiválása",
            description: "A fiók aktiválásához adjon meg egy jelszót, melyet használni szeretne a jövőben!",
            hint:
                "A jelszónak legalább 9 karakterből kell állnia, tartalmaznia kell kis- és nagybetűket, valamint számot és speciális karaktert. Kérjük ne használjon szótárban szereplő vagy céges szavakat!",
            form: {
                password: {
                    name: "Jelszó",
                },
                passwordConfirmation: {
                    name: "Jelszó megerősítése",
                },
            },
            activate: "Aktiválás",
        },
        404: {
            title: "404",
            description: "A keresett oldal nem található",
            back: "Vissza",
        },
        users: {
            list: {
                title: "Felhasználók",
                add: "ÚJ ADMIN FELHASZNÁLÓ",
                table: {
                    id: "Azonosító",
                    email: "E-mail cím",
                    name: "Név",
                    is_active: "Aktív?",
                },
            },
            create: {
                title: "Új admin felhasználó létrehozása",
            },
            edit: {
                title: "Admin felhasználó szerkesztése",
            },
            form: {
                email: {
                    name: "E-mail cím",
                },
                name: {
                    name: "Név",
                },
                permissions: {
                    name: "Jogosultságok",
                },
                passwordReset: {
                    name: "Jelszóemlékeztető",
                    button: "Küldés",
                },
                isActive: {
                    name: "Aktív?",
                },
            },
            messages: {
                createSuccess: "A felhasználó sikeresen létrehozva",
                updateSuccess: "A felhasználó sikeresen módosítva",
                activateSuccess: "A fiók sikeresen aktiválva",
                adminPasswordResetSuccess: "A jelszóemlékeztető sikeresen elküldve",
            },
        },
        matches: {
            list: {
                title: "Meccsek",
                search: "Mutasd a meccseket",
                addLink: "Link hozzáadása",
                addContent: "Cikk hozzáadása",
                table: {
                    played_at: "Dátum",
                    home_team_name: "Csapat - Gólok",
                    ticket_info: "Jegyvásárlás",
                    contents: "Cikk",
                },
                modals: {
                    createLink: {
                        title: "Jegyvásárlás link",
                        form: {
                            url: {
                                name: "Jegyvásárlás url",
                            },
                        },
                    },
                },
                createLinkSuccess: "Jegyvásárlás link sikeresen módosítva!",
                createContentsSuccess: "Kapcsolódó cikkek sikeresen módosítva!",
            },
            form: {
                season: {
                    name: "Évad",
                    placeholder: "Válasszon ki egy évadot",
                },
                round: {
                    name: "Forduló",
                    placeholder: "Válasszon ki egy fordulót",
                },
            },
        },
        highlights: {
            title: "Kiemelt szekció szerkesztése",
            addContent: "Kiválasztás listából",
            form: {
                content: {
                    name: "Bejegyzés url",
                    label: "{position}. pozíció",
                },
            },
            messages: {
                publishValidation: "5 elem kell!",
                publishSuccess: "Kiemelt szekció sikeresen publikálva!",
            },
        },
        decades: {
            list: {
                title: "Történelem",
                table: {
                    title: "Cím",
                    id: "ID",
                    years: "Időszak",
                    updated_at: "Utolsó módosítás",
                },
            },
            edit: {
                title: "Évtized szerkesztése",
            },
            form: {
                topInfo: {
                    id: "ID: {id}",
                    year: "Évtized: {year}",
                    updatedAt: "Utoljára mentve: {updatedAt}",
                },
                title: {
                    title: "Megnevezés",
                },
                content_title: {
                    title: "Cím (max. 120 karakter)",
                },
                front_page_image: {
                    title: "Címlap kép (opcionális)",
                },
                front_page_title: {
                    title: "Címlap cím (opcionális)",
                },
                front_page_lead: {
                    title: "Címlap lead (kb. 130 karakter)",
                },
                url: {
                    title: "URL",
                },
                lead: {
                    title: "Lead",
                },
                lead_image: {
                    title: "Vezérkép",
                },
                content: {
                    title: "Tartalom",
                },
                metaTable: {
                    headers: {
                        name: "Név",
                        value: "Érték",
                    },
                    meta_title: {
                        title: "og:title",
                    },
                    meta_description: {
                        title: "og:description",
                    },
                    meta_keywords: {
                        title: "og:keywords",
                    },
                },
            },
            messages: {
                updateSuccess: "Az évtized sikeresen módosításra került",
            },
        },
        galleries: {
            list: {
                title: "Galériák",
                add: "Új galéria",
                table: {
                    title: "Galéria címe",
                    image_count: "Elemek",
                    updated_at: "Módosítva",
                },
            },
            create: {
                title: "Új galéria",
            },
            edit: {
                title: "Galéria szerkesztése",
            },
            form: {
                title: {
                    title: "Cím",
                },
                add: {
                    title: "Hozzáadás a médiatárból",
                },
                caption: {
                    title: "Képaláírás (max. 300 karakter)",
                },
                credit: {
                    title: "Kredit",
                },
            },
            info: {
                caption: "Képaláírás",
                credit: "Kredit",
            },
            messages: {
                createSuccess: "A galéria létrehozása sikeres!",
                updateSuccess: "A galéria mentése sikeres!",
                notFound: "A galéria nem található!",
            },
            galleryInfoModal: {
                title: "Képaláírás, kredit hozzáadása",
            },
        },
        informations: {
            list: {
                title: "Információk",
                add: "Új információ",
                table: {
                    title: "Cím",
                    id: "ID",
                    updated_at: "Utolsó módosítás",
                },
            },
            create: {
                title: "Új információ",
            },
            edit: {
                title: "Információ szerkesztése",
                notFound: "Az információ nem található!",
            },
            form: {
                title: {
                    title: "Cím",
                },
                active_from: {
                    title: "Megjelenés dátuma",
                },
                active_to: {
                    title: "Lejárat dátuma (opcionális)",
                },
                front_page_image: {
                    title: "Címlap kép (opcionális)",
                },
                front_page_title: {
                    title: "Címlap cím (opcionális)",
                },
                front_page_lead: {
                    title: "Címlap lead (kb. 130 karakter)",
                },
                url: {
                    title: "URL",
                },
                lead: {
                    title: "Lead",
                },
                lead_image: {
                    title: "Vezérkép",
                },
                content: {
                    title: "Tartalom",
                },
                author: {
                    title: "Szerző",
                },
                topInfo: {
                    id: "ID: {id}",
                    updatedAt: "Utoljára mentve: {updatedAt}",
                    publishedAt: "Utoljára publikálva: {publishedAt}",
                },
                metaTable: {
                    headers: {
                        name: "Név",
                        value: "Érték",
                    },
                    meta_title: {
                        title: "og:title",
                    },
                    meta_description: {
                        title: "og:description",
                    },
                    meta_keywords: {
                        title: "og:keywords",
                    },
                    meta_image: {
                        title: "og:image",
                    },
                },
            },
        },
        documents: {
            title: "Dokumentumok",
            upload: "Új dokumentum feltöltése",
            uploadSuccess: "A dokumentum sikeresen feltöltésre került!",
            table: {
                file_name: "Megnevezés",
                url: "URL",
                actions: "URL másolása",
            },
            onCopySuccess: "Az URL sikeresen vágólapra másolva!",
        },
    },
    components: {
        table: {
            emptyList: "A tábla üres :(",
            previousPage: "Előző oldal",
            nextPage: "Következő oldal",
        },
        dateInput: {
            timeCaption: "Idő",
        },
        contentModal: {
            title: "Cikk kiválasztása",
            search: "Keresés ID-ra vagy címre",
            notMorePages: "Nincs több cikk erre a keresésre",
            table: {
                id: "ID",
                title: "Bejegyzés címe",
                createdAt: "Létrehozás dátuma",
            },
        },
        galleryModal: {
            title: "Galéria kiválasztása",
            search: "Keresés ID-ra vagy címre",
            table: {
                title: "Galéria címe",
                image_count: "Elemek",
                updated_at: "Módosítva",
            },
            notMorePages: "Nincs több galéria erre a keresésre",
        },
        confirmModal: {
            title: "Elem törlése",
            description: "Biztosan törölni szeretnéd az elemet? A művelet nem visszafordítható!",
        },
    },
    CKEditor: {
        defaultConfig: {
            gallery: {
                toolbarLabel: "Galéria",
                editButtonLabel: "Galéria szerkesztése",
            },
            embeddedPost: {
                toolbarLabel: "Cikk",
                editButtonLabel: "Cikk szerkesztése",
                selectedIDLabel: "Kiválasztott cikk",
            },
            cdnImage: {
                toolbarLabel: "CDN kép",
            },
            infoImage: {
                toolbarLabel: "Kép",
                descriptionLabel: "Leírás",
                authorLabel: "Szerző",
                sourceLabel: "Kredit",
            },
            heading: {
                options: {
                    highlighted: {
                        title: "Kiemelt",
                    },
                },
            },
        },
    },
    enum: {
        permissions: {
            MANAGE_ADMINS: "Admin felhasználók",
            MANAGE_CONTENT: "Cikkek",
            MANAGE_FRONT_PAGE: "Főoldal",
            MANAGE_TEAMS: "Csapatok",
            MANAGE_MATCHES: "Meccsek",
            MANAGE_DECADES: "Évtizedek",
            MANAGE_CATEGORIES: "Kategóriák",
            MANAGE_DOCUMENTS: "Dokumentumok",
            MANAGE_GALLERIES: "Galériák",
            MANAGE_INFORMATIONS: "Információk",
        },
    },
    menu: {
        highlights: "Címlap kiemelések",
        users: "Felhasználók",
        contentGroup: "Tartalmak",
        contents: {
            group: "Cikkek",
            all: "Összes",
            text: "Szöveges cikkek",
            gallery: "Galériás cikkek",
            video: "Videós cikkek",
        },
        matches: "Meccsek",
        decades: "Évtizedek",
        galleries: "Galériák",
        informations: "Információk",
        documents: "Dokumentumok",
    },
    error: {
        noPermission: "Nincs megfelelő jogosultságod",
        validation: {
            invalidPassword: "Érvénytelen jelszó!",
            passwordTooShort: `A jelszónak legalább ${ValidatorConstants.MIN_PASSWORD_LENGTH} karakter hosszúnak kell lennie!`,
            passwordsNotMatch: "Jelszavak nem egyeznek!",
            required: "A mező kitöltése kötelező!",
            invalidEmail: "Érvénytelen email-cím!",
            invalidYouTubeURL: "Érvénytelen YouTube videó cím!",
        },
        api: {
            INVALID_INPUT: "Az űrlap egy, vagy több mezője helytelen",
        },
    },
};
