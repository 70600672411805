import React from "react";
import { AuthThunks } from "actions/AuthActions";
import { ThunkDispatchProp } from "actions/Helpers";
import { connect, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { ModalLogin } from "components/Modals/ModalLogin";
import LoadingOverlay from "components/LoadingOverlay";
import AppBar from "../components/AppBar";
import { RedirectBackState } from "routes/RedirectToLogin";
import { Path } from "utils/Path";
import { AlertContainer } from "../components/cms/Alert/AlertContainer";
import { AuthSelectors } from "selectors/AuthSelectors";

interface ReduxProps {
    isLoggedIn: boolean;
}

type ComponentProps = ThunkDispatchProp & RouteComponentProps;

type Props = ComponentProps & ReduxProps;

interface State {
    email: string;
    password: string;
    isLoading: boolean;
    isError: boolean;
}

class LoginPageComponent extends React.Component<Props, State> {
    public static readonly getInitialState = (): State => {
        const isDev: boolean = process.env.NODE_ENV === "development";

        return {
            email: isDev ? process.env.REACT_APP_EMAIL || "" : "",
            password: isDev ? process.env.REACT_APP_PASSWORD || "" : "",
            isLoading: false,
            isError: false,
        };
    };

    public readonly state: State = LoginPageComponent.getInitialState();

    public componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (prevState.email !== this.state.email || prevState.password !== this.state.password) {
            if (this.state.isError) {
                this.setState({ isError: false });
            }
        }
    }

    private readonly onSubmit = async () => {
        this.setState({ isLoading: true }, async () => {
            await this.props.dispatch(AuthThunks.login(this.state.email, this.state.password));
        });
    };

    private readonly onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            email: event.currentTarget.value,
        });
    };

    private readonly onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            password: event.currentTarget.value,
        });
    };

    public render(): React.ReactElement {
        if (this.props.isLoggedIn) {
            return <Redirect to={{ pathname: (this.props.location.state as RedirectBackState)?.redirectBack?.pathname || Path.dashboard }} />;
        }
        return (
            <>
                <AppBar />

                {this.state.isLoading && <LoadingOverlay />}

                <ModalLogin
                    mounted={true}
                    email={this.state.email}
                    password={this.state.password}
                    onEmailChange={this.onEmailChange}
                    onPasswordChange={this.onPasswordChange}
                    onLoginClick={this.onSubmit}
                    hasError={this.state.isError}
                />

                <AlertContainer />
            </>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        isLoggedIn: AuthSelectors.isLoggedIn(state),
    };
};

const LoginPage = withRouter(connect(mapStateToProps)(LoginPageComponent));

export { LoginPage };
