import React, { ChangeEvent } from "react";
import { UpdateMatchInput } from "api/graphql/types";
import { Form, ModalInputWrapper } from "components/Modals/ModalLocTool";
import { Intl } from "i18n/Intl";
import { Color } from "theme/theme";
import Modal, { ButtonWrapper, StyledModalHeader } from "components/Modals/Modal";
import { H2 } from "theme/global";
import { InputWrapper } from "components/Inputs/InputWrapper";
import { Input } from "components/Inputs/Input";
import { Button } from "components/Button";

type Props = {
    mounted: boolean;
    matchId: string;
    link: string | null;
    onUpdateClick: (input: UpdateMatchInput) => void;
    onModalClose: () => void;
    errors: {
        link: string | undefined;
    };
};

interface State {
    input: UpdateMatchInput;
}

class CreateLinkModal extends React.Component<Props, State> {
    public state: State = {
        input: {
            ticket_info: this.props.link || "",
        },
    };

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.link !== this.props.link) {
            this.setState({
                input: {
                    ticket_info: this.props.link || "",
                },
            });
        }
    }

    private readonly onLinkUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            input: {
                ticket_info: e.currentTarget.value,
            },
        });
    };

    public render(): React.ReactElement {
        return (
            <Modal mounted={this.props.mounted} titleText={Intl.formatMessage({ id: "pages.matches.list.modals.createLink.title" })} underlayColor={Color.modal} onModalClose={this.props.onModalClose}>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        this.props.onUpdateClick(this.state.input);
                    }}
                >
                    <StyledModalHeader>
                        <H2 as="h5">{Intl.formatMessage({ id: "pages.matches.list.modals.createLink.title" })}</H2>
                    </StyledModalHeader>
                    <ModalInputWrapper>
                        <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.matches.list.modals.createLink.form.url.name" })} errorMsg={this.props.errors.link}>
                            <Input
                                ariaLabel={Intl.formatMessage({ id: "pages.matches.list.modals.createLink.form.url.name" })}
                                type={"text"}
                                value={this.state.input.ticket_info}
                                onChange={this.onLinkUrlChange}
                                hasError={!!this.props.errors.link}
                            />
                        </InputWrapper>
                    </ModalInputWrapper>
                    <ButtonWrapper>
                        <div className="grid-x grid-margin-y-10">
                            <div className="cell auto grid-x align-right">
                                <Button btnLabel={Intl.formatMessage({ id: "common.cancel" })} secondary={true} className="align-right" onClick={this.props.onModalClose} />
                            </div>
                            <div className="cell auto grid-x align-left">
                                <Button btnLabel={Intl.formatMessage({ id: "common.insert" })} disabled={!this.state.input.ticket_info} type={"submit"} />
                            </div>
                        </div>
                    </ButtonWrapper>
                </Form>
            </Modal>
        );
    }
}

export { CreateLinkModal };
