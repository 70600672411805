import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { UserForm, UserValidator, FormValues } from "./UserForm";
import { Formik, FormikHelpers } from "formik";
import { CreateAdminDataInput } from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "components/cms/Alert/Alert";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Path } from "utils/Path";
import { ApiError } from "api/ApiError";
import { GraphQLClient } from "api/graphql/GraphQLClient";

interface State {
    isLoading: boolean;
    errors: { [key in keyof FormValues]?: string[] };
}

type Props = RouteComponentProps;

class UserCreatePageComponent extends React.Component<Props> {
    public readonly state: State = {
        isLoading: false,
        errors: {},
    };
    private readonly onCreate = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
        this.setState({ isLoading: true }, async () => {
            try {
                const formData: CreateAdminDataInput = {
                    name: values.name,
                    email: values.email,
                    permissions: values.permissions,
                };
                const response = await Api.createAdmin(formData);
                if (response) {
                    Alert.success({ title: Intl.formatMessage({ id: "pages.users.messages.createSuccess" }) });
                    this.props.history.push(Path.userList);
                }
                this.setState({ isLoading: false });
            } catch (error) {
                if (error instanceof ApiError) {
                    const errors = GraphQLClient.parseValidationErrors<CreateAdminDataInput>(error.validation.createData);
                    formikHelpers.setErrors({
                        email: errors?.email,
                        name: errors?.name,
                        permissions: errors?.permissions,
                    });
                }
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    public render(): React.ReactElement {
        return (
            <Formik
                initialValues={{
                    email: "",
                    name: "",
                    permissions: [],
                }}
                validate={UserValidator}
                validateOnBlur={true}
                validateOnChange={false}
                onSubmit={this.onCreate}
            >
                {props => <UserForm onBackClick={() => this.props.history.push(Path.userList)} formProps={props} formMode="create" isLoading={this.state.isLoading} />}
            </Formik>
        );
    }
}

const UserCreatePage = withRouter(UserCreatePageComponent);

export { UserCreatePage };
