import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import { Fragments } from "./Fragments";

class Queries {
    public static readonly adminMe: DocumentNode = gql`
        query adminMe {
            adminMe {
                ...Admin
            }
        }
        ${Fragments.admin}
    `;
    public static readonly listAdmins: DocumentNode = gql`
        query listAdmins($filters: AdminFilters, $sortBy: AdminSort, $count: Int!, $page: Int) {
            listAdmins(filters: $filters, sortBy: $sortBy, count: $count, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...Admin
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.admin}
    `;

    public static readonly getContentsListItems: DocumentNode = gql`
        query getContentsListItems($filters: ContentFilters, $sortBy: ContentOrder, $count: Int!, $page: Int) {
            adminContents(filters: $filters, sortBy: $sortBy, count: $count, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...AnyContentListItem
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.anyContentListItem}
    `;

    public static readonly getContentByUrl: DocumentNode = gql`
        query getContentByUrl($filters: ContentFilters, $count: Int!) {
            adminContents(filters: $filters, count: $count) {
                data {
                    ...AnyContent
                }
            }
        }
        ${Fragments.anyContent}
    `;

    public static readonly seasonsWithRounds: DocumentNode = gql`
        query seasons {
            seasons {
                ...Season
            }
        }
        ${Fragments.season}
    `;

    public static readonly listMatches: DocumentNode = gql`
        query listMatches($filters: MatchFilters!, $sortBy: MatchOrder, $count: Int!, $page: Int) {
            matches(filters: $filters, sortBy: $sortBy, count: $count, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...Match
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.match}
    `;

    public static readonly listCategories: DocumentNode = gql`
        query listCategories {
            categories {
                ...Category
            }
        }
        ${Fragments.category}
    `;

    public static readonly listDecades: DocumentNode = gql`
        query listDecades($sortBy: DecadeOrder, $count: Int!, $page: Int) {
            decades(sortBy: $sortBy, count: $count, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...Decade
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.decade}
    `;

    public static readonly getDecade: DocumentNode = gql`
        query getDecade($id: ID!) {
            decadeById(id: $id) {
                ...Decade
            }
        }
        ${Fragments.decade}
    `;

    public static readonly listTeams: DocumentNode = gql`
        query listTeams($filters: TeamFilters, $sortBy: TeamOrder, $count: Int!, $page: Int) {
            teams(filters: $filters, sortBy: $sortBy, count: $count, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...Team
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.team}
    `;

    public static readonly listGalleries: DocumentNode = gql`
        query listGalleries($filters: GalleryFilters, $sortBy: GalleryOrder, $count: Int!, $page: Int) {
            galleries(filters: $filters, sortBy: $sortBy, count: $count, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...Gallery
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.gallery}
    `;

    public static readonly listInformations: DocumentNode = gql`
        query listInformations($filters: InformationFilters, $sortBy: InformationOrder, $count: Int!, $page: Int) {
            adminInformations(filters: $filters, sortBy: $sortBy, count: $count, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...Information
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.information}
    `;

    public static readonly listDocuments: DocumentNode = gql`
        query listDocuments($sortBy: DocumentOrder, $count: Int!, $page: Int) {
            documents(sortBy: $sortBy, count: $count, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...Document
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.document}
    `;
}

export { Queries };
