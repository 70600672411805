import React from "react";
import { Store } from "redux";
import { ApplicationState } from "reducers";
import { Persistor } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { Provider as ReduxProvider } from "react-redux";

interface Props {
    reduxStore: Store<ApplicationState>;
    reduxPersistor: Persistor;
}

const ReduxWrapper: React.FC<Props> = ({ reduxPersistor, reduxStore, children }) => (
    <ReduxProvider store={reduxStore}>
        <PersistGate loading={"Loading..."} persistor={reduxPersistor}>
            {children}
        </PersistGate>
    </ReduxProvider>
);

export default ReduxWrapper;
