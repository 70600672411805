import { ApolloClient, ApolloQueryResult, MutationOptions, QueryOptions } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { ApolloReducerConfig, InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { ErrorResponse, onError } from "apollo-link-error";
import { Env } from "utils/Env";
import { ApolloLink, ExecutionResult } from "apollo-link";
import { store } from "createStore";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { Log } from "utils/Log";
import { AuthActions } from "actions/AuthActions";
import { DocumentNode, GraphQLError } from "graphql";
import { ServerError, ServerParseError } from "apollo-link-http-common";

export type OnProgress = (progress: number) => any;

interface GraphQLFileUploadOptions<V> {
    mutation: DocumentNode;
    variables?: V;
    file: File;
    onProgress?: OnProgress;
}

type GraphQLValidationErrors<V> = { [key in keyof V]?: string | undefined };

export class GraphQLClient {
    private static readonly httpLink: ApolloLink = createHttpLink({ uri: Env.graphqlApiUrl, credentials: "include" });

    private static readonly errorLink: ApolloLink = onError((errorResponse: ErrorResponse) => {
        if (errorResponse.graphQLErrors) {
            if (errorResponse.graphQLErrors[0].extensions?.category === "authentication") {
                store.dispatch(AuthActions.destroySession());
            }
        }

        return;
    });

    private static readonly fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData: require("./fragmentTypes.json") });

    private static client: ApolloClient<ApolloReducerConfig> = new ApolloClient({
        link: GraphQLClient.errorLink.concat(GraphQLClient.httpLink),
        cache: new InMemoryCache({
            fragmentMatcher: GraphQLClient.fragmentMatcher,
            resultCaching: false,
        }),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "network-only",
                errorPolicy: "ignore",
            },
            query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all",
            },
        },
    });

    /**
     * GraphQLClient mutation
     * Throws error if response.data is empty
     * @param options MutationOptions<R, V>
     */
    public static async mutate<R, V = {}>(options: MutationOptions<R, V>): Promise<R> {
        try {
            const response: ExecutionResult<R> = await GraphQLClient.client.mutate<R, V>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            const validation = GraphQLClient.getValidationErrors<V>(error.graphQLErrors);
            if (validation) {
                throw new ApiError<V>(ApiErrorCode.INVALID_INPUT, validation);
            }
            if (error instanceof ApiError) {
                throw error;
            }
            throw new ApiError(GraphQLClient.getApiErrorMessage(error));
        }
    }

    /**
     * GraphQLClient query
     * Throws error if response.data is empty
     * @param options QueryOptions<R>
     */
    public static async query<R, V = {}>(options: QueryOptions<V>): Promise<R> {
        try {
            const response: ApolloQueryResult<R> = await GraphQLClient.client.query<R>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof ApiError) {
                throw error;
            }
            throw new ApiError(error);
        }
    }

    private static getResult<R>(response: ApolloQueryResult<R> | ExecutionResult<R>): R {
        if (response.errors && response.errors.length > 0) {
            throw new ApiError(GraphQLClient.getApiErrorMessage({ graphQLErrors: response.errors }));
        }

        if (!response.data) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }

        return response.data;
    }

    /**
     * Get error code from ErrorResponse
     * @param error ErrorResponse
     */
    private static getApiErrorMessage(error: { graphQLErrors?: ReadonlyArray<GraphQLError>; networkError?: Error | ServerError | ServerParseError }): ApiErrorCode | string {
        if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].extensions) {
            if (error.graphQLErrors[0].message.length) {
                return error.graphQLErrors[0].message;
            } else {
                Log.warning("Unknown errors from GraphQL response", error.graphQLErrors);
            }
        }
        if (error.networkError) {
            Log.debug("Network error occurred", error);
            return ApiErrorCode.NETWORK_ERROR;
        }
        Log.warning("Unknown error code from GraphQL response", error);
        return ApiErrorCode.UNKNOWN;
    }

    private static onProgress = (onProgressFunction: (progress: number) => any): ((this: XMLHttpRequest, ev: ProgressEvent) => any) => {
        return function (this: XMLHttpRequest, event: ProgressEvent): any {
            return onProgressFunction((event.loaded / event.total) * 100);
        };
    };

    public static upload<R, V>(options: GraphQLFileUploadOptions<V>): Promise<R> {
        return new Promise((resolve: (response: R) => void, reject: (error: Error) => void) => {
            const xhr = new XMLHttpRequest();
            const body = new FormData();
            if (!options.mutation.loc) {
                reject(new Error("options.mutation.loc not found!"));
            }
            body.append("operations", JSON.stringify({ query: options.mutation.loc!.source.body, variables: options.variables }));
            body.append("map", JSON.stringify({ 0: ["variables.file"] }));
            body.append("0", options.file);

            xhr.onerror = () => {
                reject(new ApiError(ApiErrorCode.NETWORK_ERROR));
            };

            xhr.ontimeout = () => {
                reject(new ApiError(ApiErrorCode.REQUEST_TIMEOUT));
            };

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    try {
                        const response: { data: R; errors?: GraphQLError[] } = JSON.parse(xhr.response);
                        if (response.errors) {
                            reject(new ApiError(GraphQLClient.getApiErrorMessage({ graphQLErrors: response.errors })));
                            return;
                        }
                        resolve(response.data);
                    } catch (error) {
                        reject(new ApiError(ApiErrorCode.INVALID_RESPONSE));
                    }
                }
            };

            if (!Env.graphqlApiUrl) {
                reject(new Error("Env.graphqlApiUrl not set!"));
                return;
            }

            xhr.withCredentials = true;
            xhr.open("POST", Env.graphqlApiUrl, true);
            xhr.setRequestHeader("Accept", "*/*");

            if (options.onProgress) {
                xhr.upload.onprogress = GraphQLClient.onProgress(options.onProgress);
            }

            xhr.send(body);
        });
    }

    private static getValidationErrors<V>(graphQLErrors?: ReadonlyArray<GraphQLError>): V | undefined {
        if (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].extensions && graphQLErrors[0].extensions.validation) {
            return graphQLErrors[0].extensions.validation;
        }
        return undefined;
    }

    public static parseValidationErrors<V>(errors: { [key in keyof V]?: string[] }): GraphQLValidationErrors<V> {
        const parsed: GraphQLValidationErrors<V> = {};
        Object.keys(errors).forEach(key => {
            const error: string[] | undefined = errors[key as keyof V];
            parsed[key as keyof V] = error ? error[0] : undefined;
        });
        return parsed;
    }
}
