// Note: X = extra, D = dark, L = light
export enum Color {
    black = "#283259",
    gray = "#ecf0ff",
    grayD = "#d9e1ff",
    grayL = "#f8f9ff",
    primary = "#40e7d3",
    primaryD = "#00dbc1",
    primaryL = "#59ecda",
    primaryXD = "#00b8a1",
    primaryXL = "#9df5eb",
    primaryXXL = "#c7fff9",
    red = "#ff6542",
    redD = "#d45134",
    redL = "#ff7657",
    redXL = "#ffb09e",
    secondary = "#657ed6",
    secondaryD = "#5469b3",
    secondaryL = "#7d95e8",
    secondaryXD = "#445591",
    secondaryXL = "#94acff",
    secondaryXXL = "#b8c7ff",
    white = "#ffffff",
    yellow = "#fced6b",
    modal = "rgba(236, 240, 255, 0.7)",
}

export const theme = {
    background: {
        body: {
            backgroundColor: Color.white,
        },
        appBar: {
            backgroundColor: Color.secondaryXD,
        },
        button: {
            primary: {
                backgroundColor: Color.primaryD,
                hover: Color.primary,
            },
            secondary: {
                backgroundColor: Color.grayD,
                hover: Color.gray,
            },
            hollow: {
                backgroundColor: Color.white,
                hover: Color.grayL,
            },
            disabled: {
                backgroundColor: Color.grayL,
            },
        },
        checkbox: {
            backgroundColor: Color.white,
            checked: {
                backgroundColor: Color.secondary,
            },
        },
        checkboxFlag: {
            checked: {
                backgroundColor: Color.primaryD,
            },
        },
        input: {
            backgroundColor: Color.white,
        },
        modal: {
            backgroundColor: Color.white,
        },
        radio: {
            backgroundColor: Color.grayL,
            innerToggle: {
                backgroundColor: Color.secondary,
            },
        },
        table: {
            tr: {
                hover: {
                    backgroundColor: Color.grayL,
                },
            },
        },
    },
    box: {
        a: {
            focus: {
                outlineColor: Color.secondaryXL,
                outlineOffset: 0,
                outlineStyle: "solid",
                outlineWidth: 1,
            },
        },
        appBar: {
            height: 69,
            paddingLeft: 25,
            paddingRight: 30,
        },
        body: {
            formSpacing: 29,
        },
        button: {
            borderRadius: 24,
            borderWidth: 1,
            height: 48,
            paddingLeft: 14,
            paddingRight: 14,
            primary: {
                borderColor: Color.primaryD,
            },
            secondary: {
                borderColor: Color.grayD,
            },
            hollow: {
                borderColor: Color.secondaryD,
                borderWidth: 2,
            },
            disabled: {
                borderColor: Color.secondaryXXL,
            },
            focus: {
                outlineColor: Color.secondaryXXL,
                outlineOffset: 1,
                outlineWidth: 4,
            },
        },
        checkbox: {
            borderColor: Color.grayD,
            borderRadius: 5,
            borderWidth: 1,
            height: 26,
            paddingLeft: 26 + 16,
            width: 26,
            checked: {
                boxShadow: "0 4px 12px",
                boxShadowColor: Color.secondary,
                boxShadowOpacity: ".3",
            },
        },
        checkboxFlag: {
            checked: {
                boxShadow: "0 4px 12px",
                boxShadowColor: Color.primaryD,
                boxShadowOpacity: ".3",
            },
        },
        headerSection: {
            height: 88,
        },
        input: {
            borderColor: Color.secondary,
            borderRadius: 25,
            borderWidth: 1,
            height: 50,
            paddingBottom: 14,
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 14,
            hasError: {
                borderColor: Color.red,
                borderWidth: 2,
            },
            focus: {
                outlineColor: Color.secondaryXXL,
                outlineOffset: 1,
                outlineWidth: 4,
            },
        },
        modal: {
            borderRadius: 4,
            boxShadow: "0 6px 12px",
            boxShadowColor: Color.grayD,
            boxShadowOpacity: ".3",
            paddingBottom: 50,
            paddingLeft: 55,
            paddingRight: 55,
            paddingTop: 95 + 42,
            width: 663,
        },
        select: {
            paddingLeft: 16,
            paddingRight: 12 + 2 * 16,
        },
        radio: {
            borderColor: Color.grayD,
            borderRadius: "50%",
            borderWidth: 1,
            height: 26,
            paddingLeft: 26 + 15,
            width: 26,
            innerToggle: {
                borderRadius: "50%",
                boxShadow: "0 4px 12px",
                boxShadowColor: Color.secondary,
                boxShadowOpacity: ".3",
                height: 14,
                width: 14,
            },
        },
        table: {
            th: {
                paddingTop: 6,
            },
            td: {
                borderTopColor: Color.grayD,
                borderTopWidth: 1,
                minHeight: 59,
                padding: 12,
            },
        },
    },
    typo: {
        a: {
            color: Color.secondary,
            textDecoration: "none",
            focus: {
                color: Color.secondaryXD,
            },
            hover: {
                color: Color.secondaryXD,
            },
        },
        body: {
            color: Color.secondaryXD,
            fontFamily: "Helvetica, Arial",
            fontSize: 16,
            lineHeight: 19,
        },
        appBar: {
            color: Color.white,
        },
        button: {
            fontSize: 16,
            fontWeight: 400,
            primary: {
                color: Color.secondaryXD,
            },
        },
        checkbox: {
            color: Color.black,
            toggle: {
                color: Color.white,
            },
        },
        heroText: {
            fontSize: 52,
            fontWeight: 400,
            lineHeight: 63,
        },
        h1: {
            fontSize: 40,
            fontWeight: 700,
            lineHeight: 49,
        },
        h2: {
            fontSize: 32,
            fontWeight: 400,
            lineHeight: 39,
        },
        h3: {
            fontSize: 28,
            fontWeight: 700,
            lineHeight: 34,
        },
        h4: {
            fontSize: 24,
            fontWeight: 400,
            lineHeight: 29,
        },
        h5: {
            fontSize: 20,
            fontWeight: 700,
            lineHeight: 24,
        },
        CaptionText: {
            fontSize: 14,
            lineHeight: 17,
        },
        input: {
            color: Color.secondaryXD,
            fontSize: 16,
            fontWeight: 400,
            hasError: {
                color: Color.red,
            },
            fakeLabel: {
                color: Color.secondaryXL,
            },
        },
        table: {
            caption: {
                color: Color.secondaryD,
            },
            th: {
                color: Color.secondaryXL,
                fontSize: 13,
                textTransform: "uppercase",
            },
            td: {
                color: Color.black,
            },
        },
    },
};
