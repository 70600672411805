import React from "react";
import styled from "styled-components";

import { StyledInput } from "components/Inputs/Input";
import { FakeLabel, InputWrapper } from "components/Inputs/InputWrapper";
import { SVGIconClear, SVGIconSearch } from "components/SVGCollection";

type Props = {
    ariaLabel: string;
    onClearClick?: () => void;
    hasError?: boolean;
    innerRef?: (ref: HTMLInputElement | null) => void;
    errorMsg?: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

class SearchComponent extends React.PureComponent<Props> {
    render(): JSX.Element {
        const { ariaLabel, innerRef, errorMsg, onClearClick, ...otherProps } = this.props;
        const hasClearButton = (this.props.value?.toString().length ?? 0) > 0;

        return (
            <SearchInputWrapper fakeLabel={ariaLabel} errorMsg={errorMsg}>
                <StyledSearchInput aria-label={ariaLabel} inputMode="search" ref={innerRef} required={true} type="text" hasClearButton={hasClearButton} {...otherProps} />

                {hasClearButton && (
                    <ClearButton type="button" onClick={onClearClick}>
                        <span className="show-for-sr">Clear search input</span>

                        <span aria-hidden="true">
                            <SVGIconClear focusable="false" />
                        </span>
                    </ClearButton>
                )}

                <SearchIconWrapper aria-hidden="true">
                    <SVGIconSearch focusable="false" />
                </SearchIconWrapper>
            </SearchInputWrapper>
        );
    }
}

const SearchInputWrapper = styled(InputWrapper)`
    ${FakeLabel} {
        left: ${60 - 10}px;
    }
`;

const ClearButton = styled.button`
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
`;

const SearchIconWrapper = styled.div`
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 60px;
`;

const StyledSearchInput = styled(StyledInput)<Props>`
    min-width: 290px;
    padding-left: 60px;
    padding-right: ${props => props.hasClearButton && `${props.theme.box.input.height + 2 * 8}px`};
`;

export const Search: React.ComponentClass<Props> = SearchComponent;
