import React from "react";
import { AnyContentListItem, listMatchesVariables as MatchListOptions, Match, MatchOrderField, OrderDirection, UpdateMatchInput } from "api/graphql/types";
import { Column, Table } from "components/Tables/Table";
import { Api } from "api/Api";
import { Alert } from "components/cms/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ObjectUtils } from "utils/ObjectUtils";
import { Intl } from "i18n/Intl";
import { TableLink } from "components/Tables/TableLink";
import { Button } from "components/Button";
import * as _ from "lodash";
import { CreateLinkModal } from "pages/Matches/Modals/CreateLinkModal";
import { ApiError } from "api/ApiError";
import { GraphQLClient } from "api/graphql/GraphQLClient";
import { ContentModal } from "pages/common/ContentModal";
import { ContentTypename } from "../../api/ApiTypes";
import { ConfirmModal } from "../../components/Modals/ConfirmModal";
import { SVGContentText, SVGContentGallery, SVGContentVideo } from "../../components/SVGCollection";

type Props = {
    search: {
        season: string | null;
        round: string | null;
    };
};

interface State {
    options: MatchListOptions;
    matches: Match[];
    total: number;
    pageCount: number;
    isLoading: boolean;
    tableMounted: boolean;
    linkModal: {
        mounted: boolean;
        matchId: string | null;
        link: string | null;
        errors: {
            link: string | undefined;
        };
    };
    contentModal: {
        mounted: boolean;
        matchId: string | null;
        selectedContents: AnyContentListItem[];
    };
    ticketInfoRemoveConfirmModal: {
        mounted: boolean;
        matchListItemId?: string;
    };
    contentRemoveConfirmModal: {
        mounted: boolean;
        matchListItem?: Match;
        contentId?: string;
    };
}

enum MatchListTableColumn {
    played_at = "played_at",
    home_team_name = "home_team_name",
    ticket_info = "ticket_info",
    contents = "contents",
}

class MatchListTable extends React.Component<Props, State> {
    public state: State = {
        matches: [],
        isLoading: false,
        tableMounted: !!this.props.search.season && !!this.props.search.round,
        total: 0,
        pageCount: 0,
        options: {
            filters: {
                season_id: this.props.search.season,
                round_id: this.props.search.round,
            },
            sortBy: {
                field: MatchOrderField.PLAYED_AT,
                direction: OrderDirection.DESC,
            },
            count: Table.DEFAULT_PAGE_SIZE,
            page: 1,
        },
        linkModal: {
            mounted: false,
            matchId: null,
            link: null,
            errors: {
                link: undefined,
            },
        },
        contentModal: {
            mounted: false,
            matchId: null,
            selectedContents: [],
        },
        ticketInfoRemoveConfirmModal: {
            mounted: false,
        },
        contentRemoveConfirmModal: {
            mounted: false,
        },
    };

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        const { options, tableMounted } = this.state;
        if (!_.isEqual(prevProps, this.props)) {
            this.getStateFromProps();
        }
        if (!_.isEqual(prevState.options, options) && tableMounted) {
            if (!_.isEqual(prevState.options.filters, this.state.options.filters)) {
                this.setState({
                    options: {
                        ...this.state.options,
                        page: 1,
                    },
                });
            }
            this.refreshMatches(options);
        }
    }

    private readonly getStateFromProps = (): void => {
        this.setState({
            options: {
                ...this.state.options,
                filters: {
                    season_id: this.props.search.season,
                    round_id: this.props.search.round,
                },
            },
        });
    };

    private getTypeIcon = (typename: string): React.ReactElement => {
        switch (typename) {
            case ContentTypename.TextContent:
                return <SVGContentText width={30} height={30} focusable="false" />;
            case ContentTypename.GalleryContent:
                return <SVGContentGallery width={30} height={30} focusable="false" />;
            case ContentTypename.VideoContent:
                return <SVGContentVideo width={30} height={30} focusable="false" />;
            default:
                return <></>;
        }
    };

    public readonly refreshMatches = (options: MatchListOptions): void => {
        this.setState(
            {
                isLoading: true,
                tableMounted: true,
            },
            async () => {
                try {
                    const { data, paginatorInfo } = await Api.listMatches(options);
                    this.setState({
                        matches: data,
                        total: paginatorInfo.total,
                        pageCount: paginatorInfo.lastPage,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                    });
                    this.setState({
                        isLoading: false,
                    });
                }
            },
        );
    };

    private readonly columns: Array<Column<Match>> = ObjectUtils.enumAsArray<MatchListTableColumn>(MatchListTableColumn).map(
        (columnName: MatchListTableColumn): Column<Match> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `pages.matches.list.table.${columnName}` }),
            accessor: columnName as keyof Match,
            renderCell: (matchListItem: Match): React.ReactElement<any> | null => {
                switch (columnName) {
                    case MatchListTableColumn.played_at:
                        return <>{new Date(matchListItem.played_at).toLocaleString("hu")}</>;
                    case MatchListTableColumn.home_team_name:
                        return (
                            <>
                                {matchListItem.home_team_name} <b>{matchListItem.home_team_goals}</b> <br />
                                {matchListItem.guest_team_name} <b>{matchListItem.guest_team_goals}</b>
                            </>
                        );
                    case MatchListTableColumn.ticket_info:
                        return (
                            <TableLink
                                title={Intl.formatMessage({ id: "pages.matches.list.addLink" })}
                                links={
                                    matchListItem.ticket_info
                                        ? [
                                              {
                                                  id: matchListItem.id,
                                                  content: matchListItem.ticket_info,
                                              },
                                          ]
                                        : undefined
                                }
                                onAddClick={() => this.openLinkModal(matchListItem.id, matchListItem.ticket_info)}
                                onEditClick={() => this.openLinkModal(matchListItem.id, matchListItem.ticket_info)}
                                onDeleteClick={() => this.toggleTicketInfoRemoveConfirmModal(matchListItem.id)}
                            />
                        );
                    case MatchListTableColumn.contents:
                        return (
                            <TableLink
                                title={Intl.formatMessage({ id: "pages.matches.list.addContent" })}
                                links={
                                    matchListItem.contents.length !== 0
                                        ? matchListItem.contents.map(content => {
                                              return {
                                                  id: content.id,
                                                  content: content.title,
                                                  icon: this.getTypeIcon(content.__typename),
                                              };
                                          })
                                        : undefined
                                }
                                multiple={true}
                                onAddClick={() => this.openContentModal(matchListItem.id, matchListItem.contents)}
                                onEditClick={() => this.openContentModal(matchListItem.id, matchListItem.contents)}
                                onDeleteClick={(contentId: string) => this.toggleContentRemoveConfirmModal(matchListItem, contentId)}
                            />
                        );
                    default:
                        return null;
                }
            },
            isNonSortable: false,
        }),
    );

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        this.setState({
            options: {
                ...options,
                page: pageNum,
            },
        });
    };

    private readonly openLinkModal = (matchId: string, link: string | null): void => {
        this.setState({
            linkModal: {
                mounted: true,
                link,
                matchId,
                errors: {
                    link: undefined,
                },
            },
        });
    };

    private readonly closeLinkModal = (): void => {
        this.setState({
            linkModal: {
                mounted: false,
                link: null,
                matchId: null,
                errors: {
                    link: undefined,
                },
            },
        });
    };

    private readonly onLinkUpdate = async (id: string, input: UpdateMatchInput): Promise<void> => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const response = await Api.updateMatch({
                        id,
                        input,
                    });
                    if (response.updateMatch) {
                        this.closeLinkModal();
                        await this.refreshMatches(this.state.options);
                    }
                    this.setState({
                        isLoading: false,
                    });
                    Alert.success({ title: Intl.formatMessage({ id: "pages.matches.list.createLinkSuccess" }) });
                } catch (error) {
                    if (error instanceof ApiError) {
                        const errors = GraphQLClient.parseValidationErrors<UpdateMatchInput>(error.validation.input);
                        this.setState({
                            linkModal: {
                                ...this.state.linkModal,
                                errors: {
                                    ...this.state.linkModal.errors,
                                    link: errors?.ticket_info,
                                },
                            },
                        });
                    }
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({
                        isLoading: false,
                    });
                }
            },
        );
    };

    private readonly onLinkRemove = (): void => {
        this.onLinkUpdate(this.state.ticketInfoRemoveConfirmModal.matchListItemId!, {
            ticket_info: null,
        });
        this.toggleTicketInfoRemoveConfirmModal();
    };

    private readonly onContentRemove = (): void => {
        const { contentRemoveConfirmModal } = this.state;
        this.onContentUpdate(
            contentRemoveConfirmModal.matchListItem!.contents.filter(content => content.id !== this.state.contentRemoveConfirmModal.contentId),
            contentRemoveConfirmModal.matchListItem!.id!,
        );
        this.toggleContentRemoveConfirmModal();
    };

    private readonly openContentModal = (matchId: string, contents: AnyContentListItem[]): void => {
        this.setState({
            contentModal: {
                mounted: true,
                matchId,
                selectedContents: contents,
            },
        });
    };

    private readonly closeContentModal = (): void => {
        this.setState({
            contentModal: {
                mounted: false,
                matchId: null,
                selectedContents: [],
            },
        });
    };

    private readonly toggleTicketInfoRemoveConfirmModal = (matchListItemId?: string): void => {
        this.setState({
            ticketInfoRemoveConfirmModal: {
                mounted: !this.state.ticketInfoRemoveConfirmModal.mounted,
                matchListItemId,
            },
        });
    };

    private readonly toggleContentRemoveConfirmModal = (matchListItem?: Match, contentId?: string): void => {
        this.setState({
            contentRemoveConfirmModal: {
                mounted: !this.state.contentRemoveConfirmModal.mounted,
                matchListItem,
                contentId,
            },
        });
    };

    private readonly onContentUpdate = (contents: AnyContentListItem[], matchId?: string): void => {
        const updateMatchId: string = matchId ? matchId : this.state.contentModal.matchId!;
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const input: UpdateMatchInput = {
                        contents: {
                            sync: contents.map(content => content.id),
                        },
                    };
                    const response = await Api.updateMatch({
                        id: updateMatchId,
                        input,
                    });
                    if (response.updateMatch) {
                        this.closeContentModal();
                        await this.refreshMatches(this.state.options);
                    }
                    this.setState({
                        isLoading: false,
                    });
                    Alert.success({ title: Intl.formatMessage({ id: "pages.matches.list.createContentsSuccess" }) });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({
                        isLoading: false,
                    });
                }
            },
        );
    };

    render(): React.ReactElement {
        const { linkModal, contentModal } = this.state;
        const { search } = this.props;
        return (
            <>
                <CreateLinkModal
                    mounted={linkModal.mounted}
                    matchId={linkModal.matchId!}
                    link={linkModal.link}
                    errors={linkModal.errors}
                    onUpdateClick={input => this.onLinkUpdate(linkModal.matchId!, input)}
                    onModalClose={this.closeLinkModal}
                />
                <ContentModal
                    mounted={contentModal.mounted}
                    selectedContents={contentModal.selectedContents}
                    onContentSelected={contents => this.onContentUpdate(contents)}
                    onModalClose={this.closeContentModal}
                />
                <ConfirmModal mounted={this.state.ticketInfoRemoveConfirmModal.mounted} onConfirm={this.onLinkRemove} onClose={this.toggleTicketInfoRemoveConfirmModal} />
                <ConfirmModal mounted={this.state.contentRemoveConfirmModal.mounted} onConfirm={this.onContentRemove} onClose={this.toggleContentRemoveConfirmModal} />
                {this.state.tableMounted ? (
                    <Table
                        keyExtractor={(item: Match, column?: Column<Match>): string => {
                            return `${item.id}_${column?.id || ""}`;
                        }}
                        columns={this.columns}
                        data={this.state.matches}
                        count={this.state.total}
                        pageCount={this.state.pageCount}
                        currentPage={this.state.options.page || 1}
                        isSortable={false}
                        onPageChange={this.onPageChange}
                        isPaginationEnabled={this.state.total > Table.DEFAULT_PAGE_SIZE}
                        isLoading={this.state.isLoading}
                        renderEmpty={() => ""}
                    />
                ) : (
                    <div className="grid-x">
                        <div className="cell auto">
                            <Button
                                disabled={!(search.season && search.round)}
                                btnLabel={Intl.formatMessage({ id: "pages.matches.list.search" })}
                                onClick={() => this.refreshMatches(this.state.options)}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export { MatchListTable };
