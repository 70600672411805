import React from "react";
import AppBar from "components/AppBar";
import LoadingOverlay from "components/LoadingOverlay";

const LoadingPage: React.FC = () => {
    return (
        <>
            <AppBar />
            <LoadingOverlay />
        </>
    );
};

export { LoadingPage };
