import React, { useState } from "react";
import styled from "styled-components";
import { Color } from "theme/theme";
import { IconArrowRotateWrapper } from "theme/global";
import { FoldableFC } from "components/cms/Foldable";
import { SVGIconSelectArrowDown } from "components/SVGCollection";

const MenuContainerLi: React.FC<{
    btnLabel: string;
    isActive: boolean;
    renderIcon: string | JSX.Element;
}> = props => (
    <StyledMenuContainerLi {...props}>
        {/* Note: <button type="button"><span className="show-for-sr">Menu</span></button> */}
        {props.children}

        <MenuContainerLiLabelGrid className="grid-x align-middle" aria-hidden="true">
            <div className="cell shrink">
                <MenuContainerLiIconWrapper>{props.renderIcon}</MenuContainerLiIconWrapper>
            </div>

            <MenuContainerLiLabel className="cell auto">{props.btnLabel}</MenuContainerLiLabel>
        </MenuContainerLiLabelGrid>
    </StyledMenuContainerLi>
);

const MenuContainerLiLabelGrid = styled.div`
    /* Note: we need this declaration for StyledMenuContainerLiFoldable */
`;

const MenuContainerLiIconWrapper = styled.div`
    align-items: center;
    // background-color: ${Color.white};
    // border-radius: 50%;
    color: white;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-right: 16px;
    overflow: hidden;
    width: 30px;
`;

const MenuContainerLiLabel = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    opacity: 1;
    transform: translateX(0);
    transition: opacity 150ms, transform 150ms;
`;

const StyledMenuContainerLi = styled.li<{ isActive: boolean }>`
    ${props =>
        props.isActive
            ? `

        color: ${Color.secondary};
        background-color: ${Color.white};

        > ${MenuContainerLiLabelGrid} {
            > .cell {            
                > ${MenuContainerLiIconWrapper} {
                    color: ${Color.secondary};
                }
            }
        }
        `
            : `
        background-color: transparent;
        color: ${Color.white};
            `}

    padding: 11px 22px;
    position: relative;

    > button {
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1050;
    }

    &:hover {
        background-color: ${Color.secondary};
        color: white;
        ${MenuContainerLiIconWrapper} {
            color: ${Color.white};
        }

        > ${MenuContainerLiLabelGrid} {
            > .cell {
                > ${MenuContainerLiIconWrapper} {
                    color: ${Color.white};
                }
            }
        }
    }
`;

const MenuContainerLiFoldable: React.FC<{
    btnLabel: string;
    renderIcon: string | JSX.Element;
    isActive?: boolean;
    defaultOpened?: boolean;
}> = props => {
    const [isOpened, setIsOpened] = useState<boolean>(typeof props.defaultOpened !== "undefined" ? props.defaultOpened : false);

    return (
        <StyledMenuContainerLiFoldable renderIcon={props.renderIcon} btnLabel={props.btnLabel} isActive={props.isActive || false}>
            <IconArrowRotateWrapper rotateDeg={isOpened ? 180 : 0} aria-hidden="true">
                <SVGIconSelectArrowDown focusable="false" />
            </IconArrowRotateWrapper>

            <button type="button" onClick={() => setIsOpened(!isOpened)}>
                <span className="show-for-sr">{props.btnLabel}</span>
            </button>

            <FoldableFC ariaId={props.btnLabel} isOpened={isOpened}>
                <ul style={isOpened ? { opacity: 1 } : { opacity: 0 }}>{props.children}</ul>
            </FoldableFC>
        </StyledMenuContainerLiFoldable>
    );
};

const StyledMenuContainerLiFoldable = styled(MenuContainerLi)`
    ${props =>
        props.isActive
            ? `
        background-color: ${Color.secondary};
        color: ${Color.white};

        > ${MenuContainerLiLabelGrid} {
            > .cell {            
                > ${MenuContainerLiIconWrapper} {
                    color: ${Color.white};
                }
            }
        }

        `
            : ""}

    padding: 52px 0 0;

    &::before {
        background-color: ${Color.secondaryXD};
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 52px;
        z-index: 1;
    }

    > button {
        bottom: auto;
        height: 52px;
        padding-right: 52px;
    }

    > ${IconArrowRotateWrapper} {
        align-items: center;
        display: flex;
        height: 52px;
        justify-content: center;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 52px;
    }

    > ${MenuContainerLiLabelGrid} {
        left: 22px;
        position: absolute;
        right: 22px;
        top: 11px;
    }

    ul {
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
        position: relative;
        transition: opacity 150ms;
        z-index: 2;

        li {
            padding-left: 44px;
        }

        ${MenuContainerLiLabelGrid} {
            padding-left: 5px;
        }

        ${MenuContainerLiLabel} {
            text-transform: none;
        }

        ${MenuContainerLiIconWrapper} {
            height: 20px;
            width: 20px;
        }
    }
`;

const MenuContainer: React.FC = props => {
    const [isClosed, setIsClosed] = useState<boolean>(false);

    return (
        <StyledMenuContainer isClosed={isClosed}>
            <MenuFixedContainer>
                <MenuAbsoluteContainer>
                    <MenuContainerUL>{props.children}</MenuContainerUL>
                </MenuAbsoluteContainer>

                <MenuToggleButtonWrapper>
                    <button type="button" onClick={() => setIsClosed(!isClosed)}>
                        <span className="show-for-sr">{isClosed ? "Open" : "Close"} menu</span>

                        <IconArrowRotateWrapper rotateDeg={isClosed ? -90 : 90} aria-hidden="true">
                            <SVGIconSelectArrowDown focusable="false" />
                        </IconArrowRotateWrapper>
                    </button>
                </MenuToggleButtonWrapper>
            </MenuFixedContainer>
        </StyledMenuContainer>
    );
};

const MenuFixedContainer = styled.div`
    background-color: ${Color.secondaryXD};
    bottom: 0;
    color: ${Color.white};
    left: 0;
    overflow: hidden;
    position: fixed;
    top: ${props => props.theme.box.appBar.height}px;
    transition: width 150ms;
    width: 250px;
    will-change: width;
    z-index: 1050;
`;

const StyledMenuContainer = styled.div<{ isClosed: boolean }>`
    width: 250px;
    will-change: width;

    ${props =>
        props.isClosed
            ? `
        width: 75px;

        ${MenuFixedContainer} {
            width: 75px;
        }

        ${MenuContainerLiLabel} {
            opacity: 0;
            transform: translateX(${22 - 16}px);
        }
    `
            : ""}
`;

const MenuAbsoluteContainer = styled.div`
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: ${3 * 25}px;
    position: absolute;
    top: 0;
    width: 250px;
`;

const MenuContainerUL = styled.ul`
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
`;

const MenuToggleButtonWrapper = styled.div`
    bottom: 25px;
    position: absolute;
    right: 25px;

    button {
        align-items: center;
        background-color: ${Color.white};
        border-radius: 50%;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
        color: ${Color.secondaryD};
        display: flex;
        height: 26px;
        justify-content: center;
        width: 26px;
    }

    svg {
        transform: translateY(1px);
    }
`;

export const Menu = {
    Container: MenuContainer,
    Li: MenuContainerLi,
    LiFoldable: MenuContainerLiFoldable,
};
