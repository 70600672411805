import React, { PropsWithChildren } from "react";
import AppBar from "components/AppBar";
import { LeftMenu, MenuItemType } from "components/cms/LeftMenu";
import {
    SVGIconProfile,
    SVGIconHome,
    SVGContentGroup,
    SVGContentText,
    SVGContentGallery,
    SVGContentVideo,
    SVGIconFootballBall,
    SVGIconDecades,
    SVGIconInformation,
    SVGIconDocument,
} from "components/SVGCollection";
import { AlertContainer } from "components/cms/Alert/AlertContainer";
import { Permission } from "api/graphql/types";
import { Path } from "utils/Path";
import { ContentTypeListPath } from "api/ApiTypes";

const getMenuItems = (): Array<MenuItemType | undefined> => [
    {
        name: "highlights",
        icon: <SVGIconHome />,
        path: Path.highlights,
        permission: Permission.MANAGE_CONTENT,
    },
    {
        name: "contents.group",
        icon: <SVGContentGroup />,
        path: Path.contentList(ContentTypeListPath.all),
        permission: Permission.MANAGE_CONTENT,
        subMenus: [
            {
                name: "contents.all",
                icon: <SVGContentGroup />,
                path: Path.contentList(ContentTypeListPath.all),
                permission: Permission.MANAGE_CONTENT,
            },
            {
                name: "contents.text",
                icon: <SVGContentText />,
                path: Path.contentList(ContentTypeListPath.text),
                permission: Permission.MANAGE_CONTENT,
            },
            {
                name: "contents.gallery",
                icon: <SVGContentGallery />,
                path: Path.contentList(ContentTypeListPath.gallery),
                permission: Permission.MANAGE_CONTENT,
            },
            {
                name: "contents.video",
                icon: <SVGContentVideo />,
                path: Path.contentList(ContentTypeListPath.video),
                permission: Permission.MANAGE_CONTENT,
            },
        ],
    },
    {
        name: "galleries",
        icon: <SVGContentGallery />,
        path: Path.galleryList,
        permission: Permission.MANAGE_GALLERIES,
    },
    {
        name: "matches",
        icon: <SVGIconFootballBall />,
        path: Path.matchList,
        permission: Permission.MANAGE_MATCHES,
    },
    {
        name: "decades",
        icon: <SVGIconDecades />,
        path: Path.decadeList,
        permission: Permission.MANAGE_DECADES,
    },
    {
        name: "informations",
        icon: <SVGIconInformation />,
        path: Path.informationList,
        permission: Permission.MANAGE_INFORMATIONS,
    },
    {
        name: "documents",
        icon: <SVGIconDocument />,
        path: Path.documents,
        permission: Permission.MANAGE_DOCUMENTS,
    },
    {
        name: "users",
        icon: <SVGIconProfile />,
        path: Path.userList,
        permission: Permission.MANAGE_ADMINS,
    },
];

export const LoggedInLayout = (props: PropsWithChildren<{}>) => {
    return (
        <>
            <AppBar />

            <div className="grid-x">
                <nav className="cell shrink">
                    <LeftMenu menuItems={getMenuItems()} currentPath={document.location.pathname} />
                </nav>

                <main className="cell auto">{props.children}</main>
            </div>

            <AlertContainer />
        </>
    );
};
