import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { UpdateDecadeInput } from "api/graphql/types";
import { Formik, FormikHelpers } from "formik";
import { Path } from "utils/Path";
import { DecadeForm, DecadeValidator } from "pages/Decades/DecadeForm";
import { Redirect } from "react-router-dom";
import { Api } from "api/Api";
import { Alert } from "components/cms/Alert/Alert";
import { Intl } from "i18n/Intl";
import { GraphQLClient } from "api/graphql/GraphQLClient";
import { IntlHelpers } from "i18n/IntlHelpers";
import LoadingOverlay from "components/LoadingOverlay";

interface RouteParams {
    id?: string;
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ComponentProps;

interface State {
    decade: UpdateDecadeInput | null;
    extraInfo: {
        id: string | null;
        updatedAt: any | null;
        url: string | null;
        years: string | null;
    };
    errors: { [key in keyof UpdateDecadeInput]?: string[] };
    isLoading: boolean;
}

class DecadeEditPageComponent extends React.Component<Props, State> {
    public readonly state: State = {
        decade: null,
        extraInfo: {
            id: this.props.match.params.id || null,
            updatedAt: null,
            url: null,
            years: null,
        },
        errors: {},
        isLoading: true,
    };

    public componentDidMount(): void {
        this.fetchDecade();
    }

    private readonly fetchDecade = async (): Promise<void> => {
        this.setState({ isLoading: true }, async () => {
            try {
                const response = await Api.getDecade(this.props.match.params.id!);

                this.setState({
                    isLoading: false,
                    decade: {
                        title: response.title,
                        content_title: response.content_title,
                        lead: response.lead,
                        lead_image: response.lead_image,
                        front_page_title: response.front_page_title,
                        front_page_lead: response.front_page_lead,
                        front_page_image: response.front_page_image,
                        content: response.content,
                        meta_title: response.meta_title,
                        meta_keywords: response.meta_keywords,
                        meta_description: response.meta_description,
                    },
                    extraInfo: {
                        id: response.id,
                        updatedAt: response.updated_at,
                        url: response.url,
                        years: response.years,
                    },
                });
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    private readonly onUpdate = async (values: UpdateDecadeInput, formikHelpers: FormikHelpers<UpdateDecadeInput>): Promise<void> => {
        this.setState({ isLoading: true }, async () => {
            try {
                const response = await Api.updateDecade({ id: this.props.match.params.id!, input: values });

                this.setState({
                    isLoading: false,
                    decade: response,
                    extraInfo: {
                        id: response.id,
                        updatedAt: response.updated_at,
                        url: response.url,
                        years: response.years,
                    },
                });
                Alert.success({ title: Intl.formatMessage({ id: "pages.decades.messages.updateSuccess" }) });
            } catch (error) {
                if (error.validation) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            const errors = GraphQLClient.parseValidationErrors<UpdateDecadeInput>(error.validation.input);
                            formikHelpers.setErrors({
                                title: errors.title,
                                content_title: errors.content_title,
                                front_page_title: errors.front_page_title,
                                front_page_lead: errors.front_page_lead,
                                front_page_image: errors.front_page_image,
                                lead: errors.lead,
                                lead_image: errors.lead_image,
                                content: errors.content,
                                meta_title: errors.meta_title,
                                meta_keywords: errors.meta_keywords,
                                meta_description: errors.meta_description,
                            });
                        },
                    );
                } else {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            }
        });
    };

    public render(): React.ReactElement {
        const { decade, extraInfo, isLoading } = this.state;
        if (!this.props.match.params.id || (!isLoading && !decade)) {
            return <Redirect to={Path.decadeList} />;
        }

        if (!decade) {
            return <LoadingOverlay />;
        }

        return (
            <Formik initialValues={decade} validate={DecadeValidator} validateOnBlur={true} validateOnChange={false} onSubmit={this.onUpdate}>
                {props => (
                    <DecadeForm
                        onBackClick={() => this.props.history.push(Path.decadeList)}
                        formProps={props}
                        isLoading={this.state.isLoading}
                        decadeId={extraInfo.id}
                        updatedAt={extraInfo.updatedAt}
                        url={extraInfo.url}
                        years={extraInfo.years}
                    />
                )}
            </Formik>
        );
    }
}

const DecadeEditPage = withRouter(DecadeEditPageComponent);

export { DecadeEditPage };
