import React, { PureComponent } from "react";
import { Column } from "./Table";
import { OrderDirection } from "api/graphql/types";
import { SVGChevronDown, SVGChevronUp } from "components/SVGCollection";
import styled from "styled-components";

interface Props<T> {
    column: Column<T>;
    onChangeSort?: (column?: Column<T>, sortOrder?: OrderDirection) => void;
    sortOrder?: OrderDirection;
    isSortable: boolean;
}

class ColumnHeader<T = any> extends PureComponent<Props<T>, {}> {
    /**
     * Moves the order (normal -> ascending -> descending) and fires the appropriate ascending/descending prop methods
     */
    private onHeaderClick = (column: Column<T>): ((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void) => (): void => {
        const { sortOrder, onChangeSort, isSortable } = this.props;
        let nextSortOrder: OrderDirection | undefined = undefined;

        switch (sortOrder) {
            case undefined:
                nextSortOrder = OrderDirection.ASC;
                break;
            case OrderDirection.ASC:
                nextSortOrder = OrderDirection.DESC;
                break;
            case OrderDirection.DESC:
            default:
                nextSortOrder = undefined;
                break;
        }

        if (onChangeSort && isSortable) {
            nextSortOrder ? onChangeSort(column, nextSortOrder) : onChangeSort(undefined, undefined);
        }
    };

    private renderChevrons = (): JSX.Element => {
        switch (this.props.sortOrder) {
            case OrderDirection.ASC:
                return <SVGChevronUp focusable="false" />;
            case OrderDirection.DESC:
                return <SVGChevronDown focusable="false" />;
            case undefined:
            default:
                return <span />;
        }
    };

    public render(): React.ReactElement<any> {
        const { column, isSortable } = this.props;
        return (
            <th className={`th--sticky${isSortable ? " sortable" : ""}`} scope="col" onClick={this.onHeaderClick(column)}>
                <HeaderWrapper>
                    {column.name} {this.renderChevrons()}
                </HeaderWrapper>
            </th>
        );
    }
}

export { ColumnHeader };

const HeaderWrapper = styled.span`
    display: flex;
    align-items: center;

    > svg {
        margin-left: 5px;
    }
`;
