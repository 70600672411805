import React, { Component } from "react";
import { ContentInput, ContentTypeListPath, ContentTypePagePath } from "api/ApiTypes";
import { Main } from "components/cms/MainElements";
import { PageType } from "utils/TypeUtils";
import { Intl } from "i18n/Intl";
import { Field, FieldProps, FormikProps } from "formik";
import { Button } from "components/Button";
import { Log } from "utils/Log";
import { SVGContentGallery, SVGContentText, SVGContentVideo, SVGIconWarning } from "components/SVGCollection";
import { TableFormWrapper, TableWrapper } from "components/TableElements";
import { InnerWrapperIconWrapper, InputWrapper, InputWrapperErrorWrapper } from "components/Inputs/InputWrapper";
import { Input } from "components/Inputs/Input";
import { DateInput } from "components/Inputs/DateInput";
import slugify from "slugify";
import { InputCDN } from "components/cms/InputCDN";
import { CaptionText } from "theme/global";
import { Select, SelectOption } from "components/Inputs/Select";
import CKEditor from "@ckeditor/ckeditor5-react";
import { WithContext as ReactTags } from "react-tag-input";
import { isNil, isEqual, debounce } from "lodash";
import styled from "styled-components";
import { Validator, ValidatorConstants } from "utils/Validator";
import { Category, Decade, Round, Season, Team, Gallery, Gallery_images, AnyContentListItem } from "api/graphql/types";
import { ContentPageExtraInfo } from "pages/ContentPage/ContentPage";
import { Api } from "../../api/Api";
import { Alert } from "../../components/cms/Alert/Alert";
import { IntlHelpers } from "../../i18n/IntlHelpers";
import { Env } from "../../utils/Env";
import { ContentModal } from "../common/ContentModal";
import { GalleryModal } from "../common/GalleryModal";
import { CKEditorDefaultConfig } from "utils/Constants";

export type ContentGalleryImage = {
    url: string;
    caption: string;
    credit: string;
    dataAssetURL: string;
};

type Props<T> = {
    contentType: string;
    extraInfo: ContentPageExtraInfo;
    pageType: PageType;
    formProps: FormikProps<T>;
    categories: Category[];
    decades: Decade[];
    seasons: Season[];
    selectedTeams?: Team[];
    onAdminPasswordReset?: (email: string) => Promise<void>;
};

interface Tag {
    id: string;
    name: string;
}

interface State {
    roundSuggestions: Tag[];
    teamSuggestions: Tag[];
    videoURLError: string | null;
    showGalleryModal: boolean;
    contentGalleryModal: {
        show: boolean;
        callback?: (gallery: Gallery) => void;
    };
    contentModal: {
        show: boolean;
        callback?: (content: AnyContentListItem[]) => void;
    };
}

// @ts-ignore
const CustomEditor = ClassicEditor;

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const tagDelimiters = [KeyCodes.comma, KeyCodes.enter];

class ContentFormComponent extends Component<Props<ContentInput>, State> {
    public state: State = {
        roundSuggestions: [],
        teamSuggestions: [],
        videoURLError: null,
        showGalleryModal: false,
        contentGalleryModal: {
            show: false,
        },
        contentModal: {
            show: false,
        },
    };

    public componentDidMount = () => {
        /**
         *
         * TODO dummy functions for testing
         */

        // @ts-ignore
        window.galleryCallback = (addedImages, callBack: (urls: ContentGalleryImage[]) => void) => {
            this.toggleContentGalleryModal((gallery: Gallery) => {
                callBack(
                    gallery.images.map(
                        (image: Gallery_images): ContentGalleryImage => {
                            return {
                                url: image.url,
                                dataAssetURL: `${process.env.REACT_APP_MEDIA_LIBRARY_ASSET_URL}/${image.url}`,
                                caption: image.caption || "",
                                credit: image.credit || "",
                            };
                        },
                    ),
                );
            });
        };

        // @ts-ignore
        window.embeddedPostCallback = (addedEmbeddedPost, callBack: (embeddedPost: EmbeddedPost) => void) => {
            this.toggleContentModal((contents: AnyContentListItem[]) => {
                callBack({
                    id: contents[0].id,
                    title: contents[0].title,
                    url: contents[0].url,
                });
            });
        };

        // @ts-ignore
        window.cdnImageCallback = (callBack: (url: string) => void) => {
            setTimeout(() => callBack("https://homepages.cae.wisc.edu/~ece533/images/fruits.png"), 1000);
        };
        // @ts-ignore
        window.infoImageCallback = (callBack: (image: { fileName: string; dataAssetURL: string }) => void) => {
            window.bfml.openLibrary({
                embedAuthToken: "auth",
                maximumSelectableAsset: 1,
                onSelection: (assets: string[]) => {
                    if (assets.length > 0) {
                        callBack({
                            fileName: assets[0],
                            dataAssetURL: `${process.env.REACT_APP_MEDIA_LIBRARY_ASSET_URL}/${assets[0]}`,
                        });
                    }
                },
            });
        };

        this.getSeasonSuggestions();
        this.setSelectedTeams();
        this.fetchTeams();
    };

    private readonly fetchTeams = async (search?: string): Promise<void> => {
        try {
            const teams: Team[] = (
                await Api.listTeams({
                    count: 50,
                    filters: {
                        name: search,
                    },
                })
            ).data;
            this.setState({
                teamSuggestions: [
                    ...this.state.teamSuggestions,
                    ...teams
                        .filter(team => !this.state.teamSuggestions.map(item => item.id).includes(team.id))
                        .map(
                            (team: Team): Tag => {
                                return {
                                    id: team.id,
                                    name: team.name,
                                };
                            },
                        ),
                ],
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private readonly onFetchChange = debounce((value: string) => {
        if (value.length >= 3) {
            this.fetchTeams(value);
        }
    }, 1000);

    public componentDidUpdate(prevProps: Readonly<Props<ContentInput>>): void {
        if (!isEqual(prevProps, this.props)) {
            this.getSeasonSuggestions();
        }
    }

    private readonly getTypeIcon = (typename: string): React.ReactElement => {
        switch (typename) {
            case ContentTypePagePath.text:
                return <SVGContentText width={40} height={40} focusable="false" />;
            case ContentTypePagePath.gallery:
                return <SVGContentGallery width={40} height={40} focusable="false" />;
            case ContentTypePagePath.video:
                return <SVGContentVideo width={40} height={40} focusable="false" />;
            default:
                Log.warning(`Wrong typename ${typename}`);
                return <></>;
        }
    };

    private readonly getSeasonSuggestions = (): void => {
        const suggestions: Tag[] = [];
        this.props.seasons.forEach((season: Season) => {
            season.rounds.forEach((round: Round) => {
                suggestions.push({
                    id: round.id,
                    name: Intl.formatMessage({ id: "pages.contents.page.seasonSuggestionName" }, { season: season.name, round: round.number }),
                });
            });
        });
        this.setState({
            roundSuggestions: suggestions,
        });
    };

    private readonly setSelectedTeams = (): void => {
        const { selectedTeams } = this.props;
        if (!selectedTeams) {
            return;
        }
        this.setState({
            teamSuggestions: [
                ...this.state.teamSuggestions,
                ...selectedTeams.map(team => {
                    return {
                        id: team.id,
                        name: team.name,
                    };
                }),
            ],
        });
    };

    private readonly getRoundTagsByIds = (ids?: string[]): Tag[] => {
        if (!ids) {
            return [];
        }

        const { roundSuggestions } = this.state;

        return roundSuggestions.filter((suggestion: Tag) => ids.includes(suggestion.id));
    };

    private readonly getTeamTagsByIds = (ids?: string[]): Tag[] => {
        if (!ids) {
            return [];
        }

        const { teamSuggestions } = this.state;

        return teamSuggestions.filter((suggestion: Tag) => ids.includes(suggestion.id));
    };

    private readonly getTeamSuggestions = (selectedIds?: string[]): Tag[] => {
        return this.state.teamSuggestions.filter((suggestion: Tag) => !selectedIds?.includes(suggestion.id));
    };

    private onVideoURLChange = (form: FormikProps<any>, value: string) => {
        form.setFieldValue("video_url", value);
        this.setState({ videoURLError: IntlHelpers.getValidationError(Validator.validateYouTubeURL(value)) });
    };

    private readonly renderFieldByType = (): React.ReactElement => {
        switch (this.props.contentType) {
            case ContentTypeListPath.text:
                return (
                    <Field name="lead_image">
                        {({ field, form, meta }: FieldProps) => (
                            <InputCDN
                                browseButton={<Button onClick={() => this.onPageImageSelect("lead_image")} btnLabel={Intl.formatMessage({ id: "common.upload" })} />}
                                showRemove={!!field.value && field.value !== ""}
                                onRemove={() => {
                                    form.setFieldValue("lead_image", "", true);
                                }}
                                hideShowButton={!field.value}
                                previewURL={`${Env.mediaLibraryAssetUrl}/${field.value}`}
                            >
                                <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.contents.page.lead_image.title" })} errorMsg={meta.error}>
                                    <Input
                                        type="text"
                                        readOnly={true}
                                        ariaLabel={Intl.formatMessage({
                                            id: "pages.contents.page.lead_image.title",
                                        })}
                                        {...field}
                                    />
                                </InputWrapper>
                            </InputCDN>
                        )}
                    </Field>
                );
            case ContentTypeListPath.gallery:
                return (
                    <Field name="gallery_id">
                        {({ field, form, meta }: FieldProps) => (
                            <>
                                <InputCDN
                                    browseButton={<Button onClick={() => this.setState({ showGalleryModal: true })} btnLabel={Intl.formatMessage({ id: "common.upload" })} />}
                                    showRemove={!!field.value && field.value !== ""}
                                    onRemove={() => {
                                        form.setFieldValue("gallery_id", "", true);
                                    }}
                                    hideShowButton={true}
                                >
                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.contents.page.gallery_id.title" })} errorMsg={meta.error}>
                                        <Input
                                            type="text"
                                            readOnly={true}
                                            ariaLabel={Intl.formatMessage({
                                                id: "pages.contents.page.gallery_id.title",
                                            })}
                                            {...field}
                                        />
                                    </InputWrapper>
                                </InputCDN>
                                <GalleryModal
                                    mounted={this.state.showGalleryModal}
                                    onGallerySelected={(gallery: Gallery) => form.setFieldValue("gallery_id", gallery.id)}
                                    onModalClose={() => this.setState({ showGalleryModal: false })}
                                    selectedGallery={field.value}
                                />
                            </>
                        )}
                    </Field>
                );
            case ContentTypeListPath.video:
                return (
                    <Field name="video_url">
                        {({ field, form, meta }: FieldProps) => (
                            <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.contents.page.video_url.title" })} errorMsg={this.state.videoURLError || meta.error}>
                                <Input
                                    hasError={!!this.state.videoURLError}
                                    type="text"
                                    ariaLabel={Intl.formatMessage({ id: "pages.contents.page.video_url.title" })}
                                    {...field}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.onVideoURLChange(form, e.currentTarget.value);
                                    }}
                                />
                            </InputWrapper>
                        )}
                    </Field>
                );
            default:
                Log.warning("Wrong type");
                return <></>;
        }
    };

    private readonly onSaveClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, isPublished: boolean): void => {
        event.preventDefault();
        if (isPublished) {
            this.props.formProps.setFieldValue("is_active", true);
        }
        this.props.formProps.handleSubmit();
    };

    private readonly renderError = (error?: string): React.ReactElement => {
        return !isNil(error) ? (
            <>
                <InnerWrapperIconWrapper aria-hidden="true">
                    <SVGIconWarning focusable="false" />
                </InnerWrapperIconWrapper>
                <InputWrapperErrorWrapper as="div" aria-live="assertive" aria-atomic="true">
                    {error}
                </InputWrapperErrorWrapper>
            </>
        ) : (
            <></>
        );
    };

    private readonly onPageImageSelect = (field: string): void => {
        window.bfml.openLibrary({
            embedAuthToken: "auth",
            maximumSelectableAsset: 1,
            onSelection: (assets: string[]) => {
                this.props.formProps.setFieldValue(field, assets[0], true);
            },
        });
    };

    private readonly toggleContentGalleryModal = (callback?: (gallery: Gallery) => void): void => {
        this.setState({
            contentGalleryModal: {
                show: !this.state.contentGalleryModal.show,
                callback,
            },
        });
    };

    private readonly toggleContentModal = (callback?: (content: AnyContentListItem[]) => void): void => {
        this.setState({
            contentModal: {
                show: !this.state.contentModal.show,
                callback,
            },
        });
    };

    public render(): React.ReactElement {
        return (
            <form onSubmit={this.props.formProps.handleSubmit} noValidate>
                <Main.Heading
                    headingIcon={this.getTypeIcon(this.props.contentType)}
                    headingText={Intl.formatMessage({ id: `pages.contents.page.${this.props.pageType}.title.${this.props.contentType}` })}
                >
                    <div className="grid-x align-middle grid-margin-x-15">
                        <div className="cell auto">
                            <Button
                                btnLabel={Intl.formatMessage({ id: "common.save" })}
                                secondary
                                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.onSaveClick(event, false)}
                            />
                        </div>
                        <div className="cell auto">
                            <Button btnLabel={Intl.formatMessage({ id: "common.publish" })} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.onSaveClick(event, true)} />
                        </div>
                    </div>
                </Main.Heading>
                <TableWrapper>
                    {this.props.pageType === PageType.edit && (
                        <div className="grid-x">
                            <div className="cell">
                                <TopInfo>
                                    <span>{Intl.formatMessage({ id: "pages.contents.page.topInfo.id" }, { id: this.props.extraInfo.id })}</span>
                                    <span>{Intl.formatMessage({ id: "pages.contents.page.topInfo.updatedAt" }, { updatedAt: new Date(this.props.extraInfo.updatedAt).toLocaleString("hu") })}</span>
                                    <span>
                                        {Intl.formatMessage(
                                            { id: "pages.contents.page.topInfo.publishedAt" },
                                            { publishedAt: this.props.extraInfo.lastPublishedAt && new Date(this.props.extraInfo.lastPublishedAt).toLocaleString("hu") },
                                        )}
                                    </span>
                                </TopInfo>
                            </div>
                        </div>
                    )}
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6 mt-35">
                            <Field name="url">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.contents.page.url.title" })} errorMsg={meta.error}>
                                        <Input
                                            type="text"
                                            ariaLabel={Intl.formatMessage({ id: "pages.contents.page.url.title" })}
                                            {...field}
                                            readOnly={this.props.pageType === PageType.edit}
                                            isBackgroundBlue={this.props.pageType === PageType.edit}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                        <div className="cell auto date-input-max-width">
                            <CaptionText as="h2" thStyle={true} className="mt-0">
                                {Intl.formatMessage({ id: "pages.contents.page.active_from.title" })}
                            </CaptionText>

                            <div className="grid-x">
                                <div className="cell auto">
                                    <Field name="active_from">
                                        {({ field, form, meta }: FieldProps) => (
                                            <>
                                                <DateInput
                                                    {...field}
                                                    onChange={(date: Date | null) => {
                                                        form.setFieldValue(field.name, date);
                                                    }}
                                                    showClearButton
                                                />
                                                {this.renderError(meta.error)}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </div>
                        <div className="cell auto date-input-max-width">
                            <CaptionText as="h2" thStyle={true} className="mt-0">
                                {Intl.formatMessage({ id: "pages.contents.page.active_to.title" })}
                            </CaptionText>

                            <div className="grid-x">
                                <div className="cell auto">
                                    <Field name="active_to">
                                        {({ field, form, meta }: FieldProps) => (
                                            <>
                                                <DateInput
                                                    {...field}
                                                    onChange={(date: Date | null) => {
                                                        form.setFieldValue(field.name, date);
                                                    }}
                                                    showClearButton
                                                />
                                                {this.renderError(meta.error)}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6">
                            <Field name="title">
                                {({ field, form, meta }: FieldProps) => (
                                    <InputWrapper
                                        fakeLabel={Intl.formatMessage({ id: "pages.contents.page.title.title" }, { maxCharacters: ValidatorConstants.MAX_CONTENT_TITLE_LENGTH })}
                                        errorMsg={meta.error}
                                    >
                                        <Input
                                            type="text"
                                            ariaLabel={Intl.formatMessage({ id: "pages.contents.page.title.title" }, { maxCharacters: ValidatorConstants.MAX_CONTENT_TITLE_LENGTH })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                form.setFieldValue("title", e.currentTarget.value);
                                                // Editing the URL field is not permitted in edit mode
                                                if (this.props.pageType === PageType.create) {
                                                    form.setFieldValue("url", slugify(e.currentTarget.value.toLowerCase(), { lower: true }));
                                                }
                                            }}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                        <div className="cell small-6 auto">{this.renderFieldByType()}</div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6">
                            <Field name="front_page_title">
                                {({ field, form, meta }: FieldProps) => (
                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.contents.page.front_page_title.title" })} errorMsg={meta.error}>
                                        <Input
                                            type="text"
                                            ariaLabel={Intl.formatMessage({ id: "pages.contents.page.front_page_title.title" })}
                                            {...field}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => form.setFieldValue("front_page_title", e.currentTarget.value)}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                        <div className="cell small-6 auto">
                            <Field name="front_page_image">
                                {({ field, form, meta }: FieldProps) => (
                                    <InputCDN
                                        browseButton={<Button onClick={() => this.onPageImageSelect("front_page_image")} btnLabel={Intl.formatMessage({ id: "common.upload" })} />}
                                        showRemove={!!field.value && field.value !== ""}
                                        onRemove={() => {
                                            form.setFieldValue("front_page_image", "", true);
                                        }}
                                        hideShowButton={!field.value}
                                        previewURL={`${Env.mediaLibraryAssetUrl}/${field.value}`}
                                    >
                                        <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.contents.page.front_page_image.title" })} errorMsg={meta.error}>
                                            <Input
                                                type="text"
                                                readOnly={true}
                                                ariaLabel={Intl.formatMessage({
                                                    id: "pages.contents.page.front_page_image.title",
                                                })}
                                                {...field}
                                            />
                                        </InputWrapper>
                                    </InputCDN>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell auto">
                            <Field name="front_page_lead">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper
                                        fakeLabel={Intl.formatMessage({ id: "pages.contents.page.front_page_lead.title" }, { maxCharacters: ValidatorConstants.MAX_CONTENT_FRONT_PAGE_LEAD_LENGTH })}
                                        errorMsg={meta.error}
                                    >
                                        <Input
                                            type="text"
                                            ariaLabel={Intl.formatMessage(
                                                { id: "pages.contents.page.front_page_lead.title" },
                                                { maxCharacters: ValidatorConstants.MAX_CONTENT_FRONT_PAGE_LEAD_LENGTH },
                                            )}
                                            {...field}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell auto">
                            <Field name="lead">
                                {({ meta }: FieldProps) => (
                                    <>
                                        <CaptionText as="h2" thStyle={true} className="mt-0">
                                            {Intl.formatMessage({ id: "pages.contents.page.lead.title" })}
                                        </CaptionText>
                                        <InputWrapper hideFakeLabel fakeLabel={Intl.formatMessage({ id: "pages.contents.page.lead.title" })} errorMsg={meta.error}>
                                            <LeadWrapper>
                                                <CKEditor
                                                    editor={CustomEditor}
                                                    config={{
                                                        toolbar: {
                                                            items: ["bulletedList"],
                                                        },
                                                    }}
                                                    data={this.props.formProps.values.lead}
                                                    onChange={(_event: any, editor: any) => {
                                                        this.props.formProps.setFieldValue("lead", editor.getData());
                                                    }}
                                                />
                                            </LeadWrapper>
                                        </InputWrapper>
                                    </>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell auto">
                            <Field name="content">
                                {({ meta }: FieldProps) => (
                                    <ContentFieldWrapper>
                                        <CaptionText as="h2" thStyle={true} className="mt-0">
                                            {Intl.formatMessage({ id: "pages.contents.page.content.title" })}
                                        </CaptionText>
                                        <InputWrapper hideFakeLabel fakeLabel={Intl.formatMessage({ id: "pages.contents.page.content.title" })} errorMsg={meta.error}>
                                            <CKEditor
                                                editor={CustomEditor}
                                                config={CKEditorDefaultConfig}
                                                data={this.props.formProps.values.content}
                                                onChange={(_event: any, editor: any) => {
                                                    // Uncomment this line to see CKEditor's content
                                                    // console.log(editor.getData());
                                                    this.props.formProps.setFieldValue("content", editor.getData());
                                                }}
                                            />
                                        </InputWrapper>
                                    </ContentFieldWrapper>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6">
                            <Field name="category_id">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.contents.page.category.title" })} hideFakeLabel={true} errorMsg={meta.error}>
                                        <Select
                                            fakeLabel={Intl.formatMessage({ id: "pages.contents.page.category.title" })}
                                            {...field}
                                            options={[
                                                {
                                                    title: Intl.formatMessage({ id: "pages.contents.page.category.placeholder" }),
                                                    value: undefined,
                                                },
                                                ...this.props.categories.map(
                                                    (category: Category): SelectOption => {
                                                        return {
                                                            title: category.title,
                                                            value: category.id,
                                                        };
                                                    },
                                                ),
                                            ]}
                                            errorMsg={meta.error}
                                        />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>

                        <div className="cell small-6 auto">
                            <Field name="teams">
                                {({ field }: FieldProps) => (
                                    <ReactTags
                                        tags={this.getTeamTagsByIds(field.value?.sync || []) as any}
                                        suggestions={this.getTeamSuggestions(field.value?.sync) as any}
                                        handleAddition={tag => {
                                            const value = field.value.sync || [];
                                            value.push(tag.id);
                                            this.props.formProps.setFieldValue("teams", { sync: value });
                                        }}
                                        handleDelete={index => {
                                            this.props.formProps.setFieldValue("teams", {
                                                sync: field.value.sync.filter((_tag: Tag, key: number) => key !== index),
                                            });
                                        }}
                                        handleInputChange={this.onFetchChange}
                                        placeholder={Intl.formatMessage({ id: "pages.contents.page.team.title" })}
                                        labelField="name"
                                        allowDragDrop={false}
                                        delimiters={tagDelimiters}
                                        autofocus={false}
                                    />
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6">
                            <Field name="decade_id">
                                {({ field, meta }: FieldProps) => (
                                    <Select
                                        fakeLabel={Intl.formatMessage({ id: "pages.contents.page.decade.title" })}
                                        {...field}
                                        options={[
                                            {
                                                title: Intl.formatMessage({ id: "pages.contents.page.decade.placeholder" }),
                                                value: undefined,
                                            },
                                            ...this.props.decades.map(
                                                (decade: Decade): SelectOption => {
                                                    return {
                                                        title: decade.title || decade.years,
                                                        value: decade.id,
                                                    };
                                                },
                                            ),
                                        ]}
                                        errorMsg={meta.error}
                                    />
                                )}
                            </Field>
                        </div>

                        <div className="cell small-6 auto">
                            <Field name="rounds">
                                {({ field }: FieldProps) => (
                                    <ReactTags
                                        tags={this.getRoundTagsByIds(field.value?.sync || []) as any}
                                        suggestions={this.state.roundSuggestions as any}
                                        handleAddition={tag => {
                                            const value = field.value.sync || [];
                                            value.push(tag.id);
                                            this.props.formProps.setFieldValue("rounds", { sync: value });
                                        }}
                                        handleDelete={index => {
                                            this.props.formProps.setFieldValue("rounds", {
                                                sync: field.value.sync.filter((_tag: Tag, key: number) => key !== index),
                                            });
                                        }}
                                        placeholder={Intl.formatMessage({ id: "pages.contents.page.season.title" })}
                                        labelField="name"
                                        allowUnique={true}
                                        allowDragDrop={false}
                                        delimiters={tagDelimiters}
                                        autofocus={false}
                                    />
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x-15">
                        <div className="cell small-6">
                            <Field name="author">
                                {({ field, meta }: FieldProps) => (
                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.contents.page.author.title" })} errorMsg={meta.error}>
                                        <Input type="text" ariaLabel={Intl.formatMessage({ id: "pages.contents.page.author.title" })} {...field} />
                                    </InputWrapper>
                                )}
                            </Field>
                        </div>
                    </div>
                    <TableFormWrapper>
                        <table>
                            <thead>
                                <tr>
                                    <th>{Intl.formatMessage({ id: "pages.contents.page.metaTable.headers.name" })}</th>

                                    <th>{Intl.formatMessage({ id: "pages.contents.page.metaTable.headers.value" })}</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td className="w-190">{Intl.formatMessage({ id: "pages.contents.page.metaTable.meta_title.title" })}</td>

                                    <td>
                                        <Field name="meta_title">
                                            {({ field, meta, form }: FieldProps) => (
                                                <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.contents.page.metaTable.meta_title.title" })} errorMsg={meta.error}>
                                                    <Input
                                                        type="text"
                                                        ariaLabel={Intl.formatMessage({ id: "pages.contents.page.metaTable.meta_title.title" })}
                                                        {...field}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            form.setFieldValue("meta_title", e.currentTarget.value);
                                                        }}
                                                    />
                                                </InputWrapper>
                                            )}
                                        </Field>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="w-190">{Intl.formatMessage({ id: "pages.contents.page.metaTable.meta_description.title" })}</td>

                                    <td>
                                        <Field name="meta_description">
                                            {({ field, meta, form }: FieldProps) => (
                                                <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.contents.page.metaTable.meta_description.title" })} errorMsg={meta.error}>
                                                    <Input
                                                        type="text"
                                                        ariaLabel={Intl.formatMessage({ id: "pages.contents.page.metaTable.meta_description.title" })}
                                                        {...field}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            form.setFieldValue("meta_description", e.currentTarget.value);
                                                        }}
                                                    />
                                                </InputWrapper>
                                            )}
                                        </Field>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="w-190">{Intl.formatMessage({ id: "pages.contents.page.metaTable.meta_image.title" })}</td>

                                    <td>
                                        <Field name="meta_image">
                                            {({ field, form, meta }: FieldProps) => (
                                                <InputCDN
                                                    browseButton={<Button onClick={() => this.onPageImageSelect("meta_image")} btnLabel={Intl.formatMessage({ id: "common.browse" })} />}
                                                    showRemove={!!field.value && field.value !== ""}
                                                    onRemove={() => {
                                                        form.setFieldValue("meta_image", "", true);
                                                    }}
                                                    hideShowButton={!field.value}
                                                    previewURL={`${Env.mediaLibraryAssetUrl}/${field.value}`}
                                                    showButtonClassName="h-40"
                                                >
                                                    <InputWrapper fakeLabel={Intl.formatMessage({ id: "pages.contents.page.metaTable.meta_image.title" })} errorMsg={meta.error}>
                                                        <Input type="text" readOnly={true} ariaLabel={Intl.formatMessage({ id: "pages.contents.page.metaTable.meta_image.title" })} {...field} />
                                                    </InputWrapper>
                                                </InputCDN>
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </TableFormWrapper>
                    {this.state.contentGalleryModal.show && this.state.contentGalleryModal.callback && (
                        <GalleryModal mounted={true} onGallerySelected={this.state.contentGalleryModal.callback} onModalClose={this.toggleContentGalleryModal} />
                    )}
                    {this.state.contentModal.show && this.state.contentModal.callback && (
                        <ContentModal mounted={true} onContentSelected={this.state.contentModal.callback} onModalClose={this.toggleContentModal} single={true} />
                    )}
                </TableWrapper>
            </form>
        );
    }
}

// export const FaqForm = withRouter(FaqFormComponent);
export const ContentForm = ContentFormComponent;

export const ContentFieldWrapper = styled.div`
    .ck-bf-info-image {
        display: flex;
        flex-direction: column;
        padding: 5px;
    }

    .ck-bf-gallery-images-container,
    .ck-bf-embedded-post-container {
        padding: 5px;
    }

    .ck-bf-info-image-description,
    .ck-bf-info-image-author,
    .ck-bf-info-image-source {
        padding: 5px;
        font-size: 14px;
    }
`;

export const LeadWrapper = styled.div`
    .ck.ck-editor__editable_inline {
        min-height: 80px !important;
        max-height: 80px !important;
    }
`;

export const TopInfo = styled.div`
    span {
        margin-right: 25px;
    }
`;
